import { headers } from "../../../../App/Axios"
import HTTPService from "../../../../App/HTTPService"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { getPhotoSizeErrorMessage } from "../../../Shared/SharedSlices/RoomSlice"
import { getEventDescriptionErrorState, getEventStatus, setSponsorCardData } from "../../../Shared/SharedSlices/SponsorCardSlice"

const getRequest = (organizationId: number) => {
    return HTTPService.get(`/organization/${organizationId}/events/`, headers)
}

const postRequest = (organizationId: number, data: FormData) => {
    return HTTPService.post(`/organization/${organizationId}/events/create/`, data, headers)
}
const patchRequest = (organizationId: number, eventId: number, data: object) => {
    return HTTPService.patch(`/organization/${organizationId}/event/${eventId}/`, data, headers)
}

const deleteRequest = (organizationId: number, eventId: number) => {
    return HTTPService.delete(`/organization/${organizationId}/event/${eventId}/`, headers)
}

export const postEventRequest = (organizationId: number, formData: FormData) => async (dispatch: any) => {
    try {
        const response = await postRequest(organizationId, formData)
        if (response.status === 201) {
            try {
                const response = await getRequest(organizationId)

                if (response.status === 200) {
                    dispatch(setSponsorCardData(response.data.events))
                    dispatch(setModalData({ open: false, addEvent: false }))
                    dispatch(getPhotoSizeErrorMessage(false))
                    dispatch(getEventDescriptionErrorState(""))
                }
            } catch (error: any) {
                console.log(error)
            }
        }
    } catch (error: any) {
        if (error.response.status === 413) {
            dispatch(getPhotoSizeErrorMessage(true))
        }

        if(error.response.status === 400){
            dispatch(getEventDescriptionErrorState(error.response.description))
        }
        console.log(error)
    }
}

export const patchEventRequest = (eventId: number, organizationId: number, formData: FormData) => async (dispatch: any) => {
    try {
        const response = await patchRequest(organizationId, eventId, formData)
        if (response.status === 200) {
            try {
                const response = await getRequest(organizationId)

                if (response.status === 200) {
                    dispatch(setSponsorCardData(response.data.events))
                    dispatch(setModalData({ open: false, editEvent: false }))
                    dispatch(getPhotoSizeErrorMessage(false))
                    dispatch(getEventStatus(true))
                    dispatch(getEventDescriptionErrorState(""))
                }
            } catch (error: any) {
                console.log(error)
            }
        }
    } catch (error: any) {
        if (error.response.status === 413) {
            dispatch(getPhotoSizeErrorMessage(true))
        }

        if(error.response.status === 400){
            dispatch(getEventDescriptionErrorState(error.response.data.description[0]!))
        }
        console.log(error)
    }
}

export const deleteEventRequest = (organizationId: number, eventId: number) => async (dispatch: any) => {
    try {
        const response = await deleteRequest(organizationId, eventId)
        if (response.status === 204) {
            try {
                const response = await getRequest(organizationId)

                if (response.status === 200) {
                    dispatch(setModalData({ open: false, editEvent: false }))
                    dispatch(setSponsorCardData(response.data.events))
                }
            } catch (error: any) {
                console.log(error)
            }
        }
    } catch (error: any) {
        console.log(error)
    }
}
