import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import ModalGroup from "../../Shared/Components/ModalGroup"
import { BackIcon } from "../../Shared/Components/SvgIcons"
import Header from "../../Shared/Header/Header"
import Footer from "../../Shared/Layout/Footer"
import RoomSinglePostExpanded from "../RoomPost/RoomSinglePostExpanded"
import RoomHeader from "../Components/RoomHeader"
import { useNavigate, useParams } from "react-router-dom"
import { setRoomData, setRoomManagerData } from "../../Shared/SharedSlices/RoomSlice"
import { RootState } from "../../../App/store"
import { getRoomRequest } from "../RoomServiceRequests/RoomServiceRequests"
import { JoinStatus, PostType, RoomType } from "../../../App/enums"
import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { setIsMember, setPendingType } from "../RoomSlices/MemberInRoomStatusSlice"
import { capitalizeFirstLetter } from "../../../App/Helpers/Helpers"
import BackgroundWrapper from "../../Shared/Components/BackgroundWrapper"
import RoomTextArea from "../Components/RoomTextArea"
import RoomInformation from "./RoomInformation"
import { onBackToRoomNavigation } from "../RoomPost/onNavigation"

function RoomScreen() {
    const translation = useSelector(selectTranslations)
    const { roomId, id } = useParams()
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const dispatch = useDispatch()
    const allow_requests = roomDataState?.room_permissions && roomDataState?.room_permissions[0]?.allow_requests!
    const invite_others = roomDataState?.room_permissions && roomDataState?.room_permissions[0]?.invite_others!
    const share_documents = roomDataState?.room_permissions && roomDataState?.room_permissions[0]?.share_documents!
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const isMemberInRoom = useSelector((state: RootState) => state.MemberInRoomStatusSlice.MemberInRoomStatusSlice.isMember)
    const canSeePosts = roomDataState.type === RoomType.Private ? isMemberInRoom === JoinStatus.MEMBER : true
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const navigate = useNavigate()
    const isRoomManager = useSelector((state: RootState) => state.roomSlice.roomManagerSlice.room_manager)

    useEffect(() => {
        let isMounted = true
        getRoomRequest(parseInt(roomId!))
            .then(response => {
                if (isMounted && response.status === 200) {
                    dispatch(setRoomData(response.data))
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [
        dispatch,
        roomId,
        roomDataState?.name,
        roomDataState?.photo,
        roomDataState?.type,
        allow_requests,
        invite_others,
        share_documents,
        roomDataState?.description,
    ])

    const getUserStatus = useCallback(() => {
        return HTTPService.get(`/user-status-in-room/${roomId}/`, headers)
    }, [roomId])

    useEffect(() => {
        getUserStatus().then(response => {
            if (response.status === 200) {
                dispatch(setIsMember(response.data.status))
                dispatch(setPendingType({ type: response.data.type, notification_id: response.data.notification_id }))
            }
        })
    }, [dispatch, getUserStatus])

    useEffect(() => {
        dispatch(setRoomManagerData(roomDataState?.room_managers?.some(manager => manager.id === userDataState.id)!))
    }, [dispatch, roomDataState?.room_managers, roomId, userDataState.id])

    return (
        <BackgroundWrapper>
            <Header roomHeader={true} />
            <div className="room-screen">
                <div className="room-layout">
                    <RoomInformation isMemberInRoom={isMemberInRoom} hasPrivelegedPermission={isRoomManager} />
                    <div className="room-layout__right-panel">
                        <div
                            className={`room-layout__right-panel-header ${
                                (isMemberInRoom === JoinStatus.NON_MEMBER || JoinStatus.PENDING) && "room-layout__right-panel-header--join-button"
                            }`}
                        >
                            <RoomHeader
                                roomType={capitalizeFirstLetter(roomDataState?.type! === RoomType.Private ? translation.private : translation.public)}
                            />
                        </div>
                        <div className="room-layout__right-panel-content">
                            {roomDataState.type && !canSeePosts ? (
                                <div className="room-layout__no-access">{translation.cantSeeRoomContent}</div>
                            ) : (
                                <>
                                    <div className="room-layout__back-icon-container" onClick={() => onBackToRoomNavigation(role, roomId!, id!, navigate)}>
                                        <div className="room-layout__back-icon">
                                            <BackIcon />
                                        </div>
                                        <div className="room-layout__text">{translation.allPosts}</div>
                                    </div>
                                    <RoomSinglePostExpanded />
                                </>
                            )}
                        </div>
                        {roomDataState.is_active && (
                            <RoomTextArea
                            hasPrivelegedPermission={isRoomManager}
                            isMemberInRoom={isMemberInRoom}
                            share_documents={share_documents}
                            type={PostType.comment}
                        />
                        )}
                    </div>
                </div>
            </div>
            <Footer roomfooter={true} />
            <ModalGroup />
        </BackgroundWrapper>
    )
}

export default RoomScreen
