import { headers } from "../../../../App/Axios"
import HTTPService from "../../../../App/HTTPService"
import {setRoomsSearchData } from "./OrganizationRoomsSearchSlice"

export const onOrganizationRoomsSearch = (searchQuery: string, organizationId: string) => async (dispatch: Function) => {
    try {
        const response = await HTTPService.get(`/organization/${organizationId}/rooms/?query=${searchQuery}`, headers)
            dispatch(setRoomsSearchData({joined_rooms:response.data.joined_rooms, owned_rooms:response.data.owned_rooms}))
    } catch (error: any) {
        console.log(error)
    }
}
