import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ConversationList, Conversation, Chat } from "../../Types/Conversation"
import { act } from "@testing-library/react"

const initialState:  Chat = {activeConversationId: 0,conversations: [], hasUnseenMessages: false,hasMoreConversations: false}

const ChatSlice = createSlice({
    name: "ChatListSlice",
    initialState: initialState,
    reducers: {
        setConversationList(state, action: PayloadAction<Array<Conversation>>) {
           let data = action.payload
           state.conversations = data
        },
        setActiveConversation(state, action: PayloadAction<number>) {
            let id = action.payload
            state.activeConversationId = id
         },
        setHasUnseenMessages(state, action: PayloadAction<boolean>) {
            state.hasUnseenMessages = action.payload
         },
        setHasMoreConversations(state, action: PayloadAction<boolean>) {
            state.hasMoreConversations = action.payload
         }
    }
})

export const { setConversationList,setActiveConversation,setHasUnseenMessages,setHasMoreConversations } = ChatSlice.actions
export default ChatSlice.reducer