import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import StorageService from "../../../App/StorageService"
import { RoleType, StorageKey } from "../../../App/enums"
import SessionService from "../../../App/SessionService"

export function logOut() {
    let refreshToken = StorageService.getItem(StorageKey.refreshToken) as string

    HTTPService.post("/logout/", { refresh: refreshToken }, headers)
        .then(response => {
            if (response.status === 205) {
                SessionService.setItem(StorageKey.roles, RoleType.USER)
                StorageService.clear()
                window.location.reload()
            }
        })
        .catch(error => {
            console.log(error)
        })
}
