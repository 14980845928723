import React, { MouseEventHandler, useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import "../../../Styles/main.scss"
import { CloseIcon, CTALogoIcon, FileIcon, LoadingAnimation, MediumLogoIcon, SendIcon, SmileIcon, SmileyFace } from "./SvgIcons"
import { acceptedFileTypes, fileImageType, organizationProfileDefault, profileDefault, roomDefaultPhoto } from "../../../App/GlobaleVariables"
import { RootState } from "../../../App/store"
import { useNavigate, useParams } from "react-router-dom"
import { ConvertStringToHTML, capitalizeFirstLetter, formatDate } from "../../../App/Helpers/Helpers"
import { CallerComponent, CommentType, EditorModifier, PostType, RoleType, RoomType } from "../../../App/enums"
import MessageBody from "./MessageBody"
import Image from "../../Shared/Primitive/Image"
import { setModalData } from "../SharedSlices/ModalSlice"
import { hashtagRegex } from "../../../App/Regex"
import HTTPService from "../../../App/HTTPService"
import { headers } from "../../../App/Axios"
import Button from "../Primitive/Button"
import EditorTextArea from "./Editor/EditorTextArea"
import { Editor as TinyMCEEditor } from "tinymce"
import Picker from "emoji-picker-react"
import SearchableRoomDropdown from "./SearchableRoomDropdown"
import { roomPostPostRequest } from "../../Room/RoomPost/RoomPostRequests"
import { MentionedUsersType } from "../../../Types/RoomPosts"
import TutorialBubble from "./TutorialBubble"
import DocumentTextAreaPreview from "./DocumentTextAreaPreview"

function FeedTextBox(props: {}) {
    const translation = useSelector(selectTranslations)
    const [inputState, setInputState] = useState<string>("")
    const editorRef = useRef<TinyMCEEditor | null>(null)
    const [content, setContent] = useState<string>("")
    const [isEmojiSelectorOpen, setIsEmojiSelectorOpen] = useState(false)
    let emojiRef = useRef() as React.MutableRefObject<HTMLInputElement>
    const [roomId, setRoomId] = useState(0)
    const posts = useSelector((state: RootState) => state.RoomPostsSlice.roomPostsSlice.posts)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const dispatch = useDispatch()
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const [validationError, setValidationError] = useState("")
    const [image, setImage] = useState<any>(null)
    const [dropdownKey, setDropdownKey] = useState(0)
    const [fileType, setFileType] = useState("")
    const [documentState, setDocumentState] = useState<any>(null)
    const [previewUrl, setPreviewUrl] = useState<string>()
    let fileRef = React.useRef() as React.MutableRefObject<HTMLInputElement>




    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (isEmojiSelectorOpen && emojiRef.current && !emojiRef.current.contains(event.target as HTMLElement)) {
                setIsEmojiSelectorOpen(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    const removeFile = () => {
        setPreviewUrl("")
        setImage(null)
        setDocumentState(null)
        if (fileRef.current) {
            fileRef.current.value = ""
        }
    }

    const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files

        if (files) {
            const reader = new FileReader()
            reader.readAsDataURL(files[0])

            reader.addEventListener("load", () => {
                if (fileImageType.includes(files[0].type)) {
                    setImage(files[0])
                    setDocumentState(null)
                } else {
                    setDocumentState(files[0])
                    setImage(null)
                }
                setFileType(files[0].type)
                setPreviewUrl(URL.createObjectURL(files[0]))
            })
        }
    }

    const onEmojiClick = (event: { preventDefault: () => void }, emojiObject: any) => {
        event.preventDefault()
        if (editorRef.current) {
            editorRef.current!.insertContent(emojiObject.emoji)
        }
    }

    const createPost = () => {
        const objectId = userDataState.id
        const mentionedUsersArray: MentionedUsersType[] = []
        let modifiedContent = ""
        if (editorRef.current!) {
            const htmlContent = ConvertStringToHTML(editorRef!.current!.getContent({ format: 'raw' }))
            const tagSpans = htmlContent.querySelectorAll("[data-type]")

            tagSpans.forEach((span, index) => {
                span.innerHTML = "@tagged"
                mentionedUsersArray.splice(index, 0, {
                    tagged_user: span.getAttribute("data-type") === RoleType.USER.toLocaleLowerCase() ? parseInt(span.id) : null,
                    tagged_org: span.getAttribute("data-type") === RoleType.ORGANIZATION.toLocaleLowerCase() ? parseInt(span.id) : null,
                })
                modifiedContent = htmlContent.outerHTML.replaceAll("<body>", "").replaceAll("</body>", "").replace(hashtagRegex, "$1")
            })

            if (mentionedUsersArray.length >= 1 || editorRef.current!.getContent({ format: "text" }).length > 0) {
                const formData = new FormData()

                formData.append("content", modifiedContent.length > 0 ? modifiedContent : editorRef!.current!.getContent({ format: 'raw' }).replace(hashtagRegex, "$1"))
                formData.append("content_type", role.toLowerCase())
                formData.append("object_id", objectId?.toString()!)
                formData.append("tags", JSON.stringify(mentionedUsersArray))
                formData.append("source", "FEED")


                formData.append("room", roomId.toString())
                image !== null && formData.append("post_file", image)
                documentState !== null && formData.append("post_file", documentState)
                fileType.length > 0 && formData.append("file_type", fileType)
                dispatch(roomPostPostRequest(formData, posts))
                editorRef.current!.setContent('')
                setDropdownKey(Math.floor(Math.random() * 100))

                var element = document.getElementsByClassName("feed-item-profile-card")[0];
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }

    return (
        <>
            <div className="feed-item " key="feed-compose-text-box">
                <TutorialBubble text={translation.tutorial_user_feed_textbox} modifier="left" visible={userDataState.tutorial_progress == 3} hasNext={true} />
                <div className="feed-item__card">
                    <div className="feed-item__editor-container">
                        <div className="feed-item__editor">
                            <EditorTextArea
                                inputState={inputState}
                                setInputState={setInputState}
                                editorRef={editorRef}
                                editorPlaceholder={translation.typeYourMessage}
                                setContent={setContent}
                                editorHeight={62}
                                modifier={EditorModifier.eventCommentTextArea}
                                initialEditorValue=""
                                enterPressed={() => { }}
                                isUserDashboard={true}
                            />
                        </div>

                        <div className="feed-item__editor-action-container">
                            <div className="feed-item__editor-share-text"><strong>Teilen in</strong></div>
                            <div className="feed-item__room-dropdown">
                                <SearchableRoomDropdown hasTooltip={false} itemId={roomId} setItemId={setRoomId} validationError={validationError} key={dropdownKey} />
                            </div>

                            <div onClick={() => setIsEmojiSelectorOpen(!isEmojiSelectorOpen)} className="room-layout__right-panel-icon ">
                                <SmileIcon />
                            </div>
                            <div style={{ position: "absolute", left: "45%" }}>
                                {isEmojiSelectorOpen && (
                                    <div ref={emojiRef} className="room-layout__emoji-picker-feed">
                                        <Picker disableSkinTonePicker={false} onEmojiClick={onEmojiClick} />
                                    </div>
                                )}</div>

                            <div className="room-layout__right-panel-icon">
                                <input className="room-layout__input-file" type="file" accept={acceptedFileTypes} onChange={onFileChange} ref={fileRef} />
                                <FileIcon />
                            </div>

                            {content.length > 0 && roomId > 0 ? (
                                <div className="room-layout__right-panel-icon" onClick={createPost}>
                                    <SendIcon color="green" />
                                </div>
                            ) : (
                                <div className="room-layout__right-panel-icon room-layout__right-panel-icon--disable">
                                    <SendIcon color="grey" />
                                </div>
                            )}
                        </div>

                        {image && (
                                <div className="room-layout__post-image-container">
                                    <Image className="room-layout__post-image" src={previewUrl} alt="post" />
                                    <div className="room-layout__post-image-cancel-container" onClick={removeFile}>
                                        <Image src={previewUrl} alt="post" className="room-layout__post-image room-layout__post-image--opacity" />
                                        <div className="room-layout__post-image-cancel">
                                            <CloseIcon />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {documentState && (
                                <DocumentTextAreaPreview document_name={documentState.name} document_size={documentState.size} onRemoveFile={removeFile} />
                            )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeedTextBox
