import { headers } from "../../../../App/Axios"
import HTTPService from "../../../../App/HTTPService"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice";

const postRequest = (data: { event_id: number; rooms_id: number[] }) => {
    return HTTPService.post(`/share-event/`, data, headers)
}

export const onPostEventToRoom = (data: { event_id: number; rooms_id: number[] }) => async (dispatch: any) => {
    try {
        const response = await postRequest(data)
        if (response.status === 200) {
            dispatch(setModalData({ open: false, shareEventToRoomModal: false }))
        }
    } catch (error: any) {
        console.log(error)
    }
}
