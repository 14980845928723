import "../../../Styles/main.scss"
import Card from "../Primitive/Card"
import UserCard from "./UserCard"
import { useSelector } from "react-redux"
import { RootState } from "../../../App/store"
import { profileDefault } from "../../../App/GlobaleVariables"
import { useNavigate } from "react-router-dom"

function IndividualProfileCard() {
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const navigate = useNavigate()

    const onCardClick = () => {
        navigate("/dashboard")
        window.scrollTo(0, 0)
    }
    return (
        <>
            <div className="profile-card">
                <div>
                    <Card>
                        <div className="profile-card__container" onClick={onCardClick}>
                            <UserCard
                                imageSrc={userDataState.profile_photo?.url_path || profileDefault}
                                text={`${userDataState.first_name} ${userDataState.last_name}`}
                                profileImageStyle="image image__profile image__profile--fit image__profile--size-medium "
                                userCardStyle={"item"}
                                leftPanelStyle={"left-panel"}
                                middlePanelStyle={"middle-panel"}
                                textStyle={"text"}
                                textStyleMore25={"textMore25"}
                            />
                        </div>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default IndividualProfileCard
