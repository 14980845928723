import React from "react"
import { useSelector } from "react-redux"
import { RoleType } from "../../../App/enums"
import { not_found } from "../../../App/GlobaleVariables"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import "../../../Styles/main.scss"
import BackgroundWrapper from "../../Shared/Components/BackgroundWrapper"
import DonateButton from "../../Shared/Components/DonateButton"
import ModalGroup from "../../Shared/Components/ModalGroup"
import RoomDropdown from "../../Shared/Components/RoomDropdown"
import SponsorCard from "../../Shared/Components/SponsorCard"
import Header from "../../Shared/Header/Header"
import Footer from "../../Shared/Layout/Footer"
import MainLayout from "../../Shared/Layout/MainLayout"
import NewestOrganizationsCard from "../../Shared/NewestOrganizations/NewestOrganizations"
import MyOrganizations from "./MyOrganizations"
import { useNavigate, useParams } from "react-router-dom"
import { SearchIcon } from "../../Shared/Components/SvgIcons"

function MyOrganizationsScreen() {
    const translation = useSelector(selectTranslations)
    const userRole = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const donationLink = useSelector((state: RootState) => state.UserSettingsStatusSlice.userDonationLinkSlice.link)
    const { id } = useParams()
    const navigate = useNavigate()

    const onSeeAllEventsClick = () => {
        switch (userRole) {
            case RoleType.ORGANIZATION:
                navigate(`/all-premium-events/${id}`)
                break
            case RoleType.USER:
                navigate(`/all-premium-events`)
                break
            default:
                break
        }
    }

    return (
        <BackgroundWrapper>
            <Header />
            <MainLayout
                leftPanelFirstComponent={<RoomDropdown type={RoleType.USER} />}
                leftPanelForthComponent={translation.newestOrganizations}
                leftPanelFifthComponent={<NewestOrganizationsCard />}
                RightPanelFirstComponent={userRole === RoleType.USER ? donationLink === not_found ? <DonateButton /> : null : null}
                RightPanelForthComponent={
                    <div className="main-layout__sponsored-title">
                        {translation.sponsoredText}
                        <div onClick={onSeeAllEventsClick} className="main-layout__sponsored-icon">
                            <SearchIcon color="dark-blue" />
                        </div>
                    </div>
                }
                RightPanelFifthComponent={<SponsorCard />}
                overflowModifier={true}
            >
                <div className="my-organizations-screen">
                    <MyOrganizations />
                </div>
            </MainLayout>
            <Footer />
            <ModalGroup />
        </BackgroundWrapper>
    )
}

export default MyOrganizationsScreen
