import React from "react"
import "../../../Styles/main.scss"

function Checkbox(props: { color: "red" | "dark-blue"; initialState?: boolean } & React.AllHTMLAttributes<HTMLInputElement>) {
    return (
        <label data-testid="checkbox-label-id" className="checkbox ">
            <input data-testid="checkbox-input-id" type="checkbox" {...props} />
            <span className={`checkbox__checkmark ${props["checked"] ? `checkbox__checkmark--${props.color}` : ""} `}></span>
            {props.title}
        </label>
    )
}
export default Checkbox
