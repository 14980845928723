import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import Button from "../../../Shared/Primitive/Button"
import SearchInput from "../../../Shared/Primitive/SearchInput"
import { AddUserIcon, CloseIcon } from "../../../Shared/Components/SvgIcons"
import UserCard from "../../../Shared/Components/UserCard"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { RootState } from "../../../../App/store"
import { employee, profileDefault } from "../../../../App/GlobaleVariables"
import { useParams } from "react-router-dom"
import { EmployeeGetRequest, EmployeePostRequest } from "./OrganizationProfileEmployeeServiceRequests"
import { useEffect, useState } from "react"
import { newestMemberGetRequest } from "../../../Shared/NewestMembers/NewestMemberGetRequest"
import { NewMemberResponse } from "../../../../Types/Responses/NewMemberResponse"
import { setNewMembersData } from "../../../Shared/SharedSlices/NewestMembersSlice"
import { setEmployeesData, setSearchQuery } from "./EmployeesSlice"
import HTTPService from "../../../../App/HTTPService"
import { headers } from "../../../../App/Axios"
import { User } from "../../../../Types/User"

function OrganizationProfileAddEmployeeModal() {
    const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<Array<number>>([])
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const newMembersState = useSelector((state: RootState) => state.newMembers.newMembersSlice.members)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const EmployeesState = useSelector((state: RootState) => state.employeesSlice.employeesSlice.employees)
    const { id } = useParams()
    const organizationId = parseInt(id!)
    const [inputState, setInputState] = useState("")
    const [searchedUsers, setSearchedUsers] = useState<User[]>([])

    const searchUsersGetRequest = (searchQuery: string) => {
        return HTTPService.get(`/newestmembers/?query=${searchQuery}`, headers)
    }

    useEffect(() => {
        if (inputState.length > 2) {
            searchUsersGetRequest(inputState).then(response => {
                if (response.status === 200) {
                    setSearchedUsers(response.data)
                }
            })
        }
    }, [inputState])

    const onModalClose = () => {
        dispatch(setModalData({ open: false, addEmployeeModal: false }))
    }

    useEffect(() => {
        let isMounted = true
        newestMemberGetRequest()
            .then((response: NewMemberResponse) => {
                if (isMounted && response.status === 200) {
                    dispatch(setNewMembersData(response.data))
                }
            })
            .catch(error => {
                console.log(error)
            })
        return () => {
            isMounted = false
        }
    }, [dispatch])

    const handleOnClick = (userId: number) => {
        dispatch(EmployeePostRequest(userId, organizationId, employee))
        setSelectedEmployeeIds([...selectedEmployeeIds, userId])
    }

    const handleSaveOnClick = () => {
        EmployeeGetRequest(organizationId).then(response => {
            if (response.status === 200) {
                dispatch(setEmployeesData(response.data.role))
                onModalClose()
            }
        })
    }

    const users = newMembersState.filter(users => !EmployeesState.some(employees => employees.user.id === users.id || userDataState.id === users.id))

    const onRemoveIconClick = () => {
        setInputState("")
        dispatch(setSearchQuery(""))
    }

    return (
        <div className="add-employee-modal">
            <div className="add-employee-modal__title">
                {translation.addEmployees}{" "}
                <div className="add-employee-modal__close-icon" onClick={onModalClose}>
                    <CloseIcon />
                </div>
            </div>
            <div className="add-employee-modal__search-container">
                <SearchInput
                    onRemoveIconClick={onRemoveIconClick}
                    searchvalue={inputState}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputState(event.target.value)}
                    isGlobalSearch={false}
                />
            </div>
            <div className="add-employee-modal__item-container">
                {(inputState.length > 0 ? searchedUsers : users).map(item => {
                    return (
                        <div className="add-employee-modal__item" key={item.id}>
                            <UserCard
                                imageSrc={item.profile_photo?.url_path || profileDefault}
                                text={item.first_name + " " + item.last_name}
                                subtext={""}
                                profileImageStyle="image image__profile image__profile--fit image__profile--size-small "
                                userCardStyle={"organization-profile-employees-item"}
                                leftPanelStyle={"left-panel"}
                                middlePanelStyle={"middle-panel"}
                                textStyleContainer={"text-container"}
                                textStyle={"text"}
                                textStyleMore25={"text"}
                                subtextStyle={"subtext"}
                                rightPanelStyle={"right-panel"}
                                rightTextStyle={""}
                                rightText={
                                    <div className="add-employee-modal__add-button">
                                        <Button
                                            className={
                                                selectedEmployeeIds.includes(item.id!)
                                                    ? " button button--border-radius-09 button--color-grey  button--text-color-white button--font-size-small button--font-weight-400"
                                                    : " button button--border-radius-09 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400"
                                            }
                                            onClick={() => handleOnClick(item.id!)}
                                        >
                                            <div className="add-employee-modal__button-content">
                                                <AddUserIcon /> {translation.add}
                                            </div>
                                        </Button>
                                    </div>
                                }
                            />
                        </div>
                    )
                })}
                {inputState.length > 2 && searchedUsers.length === 0 && (
                    <div className="add-employee-modal__no-data-text">{`${translation.noResultThatContain} '${inputState}' ${translation.wasFound}`}</div>
                )}
            </div>
            <div className="add-employee-modal__button-container ">
                <div className="add-employee-modal__save-button">
                    {" "}
                    <Button
                        type="submit"
                        className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-gold button--hover-brighter button--cursor-pointer button--border-radius-05"
                        onClick={handleSaveOnClick}
                    >
                        {translation.save}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default OrganizationProfileAddEmployeeModal
