import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { UserProfileResponseMessage } from "../../../Types/Responses/UserExperienceResponseMessage"
import { WorkExperience } from "../../../Types/Responses/WorkExperience"
import { WorkExperienceData } from "../../../Types/WorkExperienceData"

const initialState: WorkExperience = { work: [] }

const WorkExperienceSlice = createSlice({
    name: "workExperienceSlice",
    initialState: initialState,
    reducers: {
        setWorkExperienceData(state, action: PayloadAction<Array<WorkExperienceData>>) {
            state.work = action.payload
        },
    },
})

interface ErrorData {
    message: UserProfileResponseMessage
    status: number
}
const ErrorInitialState = {
    message: {},
    status: 0,
} as ErrorData

const UserProfileExperienceErrorSlice = createSlice({
    name: "UserExperienceErrorMessages",
    initialState: ErrorInitialState,
    reducers: {
        getUserProfileExperienceErrorMessages(state, action: PayloadAction<ErrorData>) {
            state.message = action.payload.message
            state.status = action.payload.status
        },
        resetUserProfileExperienceError(state) {
            state.message = ErrorInitialState.message
        },
    },
})

interface Experienceid {
    id: number
}

const initialIdState: Experienceid = { id: 0 }

const UserExperienceIdSlice = createSlice({
    name: "UserExperienceIdSlice",
    initialState: initialIdState,
    reducers: {
        getUserExperienceId(state, action: PayloadAction<number>) {
            state.id = action.payload
        },
    },
})

export const { setWorkExperienceData } = WorkExperienceSlice.actions
export const { getUserProfileExperienceErrorMessages } = UserProfileExperienceErrorSlice.actions
export const { getUserExperienceId } = UserExperienceIdSlice.actions
export const { resetUserProfileExperienceError } = UserProfileExperienceErrorSlice.actions
export default combineReducers({
    workExperienceSlice: WorkExperienceSlice.reducer,
    workExperienceError: UserProfileExperienceErrorSlice.reducer,
    workExperienceIdSlice: UserExperienceIdSlice.reducer,
})
