import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { headers } from "../../../App/Axios"
import { year } from "../../../App/GlobaleVariables"
import HTTPService from "../../../App/HTTPService"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { CloseIcon } from "../../Shared/Components/SvgIcons"
import Button from "../../Shared/Primitive/Button"
import Checkbox from "../../Shared/Primitive/Checkbox"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { onUpgradeSubscriptionPlan } from "./GoPremiumServiceRequest"

export type PlanInformationType = {
    ContractPeriod: { Quantity: number; Unit: string }
    Description: { _c: string }
    InternalName: string
    RecurringFee: number
}

function UpgradeConfirmationModal() {
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const { id } = useParams()
    const PlanId = useSelector((state: RootState) => state.UpgradePlanSlice.planId)
    const [isActive, setIsActive] = useState(false)
    const [planInformation, setPlanInformation] = useState<PlanInformationType>()

    const onCloseModalClick = () => {
        dispatch(setModalData({ open: false, goPremiumUpgradeModal: false }))
    }

    const getPlanInformationRequest = () => {
        return HTTPService.get(`/get-plan-variant-info/${PlanId}`, headers)
    }

    useEffect(() => {
        getPlanInformationRequest()
            .then(response => {
                if (response.status === 200) {
                    setPlanInformation(response.data)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    const onUpgradePlanClick = () => {
        dispatch(onUpgradeSubscriptionPlan(id!, PlanId!, isActive))
    }

    const removePlanId = (sentance: string) => {
        if (sentance) {
            const indexOfSpace = sentance.indexOf(" ")

            return sentance.substring(indexOfSpace + 1)
        }
        return null
    }

    let tax = planInformation?.RecurringFee! * 0.19
    let bruttoPrice = tax + planInformation?.RecurringFee!

    return (
        <div className="upgrade-confirmation-modal">
            <div className="upgrade-confirmation-modal__header">
                <div className="upgrade-confirmation-modal__title">{translation.upgradeConfirmation}</div>
                <div onClick={onCloseModalClick} className="upgrade-confirmation-modal__icon">
                    <CloseIcon />
                </div>
            </div>

            <div className="upgrade-confirmation-modal__content">
                <div className="upgrade-confirmation-modal__text">{translation.yourUpdatingToTheFollowingPlan}</div>
                <p className="upgrade-confirmation-modal__text upgrade-confirmation-modal__text--bold upgrade-confirmation-modal__text--no-margin">
                    {removePlanId(planInformation?.InternalName!)}
                </p>
                <p className="upgrade-confirmation-modal__text">{planInformation?.Description._c}</p>
                <div className="upgrade-confirmation-modal__container-text">
                    <p className="upgrade-confirmation-modal__text upgrade-confirmation-modal__text--bold upgrade-confirmation-modal__text--no-margin">
                        {translation.einzelpreis}:
                    </p>
                    <p className="upgrade-confirmation-modal__text upgrade-confirmation-modal__text--no-margin   upgrade-confirmation-modal__text--margin-left ">
                        {planInformation?.RecurringFee + "€ "}
                        {planInformation?.ContractPeriod.Unit === year ? translation.perYear : translation.perMonth}
                    </p>
                </div>
                <div className="upgrade-confirmation-modal__container-text">
                    <p className="upgrade-confirmation-modal__text upgrade-confirmation-modal__text--bold upgrade-confirmation-modal__text--no-margin">
                        {translation.menge}:
                    </p>
                    <p className="upgrade-confirmation-modal__text upgrade-confirmation-modal__text--no-margin   upgrade-confirmation-modal__text--margin-left ">
                        {planInformation?.ContractPeriod.Quantity}
                    </p>
                </div>
                <div className="upgrade-confirmation-modal__container-text">
                    <p className="upgrade-confirmation-modal__text upgrade-confirmation-modal__text--bold upgrade-confirmation-modal__text--no-margin">
                        {translation.MwSt}:
                    </p>
                    <p className="upgrade-confirmation-modal__text upgrade-confirmation-modal__text--no-margin   upgrade-confirmation-modal__text--margin-left ">
                        {tax + "€ "}
                    </p>
                </div>
                <div className="upgrade-confirmation-modal__container-text">
                    <p className="upgrade-confirmation-modal__text upgrade-confirmation-modal__text--bold upgrade-confirmation-modal__text--no-margin">
                        {translation.summeBrutto}:
                    </p>
                    <p className="upgrade-confirmation-modal__text upgrade-confirmation-modal__text--no-margin   upgrade-confirmation-modal__text--margin-left ">
                        {bruttoPrice.toFixed(2) + "€ "}
                    </p>
                </div>
                <div className="upgrade-confirmation-modal__text upgrade-confirmation-modal__text--no-margin-bottom ">
                    {translation.warningUpgrade} <br /> {translation.warningUpgrade2}
                </div>
                <div className="upgrade-confirmation-modal__checkbox">
                    <Checkbox
                        color="dark-blue"
                        checked={isActive}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIsActive(event.target.checked)}
                    />
                    <p className="upgrade-confirmation-modal__checkbox-text">
                        {translation.agreePrivacyPolicy}{" "}
                        <a className="  upgrade-confirmation-modal__link" href="https://edusiia.com/nutzungsbedingungen/" target="_blank" rel="noreferrer">
                            {translation.termsOfUse}
                        </a>{" "}
                        {translation.agreePrivacyPolicy1}
                        .*
                    </p>
                </div>
                <div className="upgrade-confirmation-modal__button-container">
                    <div className="upgrade-confirmation-modal__button">
                        {isActive ? (
                            <Button
                                onClick={onUpgradePlanClick}
                                className="button button--border-radius-05 button--color-blue button--hover-brighter  button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400  "
                            >
                                {translation.upgrade}
                            </Button>
                        ) : (
                            <Button className="button button--border-radius-05  button--font-size-small button--font-weight-400  " disabled>
                                {translation.upgrade}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpgradeConfirmationModal
