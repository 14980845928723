import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Entity, NotificationResponse, RoleType } from "../../App/enums"
import { organizationProfileDefault, profileDefault } from "../../App/GlobaleVariables"
import HTTPService from "../../App/HTTPService"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import { RootState } from "../../App/store"
import { setRoomMembers } from "../Room/RoomSlices/MembersInRoomSlice"
import { NotificationUnnreadIcon } from "../Shared/Components/SvgIcons"
import Button from "../Shared/Primitive/Button"
import { setUserNotifications } from "./NotificationSlice"
import { getMembersRequest, userNotificationGetRequest } from "./UserNotificationServiceRequest"
import { setModalData } from "../Shared/SharedSlices/ModalSlice"
import Image from "../Shared/Primitive/Image"

function NotificationDropdownItem(props: {
    id: number
    sender?: string
    action_url?: string
    redirect_url?: string
    itemSizeLarge: boolean
    name?: string
    first_name?: string
    last_name?: string
    photoSrc?: string
    notification?: string
    date?: string | null
    isRead?: boolean
    post?: number
    onClick?: () => void
    isLastItem: boolean
    roomId: number
    invitation_id?: number
    isDropDown?: boolean
    isNotificationScreen?: boolean
}) {
    const translation = useSelector(selectTranslations)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { id } = useParams()
    const notificationsPagePathnames = [`/notifications/${id}`, `/notifications`]
    const userLastName = props?.last_name === undefined ? "" : props?.last_name
    const parseNotificationContent = () => {
        if (props?.notification) {
            let occourenceCount = (props?.notification.match(/: /g) || []).length
            //if no : is found return original content
            if (occourenceCount == 0) {
                return props.notification
            }
            let [first, ...rest] = props.notification.split(":")
            first = first.replace("Zusätzliche Info", "")
            return (
                <p>
                    <span>
                        {first}
                        <br /> <strong> {rest} </strong>
                    </span>
                </p>
            )
        }
    }

    const onShowButton = () => {
        if (props.action_url === null) {
            return false
        } else {
            return true
        }
    }

    const onAccept = () => {
        HTTPService.patch(`${props.action_url}`, { status: NotificationResponse.accepted })
            .then(response => {
                if (response.status === 200) {
                    if (response.data.is_room_invite) {
                        getMembersRequest(props.roomId).then(response => {
                            if (response.status === 200) {
                                dispatch(setRoomMembers(response.data.user))
                                userNotificationGetRequest().then(response => {
                                    if (response.status === 200) {
                                        dispatch(setUserNotifications(response.data))
                                        navigate(`/room/${props.roomId}`)
                                    }
                                })
                            }
                        })
                    } else if (props.action_url === `/org/accept-deny-invite/${props.invitation_id}`) {
                        navigate(`/room/${id}/${props.roomId}`)
                    } else if (
                        props.action_url === `/reply-to-room-join-request/${props.invitation_id}` ||
                        props.action_url === `/org/change-join-request-status/${props.invitation_id}`
                    ) {
                        navigate(role === RoleType.USER ? `/room/${props.roomId}` : `/room/${id}/${props.roomId}`)
                    } else {
                        navigate(`/${props.redirect_url}`)
                    }
                }
            })
            .catch(err => {
                if (err.response.status === 400 && err.response.data[0] == "You cannot add more than 10 members to a free room!") {
                    dispatch(setModalData({ open: true, errorJoiningFullRoom: true }))
                    return
                }
            })
    }

    const onDecline = () => {
        HTTPService.patch(`${props.action_url}`, { status: NotificationResponse.declined }).then(response => {
            if (response.status === 200) {
                userNotificationGetRequest().then(response => {
                    if (response.status === 200) {
                        dispatch(setUserNotifications(response.data))
                    }
                })
            }
        })
    }

    return (
        <div
            className={`notification-dropdown-item notification-dropdown-item--${props.isLastItem && "border-none"} notification-dropdown-item--${
                !props.isRead && "color-grey"
            }`}
        >
            <div className="notification-dropdown-item__content">
                <div className="notification-dropdown-item__left-panel" onClick={props.onClick}>
                    {!props.isRead && (
                        <div className="notification-dropdown-item__unnread-icon">
                            <NotificationUnnreadIcon />
                        </div>
                    )}
                    <Image
                        alt="user"
                        src={props.sender === Entity.USER ? props?.photoSrc || profileDefault : props?.photoSrc || organizationProfileDefault}
                        className={`notification-dropdown-item__image`}
                    />
                </div>
                <div className={`notification-dropdown-item__middle-panel`}>
                    <div className={`notification-dropdown-item__user-data`} onClick={props.onClick}>
                        {props.sender === Entity.USER ? props?.first_name + " " + userLastName : props?.name}
                    </div>
                    <div
                        className={`notification-dropdown-item__notification ${!onShowButton() && "notification-dropdown-item__notification--margin-bottom"}`}
                        onClick={props.onClick}
                    >
                        {parseNotificationContent()}
                    </div>
                    {onShowButton() && (
                        <div className="notification-dropdown-item__button-container">
                            <>
                                <div className="notification-dropdown-item__button">
                                    <Button
                                        onClick={onAccept}
                                        className="button button--border-radius-05 button--color-gold button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400"
                                    >
                                        {translation.accept}
                                    </Button>
                                </div>
                                <div className="notification-dropdown-item__button">
                                    <Button
                                        onClick={onDecline}
                                        className="button button--border-radius-05 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400"
                                    >
                                        {translation.decline}
                                    </Button>
                                </div>
                            </>
                        </div>
                    )}
                </div>
            </div>

            <div
                className="notification-dropdown-item__right-panel notification-dropdown-item__right-panel--row"
                /*className={`notification-dropdown-item__right-panel ${
                    notificationsPagePathnames.includes(location!.pathname!)
                        ? "notification-dropdown-item__right-panel--row"
                        : "notification-dropdown-item__right-panel--column"
                }`}*/
                onClick={props.onClick}
            >
                {props.isNotificationScreen && (
                    <>
                        <div className={`notification-dropdown-item__time `}>
                            {" "}
                            {props.date?.slice(0, 5)} <br />
                            {props.date?.slice(5)}
                        </div>
                    </>
                )}

                {props.isDropDown && (
                    <>
                        <div className={`notification-dropdown-item__time `}>
                            {" "}
                            {props.date?.slice(0, 5)} <br />
                            {props.date?.slice(5)}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default NotificationDropdownItem
