import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"

const getRequest = (organizationId: string) => {
    return HTTPService.get(`/get-self-service-url/${organizationId}/`, headers)
}

const upgradePostRequest = (organizationId: string, subscriptionPlanId: string, isActive: boolean) => {
    return HTTPService.post(`/upgrade-plan/`, { terms: isActive, org_id: organizationId, plan_id: subscriptionPlanId }, headers)
}

export const onGetSubscriptionPlanLink = (organizationId: string) => async (dispatch: Function) => {
    try {
        const response = await getRequest(organizationId)
        if (response.status === 200) {
            window.open(response.data.self_service_url, "_blank")
        }
    } catch (error: any) {}
}

export const onUpgradeSubscriptionPlan = (organizationId: string, subscriptionPlanId: string, isActive: boolean) => async (dispatch: Function) => {
    try {
        const response = await upgradePostRequest(organizationId, subscriptionPlanId, isActive)
        if (response.status === 200) {
            dispatch(setModalData({ open: false, goPremiumUpgradeModal: false }))
            dispatch(setModalData({ open: true, successfulUpgrade: true }))
        }
    } catch (error: any) {}
}
