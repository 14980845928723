import { createSlice, PayloadAction, combineReducers } from "@reduxjs/toolkit"

const initialState = { isMember: "" }

const MemberInRoomStatusSlice = createSlice({
    name: "IsMemberInRoomSlice",
    initialState: initialState,
    reducers: {
        setIsMember(state, action: PayloadAction<string>) {
            state.isMember = action.payload
        },
    },
})

export type Pending = { type: string; notification_id: number }
const initialPendingTypeState: Pending = { type: "", notification_id: 0 }

const PendingTypeSlice = createSlice({
    name: "IsMemberInRoomSlice",
    initialState: initialPendingTypeState,
    reducers: {
        setPendingType(state, action: PayloadAction<Pending>) {
            state.type = action.payload.type
            state.notification_id = action.payload.notification_id
        },
    },
})

const initialInviteState = { isInvited: true }

const InviteInRoomStatusSlice = createSlice({
    name: "InviteInRoomStatusSlice",
    initialState: initialInviteState,
    reducers: {
        setIsInvited(state, action: PayloadAction<boolean>) {
            state.isInvited = action.payload
        },
    },
})

const initialSearchInviteState = { isInvited: true }

const InviteInRoomSearchStatusSlice = createSlice({
    name: "InviteInRoomStatusSlice",
    initialState: initialSearchInviteState,
    reducers: {
        setInviteInRoomSearchStatus(state, action: PayloadAction<boolean>) {
            state.isInvited = action.payload
        },
    },
})

export const { setIsMember } = MemberInRoomStatusSlice.actions
export const { setPendingType } = PendingTypeSlice.actions
export const { setIsInvited } = InviteInRoomStatusSlice.actions
export const { setInviteInRoomSearchStatus } = InviteInRoomSearchStatusSlice.actions

export default combineReducers({
    MemberInRoomStatusSlice: MemberInRoomStatusSlice.reducer,
    PendingTypeSlice: PendingTypeSlice.reducer,
    InviteInRoomStatusSlice: InviteInRoomStatusSlice.reducer,
    InviteInRoomSearchStatusSlice: InviteInRoomSearchStatusSlice.reducer,
})
