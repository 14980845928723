import { useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import useWindowSize from "../../Shared/Hooks/useWindowSize"
import { useEffect, useState } from "react"
import HTTPService from "../../../App/HTTPService"
import { headers } from "../../../App/Axios"
import { useNavigate } from "react-router-dom"
import { AlertCircleIcon, InviteUserIcon } from "../../Shared/Components/SvgIcons"
import RoomFilterDropdown from "../../Shared/Components/RoomFilterDropdown"
import { RoleType } from "../../../App/enums"

function UserDashboardButtons() {
    const translation = useSelector(selectTranslations)
    const windowSize = useWindowSize()
    const navigate = useNavigate()
    const breakpoint = 1024
    const [invitationNumber, setInvitationNumber] = useState(0)

    const getInvitationRequest = () => {
        return HTTPService.get("/users/invitations/", headers)
    }

    useEffect(() => {
        getInvitationRequest().then(response => {
            if (response.status === 200) {
                setInvitationNumber(response.data.length)
            }
        })
    }, [])

    const onInvitationClick = () => {
        navigate("/invitations")
    }

    return (
        
        <div className="dashboard-feed__text dashboard-feed__text--display-flex dashboard-feed__text--font-size-18 dashboard-feed__text--font-weight-700 ">
            {invitationNumber > 0 && (
            <div className="dashboard-feed__button-container dashboard-feed--margin-top-1 ">
                {" "}
                
                    <button className="dashboard-feed__button" onClick={onInvitationClick}>
                        {invitationNumber > 0 && invitationNumber}{" "}
                        {windowSize > breakpoint ? invitationNumber === 1 ? translation.Invitation : translation.Invitations : <InviteUserIcon color="white" />}
                        {invitationNumber > 0 && <AlertCircleIcon />}
                    </button>
                
            </div>
        )}
        </div>
    )
}

export default UserDashboardButtons
