import { useEffect, useState } from "react"
import EmailNotification from "../../Shared/Components/EmailNotifications"
import WebNotifications from "../../Shared/Components/WebNotification"
import { useDispatch, useSelector } from "react-redux"
import {
    userEmailNotificationsSettingsGetRequest,
    userEmailNotificationsSettingsPatchRequest,
    userWebNotificationsSettingsGetRequest,
    userWebNotificationsSettingsPatchRequest,
} from "./UserNotificationsServiceRequests"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import Button from "../../Shared/Primitive/Button"
import { LoadingAnimation, MessageActiveIcon, WebNotificationIcon } from "../../Shared/Components/SvgIcons"
import "../../../Styles/main.scss"
import { getUserNotificationsSettingsState } from "./UserSettingsSlice"
import { NotificationSettings } from "../../../App/enums"
import { UserEmailType, UserWebType } from "../../../Types/Notification"
import useWindowSize from "../../Shared/Hooks/useWindowSize"

function UserNotificationsSettings() {
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const [webNotification, setWebNotification] = useState<UserWebType>()
    const [emailNotification, setEmailNotification] = useState<UserEmailType>()
    const [isLoading, setIsLoading] = useState(true)
    const screenWidth = useWindowSize()
    const breakpoint = 768

    useEffect(() => {
        userEmailNotificationsSettingsGetRequest()
            .then(response => {
                if (response.status === 200) {
                    setEmailNotification(response.data)
                }
            })
            .catch(error => {
                console.log(error)
            })

        userWebNotificationsSettingsGetRequest()
            .then(response => {
                if (response.status === 200) {
                    setWebNotification({
                        commented_on_same_post: { value_none: !response.data.commented_on_same_post, value_immediate: response.data.commented_on_same_post },
                        comment: { value_none: !response.data.comment, value_immediate: response.data.comment },
                        tags: { value_none: !response.data.tags, value_immediate: response.data.tags },
                        room_invitation: { value_none: !response.data.room_invitation, value_immediate: response.data.room_invitation },
                        room_invitation_reply: { value_none: !response.data.room_invitation_reply, value_immediate: response.data.room_invitation_reply },
                        room_join_request: { value_none: !response.data.room_join_request, value_immediate: response.data.room_join_request },
                        room_join_request_reply: { value_none: !response.data.room_join_request_reply, value_immediate: response.data.room_join_request_reply },
                        add_admin: { value_none: !response.data.add_admin, value_immediate: response.data.add_admin },
                        remove_admin: { value_none: !response.data.remove_admin, value_immediate: response.data.remove_admin },
                        event_comment: { value_none: !response.data.event_comment, value_immediate: response.data.event_comment },
                        new_post_in_room: { value_none: !response.data.new_post_in_room, value_immediate: response.data.new_post_in_room },
                        admin_reply: { value_none: !response.data.admin_reply, value_immediate: response.data.admin_reply },
                    })
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        if (webNotification && emailNotification) {
            setIsLoading(false)
        }
    }, [emailNotification, webNotification])

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()

        const webNotifications = {
            comment: webNotification?.comment.value_immediate!,
            commented_on_same_post: webNotification?.commented_on_same_post.value_immediate!,
            tags: webNotification?.tags.value_immediate!,
            room_invitation: webNotification?.room_invitation.value_immediate!,
            room_invitation_reply: webNotification?.room_invitation_reply.value_immediate!,
            room_join_request: webNotification?.room_join_request.value_immediate!,
            room_join_request_reply: webNotification?.room_join_request_reply.value_immediate!,
            add_admin: webNotification?.add_admin.value_immediate!,
            remove_admin: webNotification?.remove_admin.value_immediate!,
            event_comment: webNotification?.event_comment.value_immediate!,
            new_post_in_room: webNotification?.new_post_in_room.value_immediate!,
            admin_reply: webNotification?.admin_reply.value_immediate!,
        }

        const emailNotifications = {
            comment: emailNotification?.comment.value_none ? 0 : emailNotification?.comment.value_daily ? 1 : 2,
            commented_on_same_post: emailNotification?.commented_on_same_post.value_none! ? 0 : emailNotification?.commented_on_same_post.value_daily ? 1 : 2,
            tags: emailNotification?.tags.value_none ? 0 : emailNotification?.tags.value_daily ? 1 : 2,
            room_invitation: emailNotification?.room_invitation.value_none ? 0 : emailNotification?.room_invitation.value_daily ? 1 : 2,
            room_invitation_reply: emailNotification?.room_invitation_reply.value_none ? 0 : emailNotification?.room_invitation_reply.value_daily ? 1 : 2,
            room_join_request: emailNotification?.room_join_request.value_none ? 0 : emailNotification?.room_join_request.value_daily ? 1 : 2,
            room_join_request_reply: emailNotification?.room_join_request_reply.value_none ? 0 : emailNotification?.room_join_request_reply.value_daily ? 1 : 2,
            add_admin: emailNotification?.add_admin.value_none ? 0 : emailNotification?.add_admin.value_daily ? 1 : 2,
            remove_admin: emailNotification?.remove_admin.value_none ? 0 : emailNotification?.remove_admin.value_daily ? 1 : 2,
            event_comment: emailNotification?.event_comment.value_none ? 0 : emailNotification?.event_comment.value_daily ? 1 : 2,
            new_post_in_room: emailNotification?.new_post_in_room.value_none ? 0 : emailNotification?.new_post_in_room.value_daily ? 1 : 2,
            admin_reply: emailNotification?.admin_reply.value_none ? 0 : emailNotification?.admin_reply.value_daily ? 1 : 2,
            unseen_chat_messages: emailNotification?.unseen_chat_messages.value_none ? 0 : emailNotification?.unseen_chat_messages.value_daily ? 1 : 2,
        }

        userWebNotificationsSettingsPatchRequest(webNotifications)
        userEmailNotificationsSettingsPatchRequest(emailNotifications)

        dispatch(getUserNotificationsSettingsState(false))
    }

    return (
        <form className="notification-settings" onSubmit={onFormSubmit}>
            {isLoading ? (
                <div className="settings-right-panel__settings-notifications-loading-container">
                    <LoadingAnimation />
                </div>
            ) : (
                <div className="notification-settings__form">
                    <div className="notification-settings__first-row">
                        <div className="notification-settings__first-row-start">
                            <MessageActiveIcon />
                            <p className="notification-settings__first-row-email">{translation.byEmail}</p>
                        </div>
                        <div className="notification-settings__first-row-end">
                            <p className="notification-settings__first-row-none">{translation.none}</p>
                            <p className="notification-settings__first-row-daily">{translation.daily}</p>
                            <p className="notification-settings__first-row-weekly">{translation.weekly}</p>
                        </div>
                    </div>
                    <div className="notification-settings__container">
                        {}
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__email-comment-title">{translation.unseenMessages}</p>
                            <EmailNotification
                                emailState={emailNotification?.unseen_chat_messages!}
                                setEmailState={setEmailNotification}
                                data={emailNotification}
                                type={NotificationSettings.unseen_chat_messages}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__email-comment-title">{translation.commentUser}</p>
                            <EmailNotification
                                emailState={emailNotification?.comment!}
                                setEmailState={setEmailNotification}
                                data={emailNotification}
                                type={NotificationSettings.comment}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__email-comment-title">{translation.commentOnSamePost}</p>
                            <EmailNotification
                                emailState={emailNotification?.commented_on_same_post!}
                                setEmailState={setEmailNotification}
                                data={emailNotification}
                                type={NotificationSettings.commented_on_same_post}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__email-comment-title">{translation.roomInvitationUser}</p>
                            <EmailNotification
                                emailState={emailNotification?.room_invitation!}
                                setEmailState={setEmailNotification}
                                type={NotificationSettings.room_invitation}
                                data={emailNotification}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__email-comment-title">{translation.roomInvitationReplyUser}</p>
                            <EmailNotification
                                emailState={emailNotification?.room_invitation_reply!}
                                setEmailState={setEmailNotification}
                                type={NotificationSettings.room_invitation_reply}
                                data={emailNotification}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__email-comment-title">{translation.roomJoinRequestUser}</p>
                            <EmailNotification
                                emailState={emailNotification?.room_join_request!}
                                setEmailState={setEmailNotification}
                                type={NotificationSettings.room_join_request}
                                data={emailNotification}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__email-comment-title">{translation.roomJoinRequestReplyUser}</p>
                            <EmailNotification
                                emailState={emailNotification?.room_join_request_reply!}
                                setEmailState={setEmailNotification}
                                type={NotificationSettings.room_join_request_reply}
                                data={emailNotification}
                            />
                        </div>

                        <div className="notification-settings__title-container">
                            <p className="notification-settings__email-comment-title">{translation.addAdminUser}</p>
                            <EmailNotification
                                emailState={emailNotification?.add_admin!}
                                setEmailState={setEmailNotification}
                                type={NotificationSettings.add_admin}
                                data={emailNotification}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__email-comment-title">{translation.removeAdminUser}</p>
                            <EmailNotification
                                emailState={emailNotification?.remove_admin!}
                                setEmailState={setEmailNotification}
                                type={NotificationSettings.remove_admin}
                                data={emailNotification}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__email-comment-title">{translation.adminReplyUser}</p>
                            <EmailNotification
                                emailState={emailNotification?.admin_reply!}
                                setEmailState={setEmailNotification}
                                type={NotificationSettings.admin_reply}
                                data={emailNotification}
                            />
                        </div>

                        <div className="notification-settings__title-container">
                            <p className="notification-settings__email-comment-title">{translation.eventCommentUser}</p>
                            <EmailNotification
                                emailState={emailNotification?.event_comment!}
                                setEmailState={setEmailNotification}
                                type={NotificationSettings.event_comment}
                                data={emailNotification}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__email-comment-title">{translation.newPostInRoomUser}</p>
                            <EmailNotification
                                emailState={emailNotification?.new_post_in_room!}
                                setEmailState={setEmailNotification}
                                type={NotificationSettings.new_post_in_room}
                                data={emailNotification}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__email-comment-title">{translation.tagsUser}</p>
                            <EmailNotification
                                emailState={emailNotification?.tags!}
                                setEmailState={setEmailNotification}
                                type={NotificationSettings.tags}
                                data={emailNotification}
                            />
                        </div>
                    </div>

                    <div className="notification-settings__line"></div>

                    <div className="notification-settings__first-row">
                        <div className="notification-settings__first-row-start">
                            <WebNotificationIcon />
                            <p className="notification-settings__first-row-email">{translation.byWeb}</p>
                        </div>
                        <div className="notification-settings__first-row-end">
                            <p className="notification-settings__first-row-none">{translation.none}</p>
                            <p className="notification-settings__first-row-immediate">{translation.immediate}</p>
                        </div>
                    </div>
                    <div className="notification-settings__container">
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__web-comment-title">{translation.commentUser}</p>
                            <WebNotifications
                                webState={webNotification?.comment!}
                                setWebState={setWebNotification}
                                type={NotificationSettings.comment}
                                data={webNotification}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__web-comment-title">{translation.commentOnSamePost}</p>
                            <WebNotifications
                                webState={webNotification?.commented_on_same_post!}
                                setWebState={setWebNotification}
                                type={NotificationSettings.commented_on_same_post}
                                data={webNotification}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__web-invitation-title">{translation.roomInvitationUser}</p>
                            <WebNotifications
                                webState={webNotification?.room_invitation!}
                                setWebState={setWebNotification}
                                type={NotificationSettings.room_invitation}
                                data={webNotification}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__web-invitation-title">{translation.roomInvitationReplyUser}</p>
                            <WebNotifications
                                webState={webNotification?.room_invitation_reply!}
                                setWebState={setWebNotification}
                                type={NotificationSettings.room_invitation_reply}
                                data={webNotification}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__web-invitation-title">{translation.roomJoinRequestUser}</p>
                            <WebNotifications
                                webState={webNotification?.room_join_request!}
                                setWebState={setWebNotification}
                                type={NotificationSettings.room_join_request}
                                data={webNotification}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__web-invitation-title">{translation.roomJoinRequestReplyUser}</p>
                            <WebNotifications
                                webState={webNotification?.room_join_request_reply!}
                                setWebState={setWebNotification}
                                type={NotificationSettings.room_join_request_reply}
                                data={webNotification}
                            />
                        </div>

                        <div className="notification-settings__title-container">
                            <p className="notification-settings__web-invitation-title">{translation.addAdminUser}</p>
                            <WebNotifications
                                webState={webNotification?.add_admin!}
                                setWebState={setWebNotification}
                                type={NotificationSettings.add_admin}
                                data={webNotification}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__web-invitation-title">{translation.removeAdminUser}</p>
                            <WebNotifications
                                webState={webNotification?.remove_admin!}
                                setWebState={setWebNotification}
                                type={NotificationSettings.remove_admin}
                                data={webNotification}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__web-invitation-title">{translation.adminReplyUser}</p>
                            <WebNotifications
                                webState={webNotification?.admin_reply!}
                                setWebState={setWebNotification}
                                type={NotificationSettings.admin_reply}
                                data={webNotification}
                            />
                        </div>

                        <div className="notification-settings__title-container">
                            <p className="notification-settings__web-invitation-title">{translation.eventCommentUser}</p>
                            <WebNotifications
                                webState={webNotification?.event_comment!}
                                setWebState={setWebNotification}
                                type={NotificationSettings.event_comment}
                                data={webNotification}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__web-invitation-title">{translation.newPostInRoomUser}</p>
                            <WebNotifications
                                webState={webNotification?.new_post_in_room!}
                                setWebState={setWebNotification}
                                type={NotificationSettings.new_post_in_room}
                                data={webNotification}
                            />
                        </div>
                        <div className="notification-settings__title-container">
                            <p className="notification-settings__web-invitation-title">{translation.tagsUser}</p>
                            <WebNotifications
                                webState={webNotification?.tags!}
                                setWebState={setWebNotification}
                                type={NotificationSettings.tags}
                                data={webNotification}
                            />
                        </div>
                    </div>

                    <div className="notification-settings__button-container">
                        <div className="notification-settings__button">
                            <Button
                                type="submit"
                                className={`button ${
                                    screenWidth > breakpoint && "button--border-radius-05"
                                } button--color-gold button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400 `}
                            >
                                {translation.save}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </form>
    )
}

export default UserNotificationsSettings
