import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"

const getRequest = (amount: string) => {
    return HTTPService.get(`/custom-donate/${amount}/`, headers)
}

export const onCustomAmountDonation = (amount: string, userId: number) => async (dispatch: Function) => {
    try {
        const response = await getRequest(amount)
        if (response.status === 200) {
            window.open(`${process.env.REACT_APP_BILLWERK_LINK + response.data.plan_variant_id}?tag=user${userId}`, "_blank")
        }
    } catch (error: any) {}
}
