import { useDispatch, useSelector } from "react-redux"
import { LoadingAnimation } from "../../Shared/Components/SvgIcons"
import { RootState } from "../../../App/store"
import UserCard from "../../Shared/Components/UserCard"
import { Conversation } from "../../../Types/Conversation"
import { setActiveConversation, setConversationList, setHasMoreConversations } from "../ChatSlice"
import { chatListGetRequest } from "../ChatServiceRequest"
import { text } from "stream/consumers"
import { Translation } from "react-i18next"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import Image from "../../Shared/Primitive/Image"
import { RefObject, useRef, useState } from "react"

function ChatList() {
    const conversations = useSelector((state: RootState) => state.chatSlice.conversations)
    const hasMoreConversations = useSelector((state: RootState) => state.chatSlice.hasMoreConversations)
    const activeConversation = useSelector((state: RootState) => state.chatSlice.activeConversationId)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)
    const scrollContainer = useRef() as RefObject<HTMLDivElement>
    const bottom = useRef() as RefObject<HTMLDivElement>
    const container = useRef() as RefObject<HTMLDivElement>
    const [pageNum, setPageNum] = useState(1)
    const [loadingConversations, setloadingConversations] = useState(false)

    const onConversationClick = (conversation: Conversation) => {
        dispatch(setActiveConversation(conversation.id))
    }

    const onScroll = () => {
        if (scrollContainer.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollContainer.current
            if (Math.ceil(scrollTop + clientHeight + 1) >= scrollHeight && hasMoreConversations && !loadingConversations) {
                setloadingConversations(true)
                const controller = new AbortController()
                const { signal } = controller

                chatListGetRequest(pageNum + 1)
                    .then(response => {
                        if (response.status === 200) {
                            dispatch(setConversationList(response.data.results.concat(conversations).sort((a: { updated_at: number },b: { updated_at: number }) => b.updated_at - a.updated_at)))
                            dispatch(setHasMoreConversations(Boolean(response.data.next)))
                            setPageNum(pageNum + 1)
                        }
                    })
                    .catch(error => {
                        if (signal.aborted) return
                        console.log(error)
                    })
                    setloadingConversations(false)
                return () => controller.abort()
            }
        }
    }

    return (
        conversations.length == 0 ? (
            <div className="chat-layout__no-chat">
                <span>{translation.chatNoConversations}</span>
            </div>

        ) : (
            <div className="chat-layout__chatlist card" ref={scrollContainer} onScroll={onScroll}>
                <div className="chat-card__members">
                    {conversations.map((item, index) => {
                        const chatPartner = item.member.filter(userObj => userObj.user.id != userDataState.id)[0]
                        const modifier = item.id === activeConversation ? "active-chat-card" : ""
                        return (
                            <div key={item.id} className={`chat-card-item chat-card-item--no-border ${modifier}`} onClick={() => onConversationClick(item)}>
                                <div className="chat-card-item">
                                    <div className="chat-card-item__left-panel">
                                        {chatPartner.user?.profile_photo?.url_path != null ? (
                                            <div className="image__profile--holder">
                                                <Image
                                                    src={chatPartner.user.profile_photo.url_path}
                                                    className="chat-card-item__image image__profile image__profile--fit image__profile--size-small"
                                                />
                                                <span className={`image__profile--status-${chatPartner.user.online ? "online" : "offline"} topRight`}>&nbsp;</span>
                                            </div>
                                        ) : (
                                            <div className="image__profile--holder">
                                                <Image
                                                    src="/edusiia_placeholder_individual_profile.png"
                                                    className="chat-card-item__image image__profile image__profile--fit image__profile--size-small"
                                                />
                                                <span className={`image__profile--status-${chatPartner.user.online ? "online" : "offline"} topRight`}>&nbsp;</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="chat-card-item__middle-panel">
                                        <div className="chat-card-item__text">
                                            {chatPartner.user.first_name != '' ? (
                                                <span>
                                                    {chatPartner.user.first_name} {chatPartner.user.last_name}
                                                </span>
                                            ) : (
                                                <span>
                                                    {translation.deletedUser}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="chat-card-item__right-panel">
                                        <div className="chat-card-item__text">
                                            {item.id != activeConversation && item.unseen_messages > 0 && <span className="message-counter-bubble">{item.unseen_messages}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    
                    <div style={{ float: "left", clear: "both" }} ref={bottom}></div>
                </div>
            </div>)
    )
}

export default ChatList
