import { headers } from "../../../../App/Axios"
import HTTPService from "../../../../App/HTTPService"
import { User } from "../../../../Types/User"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { setOrganizationData } from "../../../Shared/SharedSlices/OrganizationsSlice"

export const patchOrganizationDescription = (description: string | null, user: User, id: number) => async (dispatch: any) => {
    const OrganizationDescription = {
        description: description,
    }
    try {
        const response = await HTTPService.patch(`/organization/${id}/description/`, OrganizationDescription, headers)
        if (response.status === 200) {
            const response = await HTTPService.get(`/role-in-org/${id}/`, headers)
            if (response.status === 200) {
                dispatch(setOrganizationData(response.data.organization))
                dispatch(setModalData({ open: false, organizationDescription: false }))
            }
        }
    } catch (error: any) {
        console.log(error)
    }
}
