import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type ContactInfo = {
    email: string
    name?: string
    first_name?: string
    last_name?: string
    website?: string
}

const initialState: ContactInfo = {
    name: "",
    first_name: "",
    last_name: "",
    website: "",
    email: "",
}

const ContactInfoSlice = createSlice({
    name: "ContactInfo",
    initialState: initialState,
    reducers: {
        setContactInfo(state, action: PayloadAction<ContactInfo>) {
            state.name = action.payload.name
            state.first_name = action.payload.first_name
            state.last_name = action.payload.last_name
            state.website = action.payload.website
            state.email = action.payload.email
        },
    },
})
export const { setContactInfo } = ContactInfoSlice.actions
export default ContactInfoSlice.reducer
