import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RoomType } from "../../../App/enums"
import { profileDefault, roomDefaultPhoto } from "../../../App/GlobaleVariables"
import { isDefined } from "../../../App/Helpers/Helpers"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { RoomPermissions } from "../../../Types/Room"
import RoomModal from "../../Shared/Components/RoomModal"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { resetCreateRoomError } from "../../Shared/SharedSlices/RoomSlice"
import { patchRoomRequest } from "../RoomServiceRequests/RoomServiceRequests"
import { hashtagRegex } from "../../../App/Regex"

function EditRoomModal() {
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const [roomDescription, setRoomDescription] = useState(roomDataState?.description)
    const [roomJoinQuestion, setroomJoinQuestion] = useState(roomDataState?.join_question)
    const [roomName, setRoomName] = useState(roomDataState.name)
    const type = roomDataState.type === RoomType.Public
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const [isPublic, setIsPublic] = useState(type)
    const [permissions, setPermissions] = useState<RoomPermissions>({
        allow_requests: roomDataState?.room_permissions[0]?.allow_requests,
        invite_others: roomDataState?.room_permissions[0]?.invite_others,
        share_documents: roomDataState?.room_permissions[0]?.share_documents,
    })
    const [photo, setPhoto] = useState<any>(null)
    const [previewUrl, setPreviewUrl] = useState<string>(roomDataState.photo! || "")
    const createRoomErrorState = useSelector((state: RootState) => state.roomSlice.createRoomErrorSlice)
    const photoziseErrorState = useSelector((state: RootState) => state.roomSlice.photoSizeErrorSlice.message)
    const textWithoutHtml = roomDescription.replace(hashtagRegex, "$1")

    const onRoomTypeSelected = () => {
        setIsPublic(!isPublic)
    }

    const onModalClose = () => {
        dispatch(setModalData({ open: false, editRoom: false }))
        dispatch(resetCreateRoomError())
    }

    const onPhotoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files

        if (files) {
            const reader = new FileReader()
            reader.readAsDataURL(files[0])
            reader.addEventListener("load", () => {
                setPhoto(files[0])
                setPreviewUrl(URL.createObjectURL(files[0]))
            })
        }
    }

    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPermissions({ ...permissions, [event.target.name]: event.target.checked })
    }

    const onDeleteButtonClick = () => {
        dispatch(setModalData({ open: false, editRoom: false }))
        dispatch(setModalData({ open: true, deleteRoom: true }))
    }

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        const formData = new FormData()
        let roomType = isPublic ? "public" : "private"
        if (isDefined(photo)) {
            formData.append("photo", photo)
        }
        formData.append("name", roomName)
        formData.append("organization", roomDataState.organization?.id.toString()!)
        formData.append("description", textWithoutHtml!)
        formData.append("join_question",roomJoinQuestion)
        formData.append("room_permissions[0]allow_requests", JSON.stringify(permissions.allow_requests))
        formData.append("room_permissions[0]invite_others", JSON.stringify(permissions.invite_others))
        formData.append("room_permissions[0]share_documents", JSON.stringify(permissions.share_documents))
        formData.append("type", roomType)
        formData.append("user", userDataState.id?.toString()!)

        dispatch(patchRoomRequest(roomDataState.organization?.id!, roomDataState.id, formData))
    }

    return (
        <RoomModal
            title={translation.editRoom}
            hasDeleteOption={true}
            onModalClose={onModalClose}
            onRoomTypeSelected={onRoomTypeSelected}
            isPublicRoom={isPublic}
            isPrivateRoom={!isPublic}
            roomName={roomName}
            setRoomName={setRoomName}
            roomNameError={createRoomErrorState.message?.name!}
            description={roomDescription!}
            setDescription={setRoomDescription}
            descriptionError={createRoomErrorState.message?.description!}
            detailError={createRoomErrorState.message?.detail!}
            roomManager={userDataState.first_name + " " + userDataState.last_name}
            roomManagerPhoto={userDataState.profile_photo?.url_path || profileDefault}
            photo={roomDataState.photo! || roomDefaultPhoto}
            previewUrl={previewUrl}
            onPhotoChange={onPhotoChange}
            share_documents={permissions.share_documents}
            allow_requests={permissions.allow_requests}
            invite_others={permissions.invite_others}
            onFormSubmit={onFormSubmit}
            onCheckboxChange={onCheckboxChange}
            onDeleteButtonClick={onDeleteButtonClick}
            buttonText={translation.save}
            photoSizeError={photoziseErrorState}
            roomJoinQuestion={roomJoinQuestion!}
            setRoomJoinQuestion={setroomJoinQuestion}
            roomJoinQuestionError={createRoomErrorState.message?.join_question!}
        />
    )
}

export default EditRoomModal

