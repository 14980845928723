import { NavigateFunction } from "react-router-dom"
import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { getCreateRoomErrorMessage, getPhotoSizeErrorMessage, resetCreateRoomError, setRoomData, setRoomsData } from "../../Shared/SharedSlices/RoomSlice"

const postRequest = (organizationId: number, data: FormData) => {
    return HTTPService.post(`/organization/${organizationId}/room/`, data, headers)
}

const getRoomsRequest = (organizationId: number) => {
    return HTTPService.get(`/organization/${organizationId}/rooms/`, headers)
}

export const postRoomRequest = (id: number, formData: FormData, navigate: NavigateFunction) => async (dispatch: Function) => {
    try {
        const response = await postRequest(id, formData)
        if (response.status === 201) {
            navigate(`/room/${id}/${response.data.id}`)
            try {
                const response = await getRoomsRequest(id)
                if (response.status === 200) {
                    const data = response.data
                    dispatch(setRoomsData({ joined_rooms: data.joined_rooms, owned_rooms: data.owned_rooms }))
                    dispatch(setModalData({ open: true, createRoom: false, successfulCreateRoom: true  }))
                    //dispatch(setModalData({ open: true, successfulCreateRoom: true }))

                    await dispatch(resetCreateRoomError())
                }
            } catch (error: any) {
                console.log(error)
            }
        }
    } catch (error: any) {
       
        const errors = {
            msg: error.response.data,
            status: error.response.status,
        }

        if (error.response.status === 413) {
            dispatch(getPhotoSizeErrorMessage(true))
        }
      
    
        await dispatch(
            getCreateRoomErrorMessage({
                message: errors.msg,
                status: errors.status,
            })
        )
    }
}

export const RoomDeleteRequest = (roomId: number, organizationId: number, navigate: NavigateFunction) => async (dispatch: Function) => {
    try {
        const response = await HTTPService.delete(`/organization/${organizationId}/room/${roomId}/`, headers)
        if (response.status === 204) {
            dispatch(setModalData({ open: false, deleteRoom: false }))
            dispatch(setModalData({ open: true, deleteRoomSuccessfully: true }))

            navigate("/dashboard")
            window.scrollTo(0, 0)
        }
    } catch (error) {
        console.log(error)
    }
}

const patchRequest = (organizationId: number, roomId: number, data: FormData) => {
    return HTTPService.patch(`/organization/${organizationId}/room/${roomId}/`, data, headers)
}

export const getRoomRequest = (roomId: number) => {
    return HTTPService.get(`/room/${roomId}/`, headers)
}

export const patchRoomRequest = (id: number, roomId: number, formData: FormData) => async (dispatch: Function) => {
    try {
        const response = await patchRequest(id, roomId, formData)
        if (response.status === 200) {
            try {
                const response = await getRoomRequest(roomId)
                if (response.status === 200) {
                    dispatch(setRoomData(response.data))

                    dispatch(setModalData({ open: false, deleteRoom: false }))
                    dispatch(setModalData({ open: true, editRoomSuccessfully: true }))

                    await dispatch(resetCreateRoomError())
                }
            } catch (error: any) {
                console.log(error)
            }
        }
    } catch (error: any) {
        const errors = {
            msg: error.response.data,
            status: error.response.status,
        }
        await dispatch(
            getCreateRoomErrorMessage({
                message: errors.msg,
                status: errors.status,
            })
        )
    }
}
