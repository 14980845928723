import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"

import { SignupResponseMessage } from "../../../Types/Responses/SignupResponseMessage"

interface Data {
    first_name: string
    last_name: string
    email: string
    password: string
    confirm_password: string
    reference_id: number
    privacy_settings: Array<{ private_messages: boolean; search_results: boolean; show_contact_info: boolean; show_location: boolean }>
    room_invitation_id: string
    edusiia_matching: boolean
    location_state_id: number
    involved_organisation_category_id: number

}
const initialDataState = {} as Data

const SignupDataSlice = createSlice({
    name: "signupData",
    initialState: initialDataState,
    reducers: {
        setFormData(state, action: PayloadAction<Data>) {
            state.first_name = action.payload.first_name
            state.last_name = action.payload.last_name
            state.email = action.payload.email
            state.password = action.payload.password
            state.confirm_password = action.payload.confirm_password
            state.reference_id = action.payload.reference_id
            state.privacy_settings = action.payload.privacy_settings
            state.room_invitation_id = action.payload.room_invitation_id
            state.edusiia_matching = action.payload.edusiia_matching
            state.location_state_id = action.payload.location_state_id
            state.involved_organisation_category_id = action.payload.involved_organisation_category_id
        },
    },
})

interface SignupErrorData {
    message: SignupResponseMessage
    status: number
}

const initialSignupErrorState = {
    message: {},
    status: 0,
} as SignupErrorData

const SignupErrorSlice = createSlice({
    name: "signupError",
    initialState: initialSignupErrorState,
    reducers: {
        getSignupError(state, action: PayloadAction<SignupErrorData>) {
            state.message = action.payload.message
            state.status = action.payload.status
        },
        resetSignupError(state) {
            state.message = initialSignupErrorState.message
        },
    },
})

interface SignupFormStatus {
    status: boolean
}
const initialStatusState: SignupFormStatus = { status: false }

const SignupStatusSlice = createSlice({
    name: "signupStatusStatus",
    initialState: initialStatusState,
    reducers: {
        getSignupStatus(state, action: PayloadAction<SignupFormStatus>) {
            state.status = action.payload.status
        },
    },
})

const initialSignupEmailErrorState = { message: "" }

const SignupEmailErrorSlice = createSlice({
    name: "signupEmailError",
    initialState: initialSignupEmailErrorState,
    reducers: {
        setEmailError(state = initialSignupEmailErrorState) {
            state.message = "Diese E-Mail-Adresse wird bereits für ein Konto genutzt."
        },
    },
})

interface SignupErrorStatus {
    status: boolean
}
const initialErrorStatusState: SignupErrorStatus = { status: false }

const SignupErrorStatusSlice = createSlice({
    name: "signupErrorStatusStatus",
    initialState: initialErrorStatusState,
    reducers: {
        getSignupErrorStatus(state, action: PayloadAction<SignupErrorStatus>) {
            state.status = action.payload.status
        },
    },
})

export const { setFormData } = SignupDataSlice.actions
export const { getSignupStatus } = SignupStatusSlice.actions
export const { setEmailError } = SignupEmailErrorSlice.actions
export const { getSignupError } = SignupErrorSlice.actions
export const { getSignupErrorStatus } = SignupErrorStatusSlice.actions
export const { resetSignupError } = SignupErrorSlice.actions
export default combineReducers({
    signupData: SignupDataSlice.reducer,
    signupStatus: SignupStatusSlice.reducer,
    signupEmailError: SignupEmailErrorSlice.reducer,
    signupError: SignupErrorSlice.reducer,
    singupErrorStatus: SignupErrorStatusSlice.reducer,
})
