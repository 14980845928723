import React, { useEffect, useState } from "react"
import Button from "../Primitive/Button"
import Card from "../Primitive/Card"
import "../../../Styles/main.scss"
import UserCard from "../Components/UserCard"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../App/store"
import { LoadingAnimation } from "../Components/SvgIcons"
import { useNavigate, useParams } from "react-router-dom"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { organizationProfileDefault } from "../../../App/GlobaleVariables"
import { RoleType } from "../../../App/enums"
import HTTPService from "../../../App/HTTPService"
import { headers } from "../../../App/Axios"
import { setNewOrganizationsData } from "../SharedSlices/NewestMembersSlice"

export const newestOrganizationsGetRequest = () => {
    return HTTPService.get("/recent-organizations/", headers)
}

function NewestOrganizationsCard() {
    const newOrganizationsState = useSelector((state: RootState) => state.newMembers.newOrganizationsSlice.organizations)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(true)
    const translation = useSelector(selectTranslations)
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const navigate = useNavigate()
    const { id } = useParams()
    const filterNumber = 3

    const onRouteChange = () => {
        switch (role) {
            case RoleType.USER:
                const userPath = "/newest-organizations"
                navigate(userPath)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                const organizationPath = `/newest-organizations/${id}`
                navigate(organizationPath)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    const onNavigationToOrganizationProfile = (organizationId: number) => {
        switch (role) {
            case RoleType.USER:
                const userPath = `/organization-profile/${organizationId}`
                navigate(userPath)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                const organizationPath = `/organization-profile/${id}/${organizationId}`
                navigate(organizationPath)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    useEffect(() => {
        let isMounted = true

        newestOrganizationsGetRequest()
            .then(response => {
                if (isMounted && response.status === 200) {
                    dispatch(setNewOrganizationsData(response.data))
                    setIsLoading(false)
                }
            })
            .catch(error => {
                console.log(error)
            })
        return () => {
            isMounted = false
        }
    }, [dispatch])

    const organizations = newOrganizationsState.filter(organization => {
        return organization.id !== organizationDataState.id
    })

    return (
        <>
            {isLoading ? (
                <div className="new-members-card__loading-card">
                    <LoadingAnimation />
                </div>
            ) : (
                <div className="new-members-card">
                    <Card>
                        <div className="new-members-card__members">
                            {organizations?.slice(0, filterNumber).map((item, index) => {
                                return (
                                    <div
                                        key={item.id}
                                        className={`new-members-item  ${
                                            organizations.length <= filterNumber
                                                ? index === organizations.length - 1
                                                    ? "new-members-item--no-border"
                                                    : "new-members-item--border"
                                                : index === filterNumber - 1
                                                ? "new-members-item--no-border"
                                                : "new-members-item--border"
                                        }`}
                                    >
                                        <UserCard
                                            onClick={() => onNavigationToOrganizationProfile(item.id)}
                                            imageSrc={item.photo?.url_path || organizationProfileDefault}
                                            text={item.name}
                                            userCardStyle={`new-members-item`}
                                            leftPanelStyle={"left-panel"}
                                            middlePanelStyle={"middle-panel"}
                                            rightPanelStyle={"right-panel"}
                                            profileImageStyle={"image image__profile image__profile--fit image__profile--size-small"}
                                            textStyle={"text"}
                                            textStyleMore25={"text"}
                                            rightTextStyle={"right-text"}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                        <div className="new-members-card__button-container">
                            <div className="new-members-card__button">
                                <Button
                                    onClick={onRouteChange}
                                    className="button button--border-radius-09 button--color-grey button--hover-darker button--cursor-pointer button--text-color-grey button--font-size-small button--font-weight-400  "
                                >
                                    {translation.seeAllResults}
                                </Button>
                            </div>
                        </div>
                    </Card>
                </div>
            )}
        </>
    )
}

export default NewestOrganizationsCard
