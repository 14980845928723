import React, { useEffect, useState } from "react"
import { LogoIcon, TooltipQuestionmark, LoadingAnimation } from "../../Shared/Components/SvgIcons"
import "../../../Styles/main.scss"
import TextInput from "../../Shared/Primitive/TextInput"
import Dropdown from "../../Shared/Primitive/Dropdown"
import Button from "../../Shared/Primitive/Button"
import Tooltip from "../../Shared/Primitive/Tooltip"
import { useNavigate } from "react-router-dom"
import { emailGetRequest } from "./SignupServiceRequests"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../App/store"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { getSignupStatus } from "./SignupSlice"
import { validEmail, validName, validPassword } from "../../../App/Regex"
import SearchableDropdown from "../../Shared/Components/SearchableDropdown"
import { orgCategoriesGetRequest } from "../../Authentication/SignUp/SignupServiceRequests";
import StorageService from "../../../App/StorageService"

function SignupForm() {
    const translation = useSelector(selectTranslations)

    let navigate = useNavigate()
    const dispatch = useDispatch()
    const statusState = useSelector((state: RootState) => state.signupSlice.signupStatus)
    const emailErrorState = useSelector((state: RootState) => state.signupSlice.signupEmailError)
    const errorState = useSelector((state: RootState) => state.signupSlice.signupError)
    
    const [typeOfOrg,setTypeofOrgArray] = useState([{ id: 0, name: ""}])
    const language = StorageService.hasItem("language") ? StorageService.getItem("language") : 'deutsch'
    const locale = language === 'english' ? 'en' : 'de'
    const name_identifier = 'name_'+locale
    const [isLoading, setIsLoading] = useState(false)
    const [nameFieldError, setNameFieldError] = useState("")
    const [lastNameFieldError, setLastNameFieldError] = useState("")
    const [emailFieldError, setEmailFieldError] = useState("")
    const [passwordFieldError, setPasswordFieldError] = useState("")
    const [confirmPasswordFieldError, setConfirmPasswordFieldError] = useState("")
    const [userStateFieldError, setUserStateFieldError] = useState("")
    const [typeOfOrganizationFieldError, settypeOfOrganizationFieldError] = useState("")
    const [itemId, setItemId] = useState(0)
    const [userState, _setUserState] = useState(0)
    const [typeOfOrganization, _setTypeOfOrganization] = useState(0)
    const [userForm, setUserForm] = useState({ name: "", lastName: "", email: "", password: "", confirmPassword: "", involved_organisation_category_id: 0, location_state_id: 0 })
    let validationSuccess: boolean = false

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserForm({ ...userForm, [event.target.name]: event.target.value })
    }

    useEffect(() => {
        let isMounted = true
        orgCategoriesGetRequest()
            .then(response => {
                if (isMounted && response.status === 200) {
                    let fixedOptions = [
                        {
                            id: 14,
                            name_de: "Sonstige",
                            name_en: "Other",
                        },
                        {
                            id: 15,
                            name_de: "Möchte ich nicht angeben",
                            name_en: "Prefer not to say",
                        },
                    ]

                    response.data = [...response.data,...fixedOptions]

                    response.data.forEach( function(data: { [x: string]: any }) {
                        
                        data['name'] = data[name_identifier];
                        delete data['name_de'];
                        delete data['name_en'];
                      });

                    setTypeofOrgArray(response.data)
                    setIsLoading(false)
                }
            })
            .catch(error => {
                console.log(error.response)
            })
        return () => {
            isMounted = false
        }
    }, [])

    useEffect(() => {
        setUserForm({ ...userForm, involved_organisation_category_id: typeOfOrganization,location_state_id: userState })
    },[typeOfOrganization,userState,setUserForm])

    const onFormValidation = () => {
        if (userForm.name.length === 0) {
            setNameFieldError(translation.thisFieldIsRequired)
        } else if (!validName.test(userForm.name)) {
            setNameFieldError(translation.thisFieldContainsOnlyLetters)
        } else {
            setNameFieldError("")
            validationSuccess = true
        }
        if (userForm.lastName.length === 0) {
            setLastNameFieldError(translation.thisFieldIsRequired)
            validationSuccess = false
        } else if (!validName.test(userForm.lastName)) {
            setLastNameFieldError(translation.thisFieldContainsOnlyLetters)
            validationSuccess = false
        } else {
            setLastNameFieldError("")
            validationSuccess = validationSuccess !== false ? true : false
        }
        if (userForm.email.length === 0) {
            setEmailFieldError(translation.thisFieldIsRequired)
            validationSuccess = false
        } else if (validEmail.test(userForm.email)) {
            setEmailFieldError("")
            validationSuccess = validationSuccess !== false ? true : false
        } else {
            setEmailFieldError(translation.enterValidEmail)
            validationSuccess = false
        }
        if (userForm.password.length === 0) {
            setPasswordFieldError(translation.thisFieldIsRequired)
            validationSuccess = false
        } else if (!validPassword.test(userForm.password)) {
            setPasswordFieldError(translation.passwordLongerThan8)
            validationSuccess = false
        } else {
            setPasswordFieldError("")
            validationSuccess = validationSuccess !== false ? true : false
        }
        if (userForm.confirmPassword.length === 0) {
            setConfirmPasswordFieldError(translation.thisFieldIsRequired)
            validationSuccess = false
        } else if (userForm.confirmPassword !== userForm.password) {
            setConfirmPasswordFieldError(translation.passwordsDontMatch)
            validationSuccess = false
        } else {
            setConfirmPasswordFieldError("")
            validationSuccess = validationSuccess !== false ? true : false
        }
        if (userForm.location_state_id === 0) {
            setUserStateFieldError(translation.thisFieldIsRequired)
            validationSuccess = false
        } else {
            setUserStateFieldError("")
            validationSuccess = validationSuccess !== false ? true : false
        }
        if (userForm.involved_organisation_category_id === 0) {
            settypeOfOrganizationFieldError(translation.thisFieldIsRequired)
            validationSuccess = false
        } else {
            settypeOfOrganizationFieldError("")
            validationSuccess = validationSuccess !== false ? true : false
        }
    }

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        onFormValidation()

        if (validationSuccess === true) {
            dispatch(emailGetRequest(userForm))
        }
    }

    useEffect(() => {
        if (statusState.status === true) {
            dispatch(getSignupStatus({ status: false }))
            navigate("/edusiiamatching")
            window.scrollTo(0, 0)
        }
    }, [statusState, dispatch, navigate])

    const onSignInClick = () => {
        navigate("/")
        window.scrollTo(0, 0)
    }

    return (
        <form className="signup-form" onSubmit={onFormSubmit}>
            <div className="signup-form__logo">
                {" "}
                <LogoIcon />
            </div>
            <div className="signup-form__sign-in-text">
                {translation.alreadyHaveAnAccount}
                <p className="signup-form__link" onClick={onSignInClick}>
                    {translation.signin}
                </p>{" "}
            </div>
            <div className="signup-form__title signup-form__title--big-margin">
                <h2 className="signup-form__title"> {translation.signup} </h2>
            </div>
            <div className="signup-form__double-input-container">
                <div className="double-input-container__single-input double-input-container__single-input--margin-right-1">
                    <p className="p3 signup-form__small-text">{translation.firstName}*</p>
                    <TextInput type="text" textinputsize="small" name="name" value={userForm.name} onChange={onInputChange} />
                    <p className="p3  signup-form__error-message">
                        {nameFieldError}
                        {errorState.message.first_name}
                    </p>
                </div>
                <div className="double-input-container__single-input">
                    <p className="p3 signup-form__small-text">{translation.lastName}*</p>
                    <TextInput type="text" textinputsize="small" value={userForm.lastName} name="lastName" onChange={onInputChange} />
                    <p className="p3  signup-form__error-message">
                        {lastNameFieldError}
                        {errorState.message.last_name}
                    </p>
                </div>
            </div>
            <div className="signup-form__single-input">
                <p className="p3 signup-form__small-text">{translation.email}*</p>
                <TextInput type="text" textinputsize="large" placeholder={translation.email} name="email" value={userForm.email} onChange={onInputChange} />
                <p className="p3  signup-form__error-message">
                    {emailFieldError}
                    {errorState.message.email}
                    {emailErrorState.message}
                </p>
            </div>
            <div className="signup-form__double-input-container">
                <div className="double-input-container__single-input double-input-container__single-input--margin-right-1">
                    <div className="signup-form__tooltip-container">
                        <p className="p3 signup-form__small-text-tooltip">{translation.securePassword}* </p>
                        <div className="signup-form__tooltip">
                            <Tooltip
                                direction="right"
                                content={
                                    <p>
                                        {translation.passwordRequirement1} <br></br>
                                        {translation.passwordRequirement3}
                                        <br></br>
                                        {translation.passwordRequirement2}
                                    </p>
                                }
                            >
                                <TooltipQuestionmark />
                            </Tooltip>
                        </div>
                    </div>
                    <TextInput type="password" textinputsize="small" name="password" value={userForm.password} onChange={onInputChange} />
                    <p className="p3  signup-form__error-message">
                        {passwordFieldError}
                        {errorState.message.password}
                    </p>
                </div>
                <div className="double-input-container__single-input">
                    <p className="p3 signup-form__small-text">{translation.repeatSecurePassword}*</p>
                    <TextInput type="password" textinputsize="small" name="confirmPassword" value={userForm.confirmPassword} onChange={onInputChange} />
                    <p className="p3  signup-form__error-message">
                        {confirmPasswordFieldError}
                        {errorState.message.confirm_password}
                    </p>
                </div>
            </div>
            <p className="p3 signup-form__small-text signup-form__small-text--margin-top"> {translation.inWhichStateDoYouWork}* </p>
            <SearchableDropdown validationError={userStateFieldError} itemId={userState} setItemId={_setUserState}/>

            <p className="p3 signup-form__small-text"> {translation.typeOfOrgYourInvolvedIn}* </p>{" "}
            {isLoading ? (
                <div className="signup-form__loading-animation-container">
                    <LoadingAnimation type="dropdown" />
                </div>
            ) : (
                <div className="dropdown">
                    <Dropdown itemId={typeOfOrganization} setItemId={_setTypeOfOrganization} items={typeOfOrg} placeholder={translation.chooseMostApplicable} tabIndex={typeOfOrg[0].id} />
                    <p className="p3  signup-form__error-message">
                        {typeOfOrganizationFieldError}
                    </p>
                </div>
            )}
            <div className="signup-form__button-container">
                <div className="signup-form__button-size">
                    <Button
                        type="submit"
                        className="button button--border-radius-09 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-large button--font-weight-400  "
                    >
                        <div>{translation.next}</div>
                    </Button>
                </div>
            </div>
        </form>
    )
}
export default SignupForm
