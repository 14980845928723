import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Room, RoomError } from "../../../Types/Room"
import { RoomData } from "../../../Types/RoomData"
import { SortOptions } from "../../../App/enums"

const initialState: RoomData = { rooms: [], joined_rooms: [], owned_rooms: [],}

const RoomsSlice = createSlice({
    name: "RoomSlice",
    initialState: initialState,
    reducers: {
        setRoomsData(state, action: PayloadAction<RoomData>) {
            state.rooms = action.payload.rooms
            state.joined_rooms = action.payload.joined_rooms
            state.owned_rooms = action.payload.owned_rooms
        },
    },
})

const initialRoomState = { room: {} as Room }

const RoomSlice = createSlice({
    name: "RoomSlice",
    initialState: initialRoomState,
    reducers: {
        setRoomData(state, action: PayloadAction<Room>) {
            state.room = action.payload
        },
    },
})

interface ErrorData {
    message: RoomError
    status: number
}

const initialCreateRoomErrorState: ErrorData = {
    message: {} as RoomError,
    status: 0,
}

const CreateRoomErrorSlice = createSlice({
    name: "CreateRoomErrorSlice",
    initialState: initialCreateRoomErrorState,
    reducers: {
        getCreateRoomErrorMessage(state, action: PayloadAction<ErrorData>) {
            state.message = action.payload.message
            state.status = action.payload.status
        },
        resetCreateRoomError(state) {
            state.message = initialCreateRoomErrorState.message
        },
    },
})

const initialPhotoSizeErrorState = {
    message: false,
}

const PhotoSizeErrorSlice = createSlice({
    name: "PhotoSizeErrorSlice",
    initialState: initialPhotoSizeErrorState,
    reducers: {
        getPhotoSizeErrorMessage(state, action: PayloadAction<boolean>) {
            state.message = action.payload
        },
    },
})

const initialRoomManagerState = { room_manager: false }

const RoomManagerSlice = createSlice({
    name: "RoomManagerSlice",
    initialState: initialRoomManagerState,
    reducers: {
        setRoomManagerData(state, action: PayloadAction<boolean>) {
            state.room_manager = action.payload
        },
    },
})

const initialRoomOwnerState = { room_owner: false }

const RoomOwnerSlice = createSlice({
    name: "RoomOwnerSlice",
    initialState: initialRoomOwnerState,
    reducers: {
        setRoomOwnerData(state, action: PayloadAction<boolean>) {
            state.room_owner = action.payload
        },
    },
})


const initialOrgRoomSortOptionState = { sort_options: "" }

const OrgRoomSortOptionSlice = createSlice({
    name: "OrgRoomSortOptionSlice",
    initialState: initialOrgRoomSortOptionState,
    reducers: {
        setRoomSortOption(state, action: PayloadAction<keyof typeof SortOptions>) {
            state.sort_options = action.payload
        },
    },
})

const initialUserRoomSortOptionState = { sort_options: "" }

const UserRoomSortOptionSlice = createSlice({
    name: "UserRoomSortOptionSlice",
    initialState: initialUserRoomSortOptionState,
    reducers: {
        setUserRoomSortOption(state, action: PayloadAction<keyof typeof SortOptions>) {
            state.sort_options = action.payload
        },
    },
})


export const { setRoomsData } = RoomsSlice.actions
export const { setRoomData } = RoomSlice.actions
export const { setRoomManagerData } = RoomManagerSlice.actions
export const { getCreateRoomErrorMessage } = CreateRoomErrorSlice.actions
export const { resetCreateRoomError } = CreateRoomErrorSlice.actions
export const { getPhotoSizeErrorMessage } = PhotoSizeErrorSlice.actions
export const { setRoomOwnerData } = RoomOwnerSlice.actions
export const { setRoomSortOption } = OrgRoomSortOptionSlice.actions
export const { setUserRoomSortOption } = UserRoomSortOptionSlice.actions

export default combineReducers({
    roomsSlice: RoomsSlice.reducer,
    createRoomErrorSlice: CreateRoomErrorSlice.reducer,
    roomSlice: RoomSlice.reducer,
    roomManagerSlice: RoomManagerSlice.reducer,
    photoSizeErrorSlice: PhotoSizeErrorSlice.reducer,
    roomOwnerSlice:RoomOwnerSlice.reducer,
    orgRoomSortOptionSlice:OrgRoomSortOptionSlice.reducer,
    userRoomSortOptionSlice:UserRoomSortOptionSlice.reducer
})
