import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { CloseIcon } from "../../Shared/Components/SvgIcons"
import Button from "../../Shared/Primitive/Button"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { RootState } from "../../../App/store"
import { RoleType } from "../../../App/enums"
import { ConversationLeaveRequest, chatListGetRequest } from "../ChatServiceRequest"
import HTTPService from "../../../App/HTTPService"
import { setActiveConversation, setConversationList } from "../ChatSlice"

function LeaveChatModal() {
    const translation = useSelector(selectTranslations)
    const activeConversationId = useSelector((state: RootState) => state.chatSlice.activeConversationId)
    const dispatch = useDispatch()

    const onLeaveRoom = () => {
        try {
            ConversationLeaveRequest(activeConversationId).then(
                response => {
                    if (response.status === 200) {
                        dispatch(setActiveConversation(0))

                        chatListGetRequest().then(response => {
                            if (response.status === 200) {
                                dispatch(setConversationList(response.data))
                            }
                        })

                        dispatch(setModalData({ open: true, leaveChatModal: false }))
                    }
                }
            )
        } catch (error: any) {
            //TODO error handling   
        }
    }

    const onModalClose = () => {
        dispatch(setModalData({ open: false, leaveChatModal: false }))
    }

    return (
        <div className="delete-room-modal">
            <div className="delete-room-modal__content">
                <div className="delete-room-modal__upper-panel">
                    <div className="delete-room-modal__title">{translation.leaveChat}</div>
                    <div onClick={onModalClose} className="delete-room-modal__close-icon">
                        <CloseIcon />
                    </div>
                </div>
                <div className="delete-room-modal__divider"></div>
                <div className="delete-room-modal__middle-panel">
                    <div className="delete-room-modal__text">{translation.doYouwantToLeaveChat}</div>
                    <div className="delete-room-modal__delete-icon"></div>
                </div>
                <div className="delete-room-modal__button-container  delete-room-modal__button-container--leave-room">
                    <div className="delete-room-modal__button">
                        <Button
                            onClick={onLeaveRoom}
                            type="submit"
                            className="button button--font-size-small button--font-weight-400 button--text-color-red  button--color-grey  button--text-color-white button--color-red button--hover-brighter button--cursor-pointer button--border-radius-05 "
                        >
                            {translation.continue}
                        </Button>
                    </div>
                    <div className="delete-room-modal__button">
                        <Button
                            onClick={onModalClose}
                            type="submit"
                            className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-red button--hover-brighter button--cursor-pointer button--border-radius-05 "
                        >
                            {translation.cancel}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeaveChatModal
