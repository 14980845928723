import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, useLocation, useParams } from "react-router-dom"
import { setRoleData } from "../../Module/Shared/SharedSlices/RolesSlice"
import { StorageKey } from "../enums"
import SessionService from "../SessionService"
import { RootState } from "../store"
import { PrivateRouteProps } from "./RoutingType"
import { Role } from "../../Types/RoleType"

const handleRoleNavigation = (Role: Role, currentRole: Role) => async (dispatch: Function) => {
    if (currentRole !== Role) {
        SessionService.setItem(StorageKey.roles, Role!)
        dispatch(setRoleData(Role!))
    }
}

const PrivateRoute = ({ Component, Role }: PrivateRouteProps) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const isAuthenticated = useSelector((state: RootState) => state.signinSlice.authenticationSlice)
    const currentRole = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const { id, eventId } = useParams()
    const roleStatus = useSelector((state: RootState) => state.rolesSlice.RoleStatusSlice.status)
    const organization = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)

    useEffect(() => {
        if (!roleStatus) {
            dispatch(handleRoleNavigation(Role, currentRole))
        }
    }, [Role, currentRole, dispatch, eventId, id, organization.id])

    const authenticated = isAuthenticated.hasAccessToken

    return authenticated ? <Component /> : <Navigate to={`${`/signin?redirectUrl=${location.pathname}${location.search}`}`} />
}
export default PrivateRoute
