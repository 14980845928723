import React, { useEffect, useState } from "react"
import { VectorDown, VectorUp } from "./SvgIcons"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { countryStateGetRequest } from "../../Authentication/SignUp/SignupServiceRequests"
import { LoadingAnimation, LogoIcon } from "../../Shared/Components/SvgIcons"
import StorageService from "../../../App/StorageService"
import { validEmail } from "../../../App/Regex"
import { use } from "i18next"
import Tooltip from "../../Shared/Primitive/Tooltip"
import { TooltipQuestionmark } from "../../Shared/Components/SvgIcons"

function SearchableDropdown(
    props: {
        itemId?: null | number
        hasTooltip?: false | boolean
        tooltipContent?: any
        title?: null | string
        inSettingsModal?: null | boolean
        validationError: null | string
        setItemId: Function
    } & React.HTMLAttributes<HTMLDivElement>
) {
    const [selected, setSelected] = useState("")
    const [isSelected, setIsSelected] = useState(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    let DropdownRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    let baseDropdownClass = props.inSettingsModal == true ? "dropdown__select-settings" : "dropdown__select"
    const translation = useSelector(selectTranslations)
    const language = StorageService.hasItem("language") ? StorageService.getItem("language") : "deutsch"
    const locale = language === "english" ? "en" : "de"
    const name_identifier = "name_" + locale
    const [filter, setFilter] = useState("")
    const [statesArray, setStatesArray] = useState([{ id: 0, name_de: "", name_en: "", states: [{ id: 0, name_de: "", name_en: "" }] }])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        let isMounted = true
        countryStateGetRequest(locale)
            .then(response => {
                if (isMounted && response.status === 200) {
                    setStatesArray(response.data)
                    setIsLoading(false)

                    //set current state as selected item
                    if (props.itemId) {
                        for (let p of response.data) {
                            for (let i of p.states) {
                                if (i.id === props.itemId) {
                                    setSelected(i.name_de)
                                    setFilter(i.name_de)
                                }
                            }
                        }
                    }
                }
            })
            .catch(error => {
                console.log(error.response)
            })
        return () => {
            isMounted = false
        }
    }, [])

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>) => {
        setFilter(event.target.value)
        setIsDropdownOpen(true)
    }
    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (isDropdownOpen && DropdownRef.current && !DropdownRef.current.contains(event.target as HTMLElement)) {
                setIsDropdownOpen(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    const onLocationClick = (name: string, id: number) => {
        setSelected(name)
        setIsSelected(true)
        props.setItemId(id)
        setFilter(name)
        setIsDropdownOpen(false)
    }
    return isLoading ? (
        <div className="signup-form__loading-animation-container">
            <LoadingAnimation type="dropdown" />
        </div>
    ) : (
        <div className="dropdown dropdown--margin-bottom" ref={DropdownRef} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            {props.hasTooltip && (
                <div className="single-text-input__text">
                    {props.title}
                    <div>
                        <Tooltip direction="settings-top" content={props.tooltipContent}>
                            <TooltipQuestionmark />
                        </Tooltip>
                    </div>
                </div>
            )}
            <div className={baseDropdownClass}>
                <input
                    className="dropdown__select-input"
                    placeholder={translation.chooseYourState}
                    value={filter}
                    onChange={onInputChange}
                    autoComplete="off"
                />
                <div className="dropdown__icon">{isDropdownOpen ? <VectorUp /> : <VectorDown />}</div>
            </div>
            {isDropdownOpen && (
                <div onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="dropdown__container">
                    <div className="dropdown__content">
                        {console.log(filter)}
                        {statesArray.map((state, index) => {
                            return (
                                <div>
                                    {state.states.filter(state =>
                                        (state?.[name_identifier as keyof typeof state] as string).toLowerCase().includes(filter.toLowerCase())
                                    ).length > 0 && (
                                        <>
                                            {state[name_identifier as keyof typeof state] && (
                                                <div className={"dropdown__item dropdown__item--no-cursor"} key={index}>
                                                    <p className="dropdown__text">
                                                        {" "}
                                                        {(state?.[name_identifier as keyof typeof state] as string).toLocaleUpperCase()}
                                                    </p>
                                                </div>
                                            )}
                                        </>
                                    )}

                                    <div>
                                        {state.states
                                            .filter(state =>
                                                (state?.[name_identifier as keyof typeof state] as string).toLowerCase().includes(filter.toLowerCase())
                                            )
                                            .map((state, index) => {
                                                return (
                                                    <div
                                                        key={state.id}
                                                        className={
                                                            selected === state?.[name_identifier as keyof typeof state]
                                                                ? "dropdown__item--padding-left dropdown__item--selected-true"
                                                                : "dropdown__item dropdown__item--padding-left"
                                                        }
                                                        onClick={() => onLocationClick(state?.[name_identifier as keyof typeof state] as string, state.id)}
                                                    >
                                                        <p className="dropdown__text">{state?.[name_identifier as keyof typeof state]}</p>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
            <p className="accordion__error-message">{props.validationError}</p>
        </div>
    )
}
export default SearchableDropdown
