import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { setRoomPostData, setRoomPostsData } from "../../Room/RoomPost/RoomPostSlice"
import { setSingularEventData } from "../SharedSlices/SponsorCardSlice"
import { RootState } from "../../../App/store"
import Image from "../Primitive/Image"
import { LoadingAnimation } from "./SvgIcons"
import { CallerComponent } from "../../../App/enums"

export type ReactionType = {
    id: number
    reaction_name: string
}
type Props = {
    caller: string
    callerId: number
    open: boolean
    setIsEmojiSelectorOpen: Function
    postType?: string
    isFeed?: boolean
}

function Reaction({ open, setIsEmojiSelectorOpen, caller, callerId, postType,isFeed }: Props) {
    const dispatch = useDispatch()
    const pinboardEvent = useSelector((state: RootState) => state.sponsorSlice.SingularEventSlice.event)
    const selectedPost = useSelector((state: RootState) => state.RoomPostsSlice.roomPostSlice.post)
    const posts = useSelector((state: RootState) => state.RoomPostsSlice.roomPostsSlice.posts)
    const [reactionArray, setReactionArray] = useState<ReactionType[] | undefined>(undefined)
    const [loading, setLoading] = useState(false)

    const reactionGetRequest = () => {
        return HTTPService.get("/reaction/", headers)
    }

    const reactionPostRequest = async (reaction_name: string) => {
        try {
            const response = await HTTPService.post(`/${caller}/${callerId}/react/`, { reaction: reaction_name })
            if (response.status === 201 || response.status === 200) {
                switch (caller) {
                    case CallerComponent.eventComment:
                        dispatch(
                            setSingularEventData({
                                ...pinboardEvent,
                                comments: pinboardEvent.comments?.map(comment => {
                                    if (comment.id === callerId) {
                                        return { ...comment, reaction_info: response.data.reaction_info }
                                    }
                                    return comment
                                }),
                            })
                        )

                        break
                    case CallerComponent.post:
                        if (postType && postType === "posts") {
                            dispatch(
                                setRoomPostsData(
                                    posts.map(post => {
                                        if (post.id === callerId) {
                                            return { ...post, reaction_info: response.data.reaction_info }
                                        }
                                        return post
                                    })
                                )
                            )
                        } else {
                            dispatch(setRoomPostData({ ...selectedPost, reaction_info: response.data.reaction_info }))
                        }

                        break
                    case CallerComponent.comment:
                        dispatch(
                            setRoomPostData({
                                ...selectedPost,
                                post_comments: selectedPost.post_comments?.map(comment => {
                                    if (comment.id === callerId) {
                                        return { ...comment, reaction_info: response.data.reaction_info }
                                    }
                                    return comment
                                }),
                            })
                        )
                        break
                    default:
                        break
                }
                setIsEmojiSelectorOpen(false)
            }
        } catch (error: any) {
            console.log(error)
        }
    }

    const onReactionClick = (reaction_name: string) => {
        reactionPostRequest(reaction_name)
    }

    useEffect(() => {
        setLoading(true)
        reactionGetRequest().then(response => {
            if (response.status === 200) {
                setReactionArray(response.data)
                setLoading(false)
            }
        })
    }, [])

    return (
        <div className={`reaction-card ${isFeed? "reaction-card--feed-item":""}  ${open ? "reaction-card--open" : "reaction-card--close"}`}>
            {reactionArray?.map(reaction => {
                return (
                    <div key={reaction.id} onClick={() => onReactionClick(reaction.reaction_name!)} className="reaction-card__emoji-container">
                        {loading ? <LoadingAnimation /> : <Image src={`/reactions/${reaction.reaction_name}.svg`} className="reaction-card__emoji-image" />}
                    </div>
                )
            })}
        </div>
    )
}
export default Reaction
