import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { headers } from "../../../../App/Axios"
import { JoinStatus } from "../../../../App/enums"
import { profileDefault } from "../../../../App/GlobaleVariables"
import HTTPService from "../../../../App/HTTPService"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import { User } from "../../../../Types/User"
import { CloseIcon } from "../../../Shared/Components/SvgIcons"
import UserCard from "../../../Shared/Components/UserCard"
import SearchInput from "../../../Shared/Primitive/SearchInput"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { setSearchQuery } from "../OrganizationProfileEmpoyees/EmployeesSlice"
import { addAdminPostRequest } from "./AdminServiceRequests"

function AddAdminModal() {
    const { id } = useParams()
    const [users, setUsers] = useState<Array<User>>()
    const [searchedUsers, setSearchedUsers] = useState<User[]>([])
    const [addedUser, setAddedUser] = useState(true)
    const [addedSearchedUser, setAddedSearchedUser] = useState(true)
    const [inputState, setInputState] = useState("")
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()

    const getUserRequest = useCallback(() => {
        return HTTPService.get(`/org/${id}/admin-invite-list/`, headers)
    }, [id])

    const searchUsersGetRequest = useCallback(
        (searchQuery: string) => {
            return HTTPService.get(`/org/${id}/admin-invite-list/?query=${searchQuery}`, headers)
        },
        [id]
    )

    useEffect(() => {
        if (inputState.length > 2) {
            searchUsersGetRequest(inputState).then(response => {
                if (response.status === 200) {
                    setSearchedUsers(response.data)
                    setAddedSearchedUser(false)
                }
            })
        }
    }, [dispatch, inputState, searchUsersGetRequest, addedSearchedUser])

    useEffect(() => {
        if (addedUser) {
            getUserRequest()
                .then(response => {
                    if (response.status === 200) {
                        setUsers(response.data)
                        setAddedUser(false)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [getUserRequest, addedUser])

    const closeModal = () => {
        dispatch(setModalData({ open: false, addAdminModal: false }))
    }

    const onRemoveIconClick = () => {
        setInputState("")
        dispatch(setSearchQuery(""))
    }

    const onAddClick = (userId: number) => {
        addAdminPostRequest(parseInt(id!), userId, setAddedUser, setAddedSearchedUser)
    }

    return (
        <div className="add-admin-modal add-admin-modal--static-height">
            <div className="add-admin-modal__title-container">
                <p className="add-admin-modal__title">{translation.addAdmin}</p>
                <div className="add-admin-modal__close-icon" onClick={closeModal}>
                    <CloseIcon />
                </div>
            </div>
            <div className="add-admin-modal__search-container">
                <SearchInput
                    onRemoveIconClick={onRemoveIconClick}
                    searchvalue={inputState}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputState(event.target.value)}
                    isGlobalSearch={false}
                />
            </div>
            <div className="room-invite-modal__item-container">
                {(inputState.length > 0 ? searchedUsers : users)?.map((item, index) => {
                    return (
                        <div
                            className={`room-invite-modal__item ${
                                index === users?.length! - 1 ? "room-invite-modal__item--no-border" : "room-invite-modal__item--border"
                            }`}
                            key={item.id}
                        >
                            <UserCard
                                imageSrc={item.profile_photo?.url_path || profileDefault}
                                text={item.first_name + " " + item.last_name}
                                subtext={""}
                                profileImageStyle="image image__profile image__profile--fit image__profile--size-small "
                                userCardStyle={"room-invite-modal-item"}
                                leftPanelStyle={"left-panel"}
                                middlePanelStyle={"middle-panel"}
                                textStyleContainer={"text-container"}
                                textStyle={"text"}
                                textStyleMore25={"text"}
                                subtextStyle={"subtext"}
                                rightPanelStyle={"right-panel"}
                                rightTextStyle={""}
                                rightText={
                                    <div
                                        className={`room-invite-modal__invite-button room-invite-modal__invite-button--${item.invite_status}`}
                                        onClick={() => onAddClick(item.id)}
                                    >
                                        {item.invite_status === JoinStatus.PENDING ? translation.pending : translation.add}
                                    </div>
                                }
                            />
                        </div>
                    )
                })}
                {inputState.length > 2 && searchedUsers.length === 0 && (
                    <div className="room-invite-modal__no-data-text">{`${translation.noResultThatContain} '${inputState}' ${translation.wasFound}`}</div>
                )}
            </div>
        </div>
    )
}

export default AddAdminModal
