import { NavigateFunction } from "react-router-dom"
import { RoleType} from "../../../App/enums"


export const onBackToRoomNavigation = (role:keyof typeof RoleType, roomId:string, orgId:string, navigate:NavigateFunction) => {
    switch (role) {
        case RoleType.USER:
            navigate(`/room/${roomId}`)
            window.scrollTo(0, 0)
            break
        case RoleType.ORGANIZATION:
            navigate(`/room/${orgId}/${roomId}`)
            window.scrollTo(0, 0)
            break
        default:
            break
    }
}

export const onNavigationToExpandedPost = (role:keyof typeof RoleType,postId: number,roomId:string, orgId:string, navigate:NavigateFunction) => {
    switch (role) {
        case RoleType.USER:
            const userPath = `/room-post/${roomId}/${postId}`
            navigate(userPath)
            window.scrollTo(0, 0)
            break
        case RoleType.ORGANIZATION:
            const organizationPath = `/room-post/${orgId}/${roomId}/${postId}`
            navigate(organizationPath)
            window.scrollTo(0, 0)
            break
        default:
            break
    }
}


export const onUserClick = (content_type:string,role:keyof typeof RoleType,postUserId: number,currentUserId:number|null, orgId:string, navigate:NavigateFunction) => {
    if(postUserId){
     switch (content_type) {
         case RoleType.USER.toLowerCase():
             if (role === RoleType.USER) {
                 if (currentUserId === postUserId) {
                     navigate(`/user-profile`)
                     window.scrollTo(0, 0)
                 } else {
                     navigate(`/user/${postUserId}`)
                     window.scrollTo(0, 0)
                 }
             } else {
                 if (currentUserId === postUserId) {
                     navigate(`/user-profile`)
                     window.scrollTo(0, 0)
                 } else {
                     navigate(`/user/${postUserId}/${orgId}`)
                     window.scrollTo(0, 0)
                 }
             }
             break
         case RoleType.ORGANIZATION.toLowerCase():
              navigate(`/organization-profile/${postUserId}`)
                 window.scrollTo(0, 0)
            
             break
         default:
             break
     }
    }else{
     navigate(`/not-found`)
     window.scrollTo(0, 0)
    }
     
     
 }
