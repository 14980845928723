import { useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { halloEmail, jungedEmail, nikiEmail } from "../../../App/GlobaleVariables"
import Footer from "../Layout/Footer"
import Header from "../Header/Header"
import FAQAccordion from "./FAQAccordion"
import ModalGroup from "../Components/ModalGroup"

function FrequentlyAskedQuestions() {
    const translation = useSelector(selectTranslations)

    return (
        <>
            <div className="FAQ">
                <Header />
                <div className="FAQ__container">
                    <h1 className="FAQ__title">
                        {translation.fragen} & {translation.antworten}
                    </h1>
                    <p className="FAQ__category-title">{translation.konzeptionelles}</p>
                    <div className="FAQ__accordion-container">
                        <div className="FAQ__card">
                            <FAQAccordion label={translation.fAQKonzeptionellesQuestionOne} item={translation.fAQKonzeptionellesAnswerOne} />
                            <FAQAccordion label={translation.fAQKonzeptionellesQuestionTwo} item={translation.fAQKonzeptionellesAnswerTwo} />
                            <FAQAccordion label={translation.fAQKonzeptionellesQuestionThree} item={translation.fAQKonzeptionellesAnswerThree1}>
                                <p className="FAQ__paragraph">{translation.fAQKonzaptionellesAnswerThree2}</p>
                                <p className="FAQ__paragraph">{translation.fAQKonzaptionellesAnswerThree2}</p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.fAQKonzeptionellesQuestionFour}>
                                <p className="FAQ__paragraph">
                                    {translation.fAQKonzeptionellesAnswerFour1}
                                    <a className="FAQ__link" href="https://edusiia.com/informationen-fuer-minderjaehrige/" target="_blank" rel="noreferrer">
                                        {" "}
                                        {translation.hier}
                                    </a>{" "}
                                    {translation.fAQKonzeptionellesAnswerFour2}{" "}
                                    <a className="FAQ__link" href={`mailto:${jungedEmail}`}>
                                        {" "}
                                        {jungedEmail}{" "}
                                    </a>
                                    {translation.fAQKonzeptionellesAnswerFour3}
                                </p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.fAQKonzeptionellesQuestionFive}>
                                <p className="FAQ__paragraph">{translation.fAQKonzeptionellesAnswerFive1}</p>
                                <p className="FAQ__paragraph">{translation.fAQKonzeptionellesAnswerFive2}</p>
                                <p className="FAQ__paragraph">{translation.fAQKonzeptionellesAnswerFive3}</p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.fAQKonzeptionellesQuestionSix}>
                                <p className="FAQ__paragraph">
                                    {" "}
                                    {translation.fAQKonzeptionellesAnswerSix1}{" "}
                                    <a className="FAQ__link" href="https://edusiia.com/unsere-werte/" target="_blank" rel="noreferrer">
                                        {translation.unserenWerten}
                                    </a>{" "}
                                    {translation.fAQKonzeptionellesAnswerSix2}
                                </p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.fAQKonzeptionellesQuestionSeven}>
                                <p className="FAQ__paragraph">
                                    {translation.fAQKonzeptionellesAnswerSeven}{" "}
                                    <a className="FAQ__link" href="https://edusiia.com/edusiia-team-ueber-uns/" target="_blank" rel="noreferrer">
                                        {translation.uberUns}
                                    </a>
                                    .
                                </p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.fAQKonzeptionellesQuestionEight} item={translation.fAQKonzeptionellesAnswerEight} />
                            <FAQAccordion label={translation.fAQKonzeptionellesQuestionNine}>
                                <p className="FAQ__paragraph">
                                    {translation.fAQKonzeptionellesAnswerNine1}{" "}
                                    <a className="FAQ__link" href="https://edusiia.com/datenschutz/" target="_blank" rel="noreferrer">
                                        {translation.hier}
                                    </a>{" "}
                                    {translation.fAQKonzeptionellesAnswerNine2}.
                                </p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.fAQKonzeptionellesQuestionTen}>
                                <p className="FAQ__paragraph">{translation.fAQKonzeptionellesAnswerTen1}</p>
                                <p className="FAQ__paragraph">{translation.fAQKonzeptionellesAnswerTen2}</p>
                            </FAQAccordion>
                        </div>
                    </div>
                    <p className="FAQ__category-title">{translation.edusiiaMatching}</p>
                    <div className="FAQ__accordion-container">
                        <div className="FAQ__card">
                            <FAQAccordion label={translation.fAQEdusiiaMatchingQuestion}>
                                <p className="FAQ__paragraph">{translation.fAQEdusiiaMatchingAnswer1}</p>
                                <p className="FAQ__paragraph">
                                    <strong>{translation.fAQEdusiiaMatchingAnswer2}</strong> {translation.fAQEdusiiaMatchingAnswer3}
                                </p>
                                <p className="FAQ__paragraph">
                                    <em>{translation.fAQEdusiiaMatchingAnswer4}</em>
                                </p>
                                <p className="FAQ__paragraph">
                                    {translation.fAQEdusiiaMatchingAnswer5}{" "}
                                    <a className="FAQ__link" href={`mailto:${nikiEmail}`}>
                                        {nikiEmail}
                                    </a>{" "}
                                    {translation.fAQEdusiiaMatchingAnswer6}
                                </p>
                            </FAQAccordion>
                        </div>
                    </div>
                    <p className="FAQ__category-title">{translation.anmeldung}</p>
                    <div className="FAQ__accordion-container">
                        <div className="FAQ__card">
                            <FAQAccordion label={translation.fAQAnmeldungQuestionOne}>
                                <p className="FAQ__paragraph">
                                    {" "}
                                    {translation.fAQAnmeldungAnswerOne1}{" "}
                                    <a className="FAQ__link" href="https://edusiia.com/unsere-werte/" target="_blank" rel="noreferrer">
                                        {translation.unserenWerten}
                                    </a>{" "}
                                    {translation.fAQAnmeldungAnswerOne2}
                                </p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.fAQAnmeldungQuestionTwo} item={translation.fAQAnmeldungAnswerTwo} />
                            <FAQAccordion label={translation.fAQAnmeldungQuestionThree} item={translation.fAQAnmeldungAnswerThree} />
                            <FAQAccordion label={translation.fAQAnmeldungQuestionFour}>
                                <p className="FAQ__paragraph">{translation.fAQAnmeldungAnswerFour1}</p>
                                <p className="FAQ__paragraph">{translation.fAQAnmeldungAnswerFour2}</p>
                            </FAQAccordion>
                        </div>
                    </div>
                    <p className="FAQ__category-title">{translation.funktionen}</p>
                    <div className="FAQ__accordion-container">
                        <div className="FAQ__card">
                            <FAQAccordion label={translation.fAQFunktionenQuestionOne}>
                                <p className="FAQ__paragraph">{translation.fAQFunktionenAnswerOne1}</p>
                                <p className="FAQ__paragraph">{translation.fAQFunktionenAnswerOne2}</p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.fAQFunktionenQuestionTwo} item={translation.fAQFunktionenAnswerTwo} />
                            <FAQAccordion label={translation.fAQFunktionenQuestionThree}>
                                <p className="FAQ__paragraph">{translation.fAQFunktionenAnswerThree1}</p>
                                <p className="FAQ__paragraph">{translation.fAQFunktionenAnswerThree2}</p>
                                <p className="FAQ__paragraph">
                                    <strong>{translation.offentlicheGruppen}</strong> {translation.fAQFunktionenAnswerThree3}
                                </p>
                                <p className="FAQ__paragraph">
                                    <strong>{translation.privateGruppen}</strong> {translation.fAQFunktionenAnswerThree4}
                                </p>
                                <p className="FAQ__paragraph">
                                    <strong>{translation.geheimeGruppen}</strong> {translation.fAQFunktionenAnswerThree5}
                                </p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.fAQFunktionenQuestionFour} item={translation.fAQFunktionenAnswerFour} />
                            <FAQAccordion label={translation.fAQFunktionenQuestionFive} item={translation.fAQFunktionenAnswerFive} />
                            <FAQAccordion label={translation.fAQFunktionenQuestionSix}>
                                <p className="FAQ__paragraph">
                                    {translation.fAQFunktionenAnswerSix1}{" "}
                                    <a className="FAQ__link" href=" https://edusiia.com/#mitmachen" target="_blank">
                                        https://edusiia.com/#mitmachen
                                    </a>{" "}
                                    {translation.fAQFunktionenAnswerSix2}
                                </p>
                            </FAQAccordion>
                        </div>
                    </div>
                    <p className="FAQ__category-title">{translation.moderation}</p>
                    <div className="FAQ__accordion-container">
                        <div className="FAQ__card">
                            <FAQAccordion label={translation.fAQModerationQuestionOne}>
                                <p className="FAQ__paragraph">
                                    {translation.fAQModerationAnswerOne1}{" "}
                                    <a className="FAQ__link" href="https://edusiia.com/unsere-werte/" target="_blank" rel="noreferrer">
                                        {translation.unserenWerten}
                                    </a>{" "}
                                    {translation.fAQModerationAnswerOne2}
                                </p>
                            </FAQAccordion>
                            <FAQAccordion label={translation.fAQModerationQuestionTwo}>
                                <p className="FAQ__paragraph">
                                    {translation.fAQModerationAnswerTwo1}{" "}
                                    <a className="FAQ__link" href="https://edusiia.com/unsere-werte/" target="_blank" rel="noreferrer">
                                        {translation.unserenWerten}
                                    </a>{" "}
                                    {translation.fAQModerationAnswerTwo2}
                                </p>
                            </FAQAccordion>
                        </div>
                    </div>

                    <p className="FAQ__text">
                        {translation.fAQHaveAnyQuestions}{" "}
                        <a className="FAQ__link" target="_blank" href="https://edusiia.com/kontakt/" rel="noreferrer">
                            {" "}
                            {translation.kontaktFormular}
                        </a>{" "}
                        {translation.oderEineEMailAn}{" "}
                        <a className="FAQ__link" href={`mailto:${halloEmail}`}>
                            {halloEmail}
                        </a>
                        !
                    </p>
                </div>
            </div>
            <Footer />
            <ModalGroup />
        </>
    )
}

export default FrequentlyAskedQuestions
