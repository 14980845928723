import { NavigateFunction, useLocation } from "react-router-dom"
import { headers } from "../../App/Axios"
import HTTPService from "../../App/HTTPService"
import { setRoomMembers } from "../Room/RoomSlices/MembersInRoomSlice"
import { setUserNotifications } from "./NotificationSlice"
import { setModalData } from "../Shared/SharedSlices/ModalSlice"

export const userNotificationGetRequest = () => {
    return HTTPService.get(`/notification/user/`, headers)
}

const patchInvitationStatus = (notificationId: number, data: { status: string }) => {
    return HTTPService.patch(`/change-invitation-status/${notificationId}/`, data, headers)
}

export const userReadSigleNotificationRequest = (notificationId: number) => {
    return HTTPService.get(`/notification/read-notification/${notificationId}/`, headers)
}

export const userNotificationReadAllRequest = () => {
    return HTTPService.get(`/notification/read-all-notifications/`, headers)
}

export const userNotificationReadSingleRequest = (notificationId: number) => {
    return HTTPService.get(`notification/read-notification/${notificationId}/`, headers)
}

export const getMembersRequest = (roomId: number) => {
    return HTTPService.get(`/room/${roomId}/members/`, headers)
}

export const onRespondToInvitation = (notificationId: number, status: string, roomId: number, navigate?: NavigateFunction) => async (dispatch: any) => {
    const data = {
        status: status,
    }
    try {
        const response = await patchInvitationStatus(notificationId, data)

        if(response.status == 200 && response.data.detail == "room_full"){
            dispatch(setModalData({ open: true, errorJoiningFullRoom: true }))
            return
        }

        if(response.status == 200 && response.data.status == "success"){
            try {
                const response = await userNotificationGetRequest()
                if (response.status === 200) {
                    dispatch(setUserNotifications(response.data))
                    if (data.status === "accepted") {
                        try {
                            const response = await getMembersRequest(roomId)
                            if (response.status === 200) {
                                dispatch(setRoomMembers(response.data.user))
                                
                                if(window.location.pathname == `/room/${roomId}`){
                                    navigate && navigate(0)
                                }else{
                                    navigate && navigate(`/room/${roomId}`)
                                }
                            }
                        } catch (error: any) {}
                    }
                }
            } catch (error: any) {
                console.log(error)
            }
        }
    } catch (error: any) {
        console.log(error)
    }
}

export const onRespondToAdminRequest = (invitation_id: number, status: string, orgId?: number, navigate?: NavigateFunction) => async (dispatch: any) => {
    try {
        const response = await HTTPService.post(`/admin-invite-status/${invitation_id}/`, { status: status }, headers)
        if (response.status === 200) {
        }
    } catch (error: any) {
        console.log(error)
    }
}

export const onMarkAllUserNotificationsAsRead = () => async (dispatch: any) => {
    try {
        const response = await userNotificationReadAllRequest()
        if (response.status === 200) {
            try {
                const response = await userNotificationGetRequest()
                if (response.status === 200) {
                    dispatch(setUserNotifications(response.data))
                }
            } catch (error: any) {
                console.log(error)
            }
        }
    } catch (error: any) {
        console.log(error)
    }
}

export const onMarkSingleUserNotificationsAsRead = (notificationId: number) => async (dispatch: any) => {
    try {
        const response = await userReadSigleNotificationRequest(notificationId)
        if (response.status === 200) {
            try {
                const response = await userNotificationGetRequest()
                if (response.status === 200) {
                    dispatch(setUserNotifications(response.data))
                }
            } catch (error: any) {
                console.log(error)
            }
        }
    } catch (error: any) {
        console.log(error)
    }
}


