import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import useWindowSize from "../../Shared/Hooks/useWindowSize"
import Button from "../../Shared/Primitive/Button"
import { AlertCircleIcon, InviteUserIcon, PlusIcon } from "../../Shared/Components/SvgIcons"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import HTTPService from "../../../App/HTTPService"
import { headers } from "../../../App/Axios"
import { RoleType, SubscriptionType } from "../../../App/enums"
import { RootState } from "../../../App/store"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import RoomFilterDropdown from "../../Shared/Components/RoomFilterDropdown"

function OrganizationDashboardButtons() {
    const translation = useSelector(selectTranslations)
    const [invitationNumber, setInvitationNumber] = useState(0)
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const rooms = useSelector((state: RootState) => state.roomSlice.roomsSlice.owned_rooms)
    const windowSize = useWindowSize()
    const breakpoint = 1280
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const getInvitationRequest = useCallback(() => {
        return HTTPService.get(`/org/invitations/${id}`, headers)
    }, [id])

    useEffect(() => {
        getInvitationRequest().then(response => {
            if (response.status === 200) {
                setInvitationNumber(response.data.length)
            }
        })
    }, [getInvitationRequest])

    const roomLimitGetRequest = () => {
        return HTTPService.get(`/org/${id}/room-limit/`, headers)
    }

    const onRoomCreate = () => {
        if (organizationDataState.subscription_type === SubscriptionType.free && rooms!.length > 0) {
            dispatch(setModalData({ open: true, notPremiumCreateRoomModal: true }))
        } else {
            roomLimitGetRequest()
                .then(response => {
                    if (response.status === 200) {
                        dispatch(setModalData({ open: true, createRoom: true }))
                    }
                })
                .catch(error => {
                    if (error.response.status === 400) {
                        dispatch(setModalData({ open: true, roomLimitModal: { status: true, max_rooms: error.response.data.max_rooms } }))
                    }
                })
        }
    }

    const onInvitationClick = () => {
        navigate(`/invitations/${id}`)
    }

    return (
        <div className="organization-dashboard-feed__button-container">
            {translation.newPostsInMyRooms}
            <div className="organization-dashboard-feed__button-container organization-dashboard-feed__button-container--width">
                {" "}
                <div className="organization-dashboard-feed__button">
                    <Button
                        onClick={onRoomCreate}
                        className="button button--small button--border-radius-05 button--cursor-pointer button--hover-brighter button--color-gold button--text-color-white "
                    >
                        <div className="organization-dashboard-feed__button-text">
                            <PlusIcon /> {windowSize > breakpoint && translation.CreateRoom}
                        </div>
                    </Button>
                </div>
                <RoomFilterDropdown type={RoleType.ORGANIZATION} />
                {invitationNumber > 0 && (
                    <button className="dashboard-feed__button dashboard-feed__button--organization" onClick={onInvitationClick}>
                        {invitationNumber > 0 && invitationNumber + " "}
                        {windowSize > breakpoint ? invitationNumber === 1 ? translation.Invitation : translation.Invitations : <InviteUserIcon color="white" />}
                        {invitationNumber > 0 && <AlertCircleIcon />}
                    </button>
                )}
            </div>
        </div>
    )
}

export default OrganizationDashboardButtons
