import HTTPService from "../../../App/HTTPService"
import { getResetPasswordError, getResetPasswordStatus, resetResetPasswordError } from "./ResetPasswordSlice"
export const resetPasswordPatchRequest = (resetPasswordForm: object) => async (dispatch: Function) => {
    try {
        const response = await HTTPService.patch("/password-change-done/", resetPasswordForm)
        if (response.status === 200) {
            await dispatch(getResetPasswordStatus())

            await dispatch(resetResetPasswordError())
        }
    } catch (error: any) {
        const errors = {
            msg: error.response.data,
            status: error.response.status,
        }

        await dispatch(
            getResetPasswordError({
                message: errors.msg,
                status: errors.status,
            })
        )
    }
}
