import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface PinboardModal {
    id: number
    isYourOrganization: boolean
}

const initialState = { id: 0, isYourOrganization: false }

const PinboardIdSlice = createSlice({
    name: "PinboardIdSlice",
    initialState: initialState,
    reducers: {
        setPinboardId(state, action: PayloadAction<PinboardModal>) {
            state.id = action.payload.id
            state.isYourOrganization = action.payload.isYourOrganization
        },
    },
})

export const { setPinboardId } = PinboardIdSlice.actions
export default PinboardIdSlice.reducer
