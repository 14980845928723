import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState = { planId: "" }

const UpgradePlanSlice = createSlice({
    name: "MembersInRoomSlice",
    initialState: initialState,
    reducers: {
        setPlanId(state, action: PayloadAction<string>) {
            state.planId = action.payload
        },
    },
})

export const { setPlanId } = UpgradePlanSlice.actions
export default UpgradePlanSlice.reducer
