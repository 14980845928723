import { useDispatch, useSelector } from "react-redux"
import { RoleType } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { setModalData } from "../SharedSlices/ModalSlice"
import { CloseIcon, EmailIcon } from "./SvgIcons"

function ContactInfo() {
    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)
    const contactInfoData = useSelector((state: RootState) => state.ContactInfoSlice)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)

    const closeModal = () => {
        dispatch(setModalData({ open: false, contactInfo: false }))
    }

    return (
        <div className={`contact-info`}>
            <div className="contact-info__title-container">
                {role === RoleType.ORGANIZATION ? (
                    <p className="contact-info__title">{contactInfoData.name}</p>
                ) : (
                    <>
                        <p className="contact-info__title">{contactInfoData.first_name + " " + contactInfoData.last_name}</p>
                    </>
                )}
                <div className="contact-info__close-icon" onClick={closeModal}>
                    <CloseIcon />
                </div>
            </div>
            <div className={`contact-info__text-container `}>
                <p className={`contact-info__bold-text  `}> {translation.ContactInfo}</p>
                <div className={`contact-info__email-container `}>
                    <EmailIcon />
                    <p className={`contact-info__bold-text  contact-info__bold-text--margin-left`}>{translation.email}</p>
                </div>
                <p className={`contact-info__text  `}>{contactInfoData.email}</p>
            </div>
        </div>
    )
}

export default ContactInfo
