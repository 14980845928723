export const setMentionListPosition = (
    trimmedContent: string,
    editorContent: string,
    dropdown: HTMLElement | null,
    maxLeftPosition: number,
    isEditContent: boolean
) => {
    const trimmedContentArray = trimmedContent.split("\n\n")
    const contentArray = editorContent.split("\n\n")
    const content = trimmedContentArray![trimmedContentArray!.length - 1]
    const divId = document.getElementById("caretPosition")
    const breakpoint = 521

    if (content !== undefined) {
        divId!.innerHTML = content
    }

    if (dropdown !== null) {
        dropdown!.style.visibility = "visible"
        if (window.innerWidth > breakpoint) {
            dropdown!.style.left = divId!.clientWidth > maxLeftPosition ? maxLeftPosition.toString() : divId!.clientWidth + "px"
            let difference = contentArray.length - trimmedContentArray.length
            const marginBottom = difference === 0 ? 44 : difference * 28 + 16 + 44
            let onEditPosition = -(contentArray.length + trimmedContentArray.length * 28 + 32 + 44 * 2) + "px"
            dropdown!.style.bottom = isEditContent ? onEditPosition : contentArray?.length === 1 ? 80 + "px" : marginBottom + "px"
            dropdown!.style.width = 20.841 + "rem"
        } else {
            let difference = contentArray.length - trimmedContentArray.length
            const marginBottom = difference === 0 ? 44 : difference * 28 + 16 + 44
            let onEditPosition = -(contentArray.length + trimmedContentArray.length * 28 + 32 + 44 * 2) + "px"
            dropdown!.style.bottom = isEditContent ? onEditPosition : contentArray?.length === 1 ? 80 + "px" : marginBottom + "px"
            dropdown!.style.left = -8 + "px"
            dropdown!.style.width = 98 + "vw"
        }
    }
}
