import { useDispatch, useSelector } from "react-redux"
import { green } from "../../../../App/GlobaleVariables"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import { RootState } from "../../../../App/store"
import { DefaultOrganizationPhotoIcon, EditProfileIcon, Upload } from "../../../Shared/Components/SvgIcons"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"

function OrganizationProfilePinboardPlaceholder() {
    const translation = useSelector(selectTranslations)
    const organization = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const dispatch = useDispatch()

    const onAddEventModalOpen = () => {
        dispatch(setModalData({ open: true, addEvent: true }))
    }

    return (
        <div className="organization-profile-pinboard-placeholder" onClick={onAddEventModalOpen}>
            <div className="organization-profile-pinboard-placeholder__organization-container">
                <div className="organization-profile-pinboard-placeholder__photo">
                    <DefaultOrganizationPhotoIcon />
                </div>
                <div className="organization-profile-pinboard-placeholder__name">{organization.name}</div>{" "}
                <span className="organization-profile-pinboard-placeholder__edit">
                    <EditProfileIcon modifier="small" color={green} />
                </span>
            </div>
            <div className="organization-profile-pinboard-placeholder__title">{translation.headline}</div>

            <div className="organization-profile-pinboard-placeholder__subheadline"> {translation.subheadline}</div>
            <div className="organization-profile-pinboard-placeholder__add-image">
                <Upload />
                <div className="organization-profile-pinboard-placeholder__image-text">
                    {translation.uploadWithSpace} <br />
                    {translation.imageWithSpace}
                </div>
                <p className="organization-profile-pinboard-placeholder__image-size-text">{translation.maxImageSize}</p>
            </div>
        </div>
    )
}

export default OrganizationProfilePinboardPlaceholder
