import { useDispatch } from "react-redux"
import { PopUpType } from "../../../App/enums"

import { setModalData } from "../SharedSlices/ModalSlice"
import { CloseIcon, GreenCloseIcon, TickIcon, TrashCanIcon } from "./SvgIcons"

function PopUp(props: {
    message: string
    type: PopUpType.successful | PopUpType.error | PopUpType.signupError | PopUpType.signinMessage | PopUpType.successfulVerification
    modifier?: string
    title?: string
    message2?: string
}) {
    const dispatch = useDispatch()
    const onModalClose = () => {
        dispatch(
            setModalData({
                open: false,
                successfulCreateOrganization: false,
                successfulCreateRoom: false,
                deleteRoomSuccessfully: false,
                editRoomSuccessfully: false,
                deleteAccountSuccessfully: false,
                successfulRoomMemberRemoval: false,
                employeeRemoval: false,
                deleteOrganizationSuccessfully: false,
                newExperienceAddedSuccessfully: false,
                deleteExperienceSuccessfully: false,
                editExperienceAddedSuccessfully: false,
                deletePostSuccessfully: false,
                leaveRoomSuccessfully: false,
                confirmEmailMessage: false,
                postiveEmailVerification: false,
                negativeEmailVerification: { status: false, message: "" },
                successfullyJoinedRoom: false,
                errorJoiningFullRoom: false,
                postiveOrganziationEmailVerification: false,
                confirmNewEmailMessage: false,
                successfulUpgrade: false,
                postiveNewOrganizationEmailVerification: false,
                postiveNewEmailVerification: false,
            })
        )
    }

    return (
        <div className={`pop-up pop-up--${props.type}`}>
            <div
                className={`${
                    props.type === PopUpType.signinMessage
                        ? "pop-up__title-container"
                        : props.type === PopUpType.successfulVerification
                        ? "pop-up__title-container pop-up__title-container--no-margin"
                        : null
                } `}
            >
                {(props.type === PopUpType.signinMessage || props.type === PopUpType.successfulVerification) && (
                    <p className={`pop-up__title `}> {props.title}</p>
                )}
                <div className={`pop-up__icon `} onClick={onModalClose}>
                    {props.type === PopUpType.successful ? <GreenCloseIcon /> : <CloseIcon />}
                </div>{" "}
            </div>
            <div className={`pop-up__text-container  pop-up__text-container--${props.modifier}`}>
                {props.type === PopUpType.successful ? <TickIcon /> : props.type === PopUpType.error ? <TrashCanIcon /> : null}{" "}
                <p className={`pop-up__text  pop-up__text--${props.modifier} pop-up__text--${props.type}`}>{props.message}</p>
                {props.type === PopUpType.signinMessage && (
                    <p className={`pop-up__text  pop-up__text--${props.modifier} pop-up__text--${props.type} pop-up__text--border `}>{props.message2}</p>
                )}
            </div>
        </div>
    )
}

export default PopUp
