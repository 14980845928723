import "../../../Styles/main.scss"
import Footer from "../../Shared/Layout/Footer"
import AuthPageDescription from "../../Shared/Primitive/AuthPageDescription"
import AuthPageImage from "../../Shared/Primitive/AuthPageImage"
import EmailConfirmation from "./EmailConfirmation"
import { LogoIconMobile } from "../../Shared/Components/SvgIcons"
import { useRef } from "react"

function EmailConfirmationScreen() {
    return (
        <div className="auth-layout">
            <div className="auth-layout__main-container">
                <div className="left-panel">
                    <div className="left-panel__logo">
                        {" "}
                        <LogoIconMobile />
                    </div>
                    <div className="left-panel__description">
                        <AuthPageDescription />
                    </div>
                    <div className="edusiia-image">
                    <AuthPageImage />
                    </div>
                </div>
                <div className="right-panel ">
                    <div className="right-panel__edusiia-image">
                        <AuthPageImage />
                    </div>
                    <div className="right-panel__email-confirmation">
                        <EmailConfirmation />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EmailConfirmationScreen
