import HTTPService from "../../../App/HTTPService"
import {
    getEdusiiaMatchingStatus,
    getAddOrganizationError,
    getPrivacyPolicyError,
    resetAddOrganizationError,
    resetPrivacyPolicyError,
} from "./EdusiiaMatchingSlice"
import { getSignupError, resetSignupError } from "../SignUp/SignupSlice"
import { org_email_notifications, org_web_notifications } from "../../User/AddOrganization/DefaultOrganizationNotificationSettingsState"
import SessionService from "../../../App/SessionService"
import { StorageKey } from "../../../App/enums"

export const edusiiaMatchingPostRequestWithOrganization =
    (
        data: {
            first_name: string
            last_name: string
            email: string
            password: string
            confirm_password: string
            reference_id: number
            privacy_settings: Array<{ private_messages: boolean; search_results: boolean; show_contact_info: boolean; show_location: boolean }>
            room_invitation_id: string
            edusiia_matching: boolean
            location_state_id: number
            involved_organisation_category_id: number
        },
        permissionForm: { privacy_policy: boolean; newsletter: boolean },
        organization?: {
            name: string
            is_active: boolean
            representative: boolean
            organization_type?: number
            description?: string
            location_state_id: number
            organization_category_id: number
        }
    ) =>
    async (dispatch: Function) => {
        let registerData: object
        if (organization!.name.length !== 0 || organization!.representative === true) {
            registerData = {
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                password: data.password,
                confirm_password: data.confirm_password,
                reference_id: data.reference_id,
                privacy_settings: data.privacy_settings,
                privacy_policy: permissionForm.privacy_policy,
                edusiia_matching: data.edusiia_matching,
                newsletter: permissionForm.newsletter,
                location_state_id: data.location_state_id,
                involved_organisation_category_id: data.involved_organisation_category_id,
                organization: [organization],
                org_web_notifications: org_web_notifications,
                org_email_notifications: org_email_notifications,
                org_privacy_settings: [{ search_results: true, google_search: false, private_messages: true, show_location: false, show_contact_info: false }],
                room_invitation_id: data.room_invitation_id,
            }
            try {
                const response = await HTTPService.post("/register/", registerData)
                if (response.status === 201) {
                    await dispatch(getEdusiiaMatchingStatus({ status: true }))

                    localStorage.clear()
                    SessionService.removeItem(StorageKey.roomInvitationId)
                    await dispatch(resetPrivacyPolicyError())

                    await dispatch(resetAddOrganizationError())

                    await dispatch(resetSignupError())
                }
            } catch (error: any) {
                const errors = {
                    msg: error.response.data,
                    status: error.response.status,
                }
                await dispatch(
                    getPrivacyPolicyError({
                        message: errors.msg,
                        status: errors.status,
                    })
                )

                await dispatch(
                    getAddOrganizationError({
                        message: errors.msg,
                        status: errors.status,
                    })
                )

                await dispatch(
                    getSignupError({
                        message: errors.msg,
                        status: errors.status,
                    })
                )
            }
        } else if (organization!.name.length === 0 || organization!.representative === false) {
            registerData = {
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                password: data.password,
                confirm_password: data.confirm_password,
                reference_id: data.reference_id,
                privacy_settings: data.privacy_settings,
                privacy_policy: permissionForm.privacy_policy,
                edusiia_matching: data.edusiia_matching,
                newsletter: permissionForm.newsletter,
                organization: [],
                room_invitation_id: data.room_invitation_id,
                location_state_id: data.location_state_id,
                involved_organisation_category_id: data.involved_organisation_category_id
            }
            try {
                const response = await HTTPService.post("/register/", registerData)
                console.log(data.room_invitation_id)
                if (response.status === 201) {
                    await dispatch(getEdusiiaMatchingStatus({ status: true }))

                    localStorage.clear()

                    await dispatch(resetPrivacyPolicyError())

                    await dispatch(resetSignupError())

                    SessionService.removeItem(StorageKey.roomInvitationId)
                }
            } catch (error: any) {
                const errors = {
                    msg: error.response.data,
                    status: error.response.status,
                }

                await dispatch(
                    getPrivacyPolicyError({
                        message: errors.msg,
                        status: errors.status,
                    })
                )

                await dispatch(
                    getSignupError({
                        message: errors.msg,
                        status: errors.status,
                    })
                )
            }
        }
    }
