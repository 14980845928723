export enum NotificationFrequency {
    none = "none",
    immediate = "immediate",
    daily = "daily",
    weekly = "weekly",
}

export enum StorageKey {
    user = "user",
    language = "language",
    refreshToken = "refreshToken",
    accessToken = "accessToken",
    signupForm = "signupForm",
    email = "email",
    roles = "roles",
    organizationId = "organizationId",
    roomInvitationId = "roomInvitationId",
}

export enum Language {
    english = "english",
    deutsch = "deutsch",
}

export enum Color {
    gold = "gold",
    blue = "blue",
}

export enum OrganizationType {
    profit = 1,
    nonProfit = 2,
}

export enum RoomType {
    Public = "public",
    Private = "private",
}

export enum LabelType {
    ROOM_CREATOR = "Room Creator",
    ROOM_MANAGER = "Room Manager",
}

export enum RoleType {
    USER = "USER",
    ORGANIZATION = "ORGANIZATION",
}

export enum PhotoTypeModal {
    USER_PROFILE = "profile",
    USER_COVER = "cover",
    OTHER_USER_PROFILE = "other_user_profile",
    OTHER_USER_COVER = "other_user_cover",
    OTHER_ORGANIZATION_PROFILE = "other_organization_profile",
    OTHER_ORGANIZATION_COVER = "other_organization_cover",
    ORGANIZATION_PROFILE = "organization_profile",
    ORGANIZATION_COVER = "organization_cover",
    EVENT_PHOTO = "event_photo",
    ROOM_PHOTO = "room_photo",
    POST_FILE = "post_file",
}

export const enum InputType {
    checkbox = "checkbox",
}

export const enum NotificationType {
    COMMENT = "comment",
    ROOM_INVITATION = "room_invitation",
    REQUEST_REPLY = "request_reply",
    NEW_MEMBER = "new_member",
    POSTS = "posts",
    EVENT_COMMENT = "event_comment",
    ADD_ADMIN = "add_admin",
    ADMIN_REPLY = "admin_reply",
}

export const enum Entity {
    USER = "user",
    ORGANIZATION = "organization",
}
export enum JoinStatus {
    PENDING = "pending",
    NON_MEMBER = "non-member",
    MEMBER = "member",
}

export enum OrganizationProfitType {
    PROFIT = 1,
    NON_PROFIT = 2,
}

export enum EmployeesNumber {
    ONE_TO_TEN = "ONE_TO_TEN",
    ELEVEN_TO_ONEHUNDRED = "ELEVEN_TO_ONEHUNDRED",
    MORE_THAN_ONEHUNDRED = "MORE_THAN_ONEHUNDRED",
}

export enum Revenue {
    LES_THAN_FIVEHUNDRED = "LES_THAN_FIVEHUNDRED",
    FIVEHUNDRED_TO_MILION = "FIVEHUNDRED_TO_MILION",
    MORE_THAN_MILION = "MORE_THAN_MILION",
}

export enum NotificationResponse {
    accepted = "accepted",
    declined = "declined",
}

export enum SearchFilter {
    all_results = "all_results",
    users = "users",
    organizations = "organizations",
    rooms = "rooms",
    events = "events",
    posts_and_comments = "posts_and_comments",
    hashtags = "hashtags"
}

export enum ResultType {
    User = "User",
    Organization = "Organization",
    Room = "Room",
    Event = "Event",
    Post = "Post",
    Comment = "Comment",
    Hashtag = "Hashtag"
}
export enum RoomFilter {
    room = "room",
    organization = "organization",
}

export enum OrganizationRoomFilter{
    createdByUs = "Created by us...",
    memberIn = "Member in..."
}

export const enum PostType {
    post = "post",
    comment = "comment",
    event = "event",
}

export const enum PendingType {
    invite = "invite",
    request = "request",
}

export const enum SubscriptionType {
    free = 0,
    tenEmployeesMonthly = 1,
    aHunderdEmployeesMonthly = 2,
    fiveHundredEmployeesMonthly = 3,
    tenEmployeesYearly = 4,
    aHundredEmployeesYearly = 5,
    fiveHundredEmployeesYearly = 6,
    fiveKMonthlyRevenue = 11,
    oneMillionMonthlyRevenue = 12,
    fiveMillionMonthlyRevenue = 13,
    fiveKYearlyRevenue = 14,
    oneMillionYearlyRevenue = 15,
    fiveMillionYearlyRevenue = 16,
    customSubscription = 17,
}

export const enum PopUpType {
    successful = "successful",
    error = "error",
    signupError = "signupError",
    signinMessage = "signin-message",
    successfulVerification = "successful-verification",
}

export const enum CommentType {
    event = "event",
    post = "post",
}

export const enum PasswordConfirmationEnum {
    user_email = "user_email",
    organization_email = "organization_email",
    organization_delete = "organization_delete",
    transfer_ownership = "transfer_ownership"
}

export const enum NotificationSettings {
    comment = "comment",
    commented_on_same_post = "commented_on_same_post",
    tags = "tags",
    room_invitation = "room_invitation",
    room_invitation_reply = "room_invitation_reply",
    room_join_request = "room_join_request",
    room_join_request_reply = "room_join_request_reply",
    add_admin = "add_admin",
    remove_admin = "remove_admin",
    event_comment = "event_comment",
    new_post_in_room = "new_post_in_room",
    admin_reply = "admin_reply",
    new_member = "new_member",
    org_room_invitation = "org_room_invitation",
    unseen_chat_messages = "unseen_chat_messages"
}

export const enum UsersRoleInOrganization {
    employee = "employee",
    admin = "admin",
}
export const enum CallerComponent {
    feed = "feed",
    post = "post",
    comment = "comment",
    eventComment = "event-comment",
    roomDescription = "room-description",
    eventDescription= "event-description"
}

export const enum EditorModifier{
    eventCommentTextArea = "event-comment-text-area",
    roomTextArea = "room-text-area",
    editContent = "edit-content",
    editRoomDescription ="edit-room-description",
    eventDescription = "event-description",
    userDashboard = "user-dashboard"
}


export const enum SortOptions{
    neue_aktivität = "neue_aktivität",
    alphabetisch="alphabetisch",
    zuletzt_beigetreten="zuletzt_beigetreten",
    zuerst_beigetreten="zuerst_beigetreten"

}
