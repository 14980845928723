import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import { RootState } from "../../../../App/store"
import "../../../../Styles/main.scss"
import EditDescriptionModal from "../../../Shared/Components/EditDescriptionModal"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { GetOrganizationRequest } from "../../OrganizationDashboard/GetOrganizationServiceRequest"
import { patchOrganizationDescription } from "./OrganizationDescriptionServiceRequest"

function OrganizationDescriptionEditModal() {
    const { id } = useParams()
    const translation = useSelector(selectTranslations)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const organizationState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const [description, setDescription] = useState(organizationState?.description)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        if (userDataState.id) {
            dispatch(GetOrganizationRequest(id, userDataState))
        }
    }, [dispatch, id, navigate, userDataState, userDataState.id])

    const onModalClose = () => {
        dispatch(setModalData({ open: false, organizationDescription: false }))
    }

    const onDescriptionSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        dispatch(patchOrganizationDescription(description!, userDataState, organizationState?.id!))
    }

    return (
        <EditDescriptionModal
            closeModal={onModalClose}
            onSubmit={onDescriptionSubmit}
            description={description}
            title={translation.whatWeDoForEducation}
            setDescription={setDescription}
        />
    )
}

export default OrganizationDescriptionEditModal
