import { convertBytesToUnits, documentNameLength } from "../../../App/Helpers/Helpers"
import useWindowSize from "../Hooks/useWindowSize"
import { GreenCloseIcon } from "./SvgIcons"

function DocumentTextAreaPreview(props: { document_name: string; document_size: number; onRemoveFile: () => void }) {
    const screenWidth = useWindowSize()
    const breakpoint = 500

    return (
        <div className="room-layout__document-container">
            <img className="room-layout__document-image" src={`/document-icon.svg`} alt="doc" />
            <div className="room-layout__document-content">
                <p className="room-layout__document-title">{documentNameLength(props.document_name, screenWidth, breakpoint)}</p>
                <p className="room-layout__document-size">{`${props.document_name
                    .slice(props.document_name.indexOf(".") + 1)
                    .toUpperCase()} | ${convertBytesToUnits(props.document_size)}`}</p>
            </div>
            <div className="room-layout__document-cancel" onClick={props.onRemoveFile}>
                <GreenCloseIcon />
            </div>
        </div>
    )
}

export default DocumentTextAreaPreview
