import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { setModalData } from "../SharedSlices/ModalSlice"
import { CloseIcon } from "./SvgIcons"
import Image from "../../Shared/Primitive/Image"
import { RootState } from "../../../App/store"
import { profileDefault } from "../../../App/GlobaleVariables"
import { useState } from "react"
import { CallerComponent, RoleType } from "../../../App/enums"
import { useNavigate, useParams } from "react-router-dom"

function ReactionsModal() {
    const { id } = useParams()
    const translation = useSelector(selectTranslations)
    const reaction = useSelector((state: RootState) => state.modalSlice.reactionsModal?.reaction)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [expanded, setExpanded] = useState(true)
    const [expandedIcon, setExpandedIcon] = useState(false)
    const [filter, setFilter] = useState("")
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)

    const reactionCount = reaction?.map(reaction => {
        return reaction.count
    })

    const totalReactionCount = reactionCount?.reduce((total, num) => total + num, 0)

    const onModalClose = () => {
        dispatch(
            setModalData({
                open: false,
                reactionsModal: {
                    status: false,
                    reaction: [{ count: 0, name: "", users: [{ id: 0, first_name: "", last_name: "", profile_photo: { id: 0, url_path: "" } }] }],
                },
            })
        )
    }

    const onRoomMemberClick = (userId: number) => {
        switch (role) {
            case RoleType.USER:
                if (userDataState.id === userId) {
                    navigate("/user-profile")
                } else {
                    navigate(`/user/${userId}`)
                }
                break
            case RoleType.ORGANIZATION:
                if (userDataState.id === userId) {
                    navigate("/user-profile")
                } else {
                    navigate(`/user/${userId}/${id}`)
                }
                break
            default:
                break
        }
        dispatch(
            setModalData({
                open: false,
                reactionsModal: {
                    status: false,
                    reaction: [{ count: 0, name: "", users: [{ id: 0, first_name: "", last_name: "", profile_photo: { id: 0, url_path: "" } }] }],
                },
            })
        )
    }
    const onOpenUsers = (name: string | null) => {
        setExpanded(true)
        setExpandedIcon(false)
    }

    const onOpenIconUsers = (name: string) => {
        setExpanded(false)
        setExpandedIcon(true)
        name && setFilter(name)
    }

    return (
        <div className="reactions-group-modal">
            <div onClick={onModalClose} className="reactions-group-modal__close-icon">
                <CloseIcon />
            </div>

            <p className="reactions-group-modal__title">{translation.reactions} </p>

            <div className="reactions-group-modal__double-input-container">
                <div
                    className={`reactions-group-modal__input-container ${expanded && !expandedIcon && "reactions-group-modal__input-container--active"}`}
                    onClick={() => onOpenUsers(null)}
                >
                    <p className="reactions-group-modal__text-icon-info">{translation.allReactions}</p>

                    <p className="reactions-group-modal__reaction-count">{RoleType.USER && CallerComponent.post && totalReactionCount}</p>
                </div>

                {reaction
                    ?.filter(reactionUsers => reactionUsers.count > 0)
                    .map((emoji, index) => {
                        return (
                            <div
                                className={`reactions-group-modal__input-container ${
                                    expandedIcon && emoji.name === filter && "reactions-group-modal__input-container--active"
                                }`}
                                key={index}
                                onClick={() => onOpenIconUsers(emoji.name)}
                            >
                                <div className="reactions-group-modal__text-icon-info">
                                    <Image src={`/reactions/${emoji.name}.svg`} className="reactions-profile-card__emoji-image" />
                                </div>
                                {reaction
                                    ?.filter(reactionUsers => reactionUsers.name.includes(emoji.name))
                                    .map((reactionUsers, index) => {
                                        return (
                                            <p key={index + reactionUsers.name} className="reactions-group-modal__reaction-count">
                                                {reactionUsers?.count > 0 ? reactionUsers?.count : 0}
                                            </p>
                                        )
                                    })}
                            </div>
                        )
                    })}
            </div>

            <div className="reactions-group-modal__double-input-container-profile reactions-group-modal__input-container-profile--margin-right">
                <div className="reactions-group-modal__reactions-list">
                    {reaction?.map(reactionUsers => {
                        return (
                            (reactionUsers.name === filter || (expanded && !expandedIcon)) &&
                            reactionUsers?.users?.map((user, index) => {
                                return (
                                    <div className="reactions-profile-card" key={index} onClick={() => onRoomMemberClick(user.id)}>
                                        <div className="reactions-profile-card__images">
                                            <Image className="reactions-profile-card__profile-image" src={user.profile_photo?.url_path || profileDefault} />
                                            <div className="reactions-profile-card__profile-icon">
                                                <Image src={`/reactions/${reactionUsers.name}.svg`} className="reactions-profile-card__emoji-image" />
                                            </div>
                                        </div>
                                        <div className="reactions-profile-card__profile-right-container">
                                            <p
                                                className={
                                                    (user?.first_name! + user?.last_name!).length < 25
                                                        ? "reactions-profile-card__user-name"
                                                        : "reactions-profile-card__user-nameMore25"
                                                }
                                            >
                                                {user?.first_name ? user?.first_name + " " + user?.last_name : ""}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        )
                    })}

                    {(reaction?.length === 0 || (expandedIcon && reaction?.find(react => react.name === filter) === undefined)) && (
                        <div className="reactions-group-modal__text">0 {translation.results}</div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ReactionsModal
