import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import Button from "../../../Shared/Primitive/Button"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { CloseIcon } from "../../../Shared/Components/SvgIcons"
import { useParams } from "react-router-dom"
import HTTPService from "../../../../App/HTTPService"

function RoomInvitationLinkModal() {
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const { roomId } = useParams()
    const [pageURL, setPageURL] = useState("")

    const getRoomInvitationLinkRequest = useCallback(() => {
        return HTTPService.get(`/get-invitation-link/${roomId}`)
    }, [roomId])

    useEffect(() => {
        getRoomInvitationLinkRequest()
            .then(response => {
                if (response.status === 200) {
                    setPageURL(response.data.link)
                }
            })
            .catch(error => console.error)
    }, [getRoomInvitationLinkRequest])

    const closeModal = () => {
        dispatch(setModalData({ open: false, roomInvitationLinkModal: false }))
    }

    const handleOnClick = () => {
        if (navigator.clipboard === undefined) {
            return
        } else {
            navigator.clipboard.writeText(pageURL)
            closeModal()
        }
    }

    return (
        <div className="room-invitation-link-modal">
            <div className="room-invitation-link-modal__title">
                {translation.invitationLink}
                <div className="room-invitation-link-modal__close-icon" onClick={closeModal}>
                    <CloseIcon />
                </div>
            </div>
            <div className="room-invitation-link-modal__container">
                <div className="room-invitation-link-modal__link-area">
                    <p className="room-invitation-link-modal__link-text">{pageURL}</p>
                </div>
                <div className="room-invitation-link-modal__button">
                    <Button
                        className="button button--border-radius-05 button--color-gold button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400 "
                        onClick={handleOnClick}
                    >
                        {translation.copyLink}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default RoomInvitationLinkModal
