import React from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../App/store"
import OrganizationDescriptionEditModal from "../../Organization/OrganizationProfile/OrganizationDescription/OrganizationDescriptionEditModal"
import OrganizationProfileAddEvent from "../../Organization/OrganizationProfile/OrganizationPinboard/OrganizationProfileAddEvent"
import OrganizationProfileEditEvent from "../../Organization/OrganizationProfile/OrganizationPinboard/OrganizationProfileEditEvent"
import OrganizationProfilePinboardCommentModal from "../../Organization/OrganizationProfile/OrganizationPinboard/OrganizationProfilePinboardCommentsModal"
import OrganizationProfileAddEmployeeModal from "../../Organization/OrganizationProfile/OrganizationProfileEmpoyees/OrganizationProfileAddEmployeeModal"
import OrganizationProfileRemoveEmployeeModal from "../../Organization/OrganizationProfile/OrganizationProfileEmpoyees/OrganizationProfileRemoveEmployeeModal"
import OrganizationSettingsModal from "../../Organization/OrganizationSettings/OrganizationSettingsModal"
import AddOrganizationModal from "../../User/AddOrganization/AddOrganizationModal"
import UserProfileEditExperienceModal from "../../User/UserProfile/MyExperience/UserProfileEditExperienceModal"
import UserProfileNewExperienceModal from "../../User/UserProfile/MyExperience/UserProfileNewExperienceModal"
import UserProfileDescriptionEditDescription from "../../User/UserProfile/UserProfileDescription/UserProfileDescriptionEditDescription"
import UserProfileDescriptionEditEdusiiaReasons from "../../User/UserProfile/UserProfileDescription/UserProfileDescriptionEditEdusiiaReasons"
import UserSettingsModal from "../../User/UserSettings/UserSettingsModal"
import PhotoModal from "../PhotoDropdown/PhotoModal"
import DeleteRoomModal from "../../Room/Components/DeleteRoomModal"
import Modal from "./Modal"
import RoomInviteModal from "../../Room/Components/RoomInvite/RoomInviteModal"
import ShareEventToRoomModal from "../../Organization/OrganizationProfile/OrganizationPinboard/ShareEventToRoomModal"
import EventLinkModal from "./EventLinkModal"
import CreateRoomModal from "../../Organization/CreateRoom/CreateRoomModal"
import RoomInvitationLinkModal from "../../Room/Components/RoomInvite/RoomInvitationLinkModal"
import EditRoomModal from "../../Room/RoomModals/EditRoomModal"
import RemoveRoomMember from "../../Room/RoomModals/RemoveRoomMember"
import UpgradeConfirmationModal from "../../Organization/GoPremium/UpgradeConfirmationModal"
import RoomPostDeleteModal from "../../Room/RoomPost/RoomPostDelete"
import LinkPreviewDeleteModal from "../../Room/RoomModals/LinkPreviewDelete"
import DonationBankTransfer from "../../User/Donation/DonationBankTransfer"
import NotPremiumCreateRoomModal from "./NotPremiumCreateRoomModal"
import PopUp from "./PopUp"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { PopUpType } from "../../../App/enums"
import RoomCommentDelete from "../../Room/RoomPost/RoomCommentDelete"
import EventCommentDelete from "../../Organization/OrganizationProfile/OrganizationPinboard/EventCommentDelete"
import ContactInfo from "./ContactInfo"
import RoomMembersModal from "../../Room/Components/RoomMembersModal"
import PasswordModal from "./PasswordModal"
import AddAdminModal from "../../Organization/OrganizationProfile/OrganizationAdmins/AddAdminModal"
import ShowTutorialModal from "../../User/UserDashboard/ShowTutorialModal"
import RemoveAdminModal from "../../Organization/OrganizationProfile/OrganizationAdmins/RemoveAdminModal"
import CreatorsAndManagersModal from "../../Room/Components/CreatorsAndManagersModal"
import RoomLimitModal from "./RoomLimitModal"
import TransferOwnershipModal from "../../Organization/OrganizationProfile/OrganizationAdmins/TransferOwnershipModal"
import ReactionsModal from "./ReactionsModal"
import LeaveRoomModal from "../../Room/Components/LeaveRoomModal"
import LeaveChatModal from "../../Chat/Components/LeaveChatModal"
import { JoinRoomIcon } from "./SvgIcons"
import JoinRoomModal from "../../Room/Components/JoinRoomModal"

function ModalGroup() {
    const modalStatus = useSelector((state: RootState) => state.modalSlice)
    const translation = useSelector(selectTranslations)

    return (
        <>
            {modalStatus.cover_photo && <Modal className="modal__photo" children={<PhotoModal type="cover" />} />}
            {modalStatus.profile_photo && <Modal className="modal__photo" children={<PhotoModal type="profile" />} />}
            {modalStatus.editDescription && <Modal className="modal__card" children={<UserProfileDescriptionEditDescription />} />}
            {modalStatus.editEdusiiaReasons && <Modal className="modal__card" children={<UserProfileDescriptionEditEdusiiaReasons />} />}
            {modalStatus.viewPhotoTypeModal && (
                <Modal className={`${modalStatus.viewPhotoTypeModal}-modal__photo`} children={<PhotoModal type={modalStatus.viewPhotoTypeModal} />} />
            )}
            {modalStatus.settings && <Modal children={<UserSettingsModal />} />}
            {modalStatus.editExperience && <Modal children={<UserProfileEditExperienceModal />} />}
            {modalStatus.newExperience && <Modal children={<UserProfileNewExperienceModal />} />}
            {modalStatus.addOrganization && <Modal children={<AddOrganizationModal />} />}
            {modalStatus.addEvent && <Modal children={<OrganizationProfileAddEvent />} />}
            {modalStatus.editEvent && <Modal children={<OrganizationProfileEditEvent />} />}
            {modalStatus.reactionsModal?.status && <Modal children={<ReactionsModal />} />}
            {modalStatus.removeEmployeeModal && <Modal className="modal__card" children={<OrganizationProfileRemoveEmployeeModal />} />}
            {modalStatus.addEmployeeModal && <Modal className="modal__card" children={<OrganizationProfileAddEmployeeModal />} />}
            {modalStatus.organizationDescription && <Modal className="modal__card" children={<OrganizationDescriptionEditModal />} />}
            {modalStatus.organizationSettings && <Modal className="modal__card" children={<OrganizationSettingsModal />} />}
            {modalStatus.deleteRoom && <Modal className="modal__card" children={<DeleteRoomModal />} />}
            {modalStatus.roomInvitationLinkModal && <Modal className="modal__card" children={<RoomInvitationLinkModal />} />}
            {modalStatus.createRoom && <Modal className="modal__card modal__card--no-overflow" children={<CreateRoomModal />} />}
            {modalStatus.editRoom && <Modal className="modal__card modal__card--no-overflow" children={<EditRoomModal />} />}
            {modalStatus.roomInviteModal && <Modal className="modal__card" children={<RoomInviteModal />} />}
            {modalStatus.pinboardCommentsModal && <Modal children={<OrganizationProfilePinboardCommentModal />} />}
            {modalStatus.shareEventToRoomModal && <Modal children={<ShareEventToRoomModal />} />}
            {modalStatus.eventLinkModal && <Modal className="modal__card" children={<EventLinkModal />} />}
            {modalStatus.roomMembersModal && <Modal className="modal__card" children={<RoomMembersModal />} />}
            {modalStatus.removeRoomMemberModal && <Modal className="modal__card modal__card--no-overflow" children={<RemoveRoomMember />} />}
            {modalStatus.goPremiumUpgradeModal && <Modal className="modal__card" children={<UpgradeConfirmationModal />} />}
            {modalStatus.deleteRoomPost && <Modal className="modal__card" children={<RoomPostDeleteModal />} />}
            {modalStatus.deleteLinkPreview && <Modal className="modal__card" children={<LinkPreviewDeleteModal />} />}
            {modalStatus.donationBankTransfer && <Modal className="modal__card" children={<DonationBankTransfer />} />}
            {modalStatus.notPremiumCreateRoomModal && <Modal className="modal__card" children={<NotPremiumCreateRoomModal />} />}
            {modalStatus.successfulCreateRoom && (
                <Modal className="pop-up-card" children={<PopUp message={translation.roomCreatedSuccessfully} type={PopUpType.successful} />} />
            )}
            {modalStatus.successfulCreateOrganization && (
                <Modal
                    className="pop-up-card"
                    children={
                        <PopUp
                            modifier="signin-message"
                            title={translation.confirmEmail}
                            message={translation.confirmOrganizationEmail}
                            type={PopUpType.successfulVerification}
                        />
                    }
                />
            )}
            {modalStatus.successfulRoomMemberRemoval && (
                <Modal className="pop-up-card" children={<PopUp message={translation.roomMemberRemoval} type={PopUpType.error} />} />
            )}
            {modalStatus.deleteAccountSuccessfully && (
                <Modal className="pop-up-card" children={<PopUp message={translation.deleteAccountSuccessfully} type={PopUpType.error} />} />
            )}
            {modalStatus.employeeRemoval && <Modal className="pop-up-card" children={<PopUp message={translation.employeeRemoval} type={PopUpType.error} />} />}
            {modalStatus.deleteRoomSuccessfully && (
                <Modal className="pop-up-card" children={<PopUp message={translation.deleteRoomSuccessfully} type={PopUpType.error} />} />
            )}
            {modalStatus.deleteOrganizationSuccessfully && (
                <Modal className="pop-up-card" children={<PopUp message={translation.deleteOrganizationSuccessfully} type={PopUpType.error} />} />
            )}
            {modalStatus.newExperienceAddedSuccessfully && (
                <Modal className="pop-up-card" children={<PopUp message={translation.newExperienceAddedSuccessfully} type={PopUpType.successful} />} />
            )}
            {modalStatus.deleteExperienceSuccessfully && (
                <Modal className="pop-up-card" children={<PopUp message={translation.deleteExperienceSuccessfully} type={PopUpType.error} />} />
            )}
            {modalStatus.editExperienceAddedSuccessfully && (
                <Modal className="pop-up-card" children={<PopUp message={translation.editExperienceAddedSuccessfully} type={PopUpType.successful} />} />
            )}
            {modalStatus.editRoomSuccessfully && (
                <Modal className="pop-up-card" children={<PopUp message={translation.editRoomSuccessfully} type={PopUpType.successful} />} />
            )}
            {modalStatus.deletePostSuccessfully && (
                <Modal className="pop-up-card" children={<PopUp message={translation.deletePostSuccessfully} type={PopUpType.error} />} />
            )}
            {modalStatus.leaveRoomSuccessfully && (
                <Modal className="pop-up-card" children={<PopUp message={translation.leaveRoomSuccessfully} type={PopUpType.error} />} />
            )}
            {modalStatus.confirmEmailMessage && (
                <Modal
                    className="pop-up-card"
                    children={
                        <PopUp
                            modifier="signin-message"
                            title={translation.confirmEmail}
                            message={translation.confirmEmailMessage}
                            message2={translation.confirmEmailMessage2}
                            type={PopUpType.signinMessage}
                        />
                    }
                />
            )}
            {modalStatus.postiveEmailVerification && (
                <Modal
                    className="pop-up-card"
                    children={
                        <PopUp
                            message={translation.postiveEmailVerification}
                            modifier="signin-message"
                            title={translation.successEmailConfirm}
                            type={PopUpType.successfulVerification}
                        />
                    }
                />
            )}

            {modalStatus.negativeEmailVerification?.status && (
                <Modal className="pop-up-card" children={<PopUp message={modalStatus.negativeEmailVerification?.message} type={PopUpType.signupError} />} />
            )}
            {modalStatus.successfullyJoinedRoom && (
                <Modal className="pop-up-card" children={<PopUp message={translation.successfullyAddedToRoom} type={PopUpType.successful} />} />
            )}
            {modalStatus.errorJoiningFullRoom && (
                <Modal className="pop-up-card" children={<PopUp message={translation.errorJoiningFullRoom} type={PopUpType.signupError} />} />
                
            )}
            {modalStatus.postiveOrganziationEmailVerification && (
                <Modal
                    className="pop-up-card"
                    children={
                        <PopUp
                            message={translation.organizationEmailVerification}
                            modifier="signin-message"
                            title={translation.successEmailConfirm}
                            type={PopUpType.successfulVerification}
                        />
                    }
                />
            )}

            {modalStatus.deleteRoomComment && <Modal className="modal__card" children={<RoomCommentDelete />} />}
            {modalStatus.deleteEventComment && <Modal className="modal__card" children={<EventCommentDelete />} />}
            {modalStatus.contactInfo && <Modal className="modal__card" children={<ContactInfo />} />}
            {modalStatus.userConfirmPasswordModalState?.status && <Modal className="modal__card" children={<PasswordModal />} />}
            {modalStatus.confirmNewEmailMessage && (
                <Modal
                    className="pop-up-card"
                    children={
                        <PopUp
                            message={translation.confirmNewEmail}
                            modifier="signin-message"
                            title={translation.confirmEmail}
                            type={PopUpType.successfulVerification}
                        />
                    }
                />
            )}
            {modalStatus.addAdminModal && <Modal className="modal__card" children={<AddAdminModal />} />}
            {modalStatus.removeAdminModal?.status && <Modal className="modal__card" children={<RemoveAdminModal />} />}
            {modalStatus.successfulUpgrade && (
                <Modal
                    className="pop-up-card"
                    children={<PopUp message={translation.successfulUpgrade} modifier="upgrade" type={PopUpType.successfulVerification} />}
                />
            )}

            {modalStatus.postiveNewOrganizationEmailVerification && (
                <Modal
                    className="pop-up-card"
                    children={
                        <PopUp
                            message={translation.newOrganizationEmailVerification}
                            modifier="signin-message"
                            title={translation.successEmailConfirm}
                            type={PopUpType.successfulVerification}
                        />
                    }
                />
            )}
            {modalStatus.postiveNewEmailVerification && (
                <Modal
                    className="pop-up-card"
                    children={
                        <PopUp
                            message={translation.postiveNewEmailVerification}
                            modifier="signin-message"
                            title={translation.successEmailConfirm}
                            type={PopUpType.successfulVerification}
                        />
                    }
                />
            )}
            {modalStatus.adminRemoved && <Modal className="pop-up-card" children={<PopUp message={translation.adminRemoved} type={PopUpType.error} />} />}
            {modalStatus.creatorsAndManagers && <Modal className="modal__card" children={<CreatorsAndManagersModal />} />}
            {modalStatus.roomLimitModal?.status && (
                <Modal className="modal__card" children={<RoomLimitModal max_rooms={modalStatus.roomLimitModal?.max_rooms!} />} />
            )}
            {modalStatus.showTutorial && <Modal className="modal__tutorial" children={<ShowTutorialModal />} />}
            {modalStatus.deletedEventPopUp && <Modal className="pop-up-card" children={<PopUp message={translation.deletedEvent} type={PopUpType.error} />} />}
            {modalStatus.transferOwnership?.status && <Modal className="modal__card" children={<TransferOwnershipModal />} />}

            {modalStatus.successfullyOwnershipTransfer && (
                <Modal className="pop-up-card" children={<PopUp message={translation.successfullyOwnershipTransfer} type={PopUpType.successful} />} />
            )}
            {modalStatus.leaveRoomModal && <Modal className="modal__card" children={<LeaveRoomModal />} />}
            {modalStatus.leaveChatModal && <Modal className="modal__card" children={<LeaveChatModal />} />}
            {modalStatus.joinPrivateRoomModal && <Modal className="modal__card" children={
                <JoinRoomModal organizationName={modalStatus.joinPrivateRoomModal.organizationName} 
                question={modalStatus.joinPrivateRoomModal.question}
                roomId={modalStatus.joinPrivateRoomModal.roomId}
                roomType={modalStatus.joinPrivateRoomModal.roomType}
                orgId={modalStatus.joinPrivateRoomModal.orgId}
                 />} />
                }
        </>
    )
}

export default ModalGroup
