import React, { MouseEventHandler } from "react"
import { useSelector } from "react-redux"
import { green } from "../../../../App/GlobaleVariables"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import "../../../../Styles/main.scss"
import { CompanyIcon, EditProfileIcon } from "../../../Shared/Components/SvgIcons"
import useWindowSize from "../../../Shared/Hooks/useWindowSize"

function UserProfileExperienceItem(props: {
    workPosition: string
    companyName: string
    startDate: any
    endDate: string
    editIcon?: boolean
    onClick?: MouseEventHandler<HTMLDivElement>
    onClickCompany?: () => void  | undefined
    onClickWebsite?: () => void  | undefined
    org_id: null | number 
    isLastItem: boolean
    is_in_edusiia?: boolean
    websiteUrl: string
}) {
    
    const translation = useSelector(selectTranslations)
    const screenWidth = useWindowSize()
    const breakpoint = 550

    return (
        <div className={`user-profile-experience-item ${props.isLastItem && "user-profile-experience-item--border-none"}`}>
            <div className="user-profile-experience-item__job-container">
                <div className="user-profile-experience-item__position">{props.workPosition}</div>
                <div className={`user-profile-experience-item__company ${props.is_in_edusiia && "user-profile-experience-item__company--linked"}`}> 
                    <p className='user-profile-experience-item__company-profile'  onClick={ props.is_in_edusiia && props.org_id ? props?.onClickCompany : () => null}>{props.companyName}</p>
                    { screenWidth> breakpoint && props.websiteUrl && <div className='user-profile-experience-item__company-link-icon' onClick={ props.websiteUrl ? props?.onClickWebsite : () => null}>
                       <a href={props.websiteUrl} target="_blank" rel="noreferrer"><div title={translation.websiteTooltip}><CompanyIcon color={green}/></div></a>
                    </div>}
                </div>
            </div>
           { screenWidth < breakpoint && props.websiteUrl && <div className='user-profile-experience-item__company-link-icon' onClick={ props.websiteUrl ? props?.onClickWebsite : () => null}>
               <a href={props.websiteUrl} target="_blank" rel="noreferrer"><div title={translation.websiteTooltip}><CompanyIcon color={green}/></div></a>
            </div>}
            <div className="user-profile-experience-item__right-section">
                {" "}
                <div className="user-profile-experience-item__date-container">
                    <div className="user-profile-experience-item__date">{props.startDate + " - " + props.endDate} </div>
                </div>
                {props.editIcon && (
                    <div onClick={props.onClick} className="user-profile-experience-item__icon">
                        <EditProfileIcon modifier="small" color="#60A49F" />
                    </div>
                )}
            </div>
        </div>
    )
}

export default UserProfileExperienceItem
