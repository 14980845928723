import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { headers } from "../../../App/Axios"
import { RoleType, RoomType } from "../../../App/enums"
import { roomDefaultPhoto } from "../../../App/GlobaleVariables"
import { capitalizeFirstLetter } from "../../../App/Helpers/Helpers"
import HTTPService from "../../../App/HTTPService"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { Room } from "../../../Types/Room"
import { setSearchQuery } from "../../Organization/OrganizationProfile/OrganizationProfileEmpoyees/EmployeesSlice"
import { setRoomsSearchData } from "../../Organization/OrganizationProfile/OrganizationRooms/OrganizationRoomsSearchSlice"
import { LoadingAnimation, MembersIcon } from "../Components/SvgIcons"
import UserCard from "../Components/UserCard"
import useWindowSize from "../Hooks/useWindowSize"
import Button from "../Primitive/Button"
import SearchInput from "../Primitive/SearchInput"
import { htmlRegex } from "../../../App/Regex"

function AllRoomsCard() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const translation = useSelector(selectTranslations)
    const [inputValue, setInputValue] = useState("")
    const screenWidth = useWindowSize()
    const breakpoint = 550
    const [allRoomsData, setAllRoomsData] = useState<Array<Room>>([])
    const [hasMoreRooms, setHasMoreRooms] = useState(false)
    const [hasMoreSearchRooms, setHasMoreSearchRooms] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingMorePosts, setIsLoadingMorePosts] = useState(false)
    const [pageNum, setPageNum] = useState(1)
    const [searchPageNum, setSearchPageNum] = useState(1)
    const searchedRooms = useSelector((state: RootState) => state.OrganizationSearchRoomsSlice.rooms)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)

    const showButton = () => {
        if (inputValue.length > 0 && searchedRooms !== undefined && searchedRooms!.length > 0 && hasMoreSearchRooms) {
            return true
        } else if (inputValue.length === 0 && allRoomsData.length > 0 && hasMoreRooms) {
            return true
        } else {
            return false
        }
    }

    const onRemoveIconClick = () => {
        setInputValue("")
        dispatch(setSearchQuery(""))
    }

    const getRoomsData = (pageNumber: number) => {
        return HTTPService.get(`/room/?page=${pageNumber}`, headers)
    }

    const getSearchRoomsData = (searchedQuery: string, pageNumber: number) => {
        return HTTPService.get(`room/?query=${searchedQuery}&page=${pageNumber}`, headers)
    }

    useEffect(() => {
        getRoomsData(1)
            .then(response => {
                if (response.status === 200) {
                    setAllRoomsData(response.data.results)
                    setHasMoreRooms(response.data.next)
                    setIsLoading(false)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    const onSeeMoreRoomsClick = () => {
        setIsLoadingMorePosts(true)
        if (inputValue.length > 1) {
            getSearchRoomsData(inputValue, searchPageNum + 1)
                .then(response => {
                    if (response.status === 200) {
                        dispatch(setRoomsSearchData({ rooms: [...searchedRooms!, ...response.data.results] }))
                        setHasMoreSearchRooms(response.data.next)
                        setSearchPageNum(searchPageNum + 1)
                        setIsLoadingMorePosts(false)
                    }
                })
                .catch(error => {
                    console.log(error)
                    setSearchPageNum(1)
                })
        } else {
            getRoomsData(pageNum + 1)
                .then(response => {
                    if (response.status === 200) {
                        setAllRoomsData([...allRoomsData, ...response.data.results])
                        setHasMoreRooms(response.data.next)
                        setPageNum(pageNum + 1)
                        setIsLoadingMorePosts(false)
                    }
                })
                .catch(error => {
                    console.log(error)
                    setPageNum(1)
                })
        }
    }

    useEffect(() => {
        if (inputValue.length > 2) {
            setIsLoading(true)
            const getData = setTimeout(() => {
                getSearchRoomsData(`${inputValue}`, 1)
                    .then(response => {
                        if (response.status === 200) {
                            dispatch(setRoomsSearchData({ rooms: response.data.results }))
                            setHasMoreSearchRooms(response.data.next)
                            setSearchPageNum(1)
                            setPageNum(1)
                            setIsLoading(false)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }, 500)

            return () => clearTimeout(getData)
        }
    }, [dispatch, inputValue])

    const onNavigationToSelectedRoom = (roomId: number) => {
        switch (role) {
            case RoleType.USER:
                navigate(`/room/${roomId!}`)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(`/room/${id}/${roomId!}`)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    return (
        <div className="all-rooms-card">
            <p className="all-rooms-card__title">{translation.rooms}</p>
            <div className="all-rooms-card__search-input">
                <SearchInput
                    onRemoveIconClick={onRemoveIconClick}
                    searchvalue={inputValue!}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setInputValue(event.target.value)
                    }}
                    isGlobalSearch={false}
                />
            </div>
            {isLoading ? (
                <div className="rooms-card__loading-container">
                    {" "}
                    <LoadingAnimation type="cover" />
                </div>
            ) : (
                <div className="rooms-card__container">
                    {inputValue!.length > 0 && (searchedRooms === undefined || searchedRooms!.length === 0) ? (
                        <div className="rooms-card__no-data-text">{`${translation.noResultThatContain} '${inputValue}' ${translation.wasFound}`}</div>
                    ) : (
                        (inputValue!.length > 0 && searchedRooms!.length > 0 ? searchedRooms : allRoomsData)!.map(room => {
                            return (
                                <div key={room.id} className={`rooms-card-wrapper`} onClick={() => onNavigationToSelectedRoom(room.id)}>
                                    <div className={`rooms-card`}>
                                        <UserCard
                                            imageSrc={room.photo || roomDefaultPhoto}
                                            text={room.name}
                                            subtext={translation.by + " " + room?.organization?.name!}
                                            userCardStyle={"rooms-card-content"}
                                            leftPanelStyle={"left-panel"}
                                            middlePanelStyle={"middle-panel"}
                                            profileImageStyle={"image image__profile image__profile--fit image__profile--size-small"}
                                            textStyle={"text"}
                                            textStyleMore25={"textMore25"}
                                            subtextStyle={"subtext"}
                                            textStyleContainer={"text-container"}
                                            subtextStyleContainer={"subtext-container"}
                                        />
                                        <div className="rooms-card__members">
                                            <div className="rooms-card__members-container">
                                                <div className="rooms-card__text">
                                                    {room.total_members}{" "}
                                                    {screenWidth > breakpoint ? (
                                                        room.total_members === 1 ? (
                                                            translation.member
                                                        ) : (
                                                            translation.members
                                                        )
                                                    ) : (
                                                        <MembersIcon modifier="all-rooms" />
                                                    )}
                                                </div>
                                            </div>
                                            <div className="rooms-card-content__right-panel">
                                                <p className={`feed-item-room-card__right-text feed-item-room-card__right-text--${room.type}`}>
                                                    {capitalizeFirstLetter(room.type === RoomType.Private ? translation.private : translation.public)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <p className="rooms-card__description">
                                        {room.description
                                            .replaceAll(/&nbsp;/g, " ")
                                            .replace(htmlRegex, "")
                                            .replaceAll(/&nbsp/g, " ")}
                                    </p>
                                </div>
                            )
                        })
                    )}
                    {isLoadingMorePosts && (
                        <div className="rooms-card__container">
                            <LoadingAnimation type="profile" />
                        </div>
                    )}
                    {showButton() && (
                        <div className="user-profile-experience__button-container">
                            <div className="user-profile-experience__button-size">
                                <Button
                                    onClick={onSeeMoreRoomsClick}
                                    className="button button--border-radius-09 button--color-grey button--hover-darker button--cursor-pointer button--text-color-grey button--font-size-small button--font-weight-400  "
                                >
                                    <div className="user-profile-experience__button-content"> {translation.seeMore}</div>
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default AllRoomsCard
