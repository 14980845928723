import { RoleType } from "../../../../App/enums"
import { MentionedUsersType } from "../../../../Types/RoomPosts"

export const convertTagElement = (mentionedUsers: NodeListOf<globalThis.Element>, tags: MentionedUsersType[], role: string, orgId: string, userId: number) => {
    if (mentionedUsers !== undefined && tags !== undefined) {
        mentionedUsers.forEach((mention, index) => {
            tags?.forEach(tag => {
                if (tag!.tagged_org) {
                    if (tag.tagged_org?.toString() === mention.id) {
                        const linkElement = document.createElement("a")
                        linkElement.href = `/${tag.redirect_url!}`
                        linkElement.className = mention.className

                        if(mention.innerHTML.startsWith('@<a href')){
                            var regex = /\>(.+)\<\/a\>/g
                            var regex_res = regex.exec(mention.innerHTML)
                            linkElement.innerText = '@'+regex_res![1]
                        }else{
                            linkElement.innerText = mention.innerHTML
                        }

                        console.log(mention.innerHTML)

                        mentionedUsers[index].replaceWith(linkElement)
                    }
                } else if (tag!.tagged_user) {
                    if (tag.tagged_user?.toString() === mention.id) {
                        const finalRedirectUrl = role === RoleType.ORGANIZATION ? tag.redirect_url + "/" + orgId : tag.redirect_url
                        const linkElement = document.createElement("a")
                        linkElement.href = `/${tag.tagged_user === userId ? "user-profile" : finalRedirectUrl}`
                        linkElement.className = mention.className
                        linkElement.innerText = mention.innerHTML
                        mentionedUsers[index].replaceWith(linkElement)
                    }
                } else if (tag.redirect_url === null && index === tags.indexOf(tag)) {
                    const linkElement = document.createElement("a")
                    linkElement.href = `/not-found`
                    linkElement.className = mention.className
                    linkElement.innerText = mention.innerHTML
                    mentionedUsers[index].replaceWith(linkElement)
                }
            })
        })
    }
}
