import React from "react"
import "../../../../Styles/main.scss"
import { addNewLine } from "../../../../App/Helpers/Helpers"
import { EditProfileIcon } from "../../../Shared/Components/SvgIcons"
import Linkify from "react-linkify"

function UserProfileDescriptionItem(props: { title: string; description: string; editIcon?: boolean; onEdit?: any }) {
    return (
        <div className="user-profile-description-item">
            {props.editIcon && (
                <div onClick={props.onEdit} className="user-profile-description-item__icon">
                    <EditProfileIcon modifier="small" color="#60A49F" />
                </div>
            )}
            <div className="user-profile-description-item__title">{props.title}</div>
            <div className="user-profile-description-item__text">
                <Linkify>{addNewLine(props.description)}</Linkify>
            </div>
        </div>
    )
}

export default UserProfileDescriptionItem
