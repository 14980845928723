import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { useDispatch, useSelector } from "react-redux"
import Button from "../../Shared/Primitive/Button"
import Image from "../../Shared/Primitive/Image"
import { RootState } from "../../../App/store"
import { useState, useRef } from "react"
import { CallerComponent, CommentType, EditorModifier, PostType, RoleType } from "../../../App/enums"
import { fileImageType, organizationProfileDefault, profileDefault } from "../../../App/GlobaleVariables"
import HTTPService from "../../../App/HTTPService"
import { headers } from "../../../App/Axios"
import { EventComments } from "../../../Types/SponsorResult"
import { MentionedUsersType, PostComments } from "../../../Types/RoomPosts"
import { setSingularEventData } from "../../Shared/SharedSlices/SponsorCardSlice"
import { Editor as TinyMCEEditor } from "tinymce"
import EditorTextArea from "../../Shared/Components/Editor/EditorTextArea"
import { setPostStatus, setRoomPostsData } from "./RoomPostSlice"
import { ConvertStringToHTML, documentNameLength, replaceHTMLCharactersWithCorrespondingLetters } from "../../../App/Helpers/Helpers"
import { CloseIcon, GreenCloseIcon } from "../../Shared/Components/SvgIcons"
import { editRoomContentPatchRequest } from "./RoomPostRequests"
import useWindowSize from "../../Shared/Hooks/useWindowSize"
import { useParams } from "react-router-dom"
import { hashtagRegex } from "../../../App/Regex"

function RoomPostEdit(props: {
    id: number
    isEditable: Function
    type: PostType.comment | PostType.post
    content?: EventComments | PostComments
    removeId?: Function
    subtype?: CommentType.event | CommentType.post | undefined
}) {
    const { postId } = useParams()
    const selectedPost = useSelector((state: RootState) => state.RoomPostsSlice.roomPostSlice.post)
    const translation = useSelector(selectTranslations)
    const posts = useSelector((state: RootState) => state.RoomPostsSlice.roomPostsSlice.posts)
    const pinboardIdState = useSelector((state: RootState) => state.pinboardIdSlice.id)
    const selectedObject = postId
        ? props.type === PostType.post
            ? selectedPost
            : posts.find(post => post.id === props.id)
        : posts.find(post => post.id === props.id)
    const [textAreaValue, setTextAreaValue] = useState(selectedObject?.content! || props?.content?.content!)
    const [postFile, setPostFile] = useState<any>(selectedObject?.post_file || null)
    const [postFileType, setPostFileType] = useState(selectedObject?.file_type || null)
    const editorRef = useRef<TinyMCEEditor | null>(null)
    const dispatch = useDispatch()
    let contentObject = props.type === PostType.post ? selectedObject : props?.content
    let profilePhoto =
        contentObject?.content_type === RoleType.USER.toLowerCase()
            ? contentObject?.content_object.profile_photo?.url_path || profileDefault
            : contentObject?.content_object.photo?.url_path || organizationProfileDefault

    const onCancelClick = () => {
        props.isEditable(false)
        props.removeId !== undefined && props?.removeId(undefined)!
    }

    const singularEventGetRequest = () => {
        return HTTPService.get(`/event/${pinboardIdState}`, headers)
    }

    const onSaveClick = async () => {
        const mentionedUsersArray: MentionedUsersType[] = []
        let modifiedContent = ""
        if (editorRef.current!) {
            const htmlContent = ConvertStringToHTML(editorRef!.current!.getContent({format: 'raw'}))
            const spans = htmlContent.querySelectorAll("[data-type]")

            spans.forEach((span, index) => {
                span.innerHTML = "@tagged"
                mentionedUsersArray.splice(index, 0, {
                    tagged_user: span.getAttribute("data-type") === RoleType.USER.toLocaleLowerCase() ? parseInt(span.id) : null,
                    tagged_org: span.getAttribute("data-type") === RoleType.ORGANIZATION.toLocaleLowerCase() ? parseInt(span.id) : null,
                })
                modifiedContent = htmlContent.outerHTML.replaceAll("<body>", "").replaceAll("</body>", "").replace(hashtagRegex, "$1")
            })

            if (mentionedUsersArray.length >= 1 || editorRef.current!.getContent({ format: "text" }).length > 0) {
                const formData = new FormData()
                formData.append("content", modifiedContent.length > 0 ? modifiedContent : editorRef!.current!.getContent({format: 'raw'}).replace(hashtagRegex, "$1"))
                formData.append("tags", JSON.stringify(mentionedUsersArray))

                if (props.subtype !== CommentType.event) {
                    props.type === PostType.post && !postFile && formData.append("post_file", "")
                    props.type === PostType.post && !postFileType && formData.append("file_type", "")
                    const caller = props.type === PostType.post ? CallerComponent.post : CallerComponent.comment
                    const contentId = props.type === PostType.post ? selectedObject?.id : props?.content?.id
                    try {
                        const response = editRoomContentPatchRequest(caller, contentId!, formData)
                        if ((await response).status === 200) {
                            dispatch(setPostStatus({ postsStatus: true, singularPostStatus: true, postedInRoomStatus: false }))
                            
                            if(props.type == PostType.comment){
                                let updatedPosts = [...posts]
                                const idx = updatedPosts.findIndex(p => p.post_comments.find(c => c.id == contentId!))
                                let res = (await response).data

                                updatedPosts[idx] = {...posts[idx],post_comments: posts[idx].post_comments?.map(comment => {
                                    if (comment.id === contentId!) {
                                        return { ...comment, content: res.content }
                                    }
                                    return comment
                                })}

                                dispatch(setRoomPostsData(updatedPosts))
                            }

                            props.isEditable(false)
                            props.removeId !== undefined && props?.removeId(undefined)!
                        }
                    } catch (error: any) {
                        console.log(error)
                    }
                } else {
                    try {
                        const response = editRoomContentPatchRequest(CallerComponent.eventComment, props?.content?.id!, formData)
                        if ((await response).status === 200) {
                            singularEventGetRequest().then(response => {
                                if (response.status === 200) {
                                    dispatch(setSingularEventData(response.data))
                                }
                            })

                            props.isEditable(false)
                            props.removeId !== undefined && props?.removeId(undefined)!
                        }
                    } catch (error: any) {
                        console.log(error)
                    }
                }
            }
        }
    }

    const onRemoveClick = () => {
        setPostFile(null)
        setPostFileType(null)
    }

    const fileName = postFile && postFile!.split("/")!.pop()!.split("#")[0].split("?")[0]
    const fileType = fileName && fileName.slice(fileName.indexOf(".") + 1).toUpperCase()
    const screenWidth = useWindowSize()
    const breakpoint = 500
    const content = selectedObject?.content! || props?.content?.content!
    let regex = /(?:#([\w\u00C0-\u017F]+)|<span[^>]*\bclass="hashtag"[^>]*>(.*?)<\/span>)/gi

    const replacedText = replaceHTMLCharactersWithCorrespondingLetters(content)?.replace(regex, (match, group1, group2) => {
        const hashtag = group1 || group2
        const object = selectedObject ? selectedObject : props?.content
        const matchedHashtag = object?.hashtags?.find(h => h.replace("&nbsp;", "") === hashtag.replace("#", ""))

        if (matchedHashtag) {
            const link = `<span class="hashtag" contenteditable="false">${hashtag.startsWith("#") ? hashtag : "#" + hashtag}</span>`
            return link
        } else {
            return match
        }
    })


    return (
        <div className={`${props.type === PostType.post ? "edit-post edit-post--background-colour" : "edit-post"} `}>
            <div className="edit-post__profile-photo">
                <Image src={profilePhoto} alt="room" className="image__profile image__profile--size-small image__profile--fit" />
            </div>
            <div className="edit-post__container">
                <div className="room-layout__photo-text-container">
                    <EditorTextArea
                        inputState={textAreaValue}
                        setInputState={setTextAreaValue}
                        editorRef={editorRef}
                        editorPlaceholder={translation.typeYourMessage}
                        editorHeight={62}
                        modifier={EditorModifier.editContent}
                        initialEditorValue={replacedText!}
                        setContent={() => {}}
                        enterPressed={() => {}}
                    />
                    {postFile ? (
                        fileImageType.includes(postFileType!) ? (
                            <div className="edit-post__post-image-container edit-post__post-image-container--edit-post">
                                <Image className="edit-post__post-image edit-post__post-image--no-margin" src={postFile} alt="post" />
                                <div className="edit-post__post-image-cancel-container" onClick={onRemoveClick}>
                                    <Image src={postFile} alt="post" className="edit-post__post-image edit-post__post-image--opacity " />
                                    <div className="edit-post__post-image-cancel">
                                        <CloseIcon />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="room-layout__document-container room-layout__document-container--edit-post">
                                <img className="room-layout__document-image" src={`/document-icon.svg`} alt="doc" />
                                <div className="room-layout__document-content">
                                    <a href={postFile} className="room-layout__document-title">
                                        {documentNameLength(fileName, screenWidth, breakpoint)}
                                    </a>
                                    <p className="room-layout__document-size">{fileType}</p>
                                </div>
                                <div className="room-layout__document-cancel" onClick={onRemoveClick}>
                                    <GreenCloseIcon />
                                </div>
                            </div>
                        )
                    ) : null}
                </div>

                <div className="edit-post__button-container">
                    <div className="edit-post__button">
                        <Button
                            className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-grey-c4 button--hover-brighter button--cursor-pointer button--border-radius-05 "
                            onClick={onCancelClick}
                        >
                            {translation.cancel}
                        </Button>
                    </div>
                    <div className="edit-post__button">
                        <Button
                            className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-gold button--hover-brighter button--cursor-pointer button--border-radius-05"
                            onClick={onSaveClick}
                        >
                            {translation.save}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default RoomPostEdit
