import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SiginErrorResponseType } from "../../../Types/SigninErrorResponseType"
import { SigninTokenStatus } from "../../../Types/SigninTokenStatus"

const initialState = { message: {}, status: 0 } as SiginErrorResponseType
const SigninErrorSlice = createSlice({
    name: "signinError",
    initialState: initialState,
    reducers: {
        getSigninError(state, action: PayloadAction<SiginErrorResponseType>) {
            state.message = action.payload.message
            state.status = action.payload.status
        },
        resetSigninError(state) {
            state.message = initialState.message
        },
    },
})

const initialTokenState: SigninTokenStatus = { hasAccessToken: false }

const AuthenticationSlice = createSlice({
    name: "authenticationSlice",
    initialState: initialTokenState,
    reducers: {
        hasAuthenticationToken(state, action: PayloadAction<SigninTokenStatus>) {
            state.hasAccessToken = action.payload.hasAccessToken
        },
    },
})
export const { hasAuthenticationToken } = AuthenticationSlice.actions
export const { getSigninError } = SigninErrorSlice.actions
export const { resetSigninError } = SigninErrorSlice.actions
export default combineReducers({
    signinError: SigninErrorSlice.reducer,
    authenticationSlice: AuthenticationSlice.reducer,
})
