import { useSelector } from "react-redux"
import { RoleType } from "../../../App/enums"
import { not_found } from "../../../App/GlobaleVariables"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import BackgroundWrapper from "../Components/BackgroundWrapper"
import DonateButton from "../Components/DonateButton"
import IndividualProfileCard from "../Components/IndividualProfileCard"
import ModalGroup from "../Components/ModalGroup"
import OrganizationProfileCard from "../Components/OrganizationProfileCard"
import RoomDropdown from "../Components/RoomDropdown"
import Header from "../Header/Header"
import Footer from "../Layout/Footer"
import MainLayout from "../Layout/MainLayout"
import NewestOrganizationsCard from "../NewestOrganizations/NewestOrganizations"
import AllSponsoredEventsCard from "./AllSponsoredEventsCard"
import NewestMembersCard from "../NewestMembers/NewestMemberCard"

function AllSponsoredEventsScreen() {
    const translation = useSelector(selectTranslations)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const donationLink = useSelector((state: RootState) => state.UserSettingsStatusSlice.userDonationLinkSlice.link)

    return (
        <BackgroundWrapper>
            <Header />
            <MainLayout
                leftPanelFirstComponent={role === RoleType.USER ? <OrganizationProfileCard /> : <IndividualProfileCard />}
                leftPanelSecondComponent={<RoomDropdown type={role!} />}
                leftPanelForthComponent={role === RoleType.USER && translation.newestOrganizations}
                leftPanelFifthComponent={role === RoleType.USER ? <NewestOrganizationsCard /> : null}
                RightPanelFirstComponent={role === RoleType.USER ? donationLink === not_found ? <DonateButton /> : null : null}
                RightPanelSecondComponent={translation.newsMembersText}
                RightPanelThirdComponent={<NewestMembersCard />}
                overflowModifier={true}
            >
                <AllSponsoredEventsCard />
            </MainLayout>
            <Footer />
            <ModalGroup />
        </BackgroundWrapper>
    )
}

export default AllSponsoredEventsScreen
