import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { User } from "../../../Types/User"
import { getOrganizationNameRequest } from "../../Shared/Components/OrganizationProfileCard"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { getAddOrganizationErrorMessage, setUserOrganizationsData } from "../../Shared/SharedSlices/OrganizationsSlice"

export const createOrganization =
    (
        user: User,
        organization: { name: string; email: string; about: string; website: string },
        organization_type: any,
        representative: boolean,
        onModalClose: Function
    ) =>
    async (dispatch: any) => {
        const Data = {
            name: organization.name,
            email: organization.email,
            description: organization.about,
            website_url: organization.website,
            organization_type: organization_type,
            representative: representative,
            org_privacy_settings: [{ search_results: true, google_search: false, private_messages: true, show_location: false, show_contact_info: false }],
        }
        try {
            const response = await HTTPService.post(`/user/organization/`, Data, headers)
            if (response.status === 201) {
                getOrganizationNameRequest(user.id)
                    .then(response => {
                        if (response.status === 200) {
                            dispatch(setUserOrganizationsData(response.data))
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })

                onModalClose()

                dispatch(setModalData({ open: true, successfulCreateOrganization: true }))

                dispatch(
                    getAddOrganizationErrorMessage({
                        message: {
                            name: "",
                            email: "",
                            description: "",
                            representative: "",
                            website_url: "",
                        },
                        status: 0,
                    })
                )
            }
        } catch (error: any) {
            const errors = {
                msg: error.response.data,
                status: error.response.status,
            }
            await dispatch(
                getAddOrganizationErrorMessage({
                    message: errors.msg,
                    status: errors.status,
                })
            )
        }
    }
