import { useDispatch, useSelector } from "react-redux"
import HTTPService from "../../../App/HTTPService"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { CloseIcon } from "../../Shared/Components/SvgIcons"
import Button from "../../Shared/Primitive/Button"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { setRoomPostData, setRoomPostsData } from "./RoomPostSlice"

function RoomCommentDelete() {
    const selectedPost = useSelector((state: RootState) => state.RoomPostsSlice.roomPostSlice.post)
    const commentId = useSelector((state: RootState) => state.RoomPostsSlice.roomCommentIdSlice.commentId)
    const posts = useSelector((state: RootState) => state.RoomPostsSlice.roomPostsSlice.posts)
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()

    const onModalClose = () => {
        dispatch(setModalData({ open: false, deleteRoomComment: false }))
    }

    const DeleteRequest = async () => {
        try {
            const response = await HTTPService.delete(`/comment/${commentId}/delete/`)
            if (response.status === 204) {
                dispatch(setModalData({ open: false, deleteRoomComment: false }))
                if(selectedPost.id > 0){
                    let comments = selectedPost.post_comments.filter(comment => comment.id !== commentId)
                    dispatch(setRoomPostData({ ...selectedPost, post_comments: comments }))
                }

                let updatedPosts = [...posts]
                const idx = posts.findIndex(p => p.post_comments.find(c => c.id == commentId))
                if(idx > -1){
                    let comments = posts[idx].post_comments.filter(comment => comment.id !== commentId)
                    updatedPosts[idx] = {...posts[idx],post_comments: comments,total_commments: posts[idx].total_commments-1}
                    dispatch(setRoomPostsData(updatedPosts))
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onRoomCommentDelete = () => {
        DeleteRequest()
    }

    return (
        <div className="delete-room-modal">
            <div className="delete-room-modal__content">
                <div className="delete-room-modal__upper-panel">
                    <div className="delete-room-modal__title">{translation.deletePost}?</div>
                    <div onClick={onModalClose} className="delete-room-modal__close-icon">
                        <CloseIcon />
                    </div>
                </div>
                <div className="delete-room-modal__divider"></div>
                <div className="delete-room-modal__middle-panel">
                    <div className="delete-room-modal__text">{translation.areYouSureToDeleteComment}</div>
                    <div className="delete-room-modal__delete-icon"></div>
                </div>
                <div className="delete-room-modal__button-container">
                    <div className="delete-room-modal__button">
                        <Button
                            onClick={onRoomCommentDelete}
                            type="submit"
                            className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-red button--hover-brighter button--cursor-pointer button--border-radius-05 "
                        >
                            {translation.delete}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoomCommentDelete
