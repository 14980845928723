import React, { useEffect } from "react"
import RoomPost from "./RoomPost"
import { RootState } from "../../../App/store"
import { useDispatch, useSelector } from "react-redux"
import { CommentType, RoleType } from "../../../App/enums"
import Comments from "../../Shared/Components/Comments"
import { useNavigate, useParams } from "react-router-dom"
import { getRoomPostRequest } from "./RoomPostRequests"
import { setPostStatus, setRoomPostData } from "./RoomPostSlice"
import { hasPostDropdown } from "./hasPostDropdown"

function RoomSinglePostExpanded() {
    const dispatch = useDispatch()
    const { postId } = useParams()
    const selectedPost = useSelector((state: RootState) => state.RoomPostsSlice.roomPostSlice.post)
    const roles = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const isRoomManager = useSelector((state: RootState) => state.roomSlice.roomManagerSlice.room_manager)
    const isRoomOwner = useSelector((state: RootState) => state.roomSlice.roomOwnerSlice.room_owner)
    const hasPrivilegedPermission = roles === RoleType.USER ? isRoomManager : isRoomOwner
    const postStatus = useSelector((state: RootState) => state.RoomPostsSlice.postStatusSlice.singularPostStatus)
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(setPostStatus({ postsStatus: false, singularPostStatus: true, postedInRoomStatus: false }))
    }, [dispatch])

    useEffect(() => {
        if (postId === undefined) {
            navigate("/not-found")
        } else {
            getRoomPostRequest(parseInt(postId))
                .then(response => {
                    if (response.status === 200) {
                        dispatch(setRoomPostData(response.data))
                        dispatch(setPostStatus({ postsStatus: false, singularPostStatus: false, postedInRoomStatus: false }))
                    }
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        navigate("/not-found")
                    }
                })
        }
    }, [dispatch, navigate, postId, postStatus])

    return selectedPost?.id === parseInt(postId!) && selectedPost?.content_type ? (
        <div className="room-post-expanded">
            <RoomPost
                showCommentIcon={false}
                postDropdown={hasPostDropdown(selectedPost, roles, organizationDataState.id!, userDataState.id, hasPrivilegedPermission,roomDataState.is_active)}
                postDropdownStyle={"room-post__right-panel-content room-post__right-panel-content--height-5"}
                noCursor={selectedPost?.content_object ? false : true}
                post={selectedPost}
                postType={"singularPost"}
                showFullPost={true}
            />

            <div className="room-post-expanded__comment-section">
                <Comments commentsarray={selectedPost?.post_comments!} type={CommentType.post} />
            </div>
        </div>
    ) : null
}

export default RoomSinglePostExpanded
