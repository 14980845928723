import { useSelector } from "react-redux"
import { NotificationFrequency, NotificationSettings } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { OrganizationWebType, UserWebType } from "../../../Types/Notification"
import RadioButton from "../Primitive/RadioButton"

function WebNotifications(props: {
    webState: { value_none: boolean; value_immediate: boolean }
    setWebState: Function
    type?: string
    data?: UserWebType | OrganizationWebType
}) {
    const translation = useSelector(selectTranslations)
    const radioGroupHandler = (frequency: string) => {
        switch (props.type) {
            case NotificationSettings.comment:
                props.setWebState({
                    ...props.data,
                    comment: {
                        value_none: NotificationFrequency.none === frequency,
                        value_immediate: NotificationFrequency.immediate === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.commented_on_same_post:
                props.setWebState({
                    ...props.data,
                    commented_on_same_post: {
                        value_none: NotificationFrequency.none === frequency,
                        value_immediate: NotificationFrequency.immediate === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.org_room_invitation:
                props.setWebState({
                    ...props.data,
                    org_room_invitation: {
                        value_none: NotificationFrequency.none === frequency,
                        value_immediate: NotificationFrequency.immediate === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.room_invitation:
                props.setWebState({
                    ...props.data,
                    room_invitation: {
                        value_none: NotificationFrequency.none === frequency,
                        value_immediate: NotificationFrequency.immediate === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.room_invitation_reply:
                props.setWebState({
                    ...props.data,
                    room_invitation_reply: {
                        value_none: NotificationFrequency.none === frequency,
                        value_immediate: NotificationFrequency.immediate === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.room_join_request:
                props.setWebState({
                    ...props.data,
                    room_join_request: {
                        value_none: NotificationFrequency.none === frequency,
                        value_immediate: NotificationFrequency.immediate === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.room_join_request_reply:
                props.setWebState({
                    ...props.data,
                    room_join_request_reply: {
                        value_none: NotificationFrequency.none === frequency,
                        value_immediate: NotificationFrequency.immediate === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.add_admin:
                props.setWebState({
                    ...props.data,
                    add_admin: {
                        value_none: NotificationFrequency.none === frequency,
                        value_immediate: NotificationFrequency.immediate === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.remove_admin:
                props.setWebState({
                    ...props.data,
                    remove_admin: {
                        value_none: NotificationFrequency.none === frequency,
                        value_immediate: NotificationFrequency.immediate === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.event_comment:
                props.setWebState({
                    ...props.data,
                    event_comment: {
                        value_none: NotificationFrequency.none === frequency,
                        value_immediate: NotificationFrequency.immediate === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.new_post_in_room:
                props.setWebState({
                    ...props.data,
                    new_post_in_room: {
                        value_none: NotificationFrequency.none === frequency,
                        value_immediate: NotificationFrequency.immediate === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.admin_reply:
                props.setWebState({
                    ...props.data,
                    admin_reply: {
                        value_none: NotificationFrequency.none === frequency,
                        value_immediate: NotificationFrequency.immediate === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.new_member:
                props.setWebState({
                    ...props.data,
                    new_member: {
                        value_none: NotificationFrequency.none === frequency,
                        value_immediate: NotificationFrequency.immediate === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.tags:
                props.setWebState({
                    ...props.data,
                    tags: {
                        value_none: NotificationFrequency.none === frequency,
                        value_immediate: NotificationFrequency.immediate === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            default:
                break
        }
    }
    return (
        <div className="web-notification">
            <div className="web-notification__radio-container">
                <p className="web-notification__radio-label">{translation.none}</p>
                <RadioButton
                    modifier="radio__span--dark"
                    name="web-notification"
                    value={props.webState.value_none}
                    onClick={() => radioGroupHandler(NotificationFrequency.none)}
                />
            </div>
            <div className="web-notification__radio-container">
                <p className="web-notification__radio-label">{translation.immediate} </p>
                <RadioButton
                    modifier="radio__span--dark"
                    name="web-notification"
                    value={props.webState.value_immediate}
                    onClick={() => radioGroupHandler(NotificationFrequency.immediate)}
                />
            </div>
        </div>
    )
}

export default WebNotifications
