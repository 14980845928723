import { combineReducers } from "@reduxjs/toolkit"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ForgotPasswordResponseMessage } from "../../../Types/Responses/ForgotPasswordResponseMessage"
interface ErrorData {
    message: ForgotPasswordResponseMessage
    status: number
}
const initialerrorState = { message: {}, status: 0 } as ErrorData
const ForgotPasswordErrorSlice = createSlice({
    name: "forgotPasswordError",
    initialState: initialerrorState,
    reducers: {
        getForgotPasswordError(state, action: PayloadAction<ErrorData>) {
            state.message = action.payload.message
            state.status = action.payload.status
        },
        resetForgotPasswordError(state) {
            state.message = initialerrorState.message
        },
    },
})

const initialstatusState = { status: false }
const ForgotPasswordStatusSlice = createSlice({
    name: "forgotPasswordStatus",
    initialState: initialstatusState,
    reducers: {
        getStatus(state = initialstatusState) {
            state.status = true
        },
    },
})
export const { getForgotPasswordError } = ForgotPasswordErrorSlice.actions
export const { resetForgotPasswordError } = ForgotPasswordErrorSlice.actions
export const { getStatus } = ForgotPasswordStatusSlice.actions
export default combineReducers({ forgotPasswordError: ForgotPasswordErrorSlice.reducer, forgotPasswordStatus: ForgotPasswordStatusSlice.reducer })
