import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import Button from "../../Shared/Primitive/Button"
import Checkbox from "../../Shared/Primitive/Checkbox"
import { LoadingAnimation, MessageActiveIcon } from "../../Shared/Components/SvgIcons"
import { userPrivacySettingsGetRequest, userPutPrivacySettingsData } from "./UserSettingsPrivacyRequest"
import "../../../Styles/main.scss"
import { RootState } from "../../../App/store"
import { InputType } from "../../../App/enums"
import useWindowSize from "../../Shared/Hooks/useWindowSize"

function UserSettingsPrivacy() {
    const translation = useSelector(selectTranslations)
    const [privacySettingsForm, setPrivacySettingsForm] = useState({
        show_contact_info: undefined,
        show_location: undefined,
        search_results: undefined,
        private_messages: undefined,
    })
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch()
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const screenWidth = useWindowSize()
    const breakpoint = 768

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.type === InputType.checkbox ? event.target.checked : event.target.value
        setPrivacySettingsForm({ ...privacySettingsForm, [event.target.name]: value })
    }

    useEffect(() => {
        userPrivacySettingsGetRequest(userDataState).then(response => {
            if (response.status === 200) {
                const data = response.data.privacy_settings[0]
                setPrivacySettingsForm({
                    private_messages: data?.private_messages!,
                    show_contact_info: data?.show_contact_info,
                    show_location: data?.show_location,
                    search_results: data?.search_results,
                })
                setIsLoading(false)
            }
        })
    }, [userDataState])

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        dispatch(userPutPrivacySettingsData(privacySettingsForm, userDataState))
    }

    return (
        <form onSubmit={onFormSubmit} className="settings-privacy">
            {isLoading ? (
                <div className="settings-right-panel__settings-privacy-loading-container">
                    <LoadingAnimation />
                </div>
            ) : (
                <>
                    <div className="settings-privacy__form">
                        <div className="settings-privacy__title-container">
                            <div className="settings-privacy__icon">
                                <MessageActiveIcon />
                            </div>
                            {translation.whoCanReachMe}
                        </div>
                        <div className="settings-privacy__checkbox-container">
                            {translation.contactInfoSettings}
                            <div className="settings-privacy__checkbox">
                                {" "}
                                <Checkbox color="dark-blue" checked={privacySettingsForm.show_contact_info} name="show_contact_info" onChange={onInputChange} />
                            </div>
                        </div>
                        <div className="settings-privacy__checkbox-container">
                            {translation.locationSettings}
                            <div className="settings-privacy__checkbox">
                                {" "}
                                <Checkbox color="dark-blue" checked={privacySettingsForm.show_location} name="show_location" onChange={onInputChange} />
                            </div>
                        </div>
                        <div className="settings-privacy__checkbox-container">
                            {translation.allowProfileInformationToBeSearched}
                            <div className="settings-privacy__checkbox">
                                {" "}
                                <Checkbox color="dark-blue" checked={privacySettingsForm.search_results} name="search_results" onChange={onInputChange} />
                            </div>
                        </div>
                    </div>
                    <div className="settings-privacy__button-container">
                        <div className="settings-privacy__button">
                            <Button
                                type="submit"
                                className={`button ${
                                    screenWidth > breakpoint && "button--border-radius-05"
                                } button--color-gold button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400 `}
                            >
                                {translation.save}
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </form>
    )
}

export default UserSettingsPrivacy
