import React from "react"
import { useSelector } from "react-redux"
import { not_found } from "../../../App/GlobaleVariables"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import Header from "../Header/Header"
import DonateButton from "../Components/DonateButton"
import Footer from "../Layout/Footer"
import MainLayout from "../Layout/MainLayout"
import OrganizationProfileCard from "../Components/OrganizationProfileCard"
import ModalGroup from "../Components/ModalGroup"
import RoomDropdown from "../Components/RoomDropdown"
import SponsorCard from "../Components/SponsorCard"
import NewestMembersCard from "../NewestMembers/NewestMemberCard"
import { RoleType } from "../../../App/enums"
import IndividualProfileCard from "../Components/IndividualProfileCard"
import BackgroundWrapper from "../Components/BackgroundWrapper"
import InvitationCard from "./InvitationCard"

function InvitationScreen() {
    const translation = useSelector(selectTranslations)
    const donationLink = useSelector((state: RootState) => state.UserSettingsStatusSlice.userDonationLinkSlice.link)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)

    return (
        <BackgroundWrapper>
            <Header />
            <MainLayout
                leftPanelFirstComponent={role === RoleType.USER ? <OrganizationProfileCard /> : <IndividualProfileCard />}
                leftPanelSecondComponent={<RoomDropdown type={role!} />}
                RightPanelFirstComponent={role === RoleType.USER ? donationLink === not_found ? <DonateButton /> : null : null}
                RightPanelSecondComponent={translation.newsMembersText}
                RightPanelThirdComponent={<NewestMembersCard />}
                RightPanelForthComponent={translation.sponsoredText}
                RightPanelFifthComponent={<SponsorCard />}
                overflowModifier={true}
            >
                <InvitationCard />
            </MainLayout>
            <Footer />
            <ModalGroup />
        </BackgroundWrapper>
    )
}

export default InvitationScreen
