import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { CloseIcon } from "../../Shared/Components/SvgIcons"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"

function DonationBankTransfer() {
    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)
    const onModalClose = () => {
        dispatch(setModalData({ open: false, donationBankTransfer: false }))
    }

    return (
        <div className="donation-bank-transfer">
            <div className="donation-bank-transfer__title-container">
                <p className="donation-bank-transfer__title">{translation.bankTransfer}</p>
                <div onClick={onModalClose} className="donation-bank-transfer__icon">
                    {" "}
                    <CloseIcon />
                </div>{" "}
            </div>
            <div className="donation-bank-transfer__first-text-container">
                <p className="donation-bank-transfer__text">{translation.bankTransferParagraph}</p>
                <p className="donation-bank-transfer__text">{translation.wirDankenDirSEHR}</p>
            </div>
        </div>
    )
}

export default DonationBankTransfer
