import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { RoomType } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import RoomModal from "../../Shared/Components/RoomModal"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { getPhotoSizeErrorMessage, resetCreateRoomError } from "../../Shared/SharedSlices/RoomSlice"
import { postRoomRequest } from "../../Room/RoomServiceRequests/RoomServiceRequests"
import { RoomPermissions } from "../../../Types/Room"
import { isDefined } from "../../../App/Helpers/Helpers"
import { hashtagRegex } from "../../../App/Regex"

function CreateRoomModal() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const translation = useSelector(selectTranslations)
    const [isPublic, setIsPublic] = useState(true)
    const [roomDescription, setRoomDescription] = useState("")
    const [roomJoinQuestion, setRoomJoinQuestion] = useState("")
    const [roomName, setRoomName] = useState("")
    const [permissions, setPermissions] = useState<RoomPermissions>({ allow_requests: true, invite_others: true, share_documents: true })
    const [photo, setPhoto] = useState<any>(null)
    const [previewUrl, setPreviewUrl] = useState<string>()
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const createRoomErrorState = useSelector((state: RootState) => state.roomSlice.createRoomErrorSlice)
    const photoziseErrorState = useSelector((state: RootState) => state.roomSlice.photoSizeErrorSlice.message)
    const { id } = useParams()
    const textWithoutHtml = roomDescription!.replace(hashtagRegex, "$1")

    const onRoomTypeSelected = () => {
        setIsPublic(!isPublic)
    }

    const onModalClose = () => {
        dispatch(resetCreateRoomError())
        dispatch(setModalData({ open: false, createRoom: false }))
        dispatch(getPhotoSizeErrorMessage(false))
    }

    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPermissions({ ...permissions, [event.target.name]: event.target.checked })
    }

    const onPhotoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files

        if (files) {
            const reader = new FileReader()
            reader.readAsDataURL(files[0])
            reader.addEventListener("load", () => {
                setPhoto(files[0])
                setPreviewUrl(URL.createObjectURL(files[0]))
            })
        }
    }

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        const formData = new FormData()
        let roomType = isPublic ? RoomType.Public : RoomType.Private

        if (isDefined(photo)) {
            formData.append("photo", photo)
        }
        formData.append("name", roomName)
        formData.append("organization", id!)
        formData.append("description", textWithoutHtml)
        formData.append("join_question",roomJoinQuestion)
        formData.append("room_permissions[0]allow_requests", JSON.stringify(permissions.allow_requests))
        formData.append("room_permissions[0]invite_others", JSON.stringify(permissions.invite_others))
        formData.append("room_permissions[0]share_documents", JSON.stringify(permissions.share_documents))
        formData.append("type", roomType)
        formData.append("user", userDataState.id?.toString()!)

        dispatch(postRoomRequest(parseInt(id!), formData, navigate))
    }

    return (
        <RoomModal
            title={translation.CreateRoom}
            hasDeleteOption={false}
            onModalClose={onModalClose}
            onRoomTypeSelected={onRoomTypeSelected}
            isPublicRoom={isPublic}
            isPrivateRoom={!isPublic}
            roomName={roomName}
            setRoomName={setRoomName}
            roomNameError={createRoomErrorState.message?.name!}
            description={roomDescription}
            setDescription={setRoomDescription}
            descriptionError={createRoomErrorState.message?.description!}
            detailError={createRoomErrorState.message?.detail!}
            roomManager={userDataState.first_name + " " + userDataState.last_name}
            roomManagerPhoto={userDataState.profile_photo?.url_path}
            photo={photo}
            onPhotoChange={onPhotoChange}
            previewUrl={previewUrl}
            allow_requests={permissions.allow_requests}
            invite_others={permissions.invite_others}
            share_documents={permissions.share_documents}
            onCheckboxChange={onCheckboxChange}
            onFormSubmit={onFormSubmit}
            buttonText={translation.create}
            photoSizeError={photoziseErrorState}
            roomJoinQuestion={roomJoinQuestion}
            setRoomJoinQuestion={setRoomJoinQuestion}
            roomJoinQuestionError={createRoomErrorState.message?.join_question!}
        />
    )
}

export default CreateRoomModal
