import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { CloseIcon } from "../../Shared/Components/SvgIcons"
import Button from "../../Shared/Primitive/Button"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { RootState } from "../../../App/store"
import { EditorModifier, RoleType } from "../../../App/enums"
import { ConversationLeaveRequest } from "../../Chat/ChatServiceRequest"
import { Organization } from "../../../Types/Organization"
import EditorTextArea from "../../Shared/Components/Editor/EditorTextArea"
import { useEffect, useRef, useState } from "react"
import { Editor as TinyMCEEditor } from "tinymce"
import TextInput from "../../Shared/Primitive/TextInput"
import { onJoinRoomRequest, onOrganizationJoinRoomRequest } from "../RoomServiceRequests/JoinRoomServiceRequest"

function JoinRoomModal(props: { orgId?: number,organizationName: string, question: string, roomId: number, roomType: string }) {
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const editorRef = useRef<TinyMCEEditor | null>(null)
    const [inputState, setInputState] = useState<string>("")
    const [content, setContent] = useState<string>("")
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(true)

    const onJoinRoom = () => {
        if(props.orgId == undefined) {
            dispatch(onJoinRoomRequest(props.roomId, props.roomType,content))
        }else{
            dispatch(onOrganizationJoinRoomRequest(props.orgId!, props.roomId,content))
        }
    }

    const onModalClose = () => {
        dispatch(setModalData({ open: false, joinPrivateRoomModal: {
            status: false, 
            question: "", 
            roomId: 0,
            organizationName: "",
            roomType: ""} }))
    }

    useEffect(() => {
        let btnStatus = content.length >= 3?false:true
        setSubmitBtnDisabled(btnStatus)
    },[content,submitBtnDisabled])

    return (
        <div className="join-room-modal">
            <div className="join-room-modal__content">
                <div className="join-room-modal__upper-panel">
                    <div className="join-room-modal__title">{translation.joinRoom}</div>
                    <div onClick={onModalClose} className="join-room-modal__close-icon">
                        <CloseIcon />
                    </div>
                </div>
                <div className="join-room-modal__divider"></div>
                <div className="join-room-modal__middle-panel">
                    <div className="join-room-modal__text">
                        <strong>{props.organizationName}</strong> hat eine Verifikationsfrage für Beitrittsanfragen festgelegt: <br />
                        <span dangerouslySetInnerHTML={{__html: props.question}}/></div>
                        <div className="join-room-modal__textarea">
                            <TextInput    
                                textinputsize={"medium"}
                                type="text"
                                value={content}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setContent(event.target.value)}
                                autoComplete="off"

                            />
                            </div>
                </div>
                <div className="join-room-modal__button-container  join-room-modal__button-container--join-room">
                    <div className="join-room-modal__button">
                        <Button
                            disabled={submitBtnDisabled}
                            onClick={onJoinRoom}
                            type="submit"
                            className="button button--font-size-small button--font-weight-400 button--text-color-white  button--color-gold  button--text-color-white button--color-red button--hover-brighter button--cursor-pointer button--border-radius-05 "
                        >
                            {translation.continue}
                        </Button>
                    </div>
                    <div className="join-room-modal__button">
                        <Button
                            onClick={onModalClose}
                            type="submit"
                            className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-red button--hover-brighter button--cursor-pointer button--border-radius-05 "
                        >
                            {translation.cancel}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JoinRoomModal
