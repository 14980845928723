import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GlobalSearchResult } from "../../../Types/GlobalSearchResultType"

type SearchData = {
    result: Array<GlobalSearchResult>
}
const initialState: SearchData = { result: []}

const GlobalSearchSlice = createSlice({
    name: "GlobalSearchSlice",
    initialState: initialState,
    reducers: {
        setGlobalSearchData(state, action: PayloadAction<SearchData>) {
            state.result = action.payload.result
        },
    },
})






export const { setGlobalSearchData } = GlobalSearchSlice.actions



export default combineReducers({
    globalSearchSlice: GlobalSearchSlice.reducer,
})
