import React from "react"
import "../../../Styles/main.scss"

type InputProps = {
    firstTextInput: React.ReactElement
    secondTextInput: React.ReactElement
    firstErrorText?: string
    secondErrorText?: string
    firstTitle: string
    secondTitle: string
}

function DoubleTextInput({ firstTextInput, secondTextInput, firstErrorText, secondErrorText, firstTitle, secondTitle }: InputProps) {
    return (
        <div className="double-text-input">
            <div className="double-text-input--margin-right-05">
                <div className="double-text-input__text">{firstTitle}</div>
                {firstTextInput}
                <div className="double-text-input__error-text">{firstErrorText}</div>
            </div>
            <div>
                <div className="double-text-input__text">{secondTitle}</div>
                {secondTextInput}
                <div className="double-text-input__error-text">{secondErrorText}</div>
            </div>
        </div>
    )
}

export default DoubleTextInput
