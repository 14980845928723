import { headers } from "../../../App/Axios"
import { JoinStatus } from "../../../App/enums"
import HTTPService from "../../../App/HTTPService"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { setIsMember } from "../RoomSlices/MemberInRoomStatusSlice"
import { setRoomMembers } from "../RoomSlices/MembersInRoomSlice"

const postRequest = (roomId: number, roomType: string, answer?: string) => {
    return roomType === "private" ? HTTPService.post(`/join-room/${roomId}/ `, {verification_answer: answer}, headers) : HTTPService.post(`/join-public-room/${roomId}/ `, headers)
}
const getRequest = (roomId: number) => {
    return HTTPService.get(`/leave-room/${roomId}/ `, headers)
}
export const getMembersRequest = (roomId: number) => {
    return HTTPService.get(`/room/${roomId}/members/`, headers)
}

export const onJoinRoomRequest = (roomId: number, roomType: string, answer?: string) => async (dispatch: Function) => {

    try {
        const response = await postRequest(roomId, roomType,answer)
        
        if(response.data.status == "room_full"){
            dispatch(setModalData({ open: true, errorJoiningFullRoom: true }))
            return
        }

        if (response.status === 201) {
            try {
                const response = await getMembersRequest(roomId)
                if (response.status === 200) {
                    dispatch(setRoomMembers(response.data.user))
                    window.location.reload()
                }
                dispatch(setIsMember(JoinStatus.PENDING))
            } catch (error: any) {}
        }
        if (response.status === 200) {
            try {
                const response = await getMembersRequest(roomId)
                if (response.status === 200) {
                    dispatch(setRoomMembers(response.data.user))
                    dispatch(setModalData({ open: true, successfullyJoinedRoom: true }))
                }
                dispatch(setIsMember(JoinStatus.MEMBER))
            } catch (error: any) {}
        }
    } catch (error: any) {}
}

export const onLeaveRoomRequest = (roomId: number) => async (dispatch: Function) => {
    try {
        const response = await getRequest(roomId)
        if (response.status === 200) {
            try {
                const response = await getMembersRequest(roomId)
                if (response.status === 200) {
                    dispatch(setRoomMembers(response.data.user))
                    dispatch(setModalData({ open: true, leaveRoomSuccessfully: true }))
                }
            } catch (error: any) {}
            dispatch(setIsMember(JoinStatus.NON_MEMBER))
        }
    } catch (error: any) {}
}

const organizationJoinRoomPostRequest = (orgId:number, roomId:number,answer?: string)=>{
    return HTTPService.post(`/org/${orgId}/join-room/${roomId}/ `,{verification_answer: answer}, headers)
}


export const onOrganizationJoinRoomRequest = (orgId:number, roomId:number, answer?: string) => async (dispatch: Function) => {
    try {
        const response = await organizationJoinRoomPostRequest(orgId,roomId,answer)
        if (response.status === 201) {
            try {
                const response = await getMembersRequest(roomId)
                if (response.status === 200) {
                    dispatch(setRoomMembers(response.data.user))
                    window.location.reload()
                }
                dispatch(setIsMember(JoinStatus.PENDING))
            } catch (error: any) {}
        }
        if (response.status === 200) {
            try {
                const response = await getMembersRequest(roomId)
                if (response.status === 200) {
                    dispatch(setRoomMembers(response.data.user))
                    dispatch(setModalData({ open: true, successfullyJoinedRoom: true }))
                }
                dispatch(setIsMember(JoinStatus.MEMBER))
            } catch (error: any) {}
        }
    } catch (error: any) {}
}


const organizationLeaveRoomGetRequest = (orgId:number, roomId:number)=>{
    return HTTPService.get(`/org/${orgId}/leave-room/${roomId}/ `, headers)
}


export const onOrganizationLeaveRoomRequest = (orgId:number,roomId: number) => async (dispatch: Function) => {
    try {
        const response = await organizationLeaveRoomGetRequest(orgId,roomId)
        if (response.status === 200) {
            try {
                const response = await getMembersRequest(roomId)
                if (response.status === 200) {
                    dispatch(setRoomMembers(response.data.user))
                    dispatch(setModalData({ open: true, leaveRoomSuccessfully: true }))
                }
            } catch (error: any) {}
            dispatch(setIsMember(JoinStatus.NON_MEMBER))
        }
    } catch (error: any) {}
}