import React from "react"
import Button from "../Primitive/Button"
import "../../../Styles/main.scss"
import { HeartIcon } from "./SvgIcons"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { useNavigate } from "react-router-dom"

function DonateButton() {
    const translation = useSelector(selectTranslations)
    const navigate = useNavigate()

    const onNavigationToDonation = () => {
        navigate("/donation")
        window.scrollTo(0, 0)
    }
    return (
        <div className="donate-section" onClick={onNavigationToDonation}>
            <div className="donate-section__text-container ">
                <div className="donate-section__text donate-section__text--font-weight-700">{translation.doYouLikeYour}</div>
                <div className="donate-section__text">{translation.considerDonatingToOurCause}</div>
            </div>
            <div className="donate-section__button-size">
                <Button className=" button button--border-radius-09 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-large button--font-weight-700 ">
                    <div className="donate-section__button-content">
                        <HeartIcon /> <div>{translation.donate}</div>
                    </div>
                </Button>
            </div>
        </div>
    )
}

export default DonateButton
