import { useSelector } from "react-redux"
import { RoleType } from "../../../App/enums"
import { not_found } from "../../../App/GlobaleVariables"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import BackgroundWrapper from "../Components/BackgroundWrapper"
import DonateButton from "../Components/DonateButton"
import IndividualProfileCard from "../Components/IndividualProfileCard"
import ModalGroup from "../Components/ModalGroup"
import OrganizationProfileCard from "../Components/OrganizationProfileCard"
import RoomDropdown from "../Components/RoomDropdown"
import SponsorCard from "../Components/SponsorCard"
import Header from "../Header/Header"
import Footer from "../Layout/Footer"
import MainLayout from "../Layout/MainLayout"
import NewestOrganizationsCard from "../NewestOrganizations/NewestOrganizations"
import AllRoomsCard from "./AllRoomsCard"
import { useNavigate, useParams } from "react-router-dom"
import { SearchIcon } from "../Components/SvgIcons"

function AllRoomsScreen() {
    const translation = useSelector(selectTranslations)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const donationLink = useSelector((state: RootState) => state.UserSettingsStatusSlice.userDonationLinkSlice.link)
    const { id } = useParams()
    const navigate = useNavigate()

    const onSeeAllEventsClick = () => {
        switch (role) {
            case RoleType.ORGANIZATION:
                navigate(`/all-premium-events/${id}`)
                break
            case RoleType.USER:
                navigate(`/all-premium-events`)
                break
            default:
                break
        }
    }

    return (
        <BackgroundWrapper>
            <Header />
            <MainLayout
                leftPanelFirstComponent={role === RoleType.USER ? <OrganizationProfileCard /> : <IndividualProfileCard />}
                leftPanelSecondComponent={<RoomDropdown type={role!} />}
                leftPanelForthComponent={role === RoleType.USER && translation.newestOrganizations}
                leftPanelFifthComponent={role === RoleType.USER ? <NewestOrganizationsCard /> : null}
                RightPanelFirstComponent={role === RoleType.USER ? donationLink === not_found ? <DonateButton /> : null : null}
                RightPanelForthComponent={
                    <div className="main-layout__sponsored-title">
                        {translation.sponsoredText}
                        <div onClick={onSeeAllEventsClick} className="main-layout__sponsored-icon">
                            <SearchIcon color="dark-blue" />
                        </div>
                    </div>
                }
                RightPanelFifthComponent={<SponsorCard />}
                overflowModifier={true}
            >
                <AllRoomsCard />
            </MainLayout>
            <Footer />
            <ModalGroup />
        </BackgroundWrapper>
    )
}

export default AllRoomsScreen
