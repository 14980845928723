import { headers } from "../../../App/Axios"
import { RoleType } from "../../../App/enums"
import HTTPService from "../../../App/HTTPService"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { setRoomMembers } from "../RoomSlices/MembersInRoomSlice"
import { getMembersRequest } from "./JoinRoomServiceRequest"

const postRequest = (roomId: string, data: { }) => {
    return HTTPService.post(`/room/${roomId}/remove-member/`, data, headers)
}

export const onMemberRemove = (roomId: string, userId: number, memberType:string) => async (dispatch: Function) => {
    const data = memberType === RoleType.USER.toLowerCase() ? {user_id: userId}: {organization_id:userId}
   
    try {
        const response = await postRequest(roomId, data)
        if (response.status === 200) {
            try {
                const response = await getMembersRequest(parseInt(roomId!))
                if (response.status === 200) {
                    dispatch(setRoomMembers(response.data.user))
                    dispatch(setModalData({ open: false, removeRoomMemberModal: false }))
                    dispatch(setModalData({ open: true, successfulRoomMemberRemoval: true }))
                }
            } catch (error: any) {}
        }
    } catch (error: any) {}
}
