import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import StorageService from "../../../App/StorageService"
import { RootState } from "../../../App/store"
import Header from "../../Shared/Header/Header"
import DonateButton from "../../Shared/Components/DonateButton"
import Footer from "../../Shared/Layout/Footer"
import MainLayout from "../../Shared/Layout/MainLayout"
import ModalGroup from "../../Shared/Components/ModalGroup"
import NewestMembersCard from "../../Shared/NewestMembers/NewestMemberCard"
import OrganizationProfileCard from "../../Shared/Components/OrganizationProfileCard"
import RoomDropdown from "../../Shared/Components/RoomDropdown"
import SponsorCard from "../../Shared/Components/SponsorCard"
import UserProfileContent from "./UserProfileContent"
import { RoleType, StorageKey } from "../../../App/enums"
import NewestOrganizationsCard from "../../Shared/NewestOrganizations/NewestOrganizations"
import LoadingScreen from "../../Shared/Layout/LoadingScreen"
import BackgroundWrapper from "../../Shared/Components/BackgroundWrapper"
import { not_found } from "../../../App/GlobaleVariables"
import { useNavigate, useParams } from "react-router-dom"
import { SearchIcon } from "../../Shared/Components/SvgIcons"

function UserProfileScreen() {
    const translation = useSelector(selectTranslations)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const [loading, setLoading] = useState(true)
    const donationLink = useSelector((state: RootState) => state.UserSettingsStatusSlice.userDonationLinkSlice.link)
    const { id } = useParams()
    const navigate = useNavigate()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)

    const onSeeAllEventsClick = () => {
        switch (role) {
            case RoleType.ORGANIZATION:
                navigate(`/all-premium-events/${id}`)
                break
            case RoleType.USER:
                navigate(`/all-premium-events`)
                break
            default:
                break
        }
    }

    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])

    useEffect(() => {
        if (userDataState.id) {
            let stringUser = StorageService.stringify(userDataState)
            StorageService.setItem(StorageKey.user, stringUser)
        }
    }, [userDataState])

    return (
        <>
            {loading ? (
                <LoadingScreen />
            ) : (
                <BackgroundWrapper>
                    <Header />
                    <MainLayout
                        leftPanelFirstComponent={<OrganizationProfileCard />}
                        leftPanelSecondComponent={<RoomDropdown type={RoleType.USER} />}
                        leftPanelForthComponent={translation.newestOrganizations}
                        leftPanelFifthComponent={<NewestOrganizationsCard />}
                        RightPanelFirstComponent={donationLink === not_found ? <DonateButton /> : null}
                        RightPanelSecondComponent={translation.newsMembersText}
                        RightPanelThirdComponent={<NewestMembersCard />}
                        RightPanelForthComponent={
                            <div className="main-layout__sponsored-title">
                                {translation.sponsoredText}
                                <div onClick={onSeeAllEventsClick} className="main-layout__sponsored-icon">
                                    <SearchIcon color="dark-blue" />
                                </div>
                            </div>
                        }
                        RightPanelFifthComponent={<SponsorCard />}
                        overflowModifier={true}
                    >
                        <UserProfileContent />
                    </MainLayout>
                    <Footer />
                    <ModalGroup />
                </BackgroundWrapper>
            )}
        </>
    )
}

export default UserProfileScreen
