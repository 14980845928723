import React, { useState } from "react"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"

function HashtagInput(props: { hashtags: string[]; setHashtags: Function }) {
    const translation = useSelector(selectTranslations)
    let inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    const [inputValue, setInputValue] = useState("")
    const maxHashtagNumber = 5

    const onKeyDown = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value)

        if (event.target.value.endsWith(" ")) {
            if (!inputValue.trim()) {
                return
            } else {
                if (inputValue.trim().charAt(0) === "#") {
                    props.setHashtags([...props.hashtags, inputValue])
                } else {
                    const finalValue = "#" + inputValue.trim()
                    props.setHashtags([...props.hashtags, finalValue])
                }
            }
            setInputValue("")
        }
        return
    }

    const removeHashtag = (hashtagIndex: number) => {
        props.setHashtags(props.hashtags.filter((hashtag, index) => index !== hashtagIndex))
    }

    const onInputContainerClick = () => {
        inputRef.current.focus()
    }

    return (
        <div className="hashtag-input-container" onClick={onInputContainerClick}>
            {props.hashtags &&
                props.hashtags!.map((hashtag, index) => (
                    <div className="hashtag-input-container__item" key={index}>
                        <span className="hashtag-input-container__item-text"> {hashtag.charAt(0) !== "#" ? "#" + hashtag : hashtag}</span>
                        <span className="hashtag-input-container__item-remove-button" onClick={() => removeHashtag(index)}>
                            {" "}
                            x
                        </span>
                    </div>
                ))}
            <input
                className="hashtag-input-container__input"
                //onKeyDown={onKeyDown}
                type="text"
                ref={inputRef}
                value={inputValue}
                onChange={onKeyDown}
                disabled={props.hashtags.length >= maxHashtagNumber ? true : false}
                placeholder={props.hashtags.length >= maxHashtagNumber ? translation.reachedMaxHashtags : translation.addHashtag}
                maxLength={30}
            />
        </div>
    )
}

export default HashtagInput
