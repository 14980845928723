import React, { MouseEventHandler, ReactNode } from "react"
import Image from "../Primitive/Image"

function UserCard(props: {
    userCardStyle?: string
    leftPanelStyle?: string
    middlePanelStyle?: string
    rightPanelStyle?: string
    textStyleContainer?: string
    textStyle?: string
    textStyleMore25?: string
    subtextStyle?: string
    subtextStyleContainer?: string
    rightTextStyle?: string
    profileImageStyle?: string
    leftPanelItem?: string | JSX.Element | ReactNode[]
    leftPanelItemStyle?: string
    imageSrc?: string
    text?: string | ReactNode[] | JSX.Element
    subtext?: string | ReactNode[] | JSX.Element
    rightText?: string | ReactNode[] | JSX.Element | null
    onClick?: () => void | MouseEventHandler<HTMLDivElement>
    onLeftItemClick?: () => void
    onMiddleItemClick?: () => void
    onRightItemClick?: () => void
    onTextClick?: () => void
}) {
    return (
        <div onClick={props.onClick} className={props.userCardStyle}>
            <div className={`${props.userCardStyle}__${props.leftPanelStyle}`} onClick={props.onLeftItemClick}>
                {props.leftPanelItem}
                <Image src={props.imageSrc} className={`${props.userCardStyle}__${props.profileImageStyle}`} />
            </div>
            <div className={`${props.userCardStyle}__${props.middlePanelStyle}`} onClick={props.onMiddleItemClick}>
                <div className={`${props.userCardStyle}__${props.textStyleContainer}`}>
                    <div
                        onClick={props.onTextClick}
                        className={`${props.userCardStyle}__${  
                            props.text && typeof props.text === "string" && props.text.length < 25 ? props.textStyle : props.textStyleMore25
                        }`}
                    >
                        {props.text}
                    </div>
                </div>
                <div className={`${props.userCardStyle}__${props.subtextStyleContainer}`}>
                    <div className={`${props.userCardStyle}__${props.subtextStyle}`}> {props.subtext} </div>
                </div>
            </div>
            <div className={`${props.userCardStyle}__${props.rightPanelStyle}`}>
                <div onClick={props.onRightItemClick} className={`${props.userCardStyle}__${props.rightTextStyle}`}>
                    {props.rightText}
                </div>
            </div>
        </div>
    )
}
export default UserCard
