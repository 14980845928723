import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import HTTPService from "../../../../App/HTTPService"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import { RootState } from "../../../../App/store"
import { CloseIcon } from "../../../Shared/Components/SvgIcons"
import Button from "../../../Shared/Primitive/Button"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"

function RemoveAdminModal() {
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const admin = useSelector((state: RootState) => state.modalSlice.removeAdminModal?.admin)
    const { id } = useParams()

    const removeAdmin = () => {
        HTTPService.delete(`/organization/${id}/role/${admin?.role_id}/`).then(response => {
            if (response.status === 204) {
                dispatch(
                    setModalData({
                        open: false,
                        removeAdminModal: {
                            status: false,
                            admin: {
                                first_name: "",
                                id: 0,
                                last_name: "",
                                profile_photo: {
                                    id: 0,
                                    url_path: "",
                                },
                                role_id: 0,
                            },
                            removedAdmin: true,
                        },
                    })
                )
            }
        })
    }

    const onCloseIconClick = () => {
        dispatch(
            setModalData({
                open: false,
                removeAdminModal: {
                    status: false,
                    admin: {
                        first_name: "",
                        id: 0,
                        last_name: "",
                        profile_photo: {
                            id: 0,
                            url_path: "",
                        },
                        role_id: 0,
                    },
                    removedAdmin: false,
                },
            })
        )
    }
    const handleOnClick = () => {
        removeAdmin()
    }

    return (
        <div className="remove-employee-modal">
            <div className="remove-employee-modal__title">
                {translation.removeAdmin}
                <div className="remove-employee-modal__close-icon" onClick={onCloseIconClick}>
                    <CloseIcon />
                </div>
            </div>
            <p className="remove-employee-modal__content">
                {" "}
                {translation.removeEmployeeQuestion1 + " " + admin?.first_name + " " + admin?.last_name + " " + translation.removeAdminQuestion}
            </p>
            <div className="remove-employee-modal__button ">
                <Button
                    className=" button button--border-radius-09 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400"
                    onClick={handleOnClick}
                >
                    {translation.remove}
                </Button>
            </div>
        </div>
    )
}

export default RemoveAdminModal
