import { RoleType } from "../../../App/enums"
import { PostComments, RoomPosts } from "../../../Types/RoomPosts"
import { EventComments } from "../../../Types/SponsorResult"

export const hasPostDropdown = (post: RoomPosts|EventComments | PostComments,role:keyof typeof RoleType,orgId:number,userId:number,hasPrivilegedPermission:boolean,activeRoom?:boolean) => {
    if(activeRoom == false){
        return false
    }

    if (post.content_object === null) {
        return false
    } else if(hasPrivilegedPermission){
        return true
    }else if (role === RoleType.ORGANIZATION) {
        return post.content_type === role.toLowerCase() && post.content_object.id === orgId
    } else {
        return post.content_type === role.toLowerCase() && post.content_object.id === userId
    }
}