import { useCallback, useEffect, useState } from "react"
import { RoleType, SortOptions } from "../../../App/enums"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { FilterIcon } from "./SvgIcons"
import useWindowSize from "../Hooks/useWindowSize"
import React from "react"
import { RootState } from "../../../App/store"
import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { setRoomSortOption, setUserRoomSortOption } from "../SharedSlices/RoomSlice"

function RoomFilterDropdown(props: { type: RoleType.USER | RoleType.ORGANIZATION | string }) {
    const { id } = useParams()
    const dispatch = useDispatch()
    const windowSize = useWindowSize()
    const breakpoint = 1280
    const translation = useSelector(selectTranslations)
    const [expanded, setExpanded] = useState(false)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    let roomFilterDropdownRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    const orgSortOption = useSelector((state: RootState) => state.roomSlice.orgRoomSortOptionSlice.sort_options)
    const userSortOption = useSelector((state: RootState) => state.roomSlice.userRoomSortOptionSlice.sort_options)
    const sortOption = props.type === RoleType.ORGANIZATION ? orgSortOption : userSortOption

    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (expanded && roomFilterDropdownRef.current && !roomFilterDropdownRef.current.contains(event.target as HTMLElement)) {
                setExpanded(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    const userSortRoomPutRequest = useCallback(
        (sort_option: keyof typeof SortOptions) => {
            return HTTPService.put(`/user/${userDataState.id!}/sort-user-dashboard/`, { sort_options: sort_option }, headers)
        },
        [userDataState.id]
    )

    const orgSortRoomPutRequest = useCallback(
        (sort_option: keyof typeof SortOptions) => {
            return HTTPService.put(`/org/${id}/sort-org-dashboard/`, { sort_options: sort_option }, headers)
        },
        [id]
    )

    const onSortOptionElementClick = (sort_option: keyof typeof SortOptions) => {
        switch (props.type) {
            case RoleType.USER:
                userSortRoomPutRequest(sort_option)
                    .then(response => {
                        if (response.status === 200) {
                            dispatch(setUserRoomSortOption(response.data.sort_options))
                            setExpanded(false)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
                break
            case RoleType.ORGANIZATION:
                orgSortRoomPutRequest(sort_option)
                    .then(response => {
                        if (response.status === 200) {
                            dispatch(setRoomSortOption(response.data.sort_options))
                            setExpanded(false)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })

                break

            default:
                break
        }
    }
    return (
        <div className="room-filter-dropdown" ref={roomFilterDropdownRef}>
            <div className="room-filter-dropdown__header" onClick={() => setExpanded(!expanded)}>
                <div className="room-filter-dropdown__icon">
                    <FilterIcon />
                </div>
                {windowSize > breakpoint && <p className="room-filter-dropdown__header-text">{translation.filterRooms}</p>}
            </div>

            {expanded && (
                <div className="room-filter-dropdown__element-container">
                    <p
                        onClick={() => onSortOptionElementClick(SortOptions.neue_aktivität)}
                        className={`room-filter-dropdown__element ${sortOption === SortOptions.neue_aktivität && "room-filter-dropdown__element--blue"}`}
                    >
                        {translation.newActivity}
                    </p>
                    <p
                        onClick={() => onSortOptionElementClick(SortOptions.alphabetisch)}
                        className={`room-filter-dropdown__element ${sortOption === SortOptions.alphabetisch && "room-filter-dropdown__element--blue"}`}
                    >
                        {translation.alphabetical}
                    </p>
                    <p
                        onClick={() => onSortOptionElementClick(SortOptions.zuerst_beigetreten)}
                        className={`room-filter-dropdown__element ${sortOption === SortOptions.zuerst_beigetreten && "room-filter-dropdown__element--blue"}`}
                    >
                        {translation.firstJoined}
                    </p>
                    <p
                        onClick={() => onSortOptionElementClick(SortOptions.zuletzt_beigetreten)}
                        className={`room-filter-dropdown__element ${sortOption === SortOptions.zuletzt_beigetreten && "room-filter-dropdown__element--blue"}`}
                    >
                        {translation.lastJoined}
                    </p>
                </div>
            )}
        </div>
    )
}

export default RoomFilterDropdown
