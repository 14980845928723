import { useState, useEffect, useCallback } from "react"
import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import Button from "../Primitive/Button"
import { Entity, NotificationResponse, RoleType } from "../../../App/enums"
import { getMembersRequest, userNotificationGetRequest } from "../../Notifications/UserNotificationServiceRequest"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { Notification } from "../../../Types/Notification"
import { setRoomMembers } from "../../Room/RoomSlices/MembersInRoomSlice"
import { setUserNotifications } from "../../Notifications/NotificationSlice"
import { RootState } from "../../../App/store"
import { useNavigate, useParams } from "react-router-dom"
import { organizationProfileDefault, profileDefault } from "../../../App/GlobaleVariables"
import { capitalizeFirstLetter } from "../../../App/Helpers/Helpers"
import { InviteUserIcon, LoadingAnimation } from "../Components/SvgIcons"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import Image from "../../Shared/Primitive/Image"

function InvitationCard() {
    const [invitation, setInvitation] = useState<Notification[]>()
    const translation = useSelector(selectTranslations)
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const navigate = useNavigate()
    const { id } = useParams()
    const getUserInvitationRequest = () => {
        return HTTPService.get("/users/invitations/", headers)
    }

    const getOrganizationInvitationRequest = useCallback(() => {
        return HTTPService.get(`/org/invitations/${id}`, headers)
    }, [id])

    useEffect(() => {
        if (id) {
            getOrganizationInvitationRequest().then(response => {
                if (response.status === 200) {
                    setInvitation(response.data)
                    setIsLoading(false)
                }
            })
        } else {
            getUserInvitationRequest().then(response => {
                if (response.status === 200) {
                    setInvitation(response.data)
                    setIsLoading(false)
                }
            })
        }
    }, [getOrganizationInvitationRequest, id])

    const onAccept = (invitation: Notification) => {
        setIsLoading(true)
        HTTPService.patch(`${invitation?.action_url}`, { status: NotificationResponse.accepted }).then(response => {
            if (response.status === 200) {
                
                if (response.data.is_room_invite) {
                    getMembersRequest(invitation?.room!).then(response => {
                        if (response.status === 200) {
                            dispatch(setRoomMembers(response.data.user))
                            userNotificationGetRequest().then(response => {
                                if (response.status === 200) {
                                    dispatch(setUserNotifications(response.data))
                                    navigate(`/room/${invitation?.room}`)
                                }
                            })
                        }
                    })
                } else if (invitation?.action_url === `/org/accept-deny-invite/${invitation?.invitation_id}`) {
                    navigate(`/room/${id}/${invitation?.room}`)
                } else if (
                    invitation?.action_url === `/org/change-join-request-status/${invitation?.invitation_id}`
                ) {
                    navigate(role === RoleType.USER ? `/room/${invitation?.room}` : `/room/${id}/${invitation?.room}`)
                }else if (
                    invitation?.action_url === `/reply-to-room-join-request/${invitation?.invitation_id}/`
                ) {
                    getUserInvitationRequest().then(response => {
                        if (response.status === 200) {
                            setInvitation(response.data)
                            setIsLoading(false)
                        }
                    })
                }
                else {
                    navigate(`/${invitation?.redirect_url}`)
                }
            }
        }).catch(err => {
            if (err.response.status === 400 && err.response.data[0] == "You cannot add more than 10 members to a free room!") {
                dispatch(setModalData({ open: true, errorJoiningFullRoom: true }))
                setIsLoading(false)
                return
            }
        })
    }

    const onDecline = (invitation: Notification) => {
        setIsLoading(true)
        HTTPService.patch(`${invitation?.action_url}`, { status: NotificationResponse.declined }).then(response => {
            if (response.status === 200) {
                if (id) {
                    getOrganizationInvitationRequest().then(response => {
                        if (response.status === 200) {
                            setInvitation(response.data)
                            setIsLoading(false)
                        }
                    })
                } else {
                    getUserInvitationRequest().then(response => {
                        if (response.status === 200) {
                            setInvitation(response.data)
                            setIsLoading(false)
                        }
                    })
                }
            }
        })
    }

    const onNotificationClick = (path: string) => {
        navigate(`/${path}`)
    }

    return (
        <div className="notifications-screen">
            <div className="notifications-screen__title-invitation-container">
                <InviteUserIcon color="gold" /> <p className="notifications-screen__title notifications-screen__title--margin-1">{translation.Invitations}</p>
            </div>
            {isLoading ? (
                <div className="search-component__results-container">
                    <LoadingAnimation type="cover" />
                </div>
            ) : (
                <div className="notifications-screen__card">
                    <div className="notifications-screen__content">
                        {invitation?.length === 0 ? (
                            <div className="search-dropdown__no-data-text">{translation.NoInvitations}</div>
                        ) : (
                            invitation?.map((result, index) => {
                                return (
                                    <div
                                        key={result.id}
                                        className={`notification-dropdown-item notification-dropdown-item--${invitation.length - 1 === index && "border-none"}`}
                                    >
                                        <div className="notification-dropdown-item__content">
                                            <div className="notification-dropdown-item__left-panel" onClick={() => onNotificationClick(result.redirect_url)}>
                                                <Image
                                                    alt="user"
                                                    src={
                                                        result.content_type === Entity.USER
                                                            ? result.content_object?.photo?.url_path || profileDefault
                                                            : result.content_object?.profile_photo?.url_path || organizationProfileDefault
                                                    }
                                                    className={`notification-dropdown-item__image`}
                                                />
                                            </div>
                                            <div className={`notification-dropdown-item__middle-panel`}>
                                                <div
                                                    className={`notification-dropdown-item__user-data`}
                                                    onClick={() => onNotificationClick(result.redirect_url)}
                                                >
                                                    {result.content_type === Entity.USER
                                                        ? result.content_object?.first_name + " " + result.content_object?.last_name
                                                        : result.content_object?.name}
                                                </div>
                                                <div
                                                    className={`notification-dropdown-item__notification notification-dropdown-item__notification--margin-bottom`}
                                                    onClick={() => onNotificationClick(result.redirect_url)}
                                                >
                                                    {capitalizeFirstLetter(result.content.split('Zusätzliche Info :')[0])}
                                                    <p><strong>{result.content.split('Zusätzliche Info :')[1]}</strong></p>
                                                </div>
                                                <div className="notification-dropdown-item__button-container">
                                                    <div className="notification-dropdown-item__button">
                                                        <Button
                                                            onClick={() => onAccept(result)}
                                                            className="button button--border-radius-05 button--color-gold button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400"
                                                        >
                                                            {translation.accept}
                                                        </Button>
                                                    </div>
                                                    <div className="notification-dropdown-item__button">
                                                        <Button
                                                            onClick={() => onDecline(result)}
                                                            className="button button--border-radius-05 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400"
                                                        >
                                                            {translation.decline}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default InvitationCard
