import { headers } from "../../../../App/Axios"
import HTTPService from "../../../../App/HTTPService"
import { RoomInviteObject } from "../../../../Types/RoomInviteType"
import { setInviteInRoomSearchStatus, setIsInvited } from "../../RoomSlices/MemberInRoomStatusSlice"

const postRequest = (data: RoomInviteObject) => {
    return HTTPService.post(`/invite-to-room/`, data, headers)
}

const orgPostRequest = (data: RoomInviteObject) => {
    return HTTPService.post(`org/invite-to-room/`, data, headers)
}

export const onUserInvitePostRequest = (data: RoomInviteObject) => async (dispatch: any) => {

    try {
        const response = data.invited_user ? await postRequest(data): await orgPostRequest(data)
        if (response.status === 201) {
            dispatch(setIsInvited(true))
            dispatch(setInviteInRoomSearchStatus(true))
        }
    } catch (error: any) {
        console.log(error)
    }
}
