import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { webPatchNotifications, emailPatchNotifications } from "../../../Types/Notification"

export const userWebNotificationsSettingsGetRequest = () => {
    return HTTPService.get(`/user/web-notifications/`, headers)
}

export const userEmailNotificationsSettingsGetRequest = () => {
    return HTTPService.get(`/user/email-notifications/`, headers)
}

export const userWebNotificationsSettingsPatchRequest = (webNotifications: webPatchNotifications) => {
    HTTPService.patch(
        `/user/web-notifications/`,
        {
            comment: webNotifications.comment,
            commented_on_same_post: webNotifications.commented_on_same_post,
            tags: webNotifications.tags,
            room_invitation: webNotifications.room_invitation,
            room_invitation_reply: webNotifications.room_invitation_reply,
            room_join_request: webNotifications.room_join_request,
            room_join_request_reply: webNotifications.room_join_request_reply,
            add_admin: webNotifications.add_admin,
            remove_admin: webNotifications.remove_admin,
            event_comment: webNotifications.event_comment,
            new_post_in_room: webNotifications.new_post_in_room,
            admin_reply: webNotifications.admin_reply,
        },
        headers
    )
        .then(response => {
            if (response.status === 200) {
                userWebNotificationsSettingsGetRequest()
            }
        })
        .catch(error => {
            console.log(error)
        })
}

export const userEmailNotificationsSettingsPatchRequest = (emailPatchNotifications: emailPatchNotifications) => {
    HTTPService.patch(
        `/user/email-notifications/`,
        {
            comment: emailPatchNotifications.comment,
            commented_on_same_post: emailPatchNotifications.commented_on_same_post,
            tags: emailPatchNotifications.tags,
            room_invitation: emailPatchNotifications.room_invitation,
            room_invitation_reply: emailPatchNotifications.room_invitation_reply,
            room_join_request: emailPatchNotifications.room_join_request,
            room_join_request_reply: emailPatchNotifications.room_join_request_reply,
            add_admin: emailPatchNotifications.add_admin,
            remove_admin: emailPatchNotifications.remove_admin,
            event_comment: emailPatchNotifications.event_comment,
            new_post_in_room: emailPatchNotifications.new_post_in_room,
            admin_reply: emailPatchNotifications.admin_reply,
            unseen_chat_messages: emailPatchNotifications.unseen_chat_messages
        },
        headers
    )
        .then(response => {
            if (response.status === 200) {
                userEmailNotificationsSettingsGetRequest()
            }
        })
        .catch(error => {
            console.log(error)
        })
}
