import React from "react"
import "../../../Styles/main.scss"
interface buttonProp extends React.AllHTMLAttributes<HTMLButtonElement> {}
function Button(props: buttonProp) {
    const buttonProps: React.HTMLAttributes<HTMLButtonElement> = { ...props }
    return (
        <button {...buttonProps}>
            <div className="button__content">{props.children}</div>
        </button>
    )
}
export default Button
