import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { LabelType, RoleType } from "../../../App/enums"
import { organizationProfileDefault, profileDefault } from "../../../App/GlobaleVariables"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import "../../../Styles/main.scss"
import UserCard from "../../Shared/Components/UserCard"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"

function RoomUserCard(props: { type: LabelType.ROOM_CREATOR | LabelType.ROOM_MANAGER }) {
    const dispatch = useDispatch()
    const { id } = useParams()
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const translation = useSelector(selectTranslations)
    const navigate = useNavigate()

    const onRoomManagerClick = (roomManagerId: number) => {
        dispatch(setModalData({ open: false, roomMembersModal: false }))
        switch (role) {
            case RoleType.USER:
                if (roomManagerId === userDataState.id) {
                    navigate("/user-profile")
                    window.scrollTo(0, 0)
                } else {
                    navigate(`/user/${roomManagerId}`)
                    window.scrollTo(0, 0)
                }
                break
            case RoleType.ORGANIZATION:
                if (roomManagerId === userDataState.id) {
                    navigate("/user-profile")
                    window.scrollTo(0, 0)
                } else {
                    navigate(`/user/${roomManagerId}/${id}`)
                    window.scrollTo(0, 0)
                }
                break
            default:
                break
        }
    }

    const onRoomCreatorClick = (roomCreatorId: number) => {
        dispatch(setModalData({ open: false, roomMembersModal: false }))
        navigate(`/organization-profile/${roomCreatorId}`)
        window.scrollTo(0, 0)
    }

    if (props.type === LabelType.ROOM_MANAGER) {
        return (
            <>
                {roomDataState.room_managers?.map((roomManager, index) => {
                    return (
                        <div
                            key={roomManager.id}
                            className={` ${index === roomDataState.room_managers?.length! - 1 ? "room-user-card--no-border" : "room-user-card--border"}`}
                        >
                            <UserCard
                                key={roomManager.id}
                                userCardStyle="room-user-card"
                                leftPanelStyle="left-panel"
                                imageSrc={roomManager?.profile_photo?.url_path || profileDefault}
                                profileImageStyle="image image__profile image__profile--fit image__profile--size-small"
                                middlePanelStyle="middle-panel"
                                text={roomManager?.first_name + " " + roomManager?.last_name}
                                subtext={translation.roomManager}
                                textStyle="text room-user-card__text"
                                textStyleMore25="text room-user-card__text"
                                subtextStyle=" room-user-card__subtext room-user-card__subtext--background-color-red"
                                onClick={() => onRoomManagerClick(roomManager.id)}
                            />
                        </div>
                    )
                })}
            </>
        )
    } else {
        return (
            <div className="room-user-card--margin-top">
                <UserCard
                    userCardStyle="room-user-card"
                    leftPanelStyle="left-panel"
                    imageSrc={roomDataState?.organization?.photo?.url_path! || organizationProfileDefault}
                    profileImageStyle="image image__profile image__profile--fit image__profile--size-small"
                    middlePanelStyle="middle-panel"
                    text={roomDataState?.organization?.name}
                    subtext={translation.roomCreator}
                    textStyle="text room-user-card__text room-user-card__subtext--font-weight-400"
                    textStyleMore25="text room-user-card__text room-user-card__subtext--font-weight-400"
                    subtextStyle="subtext  room-user-card__subtext room-user-card__subtext--background-color-gold"
                    onClick={() => onRoomCreatorClick(roomDataState?.organization?.id!)}
                />
            </div>
        )
    }
}

export default RoomUserCard
