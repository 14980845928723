import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { CloseIcon } from "../../Shared/Components/SvgIcons"
import Button from "../../Shared/Primitive/Button"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { RootState } from "../../../App/store"
import { RoleType } from "../../../App/enums"
import { onLeaveRoomRequest, onOrganizationLeaveRoomRequest } from "../RoomServiceRequests/JoinRoomServiceRequest"

function LeaveRoomModal() {
    const translation = useSelector(selectTranslations)
    const { roomId } = useParams()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const organization = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const dispatch = useDispatch()

    const onLeaveRoom = () => {
        dispatch(setModalData({ open: true, leaveRoomModal: false }))
        switch (role) {
            case RoleType.USER:
                dispatch(onLeaveRoomRequest(parseInt(roomId!)))
                break
            case RoleType.ORGANIZATION:
                dispatch(onOrganizationLeaveRoomRequest(organization.id!, parseInt(roomId!)))
                break
            default:
                break
        }
    }

    const onModalClose = () => {
        dispatch(setModalData({ open: false, leaveRoomModal: false }))
    }

    return (
        <div className="delete-room-modal">
            <div className="delete-room-modal__content">
                <div className="delete-room-modal__upper-panel">
                    <div className="delete-room-modal__title">{translation.leaveRoom}</div>
                    <div onClick={onModalClose} className="delete-room-modal__close-icon">
                        <CloseIcon />
                    </div>
                </div>
                <div className="delete-room-modal__divider"></div>
                <div className="delete-room-modal__middle-panel">
                    <div className="delete-room-modal__text">{translation.doYouwantToLeaveRoom}</div>
                    <div className="delete-room-modal__delete-icon"></div>
                </div>
                <div className="delete-room-modal__button-container  delete-room-modal__button-container--leave-room">
                    <div className="delete-room-modal__button">
                        <Button
                            onClick={onLeaveRoom}
                            type="submit"
                            className="button button--font-size-small button--font-weight-400 button--text-color-red  button--color-grey  button--text-color-white button--color-red button--hover-brighter button--cursor-pointer button--border-radius-05 "
                        >
                            {translation.continue}
                        </Button>
                    </div>
                    <div className="delete-room-modal__button">
                        <Button
                            onClick={onModalClose}
                            type="submit"
                            className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-red button--hover-brighter button--cursor-pointer button--border-radius-05 "
                        >
                            {translation.cancel}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeaveRoomModal
