import { NavigateFunction } from "react-router-dom"
import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { getUserPasswordConfirmError, resetUserPasswordConfirmError } from "../../User/UserSettings/UserSettingsSlice"

export const deleteOrganizationAccountRequest = (password: string, orgId: number | string, navigate: NavigateFunction) => async (dispatch: any) => {
    try {
        const response = await HTTPService.post("/confirm-existing-password/", { password: password }, headers)
        if (response.status === 200) {
            dispatch(
                setModalData({
                    open: false,
                    userConfirmPasswordModalState: {
                        status: false,
                        setData: { first_name: "", last_name: "", email: "", password: "", confirm_password: "", location: "" },
                        type: "",
                    },
                })
            )

            HTTPService.delete(`/organization/${orgId}/delete/`, headers)
                .then(response => {
                    if (response.status === 204) {
                        dispatch(
                            setModalData({
                                open: false,
                                settings: false,
                                viewPhotoTypeModal: null,
                                changePhotoTypeModal: null,
                                organizationSettings: false,
                            })
                        )
                        dispatch(setModalData({ open: true, deleteOrganizationSuccessfully: true }))
                        navigate("/dashboard")
                        window.scrollTo(0, 0)
                    }
                })
                .catch(error => {
                    console.log(error)
                })

            await dispatch(resetUserPasswordConfirmError())
        }
    } catch (error: any) {
        dispatch(getUserPasswordConfirmError(error.response.data.message))
    }
}
