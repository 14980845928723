import { headers } from "../../../App/Axios"
import { StorageKey } from "../../../App/enums"
import HTTPService from "../../../App/HTTPService"
import StorageService from "../../../App/StorageService"
import { User } from "../../../Types/User"
import { setUserData } from "../../Shared/SharedSlices/UserDataSlice"
import { getUserPrivacySettingsState } from "./UserSettingsSlice"

export const userPrivacySettingsGetRequest = (user: User) => {
    return HTTPService.get(`/user/${user.id}/privacy-settings/`, headers)
}

export const userPutPrivacySettingsData = (privacySettingsForm: object, user: User) => async (dispatch: Function) => {
    const values = [privacySettingsForm]
    try {
        const response = await HTTPService.put(`/user/${user.id}/privacy-settings/`, { privacy_settings: values }, headers)
        if (response.status === 200) {
            userPrivacySettingsGetRequest(user).then(response => {
                if (response.status === 200) {
                    const userObject = {
                        ...user,
                        privacy_settings: response.data.privacy_settings,
                    }
                    dispatch(setUserData(userObject))
                    StorageService.setItem(StorageKey.user, StorageService.stringify(userObject))
                }
            })
            dispatch(getUserPrivacySettingsState(false))
        }
    } catch (error) {
        console.log(error)
    }
}
