import React from "react"
import { useSelector } from "react-redux"
import { RoleType } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import BackgroundWrapper from "../../Shared/Components/BackgroundWrapper"
import IndividualProfileCard from "../../Shared/Components/IndividualProfileCard"
import ModalGroup from "../../Shared/Components/ModalGroup"
import RoomDropdown from "../../Shared/Components/RoomDropdown"
import SponsorCard from "../../Shared/Components/SponsorCard"
import Header from "../../Shared/Header/Header"
import Footer from "../../Shared/Layout/Footer"
import MainLayout from "../../Shared/Layout/MainLayout"
import NewestMembersCard from "../../Shared/NewestMembers/NewestMemberCard"
import GoPremium from "./GoPremium"
import { useNavigate, useParams } from "react-router-dom"
import { RootState } from "../../../App/store"
import { SearchIcon } from "../../Shared/Components/SvgIcons"

function GoPremiumScreen() {
    const translation = useSelector(selectTranslations)
    const { id } = useParams()
    const navigate = useNavigate()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)

    const onSeeAllEventsClick = () => {
        switch (role) {
            case RoleType.ORGANIZATION:
                navigate(`/all-premium-events/${id}`)
                break
            case RoleType.USER:
                navigate(`/all-premium-events`)
                break
            default:
                break
        }
    }

    return (
        <BackgroundWrapper>
            <Header />
            <MainLayout
                leftPanelFirstComponent={<IndividualProfileCard />}
                leftPanelSecondComponent={<RoomDropdown type={RoleType.ORGANIZATION} />}
                RightPanelSecondComponent={translation.newsMembersText}
                RightPanelThirdComponent={<NewestMembersCard />}
                RightPanelForthComponent={
                    <div className="main-layout__sponsored-title">
                        {translation.sponsoredText}
                        <div onClick={onSeeAllEventsClick} className="main-layout__sponsored-icon">
                            <SearchIcon color="dark-blue" />
                        </div>
                    </div>
                }
                RightPanelFifthComponent={<SponsorCard />}
                overflowModifier={true}
            >
                <GoPremium />
            </MainLayout>
            <Footer />
            <ModalGroup />
        </BackgroundWrapper>
    )
}

export default GoPremiumScreen
