import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { CloseIcon } from "./SvgIcons"
import Button from "../Primitive/Button"
import { setModalData } from "../SharedSlices/ModalSlice"
import { RootState } from "../../../App/store"
import HTTPService from "../../../App/HTTPService"
import { headers } from "../../../App/Axios"
import { setSingularEventData } from "../SharedSlices/SponsorCardSlice"

function EventLinkModal() {
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const [pageURL, setPageURL] = useState("")
    const pinboardIdState = useSelector((state: RootState) => state.pinboardIdSlice.id)
    const pinboardEvent = useSelector((state: RootState) => state.sponsorSlice.SingularEventSlice.event)

    const singularEventGetRequest = (eventId: number) => {
        return HTTPService.get(`/event/${eventId}`, headers)
    }

    useEffect(() => {
        singularEventGetRequest(pinboardIdState).then(response => {
            if (response.status === 200) {
                dispatch(setSingularEventData(response.data))
            }
        })
    }, [])

    useEffect(() => {
        const eventUrl = window.location.origin + `/organization-profile/${pinboardEvent.organization?.id}/event/${pinboardIdState}`
        setPageURL(eventUrl)
    }, [pinboardEvent.organization?.id, pinboardIdState])

    const closeModal = () => {
        dispatch(setModalData({ open: false, eventLinkModal: false }))
    }

    const handleOnClick = () => {
        if (navigator.clipboard === undefined) {
            return
        } else {
            navigator.clipboard.writeText(pageURL)
            dispatch(setModalData({ open: false, eventLinkModal: false }))
        }
    }

    return (
        <div className="event-link-modal">
            <div className="event-link-modal__title">
                {translation.eventLink}
                <div className="event-link-modal__close-icon" onClick={closeModal}>
                    <CloseIcon />
                </div>
            </div>
            <div className="event-link-modal__container">
                <div className="event-link-modal__link-area">{pageURL}</div>
                <div className="event-link-modal__button">
                    <Button
                        className="button button--border-radius-05 button--color-gold button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400 "
                        onClick={handleOnClick}
                    >
                        {translation.copyLink}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default EventLinkModal
