import { headers } from "../../../../App/Axios"
import HTTPService from "../../../../App/HTTPService"
import { setRoomsSearchedData, setSearchQuery } from "./UserRoomSearchSlice"

export const onUserRoomSearch = (searchQuery: string, byRoom: boolean, userId: number) => async (dispatch: Function) => {

    try {
        const response = await HTTPService.get(
            `/search-user-rooms/${userId}/?${byRoom ? "room" : "organization"}=${searchQuery}&sorting=${byRoom ? "room__name" : "organization__name"}`,
            headers
        )
        if (response.status === 200) {
            dispatch(setRoomsSearchedData(response.data.rooms))
            dispatch(setSearchQuery(searchQuery))
        }
    } catch (error: any) {}
}
