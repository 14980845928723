import { createSlice, PayloadAction } from "@reduxjs/toolkit"


export type MembersType = {
    id: number
    first_name?: string
    last_name?: string
    name?:string
    profile_photo?: { id: number; url_path: string }
    photo?: { id: number; url_path: string }
    type:string
    hashtags?:string[]
}

type MembersData = {
    members: Array<MembersType>
}
const initialState: MembersData = { members: [] }

const MembersInRoomSlice = createSlice({
    name: "MembersInRoomSlice",
    initialState: initialState,
    reducers: {
        setRoomMembers(state, action: PayloadAction<Array<MembersType>>) {
            state.members = action.payload
        },
    },
})

export const { setRoomMembers } = MembersInRoomSlice.actions
export default MembersInRoomSlice.reducer
