import { headers } from "../../../../App/Axios"
import HTTPService from "../../../../App/HTTPService"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { setEmployeesData, setEmployeesSearchData } from "./EmployeesSlice"

export const EmployeePostRequest = (userId: number, organizationId: number, name: string) => async (dispatch: Function) => {
    try {
        const response = await HTTPService.post(
            "/role/",
            {
                name: name,
                organization: organizationId,
                user: userId,
            },
            headers
        )
        if (response.status === 201) {
            EmployeeGetRequest(organizationId).then(response => {
                if (response.status === 200) {
                    dispatch(setEmployeesData(response.data.role))
                }
            })
        }
    } catch (error: any) {
        console.log(error)
    }
}

export const EmployeeGetRequest = (id: number) => {
    return HTTPService.get(`/organization/${id}/role/`, headers)
}

export const EmployeeDeleteRequest = (id: number, userId: number) => async (dispatch: Function) => {
    HTTPService.delete(`/organization/${id}/role/${userId}/`)
        .then(response => {
            if (response.status === 204) {
                dispatch(setModalData({ open: false, removeEmployeeModal: false }))
                dispatch(setModalData({ open: true, employeeRemoval: true }))

                EmployeeGetRequest(id).then(response => {
                    if (response.status === 200) {
                        dispatch(setEmployeesData(response.data.role))
                    }
                })
            }
        })
        .catch(error => {
            console.log(error)
        })
}

export const onEmployeeSearch = (searchQuery: string, organizationId: string) => async (dispatch: Function) => {
    try {
        const response = await HTTPService.get(`organization/${organizationId}/role/?query=${searchQuery}`, headers)
        if (response.status === 200) {
            dispatch(setEmployeesSearchData(response.data.role))
        }
    } catch (error: any) {
        console.log(error)
    }
}
