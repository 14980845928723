import React from "react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { RoleType, StorageKey } from "../../App/enums"
import { formatDate } from "../../App/Helpers/Helpers"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import SessionService from "../../App/SessionService"
import { RootState } from "../../App/store"
import { setPostStatus } from "../Room/RoomPost/RoomPostSlice"
import { NotificationsActiveIcon, NotificationsIcon } from "../Shared/Components/SvgIcons"
import Button from "../Shared/Primitive/Button"
import UserNotificationDropdownItem from "./NotificationDropdownItem"
import { onMarkSingleOrganizationNotificationsAsRead } from "./OrganizationNotificationServiceRequest"
import { onMarkSingleUserNotificationsAsRead } from "./UserNotificationServiceRequest"

function NotificationsDropdown() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const notifications = useSelector((state: RootState) => state.UserNotificationSlice.notifications)
    const [hasNotification, setHasNotification] = useState<boolean>()
    const [hasNewNotifications, setHasNewNotifications] = useState<boolean>()
    const translation = useSelector(selectTranslations)
    const [notificationsOpen, setNotificationsOpen] = useState(false)
    const { id, roomId } = useParams()
    const orgId = SessionService.getItem(StorageKey.organizationId)
    const notificationFilterNumber = 5
    const lastNotificationIndex = notificationFilterNumber - 1
    let notificationRef = React.useRef() as React.MutableRefObject<HTMLInputElement>

    useEffect(() => {
        setHasNewNotifications(notifications.some(notification => notification.read === false))
    }, [notifications])

    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (notificationsOpen && notificationRef.current && !notificationRef.current.contains(event.target as HTMLElement)) {
                setNotificationsOpen(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    const onRouteChange = () => {
        switch (role) {
            case RoleType.USER:
                const userPath = "/notifications"
                navigate(userPath)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                if (id === orgId) {
                    const organizationPath = `/notifications/${id}`
                    navigate(organizationPath)
                    window.scrollTo(0, 0)
                } else {
                    const organizationPath = `/notifications/${orgId}`
                    navigate(organizationPath)
                    window.scrollTo(0, 0)
                }

                break
            default:
                break
        }
    }

    useEffect(() => {
        if (notifications.length === 0) {
            setHasNotification(false)
        } else {
            setHasNotification(true)
        }
    }, [notifications.length])

    const onNotificationClick = (notificationId: number, path: string) => {
        navigate(`/${path}`)

        switch (role) {
            case RoleType.USER:
                dispatch(onMarkSingleUserNotificationsAsRead(notificationId))
                break
            case RoleType.ORGANIZATION:
                dispatch(onMarkSingleOrganizationNotificationsAsRead(notificationId, id!))
                break
            default:
                break
        }

        if (roomId) {
            dispatch(setPostStatus({ postsStatus: true, singularPostStatus: false, postedInRoomStatus: false }))
        }
        setNotificationsOpen(false)
    }

    const onLastItem = (index: number) => {
        if (notifications.length < notificationFilterNumber) {
            return notifications.length - 1 === index
        } else {
            return index === lastNotificationIndex
        }
    }

    return (
        <div className="header__menu-notifications" ref={notificationRef}>
            <div
                className="header__icon"
                onClick={() => {
                    setNotificationsOpen(!notificationsOpen)
                }}
            >
                {!hasNewNotifications ? <NotificationsIcon /> : <NotificationsActiveIcon />}
            </div>
            {notificationsOpen && (
                <div className="notifications-dropdown">
                    <div className="notifications-dropdown__content">
                        <div className="notifications-dropdown__upper-panel">
                            {hasNotification ? (
                                notifications?.slice(0, notificationFilterNumber).map((notification, index) => {
                                    return (
                                        <UserNotificationDropdownItem
                                            isLastItem={onLastItem(index)}
                                            key={notification.id}
                                            id={notification.id}
                                            sender={notification!.content_type!}
                                            photoSrc={notification.content_object?.photo?.url_path || notification.content_object?.profile_photo?.url_path}
                                            itemSizeLarge={false}
                                            first_name={notification.content_object?.first_name || translation.deletedUser}
                                            last_name={notification.content_object?.last_name}
                                            name={notification.content_object?.name || translation.deletedOrganization}
                                            notification={notification.content}
                                            date={formatDate(notification?.created_at!, "HH:mm dd. MMM")}
                                            isRead={notification.read}
                                            post={notification.post}
                                            onClick={() => onNotificationClick(notification.id!, notification.redirect_url)}
                                            redirect_url={notification.redirect_url}
                                            action_url={notification?.action_url!}
                                            roomId={notification.room}
                                            invitation_id={notification.invitation_id}
                                            isDropDown={true}
                                        />
                                    )
                                })
                            ) : (
                                <div className="notifications-dropdown__text">{translation.youHaveNoNotifications}</div>
                            )}
                        </div>
                        <div className="notifications-dropdown__lower-panel">
                            {hasNotification && (
                                <div className="notifications-dropdown__button">
                                    <Button
                                        onClick={onRouteChange}
                                        className="button button--border-radius-09 button--color-grey button--hover-darker button--cursor-pointer button--text-color-grey button--font-size-small button--font-weight-400  "
                                    >
                                        {translation.seeAllResults}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}{" "}
        </div>
    )
}

export default NotificationsDropdown
