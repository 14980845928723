import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import Button from "../../Shared/Primitive/Button"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { CloseIcon } from "../../Shared/Components/SvgIcons"
import { RoomDeleteRequest } from "../RoomServiceRequests/RoomServiceRequests"
import { RootState } from "../../../App/store"
import { useNavigate } from "react-router-dom"

function DeleteRoomModal() {
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onRoomDelete = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        dispatch(RoomDeleteRequest(roomDataState.id, roomDataState.organization?.id!, navigate))
    }

    const onModalClose = () => {
        dispatch(setModalData({ open: false, deleteRoom: false }))
    }

    return (
        <div className="delete-room-modal">
            <div className="delete-room-modal__content">
                <div className="delete-room-modal__upper-panel">
                    <div className="delete-room-modal__title">{translation.deleteRoom}?</div>
                    <div onClick={onModalClose} className="delete-room-modal__close-icon">
                        <CloseIcon />
                    </div>
                </div>
                <div className="delete-room-modal__divider"></div>
                <div className="delete-room-modal__middle-panel">
                    <div className="delete-room-modal__text">{translation.areYouSureToDeleteRoom}</div>
                    <div className="delete-room-modal__delete-icon"></div>
                </div>
                <div className="delete-room-modal__button-container">
                    <div className="delete-room-modal__button">
                        <Button
                            onClick={onRoomDelete}
                            type="submit"
                            className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-red button--hover-brighter button--cursor-pointer button--border-radius-05 "
                        >
                            {translation.delete}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteRoomModal
