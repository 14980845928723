import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { LeftArrow } from "../Components/SvgIcons"
import Button from "../Primitive/Button"

function NotFound() {
    const translation = useSelector(selectTranslations)
    const navigate = useNavigate()
    return (
        <div className="not-found">
            <img src="/edusiia_illustration_404.png" alt="404" className="not-found__image" />
            <p className="not-found__text not-found__text--font-65">{translation.pageNotFound}</p>
            <p className="not-found__text not-found__text--font-30">{translation.pageNotFound2}</p>
            <div className="no-permission__button">
                <Button
                    className="button button--color-blue button--hover-brighter button--cursor-pointer button--border-radius-05 button--text-color-white button--font-weight-700"
                    onClick={() => navigate("/dashboard")}
                >
                    <LeftArrow />
                    {translation.dashboard}
                </Button>
            </div>
        </div>
    )
}

export default NotFound
