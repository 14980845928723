import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { headers } from "../../../../App/Axios"
import HTTPService from "../../../../App/HTTPService"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import { RootState } from "../../../../App/store"
import "../../../../Styles/main.scss"
import Button from "../../../Shared/Primitive/Button"
import { AddBlueIcon } from "../../../Shared/Components/SvgIcons"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { getUserExperienceId, setWorkExperienceData } from "../../../Shared/SharedSlices/WorkExperienceSlice"
import UserProfileCard from "../UserProfileCard"
import UserProfileExperienceItem from "./UserProfileExperienceItem"
import moment from "moment"
import { WorkExperienceData } from "../../../../Types/WorkExperienceData"
import { RoleType } from "../../../../App/enums"
import { getLoadingStatus } from "../../../Shared/SharedSlices/LoadingScreenSlice"

function UserProfileExperience(props: { isExpandedScreen: boolean; filterNumber: number; hasButton: boolean; isEditable: boolean; isYourProfile: boolean }) {
    const translation = useSelector(selectTranslations)
    const [showButton, setShowButton] = useState<boolean>()
    const [hasNoData, setHasNoData] = useState<boolean>()
    const [otherUserWorkExperience, setOtherUserWorkExperience] = useState<WorkExperienceData[]>()
    const dispatch = useDispatch()
    const workExperienceState = useSelector((state: RootState) => state.workExperienceSlice.workExperienceSlice.work)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const { id, userId } = useParams()
    const navigate = useNavigate()

    const onNavigationToOtherUserExperiences = () => {
        switch (role) {
            case RoleType.USER:
                const userPath = `/all-user-experience/${userId}`
                navigate(userPath)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                const organizationPath = `/${id}/all-user-experience/${userId}`
                navigate(organizationPath)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    const onRouteChange = () => {
        let path = "/all-experience"
        navigate(path)
        window.scrollTo(0, 0)
    }

    const workExperienceGetRequest = (id: number | string) => {
        return HTTPService.get(`/user/${id}/work/`, headers)
    }

    const onWorkExperienceLengthCheck = useCallback((workExperienceData: WorkExperienceData[]) => {
        if (workExperienceData && workExperienceData.length === 0) {
            setHasNoData(true)
        } else {
            setHasNoData(false)
        }
        if (workExperienceData && workExperienceData.length > 3) {
            setShowButton(true)
        } else {
            setShowButton(false)
        }
    }, [])

    useEffect(() => {
        if (props.isYourProfile) {
            onWorkExperienceLengthCheck(workExperienceState)
        } else {
            onWorkExperienceLengthCheck(otherUserWorkExperience!)
        }
    }, [workExperienceState, otherUserWorkExperience, props.isYourProfile, onWorkExperienceLengthCheck])

    useEffect(() => {
        let isMounted = true
        if (props.isYourProfile) {
            workExperienceGetRequest(userDataState.id!)
                .then(response => {
                    if (isMounted && response.status === 200) {
                        dispatch(setWorkExperienceData(response.data))
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        } else {
            workExperienceGetRequest(userId!)
                .then(response => {
                    if (isMounted && response.status === 200) {
                        setOtherUserWorkExperience(response.data)
                        dispatch(getLoadingStatus(false))
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
        return () => {
            isMounted = false
        }
    }, [dispatch, props.isYourProfile, userDataState.id, userId])

    const onNewExperienceModalOpen = () => {
        dispatch(setModalData({ open: true, newExperience: true }))
    }

    const onEditExperienceModalOpen = () => {
        dispatch(setModalData({ open: true, editExperience: true }))
    }

    const onCompanyLink = (org_id: number) => {
        navigate(`/organization-profile/${org_id}`)
    }

    const onEditExperience = (id: number) => {
        dispatch(getUserExperienceId(id))
        onEditExperienceModalOpen()
    }

    const onLastItem = (index: number) => {
        if (!props.isExpandedScreen && workExperienceState.length < props.filterNumber + 1) {
            return workExperienceState.length - 1 === index
        }
        if (!props.isExpandedScreen && workExperienceState.length > props.filterNumber) {
            return index === props.filterNumber - 1
        }
        if (props.isExpandedScreen && workExperienceState.length > props.filterNumber) {
            return workExperienceState.length - 1 === index
        } else {
            return false
        }
    }
    const work = props.isYourProfile ? workExperienceState! : otherUserWorkExperience!

    return (
        <div className={`user-profile-experience ${props.isExpandedScreen && "user-profile-experience--margin-top"}`}>
            {work && (work!.length > 0 || props.isEditable) && (
                <>
                    <div className="user-profile-experience__title-container">
                        <div className="user-profile-experience__title">{translation.myExperience}</div>
                        {props.isEditable && (
                            <div onClick={onNewExperienceModalOpen} className="user-profile-experience__icon">
                                <AddBlueIcon />
                            </div>
                        )}
                    </div>
                    <UserProfileCard
                        children={
                            !hasNoData ? (
                                props.isYourProfile ? (
                                    workExperienceState.slice(0, props.filterNumber).map((item, index) => {
                                        return (
                                            <div className="user-profile-experience__item" key={item.id}>
                                                <UserProfileExperienceItem
                                                    isLastItem={onLastItem(index)}
                                                    workPosition={item.work_position}
                                                    companyName={item.company_name}
                                                    startDate={moment(item.start_date).format("YYYY-MM")}
                                                    endDate={item.end_date === null ? translation.present : moment(item.end_date).format("YYYY-MM")}
                                                    onClick={() => onEditExperience(item.id)}
                                                    editIcon={props.isEditable}
                                                    onClickCompany={item.org_id ? () => onCompanyLink(item.org_id) : undefined}
                                                    is_in_edusiia={item.is_in_edusiia}
                                                    websiteUrl={item.website_url}
                                                    org_id={item.org_id}
                                                />
                                            </div>
                                        )
                                    })
                                ) : (
                                    otherUserWorkExperience?.slice(0, props.filterNumber).map((item, index) => {
                                        return (
                                            <div className="user-profile-experience__item" key={item.id}>
                                                <UserProfileExperienceItem
                                                    isLastItem={onLastItem(index)}
                                                    workPosition={item.work_position}
                                                    companyName={item.company_name}
                                                    startDate={moment(item.start_date).format("YYYY-MM")}
                                                    endDate={item.end_date === null ? translation.present : moment(item.end_date).format("YYYY-MM")}
                                                    onClick={() => onEditExperience(item.id)}
                                                    editIcon={props.isEditable}
                                                    onClickCompany={item.org_id ? () => onCompanyLink(item.org_id) : undefined}
                                                    is_in_edusiia={item.is_in_edusiia}
                                                    websiteUrl={item.website_url}
                                                    org_id={item.org_id}
                                                />
                                            </div>
                                        )
                                    })
                                )
                            ) : (
                                <div className="user-profile-experience__empty-item">{translation.noExperience} </div>
                            )
                        }
                        button={
                            props.hasButton === true && showButton === true ? (
                                <div className="user-profile-experience__button-container">
                                    <div className="user-profile-experience__button-size">
                                        <Button
                                            onClick={props.isYourProfile ? onRouteChange : onNavigationToOtherUserExperiences}
                                            className="button button--border-radius-09 button--color-grey button--hover-darker button--cursor-pointer button--text-color-grey button--font-size-small button--font-weight-400  "
                                        >
                                            {translation.seeAllResults}
                                        </Button>
                                    </div>
                                </div>
                            ) : null
                        }
                    />
                </>
            )}
        </div>
    )
}

export default UserProfileExperience
