import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { NotificationList, Notification } from "../../Types/Notification"

const initialState:  NotificationList = {notifications: []}

const NotificationSlice = createSlice({
    name: "UserNotificationSlice",
    initialState: initialState,
    reducers: {
        setUserNotifications(state, action: PayloadAction<Array<Notification>>) {
           let data = action.payload.filter((notification) => notification.redirect_url != null)
           state.notifications = data
        }
    }
})

export const { setUserNotifications } = NotificationSlice.actions
export default NotificationSlice.reducer