import React, { useRef } from "react"
import Footer from "../../Shared/Layout/Footer"
import "../../../Styles/main.scss"
import AuthPageDescription from "../../Shared/Primitive/AuthPageDescription"
import AuthPageImage from "../../Shared/Primitive/AuthPageImage"
import SignInForm from "./SigninForm"
import { LogoIconMobile } from "../../Shared/Components/SvgIcons"
import ModalGroup from "../../Shared/Components/ModalGroup"

const SigninScreen = () => {

    return (
        <>
            <div className="auth-layout">
                <div className="auth-layout__main-container ">
                    <div className="left-panel">
                        <div className="left-panel__logo">
                            {" "}
                            <LogoIconMobile />
                        </div>
                        <div className="left-panel__description">
                            <AuthPageDescription />
                        </div>
                        <div className="edusiia-image">
                        <AuthPageImage />
                    </div>
                    </div>
                    
                    <div className="right-panel right-panel--height-61">
                        <div className="right-panel__edusiia-image">
                            <AuthPageImage />
                        </div>
                        <div className="right-panel__signin-form">
                            <SignInForm />
                        </div>
                    </div>
                </div>
                <Footer />
                <ModalGroup />
            </div>
        </>
    )
}

export default SigninScreen
