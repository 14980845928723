import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import StorageService from "../../../App/StorageService"
import { User } from "../../../Types/User"
import { setUserData } from "../../Shared/SharedSlices/UserDataSlice"
import { hasAuthenticationToken } from "../../Authentication/SignIn/SigninSlice"
import { getUserDeleteAccountError } from "./UserDeleteAccountSlice"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"

export const deleteAccountPostRequestWithReason = (textAreaValue: string, user: User) => async (dispatch: Function) => {
    HTTPService.post(`/user/delete/reasons/`, { reason: textAreaValue }, headers)
        .then(response => {
            if (response.status === 201) {
                HTTPService.delete(`/user/${user.id}/delete/`, headers)
                    .then(response => {
                        if (response.status === 204) {
                            dispatch(
                                setUserData({
                                    id: 0,
                                    email: "",
                                    location: "",
                                    cover_photo: { id: 0, url_path: "" },
                                    first_name: "",
                                    last_name: "",
                                    description: "",
                                    edusiia_reasons: "",
                                    profile_photo: { id: 0, url_path: "" },
                                    online: false
                                })
                            )
                            StorageService.clear()
                            dispatch(hasAuthenticationToken({ hasAccessToken: false }))
                            dispatch(setModalData({ open: true, settings: false, deleteAccountSuccessfully: true }))
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        })
        .catch(error => {
            const errors = {
                msg: error.response.data,
                status: error.response.status,
            }

            dispatch(
                getUserDeleteAccountError({
                    message: errors.msg,
                    status: errors.status,
                })
            )
        })
}

export const deleteAccountRequest = (user: User) => async (dispatch: Function) => {
    HTTPService.delete(`/user/${user.id}/delete/`, headers)
        .then(response => {
            if (response.status === 204) {
                dispatch(
                    setUserData({
                        id: 0,
                        email: "",
                        location: "",
                        cover_photo: { id: 0, url_path: "" },
                        first_name: "",
                        last_name: "",
                        description: "",
                        edusiia_reasons: "",
                        profile_photo: { id: 0, url_path: "" },
                        online: false
                    })
                )
                StorageService.clear()
                dispatch(hasAuthenticationToken({ hasAccessToken: false }))
                dispatch(setModalData({ open: true, settings: false, deleteAccountSuccessfully: true }))
            }
        })
        .catch(error => {
            const errors = {
                msg: error.response.data,
                status: error.response.status,
            }

            dispatch(
                getUserDeleteAccountError({
                    message: errors.msg,
                    status: errors.status,
                })
            )
        })
        
}
