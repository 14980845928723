import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import SearchInput from "../../../Shared/Primitive/SearchInput"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { CloseIcon, InviteUserIcon } from "../../../Shared/Components/SvgIcons"
import UserCard from "../../../Shared/Components/UserCard"
import { useCallback, useEffect, useState } from "react"
import { organizationProfileDefault, profileDefault } from "../../../../App/GlobaleVariables"
import { useParams } from "react-router-dom"
import { RootState } from "../../../../App/store"
import { JoinStatus, RoleType } from "../../../../App/enums"
import { onUserInvitePostRequest } from "./RoomInviteServiceRequest"
import { headers } from "../../../../App/Axios"
import HTTPService from "../../../../App/HTTPService"
import { setInviteInRoomSearchStatus, setIsInvited } from "../../RoomSlices/MemberInRoomStatusSlice"

export type InviteUserType = {
    id: number
    first_name?: string
    last_name?: string
    name?: string
    profile_photo?: { id: number; url_path: string }
    photo?: { id: number; url_path: string }
    type: string
    invite_status: string
}

function RoomInviteModal() {
    const { id, roomId } = useParams()
    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const [users, setUsers] = useState<InviteUserType[]>()
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const [inputState, setInputState] = useState("")
    const [searchedUsers, setSearchedUsers] = useState<InviteUserType[]>([])
    const isInvited = useSelector((state: RootState) => state.MemberInRoomStatusSlice.InviteInRoomStatusSlice.isInvited)
    const searchInviteStatus = useSelector((state: RootState) => state.MemberInRoomStatusSlice.InviteInRoomSearchStatusSlice.isInvited)

    const debounce = (callback: (arg0: any) => void, wait: number | undefined) => {
        let timeoutId: number | undefined;
        return (...args: any) => {
          window.clearTimeout(timeoutId);
          timeoutId = window.setTimeout(() => {
            callback.apply(null,args);
          }, wait);
        };
      }

    const getRequest = useCallback(() => {
        return HTTPService.get(`/invite-user-list/${roomId}/`, headers)
    }, [roomId])

    const searchUsersGetRequest = useCallback(
        (searchQuery: string) => {
            return HTTPService.get(`/invite-user-list/${roomId}/?query=${searchQuery}`, headers)
        },
        [roomId]
    )

    useEffect(() => {
        const getData = setTimeout(() => {
            if (inputState.length > 2) {
                searchUsersGetRequest(inputState).then(response => {
                    if (response.status === 200) {
                        setSearchedUsers(response.data)
                        dispatch(setInviteInRoomSearchStatus(false))
                    }
                })
            }
        }, 500)

        return () => clearTimeout(getData)

        
    }, [dispatch, inputState, searchInviteStatus, searchUsersGetRequest])

    useEffect(() => {
        dispatch(setIsInvited(true))
    }, [dispatch])

    useEffect(() => {
        if (isInvited) {
            getRequest().then(response => {
                if (response.status === 200) {
                    setUsers(response.data)
                    dispatch(setIsInvited(false))
                }
            })
        }
    }, [dispatch, getRequest, isInvited])

    const onModalClose = () => {
        dispatch(setModalData({ open: false, roomInviteModal: false }))
    }

    const onUserInvite = (invitedUserId: number) => {
        if (role === RoleType.USER) {
            dispatch(onUserInvitePostRequest({ room: parseInt(roomId!), invited_user: invitedUserId, content_type: "user", object_id: userDataState.id! }))
        } else {
            dispatch(onUserInvitePostRequest({ room: parseInt(roomId!), invited_user: invitedUserId, content_type: "organization", object_id: parseInt(id!) }))
        }
    }

    const onOrganizationInvite = (invitedUserId: number) => {
        if (role === RoleType.USER) {
            dispatch(onUserInvitePostRequest({ room: parseInt(roomId!), invited_org: invitedUserId, content_type: "user", object_id: userDataState.id! }))
        } else {
            dispatch(onUserInvitePostRequest({ room: parseInt(roomId!), invited_org: invitedUserId, content_type: "organization", object_id: parseInt(id!) }))
        }
    }

    const onRemoveIconClick = () => {
        setInputState("")
    }

    return (
        <div className="room-invite-modal">
            <div className="room-invite-modal__title">
                <div className="room-invite-modal__text">
                    <div className="room-invite-modal__mobile-icon">
                        <InviteUserIcon color="gold" />
                    </div>
                    {translation.invite}
                </div>
                <div className="room-invite-modal__close-icon" onClick={onModalClose}>
                    <CloseIcon />
                </div>
            </div>
            <div className="room-invite-modal__search-container">
                <SearchInput
                    onRemoveIconClick={onRemoveIconClick}
                    searchvalue={inputState}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputState(event.target.value)}
                    isGlobalSearch={false}
                />
            </div>
            <div className="room-invite-modal__item-container">
                {(inputState.length > 0 ? searchedUsers : users)?.map((item, index) => {
                    return (
                        <div
                            className={`room-invite-modal__item ${
                                index === users?.length! - 1 ? "room-invite-modal__item--no-border" : "room-invite-modal__item--border"
                            }`}
                            key={item.id + item.type}
                        >
                            {item.type === RoleType.USER.toLocaleLowerCase() ? (
                                <UserCard
                                    imageSrc={item.profile_photo?.url_path || profileDefault}
                                    text={item.first_name + " " + item.last_name}
                                    subtext={""}
                                    profileImageStyle="image image__profile image__profile--fit image__profile--size-small "
                                    userCardStyle={"room-invite-modal-item"}
                                    leftPanelStyle={"left-panel"}
                                    middlePanelStyle={"middle-panel"}
                                    textStyleContainer={"text-container"}
                                    textStyle={"text"}
                                    textStyleMore25={"text"}
                                    subtextStyle={"subtext"}
                                    rightPanelStyle={"right-panel"}
                                    rightTextStyle={""}
                                    rightText={
                                        <div
                                            onClick={item.invite_status === null ? () => onUserInvite(item.id!) : undefined}
                                            className={`room-invite-modal__invite-button room-invite-modal__invite-button--${item.invite_status}`}
                                        >
                                            {item.invite_status === JoinStatus.PENDING ? translation.invited : translation.invite}
                                        </div>
                                    }
                                />
                            ) : (
                                <UserCard
                                    imageSrc={item.photo?.url_path || organizationProfileDefault}
                                    text={item.name}
                                    subtext={""}
                                    profileImageStyle="image image__profile image__profile--fit image__profile--size-small "
                                    userCardStyle={"room-invite-modal-item"}
                                    leftPanelStyle={"left-panel"}
                                    middlePanelStyle={"middle-panel"}
                                    textStyleContainer={"text-container"}
                                    textStyle={"text"}
                                    textStyleMore25={"text"}
                                    subtextStyle={"subtext"}
                                    rightPanelStyle={"right-panel"}
                                    rightTextStyle={""}
                                    rightText={
                                        <div
                                            onClick={item.invite_status === null ? () => onOrganizationInvite(item.id!) : undefined}
                                            className={`room-invite-modal__invite-button room-invite-modal__invite-button--${item.invite_status}`}
                                        >
                                            {item.invite_status === JoinStatus.PENDING ? translation.invited : translation.invite}
                                        </div>
                                    }
                                />
                            )}
                        </div>
                    )
                })}
                {inputState.length > 2 && searchedUsers.length === 0 && (
                    <div className="room-invite-modal__no-data-text">{`${translation.noResultThatContain} '${inputState}' ${translation.wasFound}`}</div>
                )}
            </div>
        </div>
    )
}

export default RoomInviteModal
