import { useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"

function PaymentSettings() {
    const translation = useSelector(selectTranslations)
    const donationLink = useSelector((state: RootState) => state.UserSettingsStatusSlice.userDonationLinkSlice.link)
    return (
        <div className="payment-settings">
            <div className="payment-settings__text-container">
                <p className="payment-settings__text">{translation.checkOutOrCancelYour}</p>
                <a href={donationLink} rel="noreferrer" target="_blank" className="payment-settings__red-text payment-settings__red-text--margin-left-02 ">
                    {translation.currentDonationPlan}
                </a>
            </div>
            <div className="payment-settings__center-container  payment-settings__center-container--payment-container">
                <img src="/edusiia_illustration_spende.png" alt="donate" className="payment-settings__image" />

                <p className="payment-settings__red-text  payment-settings__red-text--align-center">{translation.thankYouForYourSupport}</p>
            </div>
        </div>
    )
}

export default PaymentSettings
