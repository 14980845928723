import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RoleType, StorageKey } from "../../../App/enums"
import SessionService from "../../../App/SessionService"
import { Role } from "../../../Types/RoleType"

const initialState = { roles: SessionService.hasItem(StorageKey.roles) ? (SessionService.getItem(StorageKey.roles) as Role) : (RoleType.USER as Role) }

const RolesSlice = createSlice({
    name: "RolesSlice",
    initialState: initialState,
    reducers: {
        setRoleData(state, action: PayloadAction<Role>) {
            state.roles = action.payload
        },
    },
})

const initialRoleStatus = { status: false }

const RoleStatusSlice = createSlice({
    name: "RoleStatusSlice",
    initialState: initialRoleStatus,
    reducers: {
        setRoleStatusData(state, action: PayloadAction<boolean>) {
            state.status = action.payload
        },
    },
})

export const { setRoleData } = RolesSlice.actions
export const { setRoleStatusData } = RoleStatusSlice.actions
export default combineReducers({ RolesSlice: RolesSlice.reducer, RoleStatusSlice: RoleStatusSlice.reducer })
