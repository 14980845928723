import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { RoleType } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { CloseIcon } from "../../Shared/Components/SvgIcons"
import Button from "../../Shared/Primitive/Button"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { onMemberRemove } from "../RoomServiceRequests/RemoveMemberServiceRequest"

function RemoveRoomMember() {
    const translation = useSelector(selectTranslations)
    const memberId = useSelector((state: RootState) => state.roomMemberSlice.id)
    const memberType = useSelector((state: RootState) => state.roomMemberSlice.type)
    const dispatch = useDispatch()
    const Members = useSelector((state: RootState) => state.MembersInRoomSlice.members)
    const selectedMember = Members.find(member => member.id === memberId && member.type === memberType)
    const { roomId } = useParams()
    const memberName = memberType === RoleType.USER.toLowerCase() ? selectedMember?.first_name + " " + selectedMember?.last_name : selectedMember?.name
    const onModalClose = () => {
        dispatch(setModalData({ open: false, removeRoomMemberModal: false }))
    }

    const onMemberRemoveClick = () => {
        dispatch(onMemberRemove(roomId!, memberId!, memberType))
    }

    return (
        <div className="remove-room-member">
            <div className="remove-room-member__title">
                {translation.removeRoomMemberTitle}{" "}
                <div className="remove-room-member__close-icon" onClick={onModalClose}>
                    <CloseIcon />
                </div>
            </div>
            <div className="remove-room-member__content">
                {`${translation.removeEmployeeQuestion1}  ${memberName}  ${translation.removeRoomMemberQuestion1}`}
            </div>
            <div className="remove-room-member__button ">
                <Button
                    onClick={onMemberRemoveClick}
                    className=" button button--border-radius-09 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400"
                >
                    {translation.remove}
                </Button>
            </div>
        </div>
    )
}

export default RemoveRoomMember
