import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import HTTPService from "../../../App/HTTPService"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { CloseIcon } from "../../Shared/Components/SvgIcons"
import Button from "../../Shared/Primitive/Button"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { setRoomPostData, setRoomPostId, setRoomCommentId, setRoomEventCommentId, setPostStatus, setRoomPostsData } from "../RoomPost/RoomPostSlice"
import { setSingularEventData } from "../../Shared/SharedSlices/SponsorCardSlice"

function LinkPreviewDeleteModal() {
    const dispatch = useDispatch()
    const pinboardEvent = useSelector((state: RootState) => state.sponsorSlice.SingularEventSlice.event)
    const translation = useSelector(selectTranslations)
    const selectedPost = useSelector((state: RootState) => state.RoomPostsSlice.roomPostSlice.post)
    const posts = useSelector((state: RootState) => state.RoomPostsSlice.roomPostsSlice.posts)
    const roomPostId = useSelector((state: RootState) => state.RoomPostsSlice.roomPostIdSlice.postId)
    const roomCommentId = useSelector((state: RootState) => state.RoomPostsSlice.roomCommentIdSlice.commentId)
    const roomEventCommentId = useSelector((state: RootState) => state.RoomPostsSlice.roomEventCommentIdSlice.commentId)

    const linkPreviewPostRequest = async () => {
        try {
            const response = await HTTPService.patch(
                `/${roomPostId ? "post" : roomCommentId ? "comment" : "event-comment"}/${
                    roomPostId ? roomPostId : roomCommentId ? roomCommentId : roomEventCommentId
                }/edit/`,
                { show_preview: false }
            )
            if (response.status === 200) {
                switch (roomPostId ? "post" : roomCommentId ? "comment" : "event-comment") {
                    case "event-comment":
                        dispatch(
                            setSingularEventData({
                                ...pinboardEvent,
                                comments: pinboardEvent.comments?.map(comment => {
                                    if (comment.id === roomEventCommentId) {
                                        return { ...comment, show_preview: response.data.show_preview }
                                    }
                                    return comment
                                }),
                            })
                        )

                        break
                    case "post":
                        dispatch(setRoomPostData({ ...selectedPost, show_preview: response.data.show_preview }))
                        dispatch(setPostStatus({ postsStatus: true, singularPostStatus: false, postedInRoomStatus: false }))
                        break
                    case "comment":
                        let updatedPosts = [...posts]
                        const idx = posts.findIndex(p => p.post_comments.findIndex(c => c.id == roomCommentId))
                        if(idx > -1){
                            updatedPosts[idx] = {...posts[idx],post_comments: posts[idx].post_comments?.map(comment => {
                                if (comment.id === roomCommentId) {
                                    return { ...comment, show_preview: response.data.show_preview }
                                }
                                return comment
                            })}
                            dispatch(setRoomPostsData(updatedPosts))
                        }

                        dispatch(
                            setRoomPostData({
                                ...posts[idx],
                                post_comments: posts[idx].post_comments?.map(comment => {
                                    if (comment.id === roomCommentId) {
                                        return { ...comment, show_preview: response.data.show_preview }
                                    }
                                    return comment
                                }),
                            })
                        )
                        
                        dispatch(
                            setRoomPostData({
                                ...selectedPost,
                                post_comments: selectedPost.post_comments?.map(comment => {
                                    if (comment.id === roomCommentId) {
                                        return { ...comment, show_preview: response.data.show_preview }
                                    }
                                    return comment
                                }),
                            })
                        )
                        break
                }
                dispatch(setModalData({ open: false, deleteLinkPreview: false }))
            }
        } catch (error: any) {
            console.log(error)
        }
    }
    const onLinkDelete = (e: any) => {
        linkPreviewPostRequest()
    }

    const onModalClose = () => {
        dispatch(setModalData({ open: false, deleteLinkPreview: false }))
    }

    useEffect(() => {
        return () => {
            dispatch(setRoomPostId(0))
            dispatch(setRoomCommentId(0))
            dispatch(setRoomEventCommentId(0))
        }
    }, [])

    return (
        <div className="delete-room-modal">
            <div className="delete-room-modal__content">
                <div className="delete-room-modal__upper-panel">
                    <div className="delete-room-modal__title">{translation.deletePost}?</div>
                    <div onClick={onModalClose} className="delete-room-modal__close-icon">
                        <CloseIcon />
                    </div>
                </div>
                <div className="delete-room-modal__divider"></div>
                <div className="delete-room-modal__middle-panel">
                    <div className="delete-room-modal__text">{translation.areYouSureToDeletePreview}</div>
                    <div className="delete-room-modal__delete-icon"></div>
                </div>
                <div className="delete-room-modal__button-container">
                    <div className="delete-room-modal__button">
                        <Button
                            onClick={onLinkDelete}
                            type="submit"
                            className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-red button--hover-brighter button--cursor-pointer button--border-radius-05 "
                        >
                            {translation.delete}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LinkPreviewDeleteModal
