import { createSlice, PayloadAction, combineReducers } from "@reduxjs/toolkit"
import { OrganizationEmployeeType } from "../../../../Types/OrganizationEmployeeType"

type NewMemberData = {
    employees: Array<OrganizationEmployeeType>
}

const initialState: NewMemberData = { employees: [] }

const EmployeesSlice = createSlice({
    name: "EmployeesSlice",
    initialState: initialState,
    reducers: {
        setEmployeesData(state, action: PayloadAction<Array<OrganizationEmployeeType>>) {
            state.employees = action.payload
        },
    },
})

const searchInitialState: NewMemberData = { employees: [] }

const SearchEmployeesSlice = createSlice({
    name: "SearchEmployeesSlice",
    initialState: searchInitialState,
    reducers: {
        setEmployeesSearchData(state, action: PayloadAction<Array<OrganizationEmployeeType>>) {
            state.employees = action.payload
        },
    },
})

const queryState ={query: ""}

const SearchQuerySlice = createSlice({
    name: "SearchQuerySlice",
    initialState: queryState,
    reducers: {
        setSearchQuery(state , action: PayloadAction<string>) {
            state.query = action.payload
        }
    }
})

const initialRemoveEmployeeState = { id: 0 }

const RemoveEmployeeSlice = createSlice({
    name: "RemoveEmployeeSlice",
    initialState: initialRemoveEmployeeState,
    reducers: {
        setRemoveEmployeeId(state, action: PayloadAction<number>) {
            state.id = action.payload
        },
    },
})

export const { setEmployeesData } = EmployeesSlice.actions
export const { setRemoveEmployeeId } = RemoveEmployeeSlice.actions
export const { setEmployeesSearchData } = SearchEmployeesSlice.actions
export const { setSearchQuery } = SearchQuerySlice.actions

export default combineReducers({
    employeesSlice: EmployeesSlice.reducer,
    removeEmployeeSlice: RemoveEmployeeSlice.reducer,
    searchEmployeesSlice: SearchEmployeesSlice.reducer,
    searchQuerySlice: SearchQuerySlice.reducer,
})