import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { RoleType } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import HeaderHelpMenu from "./HeaderHelpMenu"
import {
    MediumHeartIcon,
    SmallHomeIcon,
    SmallNotificationActiveIcon,
    SmallNotificationIcon,
    SmallRoomsIcon,
    SmallQuestionIcon,
    VectorUp,
    VectorDown,
    BurgerMenu,
    ChatIcon,
    SmallChatIcon,
    SmallChatIconActive,
} from "../Components/SvgIcons"
import "../../../Styles/main.scss"

function HeaderBurgerMenu() {
    const navigate = useNavigate()
    const translation = useSelector(selectTranslations)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const { id } = useParams()
    const [hasNewNotifications, setHasNewNotifications] = useState<boolean>()
    const notifications = useSelector((state: RootState) => state.UserNotificationSlice.notifications)
    const [isQuestionMenuOpen, setIsQuestionMenuOpen] = useState(false)
    const [expanded, setExpanded] = useState(false)
    let burgerMenuRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    const [burgerMenuOpen, setBurgerMenuOpen] = useState(false)
    const hasUnseenMessages = useSelector((state: RootState) => state.chatSlice.hasUnseenMessages)
    const [unseenMessages,setUnseenMessges] = useState(false)

    useEffect(() => {
        setUnseenMessges(hasUnseenMessages)
    },[hasUnseenMessages])

    const onRouteChange = (userPath: string, organizationPath: string) => {
        switch (role) {
            case RoleType.USER:
                navigate(userPath)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(organizationPath)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }
    const onNavigationToNotificationsPage = () => {
        switch (role) {
            case RoleType.USER:
                const userPath = "/notifications"
                navigate(userPath)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                const organizationPath = `/notifications/${id}`
                navigate(organizationPath)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }
    const onNavigationToDonation = () => {
        navigate("/donation")
        window.scrollTo(0, 0)
    }
    const onNavigationToMyRooms = () => {
        switch (role) {
            case RoleType.USER:
                navigate(`/all-rooms`)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(`/organization-rooms/${id!}`)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    const onNavigationToAllRooms = () => {
        switch (role) {
            case RoleType.USER:
                navigate(`/rooms`)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(`/rooms/${id!}`)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    const handleWatchVideo = () => {
        setIsQuestionMenuOpen(!isQuestionMenuOpen)
    }

    useEffect(() => {
        setHasNewNotifications(notifications.some(notification => notification.read === false))
    }, [notifications])

    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (burgerMenuOpen && burgerMenuRef.current && !burgerMenuRef.current.contains(event.target as HTMLElement)) {
                setBurgerMenuOpen(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    return (
        <div ref={burgerMenuRef}>
            <div onClick={() => setBurgerMenuOpen(!burgerMenuOpen)}>
                <BurgerMenu />
            </div>
            {burgerMenuOpen && (
                <div className="header-burger-menu">
                    <div className="header-burger-menu__item" onClick={() => onRouteChange("/dashboard", `/organization-dashboard/${id}`)}>
                        <div className="header-burger-menu__icon">
                            <SmallHomeIcon />
                        </div>
                        <div className="header-burger-menu__text">{translation.home}</div>
                    </div>
                    <div
                        onClick={() => setExpanded(!expanded)}
                        className={`header-burger-menu__item ${expanded && "header-burger-menu__item--rooms-dropdown"}`}
                    >
                        <div className="header-burger-menu__icon">
                            <SmallRoomsIcon />
                        </div>
                        <div className="header-burger-menu__text header-burger-menu__text--rooms-dropdown">{translation.rooms}</div>
                        {expanded ? (
                            <div className="header-burger-menu__icon">
                                <VectorUp />
                            </div>
                        ) : (
                            <div className="header-burger-menu__icon">
                                <VectorDown />
                            </div>
                        )}
                    </div>
                    {expanded && (
                        <div className="header-burger-menu__rooms-dropdown-content">
                            <p className="header-burger-menu__rooms-dropdown-item" onClick={onNavigationToMyRooms}>
                                {translation.myRooms}
                            </p>
                            <p className="header-burger-menu__rooms-dropdown-item" onClick={onNavigationToAllRooms}>
                                {translation.exploreRooms}
                            </p>
                        </div>
                    )}
                    <div onClick={() => navigate(`/chat`)} className="header-burger-menu__item">
                        <div className="header-burger-menu__icon">{!unseenMessages ? <SmallChatIcon /> : <SmallChatIconActive />}</div>
                        <div className="header-burger-menu__text">{translation.directChat}</div>
                    </div>
                    <div onClick={onNavigationToNotificationsPage} className="header-burger-menu__item">
                        <div className="header-burger-menu__icon">{!hasNewNotifications ? <SmallNotificationIcon /> : <SmallNotificationActiveIcon />}</div>
                        <div className="header-burger-menu__text">{translation.notifications}</div>
                    </div>
                    <div onClick={handleWatchVideo} className="header-burger-menu__item">
                        <div className="header-burger-menu__icon">{<SmallQuestionIcon />}</div>
                        <div className="header-burger-menu__text">{translation.help}</div>
                    </div>
                    <div onClick={onNavigationToDonation} className="header-burger-menu__item header-burger-menu__item--background-red">
                        <div className="header-burger-menu__icon">
                            <MediumHeartIcon />
                        </div>
                        <div className="header-burger-menu__text header-burger-menu__text--color-white">{translation.donate}</div>
                    </div>
                    {isQuestionMenuOpen && <HeaderHelpMenu />}
                </div>
            )}{" "}
        </div>
    )
}

export default HeaderBurgerMenu
