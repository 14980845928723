import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { CloseIcon } from "../../Shared/Components/SvgIcons"
import Button from "../../Shared/Primitive/Button"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { onBackToRoomNavigation } from "./onNavigation"
import { setRoomPostsData } from "./RoomPostSlice"

function RoomPostDeleteModal(props: {}) {
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const translation = useSelector(selectTranslations)
    const { roomId, id, postId } = useParams()
    const roomPostId = useSelector((state: RootState) => state.RoomPostsSlice.roomPostIdSlice.postId)
    const posts = useSelector((state: RootState) => state.RoomPostsSlice.roomPostsSlice.posts)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const postDeleteRequest = async () => {
        try {
            if (postId) {
                const respose = await HTTPService.delete(`/post/${postId}/delete/`, headers)
                if (respose.status === 204) {
                    dispatch(setModalData({ open: false, deleteRoomPost: false }))
                    dispatch(setRoomPostsData(posts.filter(post => post.id !== parseInt(postId))))
                    onBackToRoomNavigation(role, roomId!, id!, navigate)
                    dispatch(setModalData({ open: true, deletePostSuccessfully: true }))
                }
            } else {
                const respose = await HTTPService.delete(`/post/${roomPostId}/delete/`, headers)
                if (respose.status === 204) {
                    dispatch(setModalData({ open: false, deleteRoomPost: false }))
                    dispatch(setRoomPostsData(posts.filter(post => post.id !== roomPostId)))
                    dispatch(setModalData({ open: true, deletePostSuccessfully: true }))
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const onRoomPostDelete = () => {
        postDeleteRequest()
    }

    const onModalClose = () => {
        dispatch(setModalData({ open: false, deleteRoomPost: false }))
    }

    return (
        <div className="delete-room-modal">
            <div className="delete-room-modal__content">
                <div className="delete-room-modal__upper-panel">
                    <div className="delete-room-modal__title">{translation.deletePost}?</div>
                    <div onClick={onModalClose} className="delete-room-modal__close-icon">
                        <CloseIcon />
                    </div>
                </div>
                <div className="delete-room-modal__divider"></div>
                <div className="delete-room-modal__middle-panel">
                    <div className="delete-room-modal__text">{translation.areYouSureToDeletePost}</div>
                    <div className="delete-room-modal__delete-icon"></div>
                </div>
                <div className="delete-room-modal__button-container">
                    <div className="delete-room-modal__button">
                        <Button
                            onClick={onRoomPostDelete}
                            type="submit"
                            className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-red button--hover-brighter button--cursor-pointer button--border-radius-05 "
                        >
                            {translation.delete}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoomPostDeleteModal
