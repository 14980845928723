import { ResultType, RoleType } from "../../../App/enums"
import { hashtagPlaceholder, organizationProfileDefault, profileDefault, roomDefaultPhoto } from "../../../App/GlobaleVariables"
import { GlobalSearchResult } from "../../../Types/GlobalSearchResultType"

export const onNameFilter = (result: GlobalSearchResult) => {
    if (result.type === ResultType.User) {
        return result.first_name + " " + result.last_name
    }
    if (result.type === ResultType.Organization || result.type === ResultType.Room) {
        return result.name
    }
    if (result.type === ResultType.Event) {
        return result.event ? result.event.title: result.title
    }  if (result.type === ResultType.Hashtag) {
        return result.text?.charAt(0) !== "#"? "#" + result.text : result.text
    }else {
        return ""
    }
}

export const onPhotoFilter = (result: GlobalSearchResult) => {
    if (result.type === ResultType.User) {
        return result.profile_photo?.url_path || profileDefault
    }
    if (result.type === ResultType.Organization) {
        return result.photo?.url_path || organizationProfileDefault
    }
    if (result.type === ResultType.Room) {
        return result.room_photo || roomDefaultPhoto
    }
    if (result.type === ResultType.Event) {
        return result.event ? result.event.event_photo : result.event_photo
    }
    if (result.type === ResultType.Post || result.type === ResultType.Comment) {
        return result.content_type === RoleType.ORGANIZATION.toLowerCase()
            ? result.content_object?.photo?.url_path || organizationProfileDefault
            : result.content_object?.profile_photo?.url_path || profileDefault
    } 
    if (result.type === ResultType.Hashtag) {
        return hashtagPlaceholder
    } else {
        return ""
    }
}