import { useState } from "react"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { OrangeRemoveIcon } from "../../Shared/Components/SvgIcons"
import UserProfileExperience from "./MyExperience/UserProfileExperience"
import UserProfileDescription from "./UserProfileDescription/UserProfileDescription"
import UserProfileHeader from "./UserProfileHeader"
import UserProfileRooms from "./UserProfileRooms/UserProfileRooms"
import UserProfileView from "./UserProfileView"
import UserChatButton from "./UserChatButton"

function UserProfileContent() {
    const translation = useSelector(selectTranslations)
    const [isEditable, setIsEditable] = useState(true)

    const onButtonClick = () => {
        setIsEditable(!isEditable)
        window.scrollTo(0, 0)
    }

    return (
        <div className="user-profile">
            <UserProfileHeader isEditable={isEditable} isYourProfile={true} />
            <UserProfileView className={isEditable ? "view-button" : "view-header"}>
                {isEditable ? (
                    <div className="view-button__text-container" onClick={onButtonClick}>
                        <p className="view-button__text">{translation.howOthers} </p> <p className="view-button__text"> {translation.viewMyProfile}</p>
                    </div>
                ) : (
                    <div className="view-header__container">
                        <p className="view-header__text">
                            {translation.howOthers} {translation.viewMyProfile}
                        </p>
                        <div className="view-header__icon" onClick={onButtonClick}>
                            <OrangeRemoveIcon />
                        </div>
                    </div>
                )}
            </UserProfileView>
            <UserProfileDescription isEditable={isEditable} isYourProfile={true} />
            <UserProfileExperience isExpandedScreen={false} filterNumber={3} hasButton={true} isEditable={isEditable} isYourProfile={true} />
            <UserProfileRooms
                isEditable={isEditable}
                isExpandedScreen={false}
                hasSearchCategories={false}
                hasButton={true}
                hasSearch={false}
                filterNumber={3}
                isYourProfile={true}
            />
        </div>
    )
}

export default UserProfileContent
