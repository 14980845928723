import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PasswordConfirmationEnum } from "../../../App/enums"
import { PhotoType } from "../../../Types/PhotoType"
import { AdminInfoType } from "../../Organization/OrganizationProfile/OrganizationAdmins/OrganizationProfileAdminSection"
import { AccountSettings } from "../../Organization/OrganizationSettings/OrganizationSettingsInterfaces"
import { profileSettings } from "../../User/UserSettings/UserSettingsProfileServiceRequests"
import { string } from "prop-types"
import { Organization } from "../../../Types/Organization"

export interface ModalData {
    open: boolean
    settings?: boolean
    profile_photo?: boolean
    cover_photo?: boolean
    addOrganization?: boolean
    editDescription?: boolean
    editEdusiiaReasons?: boolean
    newExperience?: boolean
    editExperience?: boolean
    viewPhotoTypeModal?: PhotoType
    changePhotoTypeModal?: PhotoType
    addEvent?: boolean
    editEvent?: boolean
    removeEmployeeModal?: boolean
    addEmployeeModal?: boolean
    organizationDescription?: boolean
    organizationSettings?: boolean
    deleteRoom?: boolean
    roomInvitationLinkModal?: boolean
    reactionsModal?: {status:boolean,reaction:{ name:string,count:number, users:{id:number, first_name?:string, last_name?:string, profile_photo?: { id: number; url_path: string }}[]}[]}
    createRoom?: boolean
    editRoom?: boolean
    roomInviteModal?: boolean
    pinboardCommentsModal?: boolean
    shareEventToRoomModal?: boolean
    eventLinkModal?: boolean
    roomMembersModal?: boolean
    removeRoomMemberModal?: boolean
    goPremiumUpgradeModal?: boolean
    deleteRoomPost?: boolean
    deleteLinkPreview?: boolean
    donationBankTransfer?: boolean
    successfulCreateRoom?: boolean
    deleteRoomSuccessfully?: boolean
    editRoomSuccessfully?: boolean
    successfulCreateOrganization?: boolean
    successfulRoomMemberRemoval?: boolean
    deleteAccountSuccessfully?: boolean
    employeeRemoval?: boolean
    deleteOrganizationSuccessfully?: boolean
    newExperienceAddedSuccessfully?: boolean
    deleteExperienceSuccessfully?: boolean
    editExperienceAddedSuccessfully?: boolean
    deletePostSuccessfully?: boolean
    leaveRoomSuccessfully?: boolean
    confirmEmailMessage?: boolean
    postiveEmailVerification?: boolean
    negativeEmailVerification?: { status: boolean; message: string }
    notPremiumCreateRoomModal?: boolean
    successfullyJoinedRoom?: boolean
    errorJoiningFullRoom?: boolean
    deleteRoomComment?: boolean
    deleteEventComment?: boolean
    contactInfo?: boolean
    postiveOrganziationEmailVerification?: boolean
    userConfirmPasswordModalState?: {
        status: boolean
        setData?: profileSettings | AccountSettings
        new_owner?:number
        type: ""|PasswordConfirmationEnum.organization_delete | PasswordConfirmationEnum.organization_email | PasswordConfirmationEnum.user_email | PasswordConfirmationEnum.transfer_ownership
    }
    confirmNewEmailMessage?: boolean
    successfulUpgrade?: boolean
    addAdminModal?: boolean
    removeAdminModal?: { status: boolean; admin: AdminInfoType; removedAdmin: boolean }
    postiveNewOrganizationEmailVerification?: boolean
    postiveNewEmailVerification?: boolean
    adminRemoved?: boolean
    creatorsAndManagers?: boolean
    roomLimitModal?: { status: boolean; max_rooms: number }
    showTutorial?: boolean
    deletedEventPopUp?: boolean
    transferOwnership?:{ status:boolean; admin:AdminInfoType[];is_owner:boolean}
    successfullyOwnershipTransfer?: boolean
    leaveRoomModal?:boolean,
    leaveChatModal?:boolean,
    joinPrivateRoomModal?:{
        status: boolean,
        question: string,
        roomId: number,
        organizationName: string,
        roomType: string,
        orgId?: number}
}

const initialState: ModalData = {
    open: false,
    settings: false,
    profile_photo: false,
    cover_photo: false,
    addOrganization: false,
    editDescription: false,
    editEdusiiaReasons: false,
    newExperience: false,
    editExperience: false,
    viewPhotoTypeModal: null!,
    changePhotoTypeModal: null!,
    addEvent: false,
    editEvent: false,
    removeEmployeeModal: false,
    addEmployeeModal: false,
    organizationDescription: false,
    organizationSettings: false,
    deleteRoom: false,
    roomInvitationLinkModal: false,
    createRoom: false,
    editRoom: false,
    roomInviteModal: false,
    pinboardCommentsModal: false,
    shareEventToRoomModal: false,
    eventLinkModal: false,
    roomMembersModal: false,
    removeRoomMemberModal: false,
    goPremiumUpgradeModal: false,
    deleteRoomPost: false,
    deleteLinkPreview: false,
    donationBankTransfer: false,
    successfulCreateRoom: false,
    deleteRoomSuccessfully: false,
    errorJoiningFullRoom: false,
    editRoomSuccessfully: false,
    successfulCreateOrganization: false,
    successfulRoomMemberRemoval: false,
    deleteAccountSuccessfully: false,
    employeeRemoval: false,
    deleteOrganizationSuccessfully: false,
    newExperienceAddedSuccessfully: false,
    deleteExperienceSuccessfully: false,
    editExperienceAddedSuccessfully: false,
    deletePostSuccessfully: false,
    leaveRoomSuccessfully: false,
    confirmEmailMessage: false,
    postiveEmailVerification: false,
    negativeEmailVerification: { status: false, message: "" },
    notPremiumCreateRoomModal: false,
    successfullyJoinedRoom: false,
    deleteRoomComment: false,
    deleteEventComment: false,
    contactInfo: false,
    postiveOrganziationEmailVerification: false,
    userConfirmPasswordModalState: {
        status: false,
        setData: { first_name: "", last_name: "", email: "", password: "", confirm_password: "", location: "" },
        new_owner:0,
        type: "",
    },
    confirmNewEmailMessage: false,
    successfulUpgrade: false,
    addAdminModal: false,
    removeAdminModal: {
        status: false,
        admin: {
            first_name: "",
            id: 0,
            last_name: "",
            profile_photo: {
                id: 0,
                url_path: "",
            },
            role_id: 0,
        },
        removedAdmin: false,
    },
    postiveNewOrganizationEmailVerification: false,
    postiveNewEmailVerification: false,
    adminRemoved: false,
    creatorsAndManagers: false,
    roomLimitModal: { status: false, max_rooms: 5 },
    deletedEventPopUp: false,
    transferOwnership:{status:false, admin: [],is_owner:false},
    successfullyOwnershipTransfer:false,
    reactionsModal: {status:false,reaction:[{count:0, name:"", users:[{id:0, first_name:"", last_name:"", profile_photo: { id: 0, url_path: "" }}]}]},
    leaveRoomModal:false,
    leaveChatModal: false,
    joinPrivateRoomModal: {status: false, question: "", roomId: 0, organizationName: "", roomType: ""}
}

const ModalSlice = createSlice({
    name: "ModalSlice",
    initialState: initialState,
    reducers: {
        setModalData(state, action: PayloadAction<ModalData>) {
            state.open = action.payload.open
            state.settings = action.payload.settings
            state.profile_photo = action.payload.profile_photo
            state.cover_photo = action.payload.cover_photo
            state.addOrganization = action.payload.addOrganization
            state.editDescription = action.payload.editDescription
            state.editEdusiiaReasons = action.payload.editEdusiiaReasons
            state.newExperience = action.payload.newExperience
            state.editExperience = action.payload.editExperience
            state.reactionsModal = action.payload.reactionsModal
            state.viewPhotoTypeModal = action.payload.viewPhotoTypeModal
            state.changePhotoTypeModal = action.payload.changePhotoTypeModal
            state.addEvent = action.payload.addEvent
            state.editEvent = action.payload.editEvent
            state.removeEmployeeModal = action.payload.removeEmployeeModal
            state.addEmployeeModal = action.payload.addEmployeeModal
            state.organizationDescription = action.payload.organizationDescription
            state.organizationSettings = action.payload.organizationSettings
            state.deleteRoom = action.payload.deleteRoom
            state.roomInvitationLinkModal = action.payload.roomInvitationLinkModal
            state.createRoom = action.payload.createRoom
            state.editRoom = action.payload.editRoom
            state.roomInviteModal = action.payload.roomInviteModal
            state.pinboardCommentsModal = action.payload.pinboardCommentsModal
            state.shareEventToRoomModal = action.payload.shareEventToRoomModal
            state.eventLinkModal = action.payload.eventLinkModal
            state.roomMembersModal = action.payload.roomMembersModal
            state.removeRoomMemberModal = action.payload.removeRoomMemberModal
            state.goPremiumUpgradeModal = action.payload.goPremiumUpgradeModal
            state.deleteRoomPost = action.payload.deleteRoomPost
            state.deleteLinkPreview = action.payload.deleteLinkPreview
            state.donationBankTransfer = action.payload.donationBankTransfer
            state.successfulCreateRoom = action.payload.successfulCreateRoom
            state.deleteRoomSuccessfully = action.payload.deleteRoomSuccessfully
            state.editRoomSuccessfully = action.payload.editRoomSuccessfully
            state.successfulCreateOrganization = action.payload.successfulCreateOrganization
            state.successfulRoomMemberRemoval = action.payload.successfulRoomMemberRemoval
            state.deleteAccountSuccessfully = action.payload.deleteAccountSuccessfully
            state.employeeRemoval = action.payload.employeeRemoval
            state.deleteOrganizationSuccessfully = action.payload.deleteOrganizationSuccessfully
            state.newExperienceAddedSuccessfully = action.payload.newExperienceAddedSuccessfully
            state.deleteExperienceSuccessfully = action.payload.deleteExperienceSuccessfully
            state.editExperienceAddedSuccessfully = action.payload.editExperienceAddedSuccessfully
            state.deletePostSuccessfully = action.payload.deletePostSuccessfully
            state.leaveRoomSuccessfully = action.payload.leaveRoomSuccessfully
            state.confirmEmailMessage = action.payload.confirmEmailMessage
            state.postiveEmailVerification = action.payload.postiveEmailVerification
            state.negativeEmailVerification = action.payload.negativeEmailVerification
            state.notPremiumCreateRoomModal = action.payload.notPremiumCreateRoomModal
            state.successfullyJoinedRoom = action.payload.successfullyJoinedRoom
            state.errorJoiningFullRoom = action.payload.errorJoiningFullRoom
            state.deleteRoomComment = action.payload.deleteRoomComment
            state.deleteEventComment = action.payload.deleteEventComment
            state.contactInfo = action.payload.contactInfo
            state.postiveOrganziationEmailVerification = action.payload.postiveOrganziationEmailVerification
            state.userConfirmPasswordModalState = action.payload.userConfirmPasswordModalState
            state.confirmNewEmailMessage = action.payload.confirmNewEmailMessage
            state.successfulUpgrade = action.payload.successfulUpgrade
            state.addAdminModal = action.payload.addAdminModal
            state.removeAdminModal = action.payload.removeAdminModal
            state.postiveNewOrganizationEmailVerification = action.payload.postiveNewOrganizationEmailVerification
            state.postiveNewEmailVerification = action.payload.postiveNewEmailVerification
            state.adminRemoved = action.payload.adminRemoved
            state.creatorsAndManagers = action.payload.creatorsAndManagers
            state.roomLimitModal = action.payload.roomLimitModal
            state.showTutorial = action.payload.showTutorial
            state.deletedEventPopUp = action.payload.deletedEventPopUp
            state.transferOwnership = action.payload.transferOwnership
            state.successfullyOwnershipTransfer = action.payload.successfullyOwnershipTransfer
            state.leaveRoomModal= action.payload.leaveRoomModal
            state.leaveChatModal = action.payload.leaveChatModal
            state.joinPrivateRoomModal = action.payload.joinPrivateRoomModal
        },
    },
})

export const { setModalData } = ModalSlice.actions
export default ModalSlice.reducer
