import { configureStore } from "@reduxjs/toolkit"
import signupData from "../Module/Authentication/SignUp/SignupSlice"
import authenticationSlice from "../Module/Authentication/SignIn/SigninSlice"
import forgotPasswordError from "../Module/Authentication/ForgotPassword/ForgotPasswordSlice"
import edusiiaMatchingStatus from "../Module/Authentication/EdusiiaMatching/EdusiiaMatchingSlice"
import resetPasswordStatus from "../Module/Authentication/ResetPassword/ResetPasswordSlice"
import i18nSlice from "./i18n/i18nSlice"
import UserDataSlice from "../Module/Shared/SharedSlices/UserDataSlice"
import ExplanationVideoSlice from "../Module/Shared/SharedSlices/ExplanationVideoSlice"
import ModalSlice from "../Module/Shared/SharedSlices/ModalSlice"
import UserDeleteAccountSlice from "../Module/User/UserSettings/UserDeleteAccountSlice"
import newestMembersSlice from "../Module/Shared/SharedSlices/NewestMembersSlice"
import SponsorCardSlice from "../Module/Shared/SharedSlices/SponsorCardSlice"
import roomSlice from "../Module/Shared/SharedSlices/RoomSlice"
import WorkExperienceSlice from "../Module/Shared/SharedSlices/WorkExperienceSlice"
import profilePhotoDropdownStatuSlice from "../Module/Shared/SharedSlices/PhotoDropdownSlice"
import UserSettingsProfileErrorSlice from "../Module/User/UserSettings/UserSettingsProfileSlice"
import OrganizationsSlice from "../Module/Shared/SharedSlices/OrganizationsSlice"
import RolesSlice from "../Module/Shared/SharedSlices/RolesSlice"
import EmployeesSlice from "../Module/Organization/OrganizationProfile/OrganizationProfileEmpoyees/EmployeesSlice"
import PinboardIdSlice from "../Module/Organization/OrganizationProfile/OrganizationProfilePinboardSlice"
import RemoveRoomMemberSlice from "../Module/Room/RoomSlices/RoomMemberSlice"
import roomPostsSlice from "../Module/Room/RoomPost/RoomPostSlice"
import MemberInRoomStatusSlice from "../Module/Room/RoomSlices/MemberInRoomStatusSlice"
import MembersInRoomSlice from "../Module/Room/RoomSlices/MembersInRoomSlice"
import UserNotificationSlice from "../Module/Notifications/NotificationSlice"
import GlobalSearchSlice from "../Module/Shared/Search/GlobalSearchSlice"
import UserRoomSearchSlice from "../Module/User/UserProfile/UserProfileRooms/UserRoomSearchSlice"
import OrganizationSearchRoomsSlice from "../Module/Organization/OrganizationProfile/OrganizationRooms/OrganizationRoomsSearchSlice"
import UpgradePlanSlice from "../Module/Organization/GoPremium/UpgradePlanSlice"
import userSettingsStatus from "../Module/User/UserSettings/UserSettingsSlice"
import organizationSettingsStatus from "../Module/Organization/OrganizationSettings/OrganizationSettingsSlice"
import LoadingStatusSlice from "../Module/Shared/SharedSlices/LoadingScreenSlice"
import ContactInfoSlice from "../Module/Shared/SharedSlices/ContactInfo"
import NotFoundStatusSlice from "../Module/Shared/SharedSlices/NotFoundSlice"
import hashtagSlice from "../Module/Shared/Hashtag/HashtagSlice"
import chatSlice from "../Module/Chat/ChatSlice"

export const store = configureStore({
    reducer: {
        signupSlice: signupData,
        signinSlice: authenticationSlice,
        forgotpasswordSlice: forgotPasswordError,
        EdusiiaMatchingSlice: edusiiaMatchingStatus,
        resetPasswordSlice: resetPasswordStatus,
        i18n: i18nSlice,
        userDataSlice: UserDataSlice,
        explanationVideoSlice: ExplanationVideoSlice,
        modalSlice: ModalSlice,
        roomSlice: roomSlice,
        workExperienceSlice: WorkExperienceSlice,
        deleteAccount: UserDeleteAccountSlice,
        userSettingsProfileSlice: UserSettingsProfileErrorSlice,
        newMembers: newestMembersSlice,
        sponsorSlice: SponsorCardSlice,
        photoDropdownSlice: profilePhotoDropdownStatuSlice,
        organizationsSlice: OrganizationsSlice,
        rolesSlice: RolesSlice,
        employeesSlice: EmployeesSlice,
        pinboardIdSlice: PinboardIdSlice,
        roomMemberSlice: RemoveRoomMemberSlice,
        RoomPostsSlice: roomPostsSlice,
        UserNotificationSlice: UserNotificationSlice,
        MemberInRoomStatusSlice: MemberInRoomStatusSlice,
        MembersInRoomSlice: MembersInRoomSlice,
        GlobalSearchSlice: GlobalSearchSlice,
        UserRoomSearchSlice: UserRoomSearchSlice,
        OrganizationSearchRoomsSlice: OrganizationSearchRoomsSlice,
        UpgradePlanSlice: UpgradePlanSlice,
        UserSettingsStatusSlice: userSettingsStatus,
        OrganziationSettingsStatusSlice: organizationSettingsStatus,
        LoadingStatusSlice: LoadingStatusSlice,
        ContactInfoSlice: ContactInfoSlice,
        NotFoundStatusSlice: NotFoundStatusSlice,
        hashtagSlice:hashtagSlice,
        chatSlice:chatSlice,
    },
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch


