import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { UserDeleteAccountErrorResponseType } from "../../../Types/Responses/UserDeleteAccountErrorResponseType"

const initialState = { message: {}, status: 0 } as UserDeleteAccountErrorResponseType

const UserDeleteAccountErrorSlice = createSlice({
    name: "deleteAccountError",
    initialState: initialState,
    reducers: {
        getUserDeleteAccountError(state, action: PayloadAction<UserDeleteAccountErrorResponseType>) {
            state.message = action.payload.message
            state.status = action.payload.status
        },
    },
})

export const { getUserDeleteAccountError } = UserDeleteAccountErrorSlice.actions
export default UserDeleteAccountErrorSlice.reducer
