import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { RoleType } from "../../App/enums"
import { not_found } from "../../App/GlobaleVariables"
import { selectTranslations } from "../../App/i18n/i18nSlice"
import { RootState } from "../../App/store"
import BackgroundWrapper from "../Shared/Components/BackgroundWrapper"
import DonateButton from "../Shared/Components/DonateButton"
import IndividualProfileCard from "../Shared/Components/IndividualProfileCard"
import ModalGroup from "../Shared/Components/ModalGroup"
import OrganizationProfileCard from "../Shared/Components/OrganizationProfileCard"
import RoomDropdown from "../Shared/Components/RoomDropdown"
import SponsorCard from "../Shared/Components/SponsorCard"
import Header from "../Shared/Header/Header"
import Footer from "../Shared/Layout/Footer"
import LoadingScreen from "../Shared/Layout/LoadingScreen"
import MainLayout from "../Shared/Layout/MainLayout"
import NewestMembersCard from "../Shared/NewestMembers/NewestMemberCard"
import NewestOrganizationsCard from "../Shared/NewestOrganizations/NewestOrganizations"
import UserProfileExperience from "../User/UserProfile/MyExperience/UserProfileExperience"
import UserProfileDescription from "../User/UserProfile/UserProfileDescription/UserProfileDescription"
import UserProfileHeader from "../User/UserProfile/UserProfileHeader"
import UserProfileRooms from "../User/UserProfile/UserProfileRooms/UserProfileRooms"
import { SearchIcon } from "../Shared/Components/SvgIcons"
import UserChatButton from "../User/UserProfile/UserChatButton"

function OtherUserPofileScreen() {
    const translation = useSelector(selectTranslations)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const { userId, id } = useParams()
    const [loading, setLoading] = useState(true)
    const donationLink = useSelector((state: RootState) => state.UserSettingsStatusSlice.userDonationLinkSlice.link)
    const navigate = useNavigate()

    const onSeeAllEventsClick = () => {
        switch (role) {
            case RoleType.ORGANIZATION:
                navigate(`/all-premium-events/${id}`)
                break
            case RoleType.USER:
                navigate(`/all-premium-events`)
                break
            default:
                break
        }
    }

    useEffect(() => {
        setLoading(true)
        setTimeout(() => setLoading(false), 1000)
    }, [userId])

    return (
        <>
            {loading ? (
                <LoadingScreen />
            ) : (
                <BackgroundWrapper>
                    <Header />
                    <MainLayout
                        leftPanelFirstComponent={role === RoleType.USER ? <OrganizationProfileCard /> : <IndividualProfileCard />}
                        leftPanelSecondComponent={
                            role === RoleType.USER ? <RoomDropdown type={RoleType.USER} /> : <RoomDropdown type={RoleType.ORGANIZATION} />
                        }
                        leftPanelForthComponent={role === RoleType.USER && translation.newestOrganizations}
                        leftPanelFifthComponent={role === RoleType.USER ? <NewestOrganizationsCard /> : null}
                        RightPanelFirstComponent={role === RoleType.USER ? donationLink === not_found ? <DonateButton /> : null : null}
                        RightPanelSecondComponent={translation.newsMembersText}
                        RightPanelThirdComponent={<NewestMembersCard otherUserId={parseInt(userId!)} />}
                        RightPanelForthComponent={
                            <div className="main-layout__sponsored-title">
                                {translation.sponsoredText}
                                <div onClick={onSeeAllEventsClick} className="main-layout__sponsored-icon">
                                    <SearchIcon color="dark-blue" />
                                </div>
                            </div>
                        }
                        RightPanelFifthComponent={<SponsorCard />}
                        overflowModifier={true}
                    >
                        <div className="other-user-profile">
                            <UserProfileHeader isEditable={false} isYourProfile={false} />
                            {role == RoleType.USER && (
                                <UserChatButton userId={userId}></UserChatButton>
                            )}
                            <UserProfileDescription isEditable={false} isYourProfile={false} />
                            <UserProfileExperience isExpandedScreen={false} filterNumber={3} hasButton={true} isEditable={false} isYourProfile={false} />
                            <UserProfileRooms
                                isExpandedScreen={false}
                                hasSearchCategories={false}
                                hasButton={true}
                                hasSearch={false}
                                filterNumber={3}
                                isYourProfile={false}
                                isEditable={false}
                            />
                        </div>
                    </MainLayout>
                    <Footer />
                    <ModalGroup />
                </BackgroundWrapper>
            )}
        </>
    )
}

export default OtherUserPofileScreen
