import { number } from "prop-types"
import { HeartIcon, SmallChatIconWhite } from "../../Shared/Components/SvgIcons"
import Button from "../../Shared/Primitive/Button"
import { CreateConversationPostRequest, chatGetByPartnerIdRequest, chatListGetRequest } from "../../Chat/ChatServiceRequest"
import { Conversation, ConversationMember } from "../../../Types/Conversation"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../App/store"
import { setActiveConversation, setConversationList } from "../../Chat/ChatSlice"
import { useNavigate } from "react-router-dom"

interface divProp extends React.AllHTMLAttributes<HTMLDivElement> {}

function UserChatButton(props: { userId?: string }) {
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const chatPartnerId: number = Number(props.userId)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onChatButtonClick = () => {
        chatGetByPartnerIdRequest(chatPartnerId).then(response => {
            if (response.status === 200) {
                dispatch(setConversationList([response.data]))
                dispatch(setActiveConversation(response.data.id))
                navigate("/chat")
            }
        }).catch(error => {
            if(error.response.status === 404){
                CreateConversationPostRequest(chatPartnerId, userDataState.id).then(conv => {
                    chatListGetRequest().then(response => {
                        if (response.status === 200) {
                            dispatch(setConversationList(response.data.results))
                            let newConversation = response.data.results.filter((conversation: Conversation) => conversation.id == conv.data.conversation)[0]
                            dispatch(setActiveConversation(newConversation.id))
                        }
                    })
                })
                navigate("/chat")}
            
        })
    }

    return (
        <div>
            <div className={`chat-button-desktop`}>
                <Button
                    className=" button button--border-radius-09 button--color-green button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-medium button--font-weight-700 "
                    onClick={() => onChatButtonClick()}
                >
                    <div className="chat-button__content">
                        <SmallChatIconWhite /> <div className="chat-button__spacer">Chat</div>
                    </div>
                </Button>
            </div>

            <div className={`chat-button-mobile`}>
                <Button
                    className=" button button--border-radius-09 button--color-green button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-medium button--font-weight-700 "
                    onClick={() => onChatButtonClick()}
                >
                    <div className="chat-button__content">
                        <SmallChatIconWhite /> <div className="chat-button__spacer">Chat</div>
                    </div>
                </Button>
            </div>
        </div>
    )
}

export default UserChatButton
