import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RoleType } from "../../../../App/enums"
import { not_found } from "../../../../App/GlobaleVariables"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import { RootState } from "../../../../App/store"
import BackgroundWrapper from "../../../Shared/Components/BackgroundWrapper"
import DonateButton from "../../../Shared/Components/DonateButton"
import ModalGroup from "../../../Shared/Components/ModalGroup"
import OrganizationProfileCard from "../../../Shared/Components/OrganizationProfileCard"
import RoomDropdown from "../../../Shared/Components/RoomDropdown"
import SponsorCard from "../../../Shared/Components/SponsorCard"
import Header from "../../../Shared/Header/Header"
import Footer from "../../../Shared/Layout/Footer"
import LoadingScreen from "../../../Shared/Layout/LoadingScreen"
import MainLayout from "../../../Shared/Layout/MainLayout"
import NewestMembersCard from "../../../Shared/NewestMembers/NewestMemberCard"
import NewestOrganizationsCard from "../../../Shared/NewestOrganizations/NewestOrganizations"
import UserProfileHeader from "../UserProfileHeader"
import UserProfileRooms from "./UserProfileRooms"
import { useNavigate, useParams } from "react-router-dom"
import { SearchIcon } from "../../../Shared/Components/SvgIcons"

function UserProfileRoomsScreen() {
    const translation = useSelector(selectTranslations)
    const [loading, setLoading] = useState(true)
    const donationLink = useSelector((state: RootState) => state.UserSettingsStatusSlice.userDonationLinkSlice.link)
    const { id } = useParams()
    const navigate = useNavigate()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)

    const onSeeAllEventsClick = () => {
        switch (role) {
            case RoleType.ORGANIZATION:
                navigate(`/all-premium-events/${id}`)
                break
            case RoleType.USER:
                navigate(`/all-premium-events`)
                break
            default:
                break
        }
    }

    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    }, [])
    return (
        <>
            {loading ? (
                <LoadingScreen />
            ) : (
                <BackgroundWrapper>
                    <Header />
                    <MainLayout
                        leftPanelFirstComponent={<OrganizationProfileCard />}
                        leftPanelSecondComponent={<RoomDropdown type={RoleType.USER} />}
                        leftPanelForthComponent={translation.newestOrganizations}
                        leftPanelFifthComponent={<NewestOrganizationsCard />}
                        RightPanelFirstComponent={donationLink === not_found ? <DonateButton /> : null}
                        RightPanelSecondComponent={translation.newsMembersText}
                        RightPanelThirdComponent={<NewestMembersCard />}
                        RightPanelForthComponent={
                            <div className="main-layout__sponsored-title">
                                {translation.sponsoredText}
                                <div onClick={onSeeAllEventsClick} className="main-layout__sponsored-icon">
                                    <SearchIcon color="dark-blue" />
                                </div>
                            </div>
                        }
                        RightPanelFifthComponent={<SponsorCard />}
                        overflowModifier={true}
                    >
                        <div className="user-profile-rooms-screen">
                            <UserProfileHeader isYourProfile={true} isEditable={true} />
                            <UserProfileRooms
                                isExpandedScreen={true}
                                isYourProfile={true}
                                hasSearchCategories={true}
                                hasButton={false}
                                hasSearch={true}
                                filterNumber={1000}
                                isEditable={true}
                            />
                        </div>
                    </MainLayout>
                    <Footer />
                    <ModalGroup />
                </BackgroundWrapper>
            )}
        </>
    )
}

export default UserProfileRoomsScreen
