import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { CloseIcon } from "../../../Shared/Components/SvgIcons"
import { RootState } from "../../../../App/store"
import UserCard from "../../../Shared/Components/UserCard"
import { profileDefault } from "../../../../App/GlobaleVariables"
import { PasswordConfirmationEnum } from "../../../../App/enums"

function TransferOwnershipModal() {
    const translation = useSelector(selectTranslations)
    const admin = useSelector((state: RootState) => state.modalSlice.transferOwnership?.admin)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const is_owner = useSelector((state: RootState) => state.modalSlice.transferOwnership?.is_owner)
    const dispatch = useDispatch()
    const closeModal = () => {
        dispatch(setModalData({ open: false, transferOwnership: { status: false, admin: [], is_owner: false } }))
    }

    const onTransferClick = (admin_id: number) => {
        dispatch(
            setModalData({
                open: true,
                userConfirmPasswordModalState: { status: true, new_owner: admin_id, type: PasswordConfirmationEnum.transfer_ownership },
            })
        )
    }

    return (
        <div className="add-admin-modal">
            <div className="add-admin-modal__title-container">
                <p className="add-admin-modal__title">{translation.transferOwnership}</p>
                <div className="add-admin-modal__close-icon" onClick={closeModal}>
                    <CloseIcon />
                </div>
            </div>

            {admin?.length === 1 ? (
                <p className="add-admin-modal__text">
                    {translation.onlyOwnerTooltip1} {translation.onlyOwnerTooltip2}
                    {translation.onlyOwnerTooltip3}
                </p>
            ) : (
                <>
                    {" "}
                    <p className="add-admin-modal__small-text">
                        {translation.transferOwnershipParagraph}{" "}
                        <a href="https://edusiia.com/faq/#mitgliedschaft" target="_blank" rel="noreferrer" className="edusiiamatching-form__link">
                            FAQ
                        </a>{" "}
                        {translation.transferOwnershipParagraph2}
                    </p>{" "}
                    <div className="room-invite-modal__item-container room-invite-modal__item-container--no-scroll">
                        {admin
                            ?.filter(item => is_owner && item.id !== userDataState.id)
                            .map((item, index) => {
                                return (
                                    <div
                                        className={`room-invite-modal__item ${
                                            index === admin?.length! - 1 ? "room-invite-modal__item--no-border" : "room-invite-modal__item--border"
                                        }`}
                                        key={item.id}
                                    >
                                        <UserCard
                                            imageSrc={item.profile_photo?.url_path || profileDefault}
                                            text={item.first_name + " " + item.last_name}
                                            subtext={""}
                                            profileImageStyle="image image__profile image__profile--fit image__profile--size-small "
                                            userCardStyle={"room-invite-modal-item"}
                                            leftPanelStyle={"left-panel"}
                                            middlePanelStyle={"middle-panel"}
                                            textStyleContainer={"text-container"}
                                            textStyle={"text"}
                                            textStyleMore25={"text"}
                                            subtextStyle={"subtext"}
                                            rightPanelStyle={"right-panel"}
                                            rightTextStyle={""}
                                            rightText={
                                                <div
                                                    className={`room-invite-modal__invite-button room-invite-modal__invite-button--grey`}
                                                    onClick={() => onTransferClick(item.id)}
                                                >
                                                    {translation.add}
                                                </div>
                                            }
                                        />
                                    </div>
                                )
                            })}
                    </div>
                </>
            )}
        </div>
    )
}

export default TransferOwnershipModal
