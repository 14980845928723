import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { LeftArrow } from "../Components/SvgIcons"
import LoadingScreen from "./LoadingScreen"
import Button from "../Primitive/Button"
import BackgroundWrapper from "../Components/BackgroundWrapper"

export enum OrderType {
    donate = "donate",
    go_premium = "go_premium",
}

function SuccessfulOrder() {
    const [orderType, setOrderType] = useState<OrderType.donate | OrderType.go_premium>(OrderType.go_premium)
    const translation = useSelector(selectTranslations)
    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    let params = new URLSearchParams(location.search)
    const contractId = params.get("contractId")

    const billwerkPostRequest = useCallback(() => {
        return HTTPService.post("/user-or-org-plan/", { contract_id: contractId }, headers)
    }, [contractId])

    useEffect(() => {
        billwerkPostRequest()
            .then(response => {
                if (response.status === 200) {
                    setOrderType(response.data.plan_type)
                    setLoading(false)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [billwerkPostRequest])

    return loading ? (
        <LoadingScreen />
    ) : (
        <BackgroundWrapper>
            <div className="successful-order">
                <img
                    src={orderType === OrderType.donate ? "/edusiia_illustration_spende.png" : "/edusiia_illustration_premium.png"}
                    alt="donate"
                    className={orderType === OrderType.donate ? "successful-order__donate-image" : "successful-order__premium-image"}
                />
                {orderType === OrderType.donate && <p className="successful-order__red-text">{translation.thankYouForTheSupport} </p>}
                <p className="successful-order__text">{orderType === OrderType.donate ? translation.successfulEdufan : translation.goPremiumSuccessful}</p>
                <div className="no-permission__button">
                    <Button
                        className="button button--color-blue button--hover-brighter button--cursor-pointer button--border-radius-05 button--text-color-white button--font-weight-700"
                        onClick={() => navigate("/dashboard")}
                    >
                        <LeftArrow />
                        {translation.dashboard}
                    </Button>
                </div>
            </div>
        </BackgroundWrapper>
    )
}

export default SuccessfulOrder
