import { useSelector } from "react-redux"
import { NotificationFrequency, NotificationSettings } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { OrganizationEmailType, UserEmailType } from "../../../Types/Notification"
import RadioButton from "../Primitive/RadioButton"

function EmailNotification(props: {
    emailState: { value_none: boolean; value_daily: boolean; value_weekly: boolean }
    setEmailState: Function
    type?: string
    data?: UserEmailType | OrganizationEmailType
}) {
    const translation = useSelector(selectTranslations)

    const radioGroupHandler = (frequency: string) => {
        switch (props.type) {
            case NotificationSettings.unseen_chat_messages:
                props.setEmailState({
                    ...props.data,
                    unseen_chat_messages: {
                        value_none: NotificationFrequency.none === frequency,
                        value_daily: NotificationFrequency.daily === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.comment:
                props.setEmailState({
                    ...props.data,
                    comment: {
                        value_none: NotificationFrequency.none === frequency,
                        value_daily: NotificationFrequency.daily === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.commented_on_same_post:
                props.setEmailState({
                    ...props.data,
                    commented_on_same_post: {
                        value_none: NotificationFrequency.none === frequency,
                        value_daily: NotificationFrequency.daily === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.room_invitation:
                props.setEmailState({
                    ...props.data,
                    room_invitation: {
                        value_none: NotificationFrequency.none === frequency,
                        value_daily: NotificationFrequency.daily === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.room_invitation_reply:
                props.setEmailState({
                    ...props.data,
                    room_invitation_reply: {
                        value_none: NotificationFrequency.none === frequency,
                        value_daily: NotificationFrequency.daily === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.room_join_request:
                props.setEmailState({
                    ...props.data,
                    room_join_request: {
                        value_none: NotificationFrequency.none === frequency,
                        value_daily: NotificationFrequency.daily === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.room_join_request_reply:
                props.setEmailState({
                    ...props.data,
                    room_join_request_reply: {
                        value_none: NotificationFrequency.none === frequency,
                        value_daily: NotificationFrequency.daily === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.add_admin:
                props.setEmailState({
                    ...props.data,
                    add_admin: {
                        value_none: NotificationFrequency.none === frequency,
                        value_daily: NotificationFrequency.daily === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.remove_admin:
                props.setEmailState({
                    ...props.data,
                    remove_admin: {
                        value_none: NotificationFrequency.none === frequency,
                        value_daily: NotificationFrequency.daily === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.event_comment:
                props.setEmailState({
                    ...props.data,
                    event_comment: {
                        value_none: NotificationFrequency.none === frequency,
                        value_daily: NotificationFrequency.daily === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.new_post_in_room:
                props.setEmailState({
                    ...props.data,
                    new_post_in_room: {
                        value_none: NotificationFrequency.none === frequency,
                        value_daily: NotificationFrequency.daily === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.admin_reply:
                props.setEmailState({
                    ...props.data,
                    admin_reply: {
                        value_none: NotificationFrequency.none === frequency,
                        value_daily: NotificationFrequency.daily === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.new_member:
                props.setEmailState({
                    ...props.data,
                    new_member: {
                        value_none: NotificationFrequency.none === frequency,
                        value_daily: NotificationFrequency.daily === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            case NotificationSettings.tags:
                props.setEmailState({
                    ...props.data,
                    tags: {
                        value_none: NotificationFrequency.none === frequency,
                        value_daily: NotificationFrequency.daily === frequency,
                        value_weekly: NotificationFrequency.weekly === frequency,
                    },
                })
                break
            default:
                break
        }
    }

    return (
        <div className="email-notification">
            <div className="email-notification__radio-container">
                <p className="email-notification__radio-label">{translation.none}</p>
                <RadioButton
                    modifier="radio__span--dark"
                    name="web-notification"
                    value={props.emailState.value_none}
                    onClick={() => radioGroupHandler(NotificationFrequency.none)}
                />
            </div>
            <div className="email-notification__radio-container">
                <p className="email-notification__radio-label">{translation.daily} </p>
                <RadioButton
                    modifier="radio__span--dark"
                    name="web-notification"
                    value={props.emailState.value_daily}
                    onClick={() => radioGroupHandler(NotificationFrequency.daily)}
                />
            </div>
            <div className="email-notification__radio-container">
                <p className="email-notification__radio-label"> {translation.weekly} </p>
                <RadioButton
                    modifier="radio__span--dark"
                    name="web-notification"
                    value={props.emailState.value_weekly}
                    onClick={() => radioGroupHandler(NotificationFrequency.weekly)}
                />
            </div>
        </div>
    )
}

export default EmailNotification
