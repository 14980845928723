import { ArticleCardData } from "./ArticleCardData"
import Image from "../../Shared/Primitive/Image"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { useNavigate, useParams } from "react-router-dom"
import { RootState } from "../../../App/store"
import { RoleType } from "../../../App/enums"

function ArticleCard() {
    const { id } = useParams()
    const navigate = useNavigate()
    const translation = useSelector(selectTranslations)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)

    const navigateToPost = () => {
        switch (role) {
            case RoleType.USER:
                navigate(`${ArticleCardData.redirect_url}`)
                break
            case RoleType.ORGANIZATION:
                navigate(`/room-post/${id}/82/813`)
                break
            default:
                break
        }
    }
    return (
        <div>
            <div className="article-card__title">
                <Image
                    src={ArticleCardData.organization.photo}
                    className="article-card__organization-image"
                    onClick={() => {
                        navigate(`/organization-profile/${ArticleCardData.organization.id}`)
                    }}
                />
                {translation.educationNews}
            </div>
            <div className="article-card__content" onClick={navigateToPost}>
                <p className="article-card__article-title"> {ArticleCardData.title}</p>

                <div className="article-card__article-content">
                    <div className="article-card__text">
                        {ArticleCardData.description.length > 250 ? ArticleCardData.description!.substring(0, 250) + "..." : ArticleCardData.description}
                    </div>
                    <p className="article-card__text article-card__text--bold">{translation.readMore}</p>

                    <div className="article-card__article-image">
                        <Image src={ArticleCardData.photo} className="article-card__image" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArticleCard
