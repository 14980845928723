import React from "react"
import "../../../Styles/main.scss"

function TextInput(
    props: {
        textinputsize: "extra-small" | "small" | "small-40" | "medium-40" | "medium" | "large-40" | "large" | "large-42 "
        isdisabled?: boolean
    } & React.AllHTMLAttributes<HTMLInputElement>
) {
    const inputProps: React.HTMLAttributes<HTMLInputElement> = { ...props }

    return (
        <input
            data-testid="text-input"
            {...inputProps}
            className={`text-input text-input--focus text-input--${props.textinputsize} ${props.isdisabled ? "text-input--disabled" : undefined}`}
        />
    )
}

export default TextInput
