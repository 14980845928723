import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState = { isVideoVisible: false }

const ExplanationVideoSlice = createSlice({
    name: "ExplanationVideo",
    initialState: initialState,
    reducers: {
        setVideoVisible(state, action: PayloadAction<boolean>) {
            state.isVideoVisible = action.payload
        },
    },
})
export const { setVideoVisible } = ExplanationVideoSlice.actions
export default ExplanationVideoSlice.reducer
