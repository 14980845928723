import React, { MouseEventHandler } from "react"
import { useSelector } from "react-redux"
import { eventDescriptionMaxLength } from "../../../App/GlobaleVariables"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import Button from "../Primitive/Button"
import CharacterCounter from "./CharacterCounter"
import { CloseIcon } from "./SvgIcons"

function EditDescriptionModal(props: {
    closeModal: () => void
    title: string
    description: any
    setDescription: any
    onSubmit: MouseEventHandler<HTMLButtonElement>
}) {
    const translation = useSelector(selectTranslations)
    return (
        <form className="user-profile-description-edit-modal">
            <div className="user-profile-description-edit-modal__title-container">
                <div className="user-profile-description-edit-modal__title">{props.title}</div>
                <div onClick={props.closeModal} className="user-profile-description-edit-modal__close-icon">
                    <CloseIcon />
                </div>
            </div>

            <div className="user-profile-description-edit-modal__max-character-container">
                <textarea
                    name="description"
                    value={props.description}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => props.setDescription(event.target.value)}
                    placeholder={translation.description}
                    rows={50}
                    cols={50}
                    maxLength={eventDescriptionMaxLength}
                    className="user-profile-description-edit-modal__input"
                />
                <CharacterCounter
                    current={props.description?.length! === undefined ? 0 : props.description?.length!}
                    max={eventDescriptionMaxLength}
                    modifier="margin-right"
                />
            </div>

            <div className="user-profile-description-edit-modal__button-container">
                <div className="user-profile-description-edit-modal__button">
                    <Button
                        onClick={props.onSubmit}
                        type="submit"
                        className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-gold button--hover-brighter button--cursor-pointer button--border-radius-05 "
                    >
                        {translation.save}
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default EditDescriptionModal
