import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SponsorData } from "../../../Types/SponsorData"
import { SponsorResult } from "../../../Types/SponsorResult"

const initialState = { events: [] } as SponsorData

const SponsorCardSlice = createSlice({
    name: "SponsorCardSlice",
    initialState: initialState,
    reducers: {
        setSponsorCardData(state, action: PayloadAction<Array<SponsorResult>>) {
            state.events = action.payload
        },
    },
})

interface EventId {
    id: number
}

const idState: EventId = { id: 0 }

const EventIdSlice = createSlice({
    name: "EventIdSlice",
    initialState: idState,
    reducers: {
        getEventId(state, action: PayloadAction<number>) {
            state.id = action.payload
        },
    },
})

const initialEventState = { event: {} as SponsorResult }

const SingularEventSlice = createSlice({
    name: "SingularEventSlice",
    initialState: initialEventState,
    reducers: {
        setSingularEventData(state, action: PayloadAction<SponsorResult>) {
            state.event = action.payload
        },
    },
})

interface EventStatus {
    status: boolean
}

const eventStatusState: EventStatus = { status: true }

const EventStatusSlice = createSlice({
    name: "EventStatusSlice",
    initialState: eventStatusState,
    reducers: {
        getEventStatus(state, action: PayloadAction<boolean>) {
            state.status = action.payload
        },
    },
})

const eventDescriptionErrorState = { message: "" }

const EventDescriptionErrorSlice = createSlice({
    name: "EventDescriptionErrorSlice",
    initialState: eventDescriptionErrorState,
    reducers: {
        getEventDescriptionErrorState(state, action: PayloadAction<string>) {
            state.message = action.payload
        },
    },
})

export const { getEventId } = EventIdSlice.actions
export const { setSponsorCardData } = SponsorCardSlice.actions
export const { setSingularEventData } = SingularEventSlice.actions
export const { getEventStatus } = EventStatusSlice.actions
export const { getEventDescriptionErrorState } =EventDescriptionErrorSlice.actions

export default combineReducers({
    SponsorCardSlice: SponsorCardSlice.reducer,
    EventIdSlice: EventIdSlice.reducer,
    SingularEventSlice: SingularEventSlice.reducer,
    EventStatusSlice: EventStatusSlice.reducer,
    EventDescriptionErrorSlice:EventDescriptionErrorSlice.reducer
})
