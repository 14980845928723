import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { PasswordConfirmationEnum } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { ownerConfirmPasswordPostRequest } from "../../Organization/OrganizationSettings/OrganizationAccountServiceRequest"
import { deleteOrganizationAccountRequest } from "../../Organization/OrganizationSettings/OrganizationDeleteAccountServiceRequest."
import { confirmPasswordPostRequest } from "../../User/UserSettings/UserSettingsProfileServiceRequests"
import Button from "../Primitive/Button"
import TextInput from "../Primitive/TextInput"
import { setModalData } from "../SharedSlices/ModalSlice"
import { CloseIcon } from "./SvgIcons"
import { transferOwnershipConfirmPasswordPostRequest } from "../../Organization/OrganizationProfile/OrganizationAdmins/AdminServiceRequests"

function PasswordModal() {
    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)
    const [password, setPassword] = useState("")
    const navigate = useNavigate()
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const { id } = useParams()
    const profileData = useSelector((state: RootState) => state.modalSlice.userConfirmPasswordModalState?.setData)
    const new_owner = useSelector((state: RootState) => state.modalSlice.userConfirmPasswordModalState?.new_owner)
    const error = useSelector((state: RootState) => state.UserSettingsStatusSlice.userPasswordConfrimErrorSlice.message)
    const type = useSelector((state: RootState) => state.modalSlice.userConfirmPasswordModalState?.type)

    const onConfirmClick = () => {
        if (type === PasswordConfirmationEnum.user_email) {
            dispatch(confirmPasswordPostRequest(password, profileData!, userDataState))
        } else if (type === PasswordConfirmationEnum.organization_email) {
            dispatch(ownerConfirmPasswordPostRequest(password, profileData!, id!, userDataState.id))
        } else if (type === PasswordConfirmationEnum.transfer_ownership) {
            dispatch(transferOwnershipConfirmPasswordPostRequest(password, new_owner!, parseInt(id!)))
        } else {
            dispatch(deleteOrganizationAccountRequest(password, id!, navigate))
        }
    }

    const onCloseIconClick = () => {
        dispatch(
            setModalData({
                open: false,
                userConfirmPasswordModalState: {
                    status: false,
                    setData: { first_name: "", last_name: "", email: "", password: "", confirm_password: "", location: "" },
                    type: "",
                },
            })
        )
    }
    return (
        <div className="password-modal">
            <div className="password-modal__title-container">
                <p className="password-modal__title">
                    {type === PasswordConfirmationEnum.transfer_ownership ? translation.transferInProgress : translation.confirmPassword}
                </p>
                <div className="password-modal__close-icon" onClick={onCloseIconClick}>
                    <CloseIcon />
                </div>
            </div>
            <div className="password-modal__input">
                {type === PasswordConfirmationEnum.transfer_ownership && <p className="password-modal__input-text">{translation.confirmTransfer}</p>}
                <TextInput
                    textinputsize={"large-42 "}
                    placeholder={translation.password}
                    type="password"
                    value={password}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setPassword(event.target.value)
                    }}
                    autoComplete="new-password"
                />
            </div>
            <p className="password-modal__error">{error}</p>
            <div className="password-modal__button">
                <Button
                    type="submit"
                    className="button button--border-radius-05 button--color-gold button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400 "
                    onClick={onConfirmClick}
                >
                    {translation.confirm}
                </Button>
            </div>
        </div>
    )
}

export default PasswordModal
