import React, { useEffect, useState } from "react"
import "../../../Styles/main.scss"
import Button from "../../Shared/Primitive/Button"
import { LoadingAnimation, LogoIcon } from "../../Shared/Components/SvgIcons"
import RadioButton from "../../Shared/Primitive/RadioButton"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../../App/store"
import { getSignupErrorStatus } from "../SignUp/SignupSlice"
import StorageService from "../../../App/StorageService"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { nikiEmail } from "../../../App/GlobaleVariables"
import { StorageKey } from "../../../App/enums"
import { getEdusiiaMatchingStatus } from "./EdusiiaMatchingSlice"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { dropdownGetRequest } from "../SignUp/SignupServiceRequests"
import Dropdown from "../../Shared/Primitive/Dropdown"

function EdusiiaMatchingForm() {
    const navigate = useNavigate()
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const statusState = useSelector((state: RootState) => state.EdusiiaMatchingSlice.edusiiaMatchingStatus)
    const signupErrorStatusState = useSelector((state: RootState) => state.signupSlice.singupErrorStatus)
    const localSignupForm = StorageService.parse(StorageService.getItem(StorageKey.signupForm) as string)
    const [dropdownItems, setDropdownItems] = useState([{ id: 0, name: "" }])
    const [edusiiaMatching, setEdusiiaMatching] = useState(true)
    const errorState = useSelector((state: RootState) => state.signupSlice.signupError)
    const [idFieldError, setIdFieldError] = useState("")
    const [isLoading, setIsLoading] = useState(true)
    const [itemId, setItemId] = useState(0)
    let validationSuccess: boolean = false


    useEffect(() => {
        let isMounted = true
        dropdownGetRequest()
            .then(response => {
                if (isMounted && response.status === 200) {
                    setDropdownItems(response.data)
                    setIsLoading(false)
                }
            })
            .catch(error => {
                console.log(error.response)
            })
        return () => {
            isMounted = false
        }
    }, [])

    const onRadioButtonClick = (agree: boolean) => {
        if (agree) {
            setEdusiiaMatching(false)
        } else {
            setEdusiiaMatching(true)
        }
    }

    const onFormValidation = () => {
        if (itemId === 0) {
            setIdFieldError(translation.thisFieldIsRequired)
        }else {
            setIdFieldError("")
            validationSuccess = true
        }
    }

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        onFormValidation()

        if (validationSuccess === true) {
            const edusiiaMatchForm = { ...localSignupForm, edusiia_matching: edusiiaMatching, id: itemId }
            StorageService.setItem(StorageKey.signupForm, JSON.stringify(edusiiaMatchForm))
            navigate("/create-organization")
        }
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        if (statusState.status) {
            navigate("/")
            window.scrollTo(0, 0)
            dispatch(getEdusiiaMatchingStatus({ status: false }))
            dispatch(setModalData({ open: true, confirmEmailMessage: true }))
        }
    }, [dispatch, navigate, statusState])

    useEffect(() => {
        if (signupErrorStatusState.status === true) {
            dispatch(getSignupErrorStatus({ status: false }))
            navigate("/signup")
            window.scrollTo(0, 0)
        }
    }, [dispatch, navigate, signupErrorStatusState])

    return (
        <form className="edusiiamatching-form" onSubmit={onFormSubmit}>
            <div className="edusiiamatching-form__logo">
                <LogoIcon />
            </div>

            <div>
                <>
                    <h2 className=" signup-form__title signup-form__title--small-margin"> {translation.howDidYouLearnAboutEdusiia}* </h2>
                </>
                {isLoading ? (
                    <div className="signup-form__loading-animation-container">
                        <LoadingAnimation type="dropdown" />
                    </div>
                ) : (
                    <div className="dropdown">
                        <Dropdown
                            itemId={itemId}
                            setItemId={setItemId}
                            items={dropdownItems}
                            placeholder={translation.pleaseSelect}
                            tabIndex={dropdownItems[0].id}
                        />
                    </div>
                )}
                <p className="p3  signup-form__error-message">
                    {idFieldError}
                    {errorState.message.reference_id}
                </p>
            </div>

            <h2 className="edusiiamatching-form__title">{translation.edusiiaMatching}*</h2>
            <div className="edusiiamatching-form__radio-conainer">
                <p className=" edusiiamatching-form__radio-text">
                    {translation.edusiiaMatchingConnectViaDirectMessage} <br /> {translation.edusiiaMatchingRevokeDecisionByEmail + " "}
                    <a className="  edusiiamatching-form__link" href={`mailto:${nikiEmail}`}>
                        {nikiEmail}
                    </a>
                    . {" " + translation.edusiiaMatchingText}
                </p>
                <div className="edusiiamatching-form__radio-yes">
                    <RadioButton modifier="radio__span--red" name="matching" value={edusiiaMatching} onClick={() => onRadioButtonClick(false)} />
                    <p className="">{translation.edusiiaMatchingYes}</p>
                </div>
                <div className="edusiiamatching-form__radio-no">
                    <RadioButton modifier="radio__span--red" name="matching" value={!edusiiaMatching} onClick={() => onRadioButtonClick(true)} />
                    <p className="">{translation.edusiiaMatchingNo} </p>
                </div>
            </div>

            <div className="signup-form__button-container">
                <div className="signup-form__button-size">
                    <Button
                        type="submit"
                        className="button button--border-radius-09 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-large button--font-weight-400  "
                    >
                        <div>{translation.next}</div>
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default EdusiiaMatchingForm
