import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { not_found } from "../../../App/GlobaleVariables"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import "../../../Styles/main.scss"
import { BackIcon, CloseIcon } from "../../Shared/Components/SvgIcons"
import useWindowSize from "../../Shared/Hooks/useWindowSize"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import PaymentSettings from "./PaymentSettings"
import UserDeleteAccount from "./UserDeleteAccount"
import UserNotificationsSettings from "./UserNotificationsSettings"
import UserSettingsPrivacy from "./UserSettingsPrivacy"
import UserSettingsProfile from "./UserSettingsProfile"
import { resetUserSettingsProfileError } from "./UserSettingsProfileSlice"
import { getUserNotificationsSettingsState, getUserPaymentSettingsState, getUserPrivacySettingsState, getUserProfileSettingsState } from "./UserSettingsSlice"
import { getUserDeleteAccountError } from "./UserDeleteAccountSlice"

function UserSettingsModal() {
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const screenWidth = useWindowSize()
    const breakpoint = 768
    const profile = useSelector((state: RootState) => state.UserSettingsStatusSlice.userSettingsStatus.status)
    const isHashtag = useSelector((state: RootState) => state.UserSettingsStatusSlice.userSettingsStatus.isHashtag)
    const notification = useSelector((state: RootState) => state.UserSettingsStatusSlice.userNotificationsSettingsStateSlice.status)
    const privacy = useSelector((state: RootState) => state.UserSettingsStatusSlice.userPrivacySettingsStateSlice.status)
    const payment = useSelector((state: RootState) => state.UserSettingsStatusSlice.userPaymentSettingsStateSlice.status)
    const [deleteAccount, setDeleteAccount] = useState(false)
    const donationLink = useSelector((state: RootState) => state.UserSettingsStatusSlice.userDonationLinkSlice.link)

    const onModalClose = () => {
        dispatch(setModalData({ open: false, settings: false, viewPhotoTypeModal: null, changePhotoTypeModal: null }))
        dispatch(getUserNotificationsSettingsState(false))
        dispatch(getUserPrivacySettingsState(false))
        dispatch(getUserPaymentSettingsState(false))
        dispatch(
            getUserDeleteAccountError({
                message: { error: "", reason: "" },
                status: 0,
            })
        )
    }

    const onProfileSettingsClick = () => {
        dispatch(getUserProfileSettingsState({ status: !profile, isHashtag: false }))
        dispatch(resetUserSettingsProfileError())
    }

    useEffect(() => {
        if (screenWidth < breakpoint && !isHashtag) {
            dispatch(getUserProfileSettingsState({ status: false, isHashtag: false }))
        }
    }, [])

    return (
        <div className="settings-modal">
            <div className="settings-modal__mobile-close-icon-container">
                <div onClick={onModalClose} className="settings-modal__mobile-close-icon">
                    <CloseIcon />
                </div>
            </div>
            <div className="settings-left-panel">
                <div className="settings-left-panel__title">
                    <div className="settings-text">{translation.settings}</div>
                </div>
                {!notification && !privacy && !deleteAccount && !payment ? (
                    <div className={`settings-left-panel__item ${profile && "settings-left-panel__item--align-start"}`} onClick={onProfileSettingsClick}>
                        <div className="settings-left-panel__icon">{profile && <BackIcon />}</div>
                        <div className={`settings-text ${profile && "text-position"}`}>{!profile ? translation.Profile : translation.AllSettings}</div>
                    </div>
                ) : null}
                {!profile && !privacy && !deleteAccount && !payment ? (
                    <div
                        className={`settings-left-panel__item ${notification && "settings-left-panel__item--align-start"}`}
                        onClick={() => dispatch(getUserNotificationsSettingsState(!notification))}
                    >
                        <div className="settings-left-panel__icon">{notification && <BackIcon />}</div>
                        <div className={`settings-text ${notification && "text-position"}`}>
                            {!notification ? translation.notifications : translation.AllSettings}
                        </div>
                    </div>
                ) : null}
                {!profile && !notification && !deleteAccount && !payment ? (
                    <div
                        className={`settings-left-panel__item ${privacy && "settings-left-panel__item--align-start"}`}
                        onClick={() => dispatch(getUserPrivacySettingsState(!privacy))}
                    >
                        <div className="settings-left-panel__icon">{privacy && <BackIcon />}</div>
                        <div className={`settings-text ${privacy && "text-position"}`}>{!privacy ? translation.privacy : translation.AllSettings}</div>
                    </div>
                ) : null}
                {!profile && !notification && !deleteAccount && !privacy && donationLink !== not_found ? (
                    <div className={`settings-left-panel__item`} onClick={() => dispatch(getUserPaymentSettingsState(!payment))}>
                        <div className="settings-left-panel__icon">{payment && <BackIcon />}</div>
                        <div className={`settings-text ${payment && "text-position"}`}>{!payment ? translation.payments : translation.AllSettings}</div>
                    </div>
                ) : null}
                {!profile && !notification && !privacy && !payment ? (
                    <div className={`settings-left-panel__item`} onClick={() => setDeleteAccount(!deleteAccount)}>
                        <div className={`settings-left-panel__icon`}>{deleteAccount && <BackIcon />}</div>
                        <div className={`settings-text ${deleteAccount && "text-position"}`}>
                            {!deleteAccount ? translation.DeleteAccount : translation.AllSettings}
                        </div>
                    </div>
                ) : null}
                <div
                    className={`settings-modal__mobile-settings ${
                        !deleteAccount && !privacy && !profile && !payment && "settings-modal__mobile-settings--height-none"
                    }`}
                >
                    {deleteAccount && <UserDeleteAccount />} {privacy && <UserSettingsPrivacy />} {profile && <UserSettingsProfile />}{" "}
                    {payment && <PaymentSettings />}
                </div>
            </div>
            <div className={`settings-right-panel  ${notification && "settings-right-panel--mobile"}`}>
                <div className="settings-right-panel__close-settings">
                    <div onClick={onModalClose} className="close-icon">
                        <CloseIcon />
                    </div>
                </div>
                <div className={`settings-right-panel__settings ${notification && "settings-right-panel__settings--mobile"}`}>
                    {deleteAccount && <UserDeleteAccount />}
                    {privacy && <UserSettingsPrivacy />}
                    {profile && <UserSettingsProfile />}
                    {notification && <UserNotificationsSettings />}
                    {payment && <PaymentSettings />}
                </div>
            </div>
        </div>
    )
}

export default UserSettingsModal
