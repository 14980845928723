import { NavigateFunction } from "react-router-dom";
import { RoleType } from "../../../App/enums";

export const onHashtagClick = (hashtag:string,navigate:NavigateFunction,role: keyof typeof RoleType,orgId:number)=>{
   
    switch (role) {
        case RoleType.USER:
             window.open(`/hashtag?keyword=${hashtag}`,'_blank') 
            break;
    case RoleType.ORGANIZATION:
        window.open(`/${orgId}/hashtag?keyword=${hashtag}`,'_blank') 
        break;
        default:
            break;
    }
  
}