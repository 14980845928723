import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"
interface CoverPhotoDropdownStatus {
    status: boolean
}
const initialCoverPhotoStatusState: CoverPhotoDropdownStatus = { status: false }

const CoverPhotoDropdownStatusSlice = createSlice({
    name: "coverPhotoDropdownStatus",
    initialState: initialCoverPhotoStatusState,
    reducers: {
        getCoverPhotoDropdownStatus(state, action: PayloadAction<CoverPhotoDropdownStatus>) {
            state.status = action.payload.status
        },
    },
})

interface LoadingCoverPhotoStatus {
    loading: boolean
}
const initialLoadingCoverPhotoStatusState: LoadingCoverPhotoStatus = { loading: false }

const LoadingCoverPhotoDropdownStatusSlice = createSlice({
    name: "loadingCoverPhotoDropdownStatus",
    initialState: initialLoadingCoverPhotoStatusState,
    reducers: {
        getLoadingCoverPhotoDropdownStatus(state, action: PayloadAction<LoadingCoverPhotoStatus>) {
            state.loading = action.payload.loading
        },
    },
})

interface OrganizationProfilePhotoDropdownStatus {
    status: boolean
}
const initialOrganizationProfilePhotoStatusState: OrganizationProfilePhotoDropdownStatus = { status: false }

const OrganizationProfilePhotoDropdownStatusSlice = createSlice({
    name: "profilePhotoDropdownStatus",
    initialState: initialOrganizationProfilePhotoStatusState,
    reducers: {
        getOrganizationProfilePhotoDropdownStatus(state, action: PayloadAction<OrganizationProfilePhotoDropdownStatus>) {
            state.status = action.payload.status
        },
    },
})

interface LoadingOrganizationCoverPhotoDropdownStatus {
    loading: boolean
}
const initialLoadingOrganizationCoverPhotoStatusState: LoadingOrganizationCoverPhotoDropdownStatus = { loading: false }

const LoadingOrganizationCoverPhotoDropdownStatusSlice = createSlice({
    name: "loadingCoverPhotoDropdownStatus",
    initialState: initialLoadingOrganizationCoverPhotoStatusState,
    reducers: {
        getLoadingOrganizationCoverPhotoDropdownStatus(state, action: PayloadAction<LoadingOrganizationCoverPhotoDropdownStatus>) {
            state.loading = action.payload.loading
        },
    },
})

interface OrganizationCoverPhotoDropdownStatus {
    status: boolean
}
const initialOrganizationCoverPhotoStatusState: OrganizationCoverPhotoDropdownStatus = { status: false }

const OrganizationCoverPhotoDropdownStatusSlice = createSlice({
    name: "CoverPhotoDropdownStatus",
    initialState: initialOrganizationCoverPhotoStatusState,
    reducers: {
        getOrganizationCoverPhotoDropdownStatus(state, action: PayloadAction<OrganizationCoverPhotoDropdownStatus>) {
            state.status = action.payload.status
        },
    },
})

interface LoadingOrganizationProfilePhotoDropdownStatus {
    loading: boolean
}
const initialLoadingOrganizationProfilePhotoStatusState: LoadingOrganizationProfilePhotoDropdownStatus = { loading: false }

const LoadingOrganizationProfilePhotoDropdownStatusSlice = createSlice({
    name: "loadingProfilePhotoDropdownStatus",
    initialState: initialLoadingOrganizationProfilePhotoStatusState,
    reducers: {
        getLoadingOrganizationProfilePhotoDropdownStatus(state, action: PayloadAction<LoadingOrganizationProfilePhotoDropdownStatus>) {
            state.loading = action.payload.loading
        },
    },
})

interface ProfilePhotoDropdownStatus {
    status: boolean
}
const initialProfilePhotoStatusState: ProfilePhotoDropdownStatus = { status: false }

const ProfilePhotoDropdownStatusSlice = createSlice({
    name: "profilePhotoDropdownStatus",
    initialState: initialProfilePhotoStatusState,
    reducers: {
        getProfilePhotoDropdownStatus(state, action: PayloadAction<ProfilePhotoDropdownStatus>) {
            state.status = action.payload.status
        },
    },
})

interface LoadingProfilePhotoDropdownStatus {
    loading: boolean
}
const initialLoadingProfilePhotoStatusState: LoadingProfilePhotoDropdownStatus = { loading: false }

const LoadingProfilePhotoDropdownStatusSlice = createSlice({
    name: "loadingProfilePhotoDropdownStatus",
    initialState: initialLoadingProfilePhotoStatusState,
    reducers: {
        getLoadingProfilePhotoDropdownStatus(state, action: PayloadAction<LoadingProfilePhotoDropdownStatus>) {
            state.loading = action.payload.loading
        },
    },
})

interface otherOrganizationPhotoUrl {
    url: string
}
const initialOtherOrganizationPhotoUrlState: otherOrganizationPhotoUrl = { url: "" }

const OtherOrganizationPhotoUrlSlice = createSlice({
    name: "OtherOrganizationPhotoUrlSlice",
    initialState: initialOtherOrganizationPhotoUrlState,
    reducers: {
        getOtherOrganizationPhotoUrl(state, action: PayloadAction<otherOrganizationPhotoUrl>) {
            state.url = action.payload.url
        },
    },
})

const initialErrorState = { message: ""} 
const ProfilePhotoErrorSlice = createSlice({
    name: "profilePhotoError",
    initialState: initialErrorState,
    reducers: {
        getProfilePhotoError(state, action: PayloadAction<string>) {
            state.message = action.payload
  
        },
        resetProfilePhotoError(state) {
            state.message =  initialErrorState.message 
        },
    },
})

export const { getCoverPhotoDropdownStatus } = CoverPhotoDropdownStatusSlice.actions
export const { getProfilePhotoDropdownStatus } = ProfilePhotoDropdownStatusSlice.actions
export const { getLoadingCoverPhotoDropdownStatus } = LoadingCoverPhotoDropdownStatusSlice.actions
export const { getLoadingProfilePhotoDropdownStatus } = LoadingProfilePhotoDropdownStatusSlice.actions
export const { getOrganizationProfilePhotoDropdownStatus } = OrganizationProfilePhotoDropdownStatusSlice.actions
export const { getLoadingOrganizationProfilePhotoDropdownStatus } = LoadingOrganizationProfilePhotoDropdownStatusSlice.actions
export const { getOrganizationCoverPhotoDropdownStatus } = OrganizationCoverPhotoDropdownStatusSlice.actions
export const { getLoadingOrganizationCoverPhotoDropdownStatus } = LoadingOrganizationCoverPhotoDropdownStatusSlice.actions
export const { getOtherOrganizationPhotoUrl } = OtherOrganizationPhotoUrlSlice.actions
export const { getProfilePhotoError } = ProfilePhotoErrorSlice.actions
export const { resetProfilePhotoError } = ProfilePhotoErrorSlice.actions

export default combineReducers({
    coverPhotoDropdownStatusSlice: CoverPhotoDropdownStatusSlice.reducer,
    profilePhotoDropdownStatuSlice: ProfilePhotoDropdownStatusSlice.reducer,
    organizationProfilePhotoDropdownStatusSlice: OrganizationProfilePhotoDropdownStatusSlice.reducer,
    loadingOrganizationProfilePhotoDropdownStatusSlice: LoadingOrganizationProfilePhotoDropdownStatusSlice.reducer,
    loadingCoverPhotoDropdownStatusSlice: LoadingCoverPhotoDropdownStatusSlice.reducer,
    loadingProfilePhotoDropdownStatusSlice: LoadingProfilePhotoDropdownStatusSlice.reducer,
    organizationCoverPhotoDropdownStatusSlice: OrganizationCoverPhotoDropdownStatusSlice.reducer,
    loadingOrganizationCoverPhotoDropdownStatusSlice: LoadingOrganizationCoverPhotoDropdownStatusSlice.reducer,
    otherOrganizationPhotoUrlSlice: OtherOrganizationPhotoUrlSlice.reducer,
    profilePhotoErrorSlice:ProfilePhotoErrorSlice.reducer
})
