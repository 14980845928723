import { headers } from "../../../../App/Axios"
import HTTPService from "../../../../App/HTTPService"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { getUserPasswordConfirmError } from "../../../User/UserSettings/UserSettingsSlice"

export const addAdminPostRequest = (orgId: number, userId: number, setAddedUser: Function, setAddedSearchedUser: Function) => {
    HTTPService.post(`/invite-admin/`, { invited_by: orgId, invited_user: userId }, headers)
        .then(response => {
            if (response.status === 201) {
                setAddedUser(true)
                setAddedSearchedUser(true)
            }
        })
        .catch(error => {
            console.log(error)
        })
}

export const transferOwnershipPostRequest = (organization_id: number, new_owner_id: number) => async (dispatch: any) => {
    HTTPService.post(`/org/transfer-ownership/`, { organization_id: organization_id, new_owner_id: new_owner_id }, headers)
        .then(response => {
            if (response.status === 200) {
                dispatch(setModalData({ open: true, successfullyOwnershipTransfer: true }))
            }
        })
        .catch(error => {
            console.log(error)
        })
}

export const transferOwnershipConfirmPasswordPostRequest = (password: string, new_owner: number, organizationId: number) => async (dispatch: any) => {
    try {
        const response = await HTTPService.post("/confirm-existing-password/", { password: password }, headers)
        if (response.status === 200) {
            dispatch(
                setModalData({
                    open: false,
                    userConfirmPasswordModalState: {
                        status: false,
                        setData: { first_name: "", last_name: "", email: "", password: "", confirm_password: "", location: "" },
                        new_owner: 0,
                        type: "",
                    },
                })
            )

            dispatch(transferOwnershipPostRequest(organizationId, new_owner))
        }
    } catch (error: any) {
        dispatch(getUserPasswordConfirmError(error.response.data.message))
    }
}
