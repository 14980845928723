import React from "react"
import { CheckedIconBlue, CheckedIconGold, UnCheckedIcon} from "../../Shared/Components/SvgIcons"

function GoPremiumPackageInfoItem(props: { text: string; onBasic?: boolean }) {
    return (
        <div className="go-premium-package-info-item">
            <div className="go-premium-package-info-item__left-panel">
                <div className="go-premium-package-info-item__text-container">
                    <div className="go-premium-package-info-item__text">{props.text}</div>
                </div>
            </div>
            <div className="go-premium-package-info-item__right-panel">
                {props.onBasic ? (
                    <div className="go-premium-package-info-item__icon">
                        <CheckedIconBlue />
                    </div>
                ) : (
                    <div className="go-premium-package-info-item__icon">
                        <UnCheckedIcon />
                    </div>
                )}
                <div className="go-premium-package-info-item__icon">
                    <CheckedIconGold />
                </div>
            </div>
        </div>
    )
}

export default GoPremiumPackageInfoItem
