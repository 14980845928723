import { useDispatch, useSelector } from "react-redux"
import { LabelType } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { CloseIcon, CreatorsAndManagers } from "../../Shared/Components/SvgIcons"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import RoomUserCard from "./RoomUserCard"

function CreatorsAndManagersModal() {
    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)

    const onModalClose = () => {
        dispatch(setModalData({ open: false, roomMembersModal: false }))
    }

    return (
        <div className="room-creators-manager-modal">
            <div className="room-members-modal__header">
                <div className="room-members-modal__text">
                    <div className="room-members-modal__mobile-icon">
                        <CreatorsAndManagers />
                    </div>
                    {translation.creatorsAndManagers}
                </div>
                <div className="room-members-modal__close-icon room-members-modal__close-icon--mobile-icon" onClick={onModalClose}>
                    <CloseIcon />
                </div>
            </div>
            <div className="room-creators-manager-modal__card">
                <RoomUserCard type={LabelType.ROOM_CREATOR} />

                <RoomUserCard type={LabelType.ROOM_MANAGER} />
            </div>
        </div>
    )
}

export default CreatorsAndManagersModal
