import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import "../../../../Styles/main.scss"
import Button from "../../../Shared/Primitive/Button"
import Checkbox from "../../../Shared/Primitive/Checkbox"
import { CloseIcon } from "../../../Shared/Components/SvgIcons"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import { postUserExperience } from "./UserProfileExperienceServiceRequest"
import { RootState } from "../../../../App/store"
import TextInput from "../../../Shared/Primitive/TextInput"
import { InputType } from "../../../../App/enums"
import { resetUserProfileExperienceError } from "../../../Shared/SharedSlices/WorkExperienceSlice"
import HTTPService from "../../../../App/HTTPService"
import { headers } from "../../../../App/Axios"
import { searchedOrganization } from "../../../../Types/SearchedOrganizations"

function UserProfileNewExperienceModal() {
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const userId = useSelector((state: RootState) => state.userDataSlice.id)
    const [expanded, setExpanded] = useState(false)
    const [filter, setFilter] = useState("")
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    let DropdownRef = React.useRef() as React.MutableRefObject<HTMLInputElement>

    const [newExperienceForm, setNewExperienceForm] = useState({
        work_position: "",
        company_name: "",
        start_date: null as Date | null,
        end_date: null as Date | null,
        is_in_edusiia: false,
        org_id: null as number | null,
        website_url: "" as string | undefined,
        is_current_role: false,
    })

    const [searchedOrganizations, setSearchedOrganizations] = useState<searchedOrganization[]>([])

    const getRequest = useCallback((name: string) => {
        return HTTPService.get(`/org/search?${name}`, headers)
    }, [])

    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (isDropdownOpen && DropdownRef.current && !DropdownRef.current.contains(event.target as HTMLElement)) {
                setIsDropdownOpen(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    useEffect(() => {
        if (newExperienceForm?.company_name?.length > 1) {
            setExpanded(true)
            getRequest(newExperienceForm?.company_name)
                .then(response => {
                    if (response.status === 200) {
                        setSearchedOrganizations(response.data)
                    }
                })
                .catch(error => {
                    setExpanded(false)
                })
        } else {
            setExpanded(false)
        }
    }, [getRequest, newExperienceForm?.company_name])

    const errorMessagesState = useSelector((state: RootState) => state.workExperienceSlice.workExperienceError)

    const onModalClose = () => {
        dispatch(setModalData({ open: false, newExperience: false }))
        dispatch(resetUserProfileExperienceError())
    }

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        const formattedStartDate = moment(newExperienceForm.start_date).format("YYYY-MM-DD")
        const formattedEndDate = moment(newExperienceForm.end_date).format("YYYY-MM-DD")

        if (newExperienceForm.end_date !== null) {
            dispatch(
                postUserExperience(
                    newExperienceForm.work_position,
                    newExperienceForm.company_name,
                    formattedStartDate,
                    formattedEndDate,
                    userId!,
                    newExperienceForm.org_id,
                    newExperienceForm.is_in_edusiia,
                    newExperienceForm.website_url,
                    newExperienceForm.is_current_role
                )
            )
        } else {
            dispatch(
                postUserExperience(
                    newExperienceForm.work_position,
                    newExperienceForm.company_name,
                    formattedStartDate,
                    null,
                    userId!,
                    newExperienceForm.org_id,
                    newExperienceForm.is_in_edusiia,
                    newExperienceForm.website_url,
                    newExperienceForm.is_current_role
                )
            )
        }
    }

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.type === InputType.checkbox ? event.target.checked : event.target.value
        setNewExperienceForm({ ...newExperienceForm, [event.target.name]: value })
        if (event.target.name === "company_name") {
            newExperienceForm.company_name.length > 1 && setFilter(event.target.value)
            newExperienceForm.org_id === null
                ? setNewExperienceForm({ ...newExperienceForm, company_name: event.target.value, is_in_edusiia: false })
                : setNewExperienceForm({ ...newExperienceForm, company_name: event.target.value, org_id: null })
        }
    }

    const onOrganizationClick = (organizationName: string, org_id: number) => {
        setExpanded(false)
        setNewExperienceForm({ ...newExperienceForm, company_name: organizationName, is_in_edusiia: true, org_id: org_id })
    }

    return (
        <form onSubmit={onFormSubmit} className="user-profile-edit-experience-modal">
            <div onClick={onModalClose} className="user-profile-edit-experience-modal__close-icon">
                <CloseIcon />
            </div>
            <div className="user-profile-edit-experience-modal__title-container">
                <div className="user-profile-edit-experience-modal__title">{translation.myExperience} </div>
            </div>
            <div className="user-profile-edit-experience-modal__content">
                <div className="user-profile-edit-experience-modal__double-input-container">
                    <div className="user-profile-edit-experience-modal__input-container  user-profile-edit-experience-modal__input-container--margin-right">
                        <div className="user-profile-edit-experience-modal__text">{translation.position}</div>
                        <TextInput
                            textinputsize="small-40"
                            placeholder={translation.position}
                            value={newExperienceForm.work_position}
                            name="work_position"
                            onChange={onInputChange}
                            autoComplete="off"
                        />
                        <div className="user-profile-edit-experience-modal__error-text">{errorMessagesState.message.work_position}</div>
                    </div>
                    <div className="user-profile-edit-experience-modal__input-container " ref={DropdownRef} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        <div className="user-profile-edit-experience-modal__text">{translation.companyName}</div>
                        <TextInput
                            textinputsize="small-40"
                            placeholder={translation.companyName}
                            value={newExperienceForm?.company_name}
                            onChange={onInputChange}
                            name="company_name"
                            autoComplete="off"
                        />
                        {expanded && newExperienceForm.org_id === null && isDropdownOpen && (
                            <div onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="user-profile-edit-experience-modal__input-list">
                                {searchedOrganizations
                                    ?.filter(organization => organization.name.toLowerCase().includes(filter.toLowerCase()))
                                    .map((organization, index) => {
                                        return (
                                            <div
                                                key={organization.id}
                                                className={`user-profile-edit-experience-modal__input-list_organization  
                                        ${
                                            searchedOrganizations.length - 1 === index &&
                                            "user-profile-edit-experience-modal__input-list_organization--no-border"
                                        } `}
                                                onClick={() => onOrganizationClick(organization.name, organization.id)}
                                            >
                                                <div className="user-profile-edit-experience-modal__text">{organization?.name}</div>
                                            </div>
                                        )
                                    })}
                            </div>
                        )}
                        <div className="user-profile-edit-experience-modal__error-text">{errorMessagesState.message.company_name}</div>
                    </div>
                </div>
                <div className="user-profile-edit-experience-modal__double-input-container">
                    <div className="user-profile-edit-experience-modal__input-container  user-profile-edit-experience-modal__input-container--margin-right">
                        <div className="user-profile-edit-experience-modal__text">
                            {translation.website}
                            <div className="user-profile-edit-experience-modal__text--optional">{translation.optional}</div>
                        </div>
                        <TextInput
                            textinputsize="small-40"
                            placeholder="https://"
                            value={newExperienceForm.website_url}
                            name="website_url"
                            onChange={onInputChange}
                            autoComplete="off"
                        />
                        <div className="user-profile-edit-experience-modal__error-text">{errorMessagesState.message.work_position}</div>
                    </div>
                </div>
                <div className="user-profile-edit-experience-modal__double-input-container user-profile-edit-experience-modal__double-input-container--margin-left-02 ">
                    <div className="user-profile-edit-experience-modal__date-container user-profile-edit-experience-modal__date-container--margin-right-2">
                        <div className="user-profile-edit-experience-modal__text">{translation.startDate}</div>
                        <DatePicker
                            wrapperClassName="react-datepicker-wrapper"
                            dateFormat={"yyyy-MM-dd"}
                            selected={newExperienceForm.start_date}
                            onChange={(date: Date) => setNewExperienceForm({ ...newExperienceForm, start_date: date })}
                            maxDate={newExperienceForm.end_date || new Date()}
                            placeholderText="yyyy-mm-dd"
                            showYearDropdown
                            scrollableYearDropdown
                            dropdownMode="select"
                        />
                        <div
                            className={`user-profile-edit-experience-modal__error-text
                                        ${errorMessagesState.message.start_date && "user-profile-edit-experience-modal__error-text--spacing"} `}
                        >
                            {errorMessagesState.message.start_date}
                        </div>
                    </div>

                    {!newExperienceForm.is_current_role && (
                        <div className="user-profile-edit-experience-modal__date-container user-profile-edit-experience-modal__date-container--margin-right-2">
                            <div className="user-profile-edit-experience-modal__date-container">
                                <div className="user-profile-edit-experience-modal__text">{translation.endDate}</div>
                                <DatePicker
                                    wrapperClassName="react-datepicker-wrapper"
                                    dateFormat={"yyyy-MM-dd"}
                                    selected={newExperienceForm.end_date}
                                    onChange={(date: Date) =>
                                        newExperienceForm.is_current_role
                                            ? setNewExperienceForm({ ...newExperienceForm, end_date: null })
                                            : setNewExperienceForm({ ...newExperienceForm, end_date: date })
                                    }
                                    minDate={newExperienceForm.is_current_role ? null : newExperienceForm.start_date}
                                    maxDate={new Date()}
                                    placeholderText="yyyy-mm-dd"
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                />
                                <div className="user-profile-edit-experience-modal__error-text">{errorMessagesState.message.end_date}</div>
                                <div className="user-profile-edit-experience-modal__date-input-container"></div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="user-profile-edit-experience-modal__checkbox-container">
                    <Checkbox
                        color="dark-blue"
                        checked={newExperienceForm.is_current_role}
                        onChange={() => setNewExperienceForm({ ...newExperienceForm, end_date: null, is_current_role: !newExperienceForm.is_current_role })}
                    />
                    <div className="user-profile-edit-experience-modal__checkbox-label">{translation.currentlyWorking}</div>
                </div>
            </div>
            <div className="user-profile-edit-experience-modal__custom-error-text">{errorMessagesState.message.is_current_role}</div>
            <div className="user-profile-edit-experience-modal__buttons-container user-profile-edit-experience-modal__buttons-container--flex-end">
                <div className="user-profile-edit-experience-modal__right-button">
                    <Button
                        type="submit"
                        className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-gold button--hover-brighter button--cursor-pointer button--border-radius-05 "
                    >
                        {translation.save}
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default UserProfileNewExperienceModal
