import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RoomData } from "../../../../Types/RoomData"



const searchInitialState: RoomData = { joined_rooms: [], owned_rooms: []  }

const OrganizationSearchRoomsSlice = createSlice({
    name: "OrganizationSearchRoomsSlice",
    initialState: searchInitialState,
    reducers: {
        setRoomsSearchData(state, action: PayloadAction<RoomData>) {
            state.joined_rooms = action.payload.joined_rooms
            state.owned_rooms = action.payload.owned_rooms
            state.rooms = action.payload.rooms
        },
    },
})

export const { setRoomsSearchData } = OrganizationSearchRoomsSlice.actions
export default OrganizationSearchRoomsSlice.reducer 