import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { ResultType, RoleType } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { GlobalSearchResult } from "../../../Types/GlobalSearchResultType"
import { LoadingAnimation } from "../Components/SvgIcons"
import { onSearchResultClick } from "./onSearchResultClick"
import { onPhotoFilter } from "./SearchDataFilterFunctions"
import Image from "../../Shared/Primitive/Image"
import EventPhoto from "../../../App/assets/EventPhoto.jpg"

function SearchDropdown(props: { isLoading: boolean }) {
    const translation = useSelector(selectTranslations)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const searchData = useSelector((state: RootState) => state.GlobalSearchSlice.globalSearchSlice.result)
    const location = useLocation()
    let params = new URLSearchParams(location.search)
    const searchedQuery = params.get("searchedQuery")
    const searchFilterNumber = 5
    const lastSearchDropdownIndex = searchFilterNumber - 1
    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useDispatch()

    const onNameFilter = (result: GlobalSearchResult) => {
        if (result.type === ResultType.User) {
            return result.first_name + " " + result.last_name
        }
        if (result.type === ResultType.Organization || result.type === ResultType.Room) {
            return result.name
        }
        if (result.type === ResultType.Event) {
            return result.event ? result.event?.title : result.title
        }
        if (result.type === ResultType.Post || result.type === ResultType.Comment) {
            return result.content_type === RoleType.ORGANIZATION.toLowerCase()
                ? result.content_object?.name || translation.deletedOrganization
                : result.content_object === null
                ? translation.deletedUser
                : result.content_object?.first_name + " " + result.content_object?.last_name
        }
        if (result.type === ResultType.Hashtag) {
            return result.text?.charAt(0) !== "#" ? "#" + result.text : result.text
        } else {
            return ""
        }
    }

    const onNavigationToSearchScreen = () => {
        switch (role) {
            case RoleType.USER:
                navigate(`/search-screen?searchedQuery=${searchedQuery}`)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(`/search-screen/${id!}?searchedQuery=${searchedQuery}`)
                window.scrollTo(0, 0)

                break
            default:
                break
        }
    }

    const onLastItem = (index: number) => {
        if (searchData.length < searchFilterNumber) {
            return searchData.length - 1 === index
        } else {
            return index === lastSearchDropdownIndex
        }
    }

    return (
        <div className="search-dropdown">
            {props.isLoading ? (
                <div className="search-dropdown__content">
                    <LoadingAnimation type="cover" />
                </div>
            ) : (
                <div className="search-dropdown__content">
                    {searchData &&
                        searchData!.length! > 0 &&
                        searchData!.slice(0, searchFilterNumber)?.map((result, index) => {
                            return result.type === ResultType.Hashtag && result.total_results === null ? (
                                <div className="search-dropdown__no-data-text">{`${translation.noResultThatContain} '${searchedQuery}' ${translation.wasFound}`}</div>
                            ) : (
                                <div
                                    onClick={() => onSearchResultClick(result, navigate, dispatch, role, userDataState.id, parseInt(id!))}
                                    key={result.id + result.type}
                                    className={`search-dropdown__item ${onLastItem(index) && "search-dropdown__item--no-border"} ${
                                        (result.type === ResultType.Post || result.type === ResultType.Comment) && "search-dropdown__item--post"
                                    }`}
                                >
                                    <div className="search-dropdown__left-panel">
                                        {result.type === ResultType.Event ? (
                                            <Image className="search-dropdown__image" src={onPhotoFilter(result) || EventPhoto} alt="" />
                                        ) : null}
                                        {result.type !== ResultType.Event && <Image className="search-dropdown__image" src={onPhotoFilter(result)} alt="" />}
                                    </div>
                                    <div className="search-dropdown__right-panel">
                                        <div
                                            className={`search-dropdown__text ${result.type === ResultType.Event && "search-dropdown__text--font-large"} ${
                                                (result.type === ResultType.Post || result.type === ResultType.Comment) &&
                                                !result?.content_object?.id &&
                                                "search-dropdown__text--deleted"
                                            }${result.type === ResultType.Hashtag && "search-dropdown__text--hashtag"}`}
                                        >
                                            {onNameFilter(result!)}
                                        </div>
                                        {(result.type === ResultType.Post || result.type === ResultType.Comment) && (
                                            <div className={`search-component__text search-component__text--font-size-12`}>
                                                {translation.at + " " + result.room_name!}
                                            </div>
                                        )}
                                        <div className="search-dropdown__subtext">
                                            {result.type === ResultType.User
                                                ? translation.individual
                                                : result.type === ResultType.Organization
                                                ? translation.organization
                                                : result.type === ResultType.Room
                                                ? translation.room
                                                : result.type === ResultType.Post
                                                ? translation.post
                                                : result.type === ResultType.Comment
                                                ? translation.comment
                                                : result.type === ResultType.Hashtag
                                                ? result.total_results === 1
                                                    ? result.total_results + " " + translation.result
                                                    : result.total_results + " " + translation.results
                                                : translation.event}
                                        </div>
                                        {(result.type === ResultType.User || ResultType.Organization) && (
                                            <div className="search-dropdown__tag-container">
                                                {result.hashtags?.slice(0, 2)?.map((hashtag, index) => (
                                                    <div className="search-dropdown__tag" key={index}>
                                                        {hashtag.charAt(0) !== "#" ? "#" + hashtag : hashtag}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                    {searchData && searchData!.length === 0 && searchedQuery!.length > 0 && (
                        <div className="search-dropdown__no-data-text">{`${translation.noResultThatContain} '${searchedQuery}' ${translation.wasFound}`}</div>
                    )}
                    {searchData === undefined && (
                        <div className="search-dropdown__no-data-text">{`${translation.noResultThatContain} '${searchedQuery}' ${translation.wasFound}`}</div>
                    )}
                    {searchData && searchData!.length > 0 && (
                        <div className="search-dropdown__button-container">
                            <div onClick={onNavigationToSearchScreen} className="search-dropdown__button">
                                {translation.seeAllResults}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default SearchDropdown
