import { NavigateFunction } from "react-router-dom"
import { headers } from "../../App/Axios"
import HTTPService from "../../App/HTTPService"
import { setRoomMembers } from "../Room/RoomSlices/MembersInRoomSlice"
import { setUserNotifications } from "./NotificationSlice"
import { getMembersRequest } from "./UserNotificationServiceRequest"
import { setModalData } from "../Shared/SharedSlices/ModalSlice"

export const organizationNotificationGetRequest = (organizationId: string) => {
    return HTTPService.get(`/notification/organization/${organizationId}/`, headers)
}

const organizationNotificationReadAllRequest = (organizationId: string) => {
    return HTTPService.get(`/notification/read-all-notifications/org/${organizationId}/`, headers)
}

export const organizationReadSigleNotificationRequest = (notificationId: number, organizationId: string) => {
    return HTTPService.get(`/notification/read-notification/${notificationId}/org/${organizationId}/`, headers)
}

export const onMarkAllOrganizationNotificationsAsRead = (organizationId: string) => async (dispatch: any) => {
    try {
        const response = await organizationNotificationReadAllRequest(organizationId)
        if (response.status === 200) {
            try {
                const response = await organizationNotificationGetRequest(organizationId)
                if (response.status === 200) {
                    dispatch(setUserNotifications(response.data))
                }
            } catch (error: any) {
                console.log(error)
            }
        }
    } catch (error: any) {
        console.log(error)
    }
}

export const onMarkSingleOrganizationNotificationsAsRead = (notificationId: number, organizationId: string) => async (dispatch: any) => {
    try {
        const response = await organizationReadSigleNotificationRequest(notificationId, organizationId)
        if (response.status === 200) {
            try {
                const response = await organizationNotificationGetRequest(organizationId)
                if (response.status === 200) {
                    dispatch(setUserNotifications(response.data))
                }
            } catch (error: any) {
                console.log(error)
            }
        }
    } catch (error: any) {
        console.log(error)
    }
}


const patchOrganizationInvitationStatus = (notificationId: number, data: { status: string }) => {
    return HTTPService.patch(`/org/accept-deny-invite/${notificationId}/`, data, headers)
}

export const onOrganizationRespondToInvitation = (notificationId: number, status: string, roomId: number, orgId:string,navigate?: NavigateFunction) => async (dispatch: any) => {
    const data = {
        status: status,
    }
    try {
        const response = await patchOrganizationInvitationStatus(notificationId, data)

        if(response.status === 200 && response.data.detail == "room_full"){
            dispatch(setModalData({ open: true, errorJoiningFullRoom: true }))
            return
        }

        if (response.status === 200) {
            try {
                const response = await organizationNotificationGetRequest(orgId)
                if (response.status === 200) {
                    dispatch(setUserNotifications(response.data))
                    if (data.status === "accepted") {
                        try {
                            const response = await getMembersRequest(roomId)
                            if (response.status === 200) {
                                dispatch(setRoomMembers(response.data.user))
                                navigate && navigate(`/room/${orgId}/${roomId}`)

                                if(window.location.pathname == `/room/${orgId}/${roomId}`){
                                    navigate && navigate(0)
                                }else{
                                    navigate && navigate(`/room/${orgId}/${roomId}`)
                                }
                            }
                        } catch (error: any) {}
                    }
                }
            } catch (error: any) {
                console.log(error)
            }
        }
    } catch (error: any) {
        console.log(error)
    }
}