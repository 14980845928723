import { InstagramIcon, LinkedinIcon, TwitterIcon } from "../Components/SvgIcons"
import "../../../Styles/main.scss"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"

function Footer(props: { roomfooter?: boolean }) {
    const translation = useSelector(selectTranslations)
    return (
        <div className={`footer ${props.roomfooter && "footer--display-none"}`}>
            <div className="footer__content">
                <div className="footer__links">
                    <a className="footer__item " href="https://edusiia.com/edusiia-team-ueber-uns/" target="_blank" rel="noreferrer">
                        {translation.aboutUs}
                    </a>
                    <div className="footer__line">|</div>
                    <a className="footer__item " href="https://edusiia.com/unsere-werte/" target="_blank" rel="noreferrer">
                        {translation.ourValues}
                    </a>
                    <div className="footer__line">|</div>
                    <a className="footer__item " href="https://edusiia.com/veranstaltungen/" target="_blank" rel="noreferrer">
                        {translation.eventCalendar}
                    </a>
                    <div className="footer__line">| </div>
                    <a className="footer__item " href={"https://edusiia.com/presse/"} target="_blank" rel="noreferrer">
                        {translation.supportEdusiia}
                    </a>
                    <div className="footer__line">|</div>
                    <a className="footer__item " href={"https://edusiia.com/informationen-fuer-minderjaehrige/"} target="_blank" rel="noreferrer">
                        {translation.youthProtection}
                    </a>
                    <div className="footer__line">|</div>
                    <a className="footer__item" href="https://edusiia.com/nutzungsbedingungen/" target="_blank" rel="noreferrer">
                        {translation.termsOfUse}
                    </a>
                    <div className="footer__line">|</div>
                    <a className="footer__item " href="https://edusiia.com/datenschutz/" target="_blank" rel="noreferrer">
                        {translation.privacy}
                    </a>
                    <div className="footer__line">|</div>
                    <a className="footer__item " href={"https://edusiia.com/impressum/"} target="_blank" rel="noreferrer">
                        {translation.impressum}
                    </a>
                </div>
                <div className="footer__social-media">
                    <a className="footer__linkedin-icon" href={"https://www.linkedin.com/company/edusiia/"} target="_blank" rel="noreferrer">
                        <LinkedinIcon />
                    </a>
                    <div className="footer__icon-line"></div>
                    <a className="footer__twitter-icon" href="https://twitter.com/edusiia" target="_blank" rel="noreferrer">
                        <TwitterIcon />
                    </a>
                    <div className="footer__icon-line"></div>
                    <a className="footer__twitter-icon" href="https://www.instagram.com/edusiia_de/" target="_blank" rel="noreferrer">
                        <InstagramIcon />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer
