import React, { useEffect, useCallback } from "react"
import Image from "../../Shared/Primitive/Image"
import { roomDefaultPhoto } from "../../../App/GlobaleVariables"
import { AddRoom, PremiumIconSmall } from "../../Shared/Components/SvgIcons"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { useNavigate, useParams } from "react-router-dom"
import HTTPService from "../../../App/HTTPService"
import { headers } from "../../../App/Axios"
import { User } from "../../../Types/User"
import { OrganizationRoomsResponse, UserRoomsResponse } from "../../../Types/Responses/RoomResponse"
import { setRoomsData } from "../../Shared/SharedSlices/RoomSlice"
import { isDefined } from "../../../App/Helpers/Helpers"
import { RoleType, RoomType } from "../../../App/enums"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { setPostStatus } from "../RoomPost/RoomPostSlice"

function RoomsDropdown(props: { isPremiumOrganization: boolean; ref?: React.LegacyRef<HTMLDivElement>; isOpen: Function }) {
    const translation = useSelector(selectTranslations)
    const roleState = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const roomsState = useSelector((state: RootState) => state.roomSlice.roomsSlice.rooms)
    const ownedRoomsState = useSelector((state: RootState) => state.roomSlice.roomsSlice.owned_rooms)
    const individualRoomState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const isRoomOwner = useSelector((state: RootState) => state.roomSlice.roomOwnerSlice.room_owner)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()

    const userRoomGetRequest = (user: User) => {
        return HTTPService.get(`/user/${user.id}/room/`, headers)
    }

    const organizationRoomGetRequest = useCallback(() => {
        return HTTPService.get(`/organization/${id}/rooms/`, headers)
    }, [id])

    useEffect(() => {
        let isMounted = true
        switch (roleState) {
            case RoleType.USER:
                if (userDataState.id) {
                    userRoomGetRequest(userDataState)
                        .then((response: UserRoomsResponse) => {
                            if (isMounted && response.status === 200) {
                                const data = response.data.room
                                dispatch(setRoomsData({ rooms: data }))
                            }
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }

                break
            case RoleType.ORGANIZATION:
                if (isDefined(id)) {
                    organizationRoomGetRequest()
                        .then((response: OrganizationRoomsResponse) => {
                            if (isMounted && response.status === 200) {
                                const data = response.data
                                dispatch(setRoomsData({ joined_rooms: data.joined_rooms, owned_rooms: data.owned_rooms }))
                            }
                        })
                        .catch(error => {
                            console.log(error)
                        })
                }
                break
            default:
                break
        }
        return () => {
            isMounted = false
        }
    }, [dispatch, id, organizationRoomGetRequest, roleState, userDataState])

    const onNavigationToPremiumPage = () => {
        navigate(`/go-premium/${individualRoomState.organization?.id}`)
        window.scrollTo(0, 0)
    }

    const onRoomClick = (roomId: number, organizationId: number) => {
        switch (roleState) {
            case RoleType.USER:
                navigate(`/room/${roomId}`)
                dispatch(setPostStatus({ postsStatus: true, singularPostStatus: false, postedInRoomStatus: false }))
                window.scrollTo(0, 0)
                props.isOpen(false)
                break
            case RoleType.ORGANIZATION:
                navigate(`/room/${organizationId}/${roomId}`)
                dispatch(setPostStatus({ postsStatus: true, singularPostStatus: false, postedInRoomStatus: false }))
                window.scrollTo(0, 0)
                props.isOpen(false)
                break
            default:
                break
        }
    }

    const onModalOpen = () => {
        dispatch(setModalData({ open: true, createRoom: true }))
    }

    const roomArray = [individualRoomState]
    const rooms = roomsState
        ? roomsState!.filter(rooms => !roomArray.some(room => room.id === rooms.id))
        : ownedRoomsState!.filter(rooms => !roomArray.some(room => room.id === rooms.id))

    return (
        <div ref={props.ref} className="rooms-dropdown">
            <div className="rooms-dropdown__title">{translation.rooms}</div>
            <div className="rooms-dropdown__rooms-list">
                {rooms.map(room => {
                    return (
                        <div className="rooms-dropdown__room" key={room.id} onClick={() => onRoomClick(room.id, parseInt(id!))}>
                            <div className="rooms-dropdown__room-image">
                                <Image src={room.photo || roomDefaultPhoto} className="image__profile image__profile--size-small image__profile--fit" />
                            </div>
                            <div className="rooms-dropdown__room-name-container">
                                <div className="rooms-dropdown__room-name">{room.name}</div>
                            </div>
                            <div
                                className={`rooms-dropdown__room-type ${
                                    room.type === RoomType.Private
                                        ? "rooms-dropdown__room-type--background-color-gold"
                                        : "rooms-dropdown__room-type--background-color-blue "
                                }`}
                            >
                                {room.type === RoomType.Private ? translation.private : translation.public}
                            </div>
                        </div>
                    )
                })}
            </div>
            {props.isPremiumOrganization && isRoomOwner && (
                <div className="rooms-dropdown__add-room" onClick={onModalOpen}>
                    <div className="rooms-dropdown__add-room-icon">
                        <AddRoom />
                    </div>
                    <div className="rooms-dropdown__add-room-text"> {translation.CreateRoom}</div>
                </div>
            )}
            {!props.isPremiumOrganization && isRoomOwner && (
                <div onClick={onNavigationToPremiumPage} className="rooms-dropdown__go-premium">
                    <PremiumIconSmall />
                    <div className="rooms-dropdown__go-premium-text">{translation.goPremiumToCreateMoreRooms}</div>
                </div>
            )}
        </div>
    )
}

export default RoomsDropdown
