import { createSlice, PayloadAction } from "@reduxjs/toolkit"
const notFoundStatusState = { status: false }

const NotFoundStatusSlice = createSlice({
    name: "NotFoundStatusSlice",
    initialState: notFoundStatusState,
    reducers: {
        getNotFoundStatus(state, action: PayloadAction<boolean>) {
            state.status = action.payload
        },
    },
})

export const { getNotFoundStatus } = NotFoundStatusSlice.actions

export default NotFoundStatusSlice.reducer
