import { useState } from "react"
import { GreenVectorDown, GreyVectorRight } from "../Components/SvgIcons"

function FAQAccordion(props: { label: string; item?: string; children?: JSX.Element[] | JSX.Element; noborder?: string }) {
    const [expanded, setExpanded] = useState(false)

    return (
        <>
            <div className={`FAQ-Accordion  FAQ-Accordion--${props.noborder}`}>
                <button type="button" className="FAQ-Accordion__label" onClick={() => setExpanded(!expanded)}>
                    {expanded ? (
                        <div className="FAQ-Accordion__icon">
                            <GreenVectorDown />{" "}
                        </div>
                    ) : (
                        <div className="FAQ-Accordion__icon">
                            <GreyVectorRight />
                        </div>
                    )}{" "}
                    <p className="FAQ-Accordion__text">{props.label}</p>
                </button>
                <div className={expanded ? "FAQ-Accordion__content FAQ-Accordion__content--active" : "FAQ-Accordion__content FAQ-Accordion__content--inactive"}>
                    <div className={expanded ? "FAQ-Accordion__item FAQ-Accordion__item--active" : "FAQ-Accordion__item FAQ-Accordion__item--inactive"}>
                        {props.item} {props.children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default FAQAccordion
