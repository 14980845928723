import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Organization, OrganizationErrorMessage } from "../../../Types/Organization"
import { OrganizationProfileCardResponse } from "../../../Types/OrganizationProfileCardResponse"

const initialState = {
    organizations: [] as OrganizationProfileCardResponse,
}

const UserOrganizationsSlice = createSlice({
    name: "UserOrganizationsSlice",
    initialState: initialState,
    reducers: {
        setUserOrganizationsData(state, action: PayloadAction<OrganizationProfileCardResponse>) {
            state.organizations = action.payload
        },
    },
})

const initialOrganizationState = {
    organization: {} as Organization,
}

const OrganizationSlice = createSlice({
    name: "OrganizationSlice",
    initialState: initialOrganizationState,
    reducers: {
        setOrganizationData(state, action: PayloadAction<Organization>) {
            state.organization = action.payload
        },
    },
})

interface ErrorData {
    message: OrganizationErrorMessage
    status: number
}
const ErrorInitialState = {
    message: {},
    status: 0,
} as ErrorData

const AddOrganizationsErrorSlice = createSlice({
    name: "OrganizationsErrorSlice",
    initialState: ErrorInitialState,
    reducers: {
        getAddOrganizationErrorMessage(state, action: PayloadAction<ErrorData>) {
            state.message = action.payload.message
            state.status = action.payload.status
        },
        resetAddOrganizationError(state) {
            state.message = ErrorInitialState.message
        },
    },
})

interface AccountErrorData {
    message: { name: string; email: string; location: string; website_url: string }
    status: number
}
const AccountErrorState = {
    message: { name: "", email: "", location: "", website_url: "" },
    status: 0,
}

const OrganizationAccountErrorSlice = createSlice({
    name: "OrganizationAccountErrorSlice",
    initialState: AccountErrorState,
    reducers: {
        setOrganizationAccountErrorMessage(state, action: PayloadAction<AccountErrorData>) {
            state.message = action.payload.message
            state.status = action.payload.status
        },
        resetOrganizationAccountError(state) {
            state.message = AccountErrorState.message
        },
    },
})

const initialCommentState = { commentId: 0 }

const PinboardCommentIdSlice = createSlice({
    name: "PinboardCommentIdSlice",
    initialState: initialCommentState,
    reducers: {
        setPinboardCommentId(state, action: PayloadAction<number>) {
            state.commentId = action.payload
        },
    },
})

const isOwnerState = { isOwner: false }

const IsOwnerSlice = createSlice({
    name: "IsOwnerSlice",
    initialState: isOwnerState,
    reducers: {
        setIsOwner(state, action: PayloadAction<boolean>) {
            state.isOwner = action.payload
        },
    },
})

export const { setOrganizationAccountErrorMessage } = OrganizationAccountErrorSlice.actions
export const { setUserOrganizationsData } = UserOrganizationsSlice.actions
export const { getAddOrganizationErrorMessage } = AddOrganizationsErrorSlice.actions
export const { resetAddOrganizationError } = AddOrganizationsErrorSlice.actions
export const { resetOrganizationAccountError } = OrganizationAccountErrorSlice.actions
export const { setOrganizationData } = OrganizationSlice.actions
export const { setPinboardCommentId } = PinboardCommentIdSlice.actions
export const { setIsOwner } = IsOwnerSlice.actions

export default combineReducers({
    organizationAccountErrorSlice: OrganizationAccountErrorSlice.reducer,
    userOrganizationsSlice: UserOrganizationsSlice.reducer,
    organizationErrorSlice: AddOrganizationsErrorSlice.reducer,
    organizationSlice: OrganizationSlice.reducer,
    pinboardCommentIdSlice: PinboardCommentIdSlice.reducer,
    isOwnerSlice: IsOwnerSlice.reducer,
})
