import { createSlice } from "@reduxjs/toolkit"
import { Language } from "../enums"
import StorageService from "../StorageService"
import { deutsch } from "./de"
import { english } from "./en"

const initialState = {
    language: StorageService.hasItem("language") ? StorageService.getItem("language") : Language.deutsch,

    translations: {
        english,
        deutsch,
    },
}
export const i18nSlice = createSlice({
    name: "i18Slice",
    initialState: initialState,
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload
        },
    },
})

export const { setLanguage } = i18nSlice.actions

export const selectTranslations = (state: any) => state.i18n.translations[state.i18n.language]

export default i18nSlice.reducer
