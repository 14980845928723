import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ResetPasswordResponseMessage } from "../../../Types/Responses/ResetPasswordResponseMessage"

const initialStatusState = { status: false }

const ResetPasswordStatusSlice = createSlice({
    name: "resetPasswordStatus",
    initialState: initialStatusState,
    reducers: {
        getResetPasswordStatus(state = initialStatusState) {
            state.status = true
        },
    },
})

interface ErrorData {
    message: ResetPasswordResponseMessage
    status: number
}

const initialErrorState = { message: {}, status: 0 } as ErrorData
const ResetPasswordErrorSlice = createSlice({
    name: "resetPasswordError",
    initialState: initialErrorState,
    reducers: {
        getResetPasswordError(state, action: PayloadAction<ErrorData>) {
            state.message = action.payload.message
            state.status = action.payload.status
        },
        resetResetPasswordError(state) {
            state = { ...initialErrorState }
        },
    },
})

export const { getResetPasswordStatus } = ResetPasswordStatusSlice.actions
export const { getResetPasswordError } = ResetPasswordErrorSlice.actions
export const { resetResetPasswordError } = ResetPasswordErrorSlice.actions

export default combineReducers({ resetPasswordStatus: ResetPasswordStatusSlice.reducer, resetPasswordError: ResetPasswordErrorSlice.reducer })
