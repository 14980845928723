import { headers } from "../../../../App/Axios"
import HTTPService from "../../../../App/HTTPService"
import { setMentionListPosition } from "./setMentionListPosition"



export const getUsers = (
    query: string,
    setSearchResult: Function,
    setSearchState: Function,
    dropdown: HTMLElement | null,
    trimmedContent: string,
    editorContent: string,
    maxLeftPosition: number,
    IsEditContent: boolean
) => {
    //dropdown!.style.visibility = "hidden"

    setSearchState("busy")
    if (query.length > 0) {
        HTTPService.get(`/notification/tag-options-list/?query=${query}`, headers).then(response => {
            if (response.status === 200) {
                setSearchResult(response.data)
                if (response.data.length === 0) {
                    dropdown!.style.visibility = "hidden"
                } else {
                    setMentionListPosition(trimmedContent, editorContent, dropdown, maxLeftPosition, IsEditContent)
                }
            }
        })
    } else {
        dropdown!.style.visibility = "hidden"
    }

    setSearchState("idle")
}
