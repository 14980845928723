import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AddOrganizationResponseMessage } from "../../../Types/Responses/AddOrganizationResponseMessage"
import { PrivacyPolicyResponseMessage } from "../../../Types/Responses/PrivacyPolicyResponseMessage"

interface EdusiiaMatchingStatus {
    status: boolean
}
const initialStatusState: EdusiiaMatchingStatus = { status: false }

const EdusiiaMatchingStatusSlice = createSlice({
    name: "EdusiiaMatchingStatus",
    initialState: initialStatusState,
    reducers: {
        getEdusiiaMatchingStatus(state, action: PayloadAction<EdusiiaMatchingStatus>) {
            state.status = action.payload.status
        },
    },
})

interface AddOrganizationErrorData {
    message: AddOrganizationResponseMessage
    status: number
}

const initialOrganizationErrorState = {
    message: { organization: [{ name: "", representative: "", email: "" }], error: "" },
    status: 0,
} as AddOrganizationErrorData

const AddOrganizationErrorSlice = createSlice({
    name: "addOrganizationError",
    initialState: initialOrganizationErrorState,
    reducers: {
        getAddOrganizationError(state, action: PayloadAction<AddOrganizationErrorData>) {
            state.message = action.payload.message
            state.status = action.payload.status
        },
        resetAddOrganizationError(state) {
            state.message = initialOrganizationErrorState.message
        },
    },
})

interface PrivacyPolicyErrorData {
    message: PrivacyPolicyResponseMessage
    status: number
}

const initialPrivacyPolicyErrorState = { message: {}, status: 0 } as PrivacyPolicyErrorData

const PrivacyPolicyErrorSlice = createSlice({
    name: "privacyPolicyError",
    initialState: initialPrivacyPolicyErrorState,
    reducers: {
        getPrivacyPolicyError(state, action: PayloadAction<PrivacyPolicyErrorData>) {
            state.message = action.payload.message
            state.status = action.payload.status
        },
        resetPrivacyPolicyError(state) {
            state.message = initialPrivacyPolicyErrorState.message
        },
    },
})
export const { getEdusiiaMatchingStatus } = EdusiiaMatchingStatusSlice.actions
export const { getAddOrganizationError } = AddOrganizationErrorSlice.actions
export const { resetAddOrganizationError } = AddOrganizationErrorSlice.actions
export const { getPrivacyPolicyError } = PrivacyPolicyErrorSlice.actions
export const { resetPrivacyPolicyError } = PrivacyPolicyErrorSlice.actions
export default combineReducers({
    edusiiaMatchingStatus: EdusiiaMatchingStatusSlice.reducer,
    addOrganizationError: AddOrganizationErrorSlice.reducer,
    privacyPolicyError: PrivacyPolicyErrorSlice.reducer,
})
