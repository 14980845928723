import React from "react"
import "../../../Styles/main.scss"
import ArticalCard from "../ArticleCard/ArticleCard"

interface Props {
    children: JSX.Element[] | JSX.Element
    leftPanelFirstComponent?: JSX.Element[] | JSX.Element
    leftPanelSecondComponent?: JSX.Element[] | JSX.Element
    leftPanelThirdComponent?: JSX.Element[] | JSX.Element | null
    leftPanelForthComponent?: JSX.Element[] | JSX.Element | null
    leftPanelFifthComponent?: JSX.Element[] | JSX.Element | null
    RightPanelFirstComponent?: JSX.Element[] | JSX.Element | null
    RightPanelSecondComponent?: JSX.Element[] | JSX.Element
    RightPanelThirdComponent?: JSX.Element[] | JSX.Element
    RightPanelForthComponent?: JSX.Element[] | JSX.Element
    RightPanelFifthComponent?: JSX.Element[] | JSX.Element
    RightPanelSixthComponent?: JSX.Element[] | JSX.Element | null
    overflowModifier?: boolean
    noPadding?: boolean
    isDashboad?: boolean
}
function MainLayout({
    children,
    leftPanelFirstComponent,
    leftPanelSecondComponent,
    leftPanelThirdComponent,
    leftPanelForthComponent,
    leftPanelFifthComponent,
    RightPanelFirstComponent,
    RightPanelSecondComponent,
    RightPanelThirdComponent,
    RightPanelForthComponent,
    RightPanelFifthComponent,
    RightPanelSixthComponent,
    overflowModifier,
    noPadding,
    isDashboad,
}: Props) {
    return (
        <div className="main-layout">
            <div className="main-layout__container">
                <div className="main-left-panel">
                    <div className="main-left-panel__container">
                        {leftPanelFirstComponent}
                        {leftPanelSecondComponent}
                        {leftPanelThirdComponent}
                        <p className="main-left-panel__text">{leftPanelForthComponent}</p>
                        {leftPanelFifthComponent}
                    </div>
                </div>
                <div className={`main-middle-panel ${noPadding && "main-middle-panel--no-padding"}`}>
                    <div className="main-middle-panel__child-container"> {children} </div>
                </div>
                <div className="main-right-panel">
                    <div
                        className={`main-right-panel__container ${
                            overflowModifier ? "main-right-panel__container--overflow" : "main-right-panel__container--size"
                        }`}
                    >
                        {RightPanelFirstComponent}
                        {isDashboad && <ArticalCard />}
                        <p className="main-right-panel__text"> {RightPanelSecondComponent} </p>
                        {RightPanelThirdComponent}
                        {RightPanelForthComponent}
                        {RightPanelFifthComponent}
                        <p className="main-right-panel__text"> {RightPanelSixthComponent}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainLayout
