import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { green } from "../../../../App/GlobaleVariables"
import { addNewLine } from "../../../../App/Helpers/Helpers"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import { RootState } from "../../../../App/store"
import "../../../../Styles/main.scss"
import { Organization } from "../../../../Types/Organization"
import { EditProfileIcon } from "../../../Shared/Components/SvgIcons"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import Linkify from "react-linkify"

function OrganizationProfileDescription(props: { isEditable: boolean; isYourOrganization: boolean; otherOrganization: Organization }) {
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const organizationState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)

    const onModalOpen = () => {
        dispatch(setModalData({ open: true, organizationDescription: true }))
    }

    return (
        <div className="organization-profile-description">
            <div className="organization-profile-description__title-container">
                <div className="organization-profile-description__title">{translation.whatWeDoForEducation}</div>
                {props.isYourOrganization && props.isEditable && (
                    <div onClick={onModalOpen} className="organization-profile-description__icon">
                        <EditProfileIcon modifier="small" color={green} />
                    </div>
                )}
            </div>
            <div className="organization-profile-description__text-container">
                <div className="organization-profile-description__text">
                    <Linkify>
                        {props.isYourOrganization ? addNewLine(organizationState?.description!) : addNewLine(props.otherOrganization?.description!)}
                    </Linkify>
                </div>
            </div>
        </div>
    )
}

export default OrganizationProfileDescription
