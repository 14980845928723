import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { headers } from "../../../App/Axios"
import { JoinStatus, RoleType, RoomType } from "../../../App/enums"
import { organizationProfileDefault, profileDefault } from "../../../App/GlobaleVariables"
import HTTPService from "../../../App/HTTPService"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { CloseIcon, MembersIcon } from "../../Shared/Components/SvgIcons"
import UserCard from "../../Shared/Components/UserCard"
import SearchInput from "../../Shared/Primitive/SearchInput"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { MembersType, setRoomMembers } from "../RoomSlices/MembersInRoomSlice"
import { setRemoveRoomMemberId } from "../RoomSlices/RoomMemberSlice"
import { onHashtagClick } from "../../Shared/Hashtag/onHashtagClick"

function RoomMembersModal() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { roomId, id } = useParams()
    const [inputState, setInputState] = useState("")
    const translation = useSelector(selectTranslations)
    const isMemberInRoom = useSelector((state: RootState) => state.MemberInRoomStatusSlice.MemberInRoomStatusSlice.isMember)
    const isRoomManager = useSelector((state: RootState) => state.roomSlice.roomManagerSlice.room_manager)
    const isRoomOwner = useSelector((state: RootState) => state.roomSlice.roomOwnerSlice.room_owner)
    const Members = useSelector((state: RootState) => state.MembersInRoomSlice.members)
    const [searchedMembers, setSearchedMembers] = useState<MembersType[]>([])
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const canSeeMembers = roomDataState.type === RoomType.Private ? isMemberInRoom === JoinStatus.MEMBER || isRoomOwner : true
    const hasPrivelegedPermission = role === RoleType.USER ? isRoomManager : isRoomOwner

    const debounce = (callback: (arg0: any) => void, wait: number | undefined) => {
        let timeoutId: number | undefined;
        return (...args: any) => {
          window.clearTimeout(timeoutId);
          timeoutId = window.setTimeout(() => {
            callback.apply(null,args);
          }, wait);
        };
      }

    const getRequest = useCallback(() => {
        return HTTPService.get(`/room/${roomId}/members/`, headers)
    }, [roomId])

    const searchMembersGetRequest = useCallback(
        (searchQuery: string) => {
            return HTTPService.get(`/room/${roomId}/members/?query=${searchQuery}`, headers)
        },
        [roomId]
    )

    useEffect(() => {
        const getData = setTimeout(() => {
            if (inputState.length > 1) {
                searchMembersGetRequest(inputState).then(response => {
                    if (response.status === 200) {
                        setSearchedMembers(response.data.user)
                    }
                })
            }
        }, 500)

        return () => clearTimeout(getData)
    }, [inputState, searchMembersGetRequest])

    useEffect(() => {
        getRequest().then(response => {
            if (response.status === 200) {
                dispatch(setRoomMembers(response.data.user))
            }
        })
    }, [dispatch, getRequest])

    const onRemoveIconClick = () => {
        setInputState("")
    }

    const onRoomMemberClick = (memberId: number) => {
        switch (role) {
            case RoleType.USER:
                if (userDataState.id === memberId) {
                    navigate("/user-profile")
                } else {
                    navigate(`/user/${memberId}`)
                }
                break
            case RoleType.ORGANIZATION:
                if (userDataState.id === memberId) {
                    navigate("/user-profile")
                } else {
                    navigate(`/user/${memberId}/${id}`)
                }
                break
            default:
                break
        }
        dispatch(setModalData({ open: false, roomMembersModal: false }))
    }

    const onModalClose = () => {
        setInputState("")
        dispatch(setModalData({ open: false, roomMembersModal: false }))
    }

    const onRemoveRoomMemberModalOpen = (id: number, type: string) => {
        dispatch(setRemoveRoomMemberId({ id: id, type: type }))
        dispatch(setModalData({ open: true, roomMembersModal: false, removeRoomMemberModal: true }))
        setInputState("")
    }
    const onOrganizationRoomMemberClick = (memberId: number) => {
        navigate(`/organization-profile/${memberId}`)
        dispatch(setModalData({ open: false, roomMembersModal: false }))
    }

    return (
        <div className="room-members-modal">
            <div className="room-members-modal__header">
                <div className="room-members-modal__text">
                    <div className="room-members-modal__mobile-icon">
                        <MembersIcon />
                    </div>
                    {Members?.length || 0} {Members?.length === 1 ? translation.member : translation.members}
                </div>
                <div className="room-members-modal__close-icon room-members-modal__close-icon--mobile-icon" onClick={onModalClose}>
                    <CloseIcon />
                </div>
            </div>
            {isMemberInRoom && !canSeeMembers ? (
                <div className="room-members__no-access"> {translation.onlyMembersCanSeeOtherMembers} </div>
            ) : (
                <>
                    <div className="room-members-modal__search-container">
                        <SearchInput
                            onRemoveIconClick={onRemoveIconClick}
                            searchvalue={inputState}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputState(event.target.value)}
                            isGlobalSearch={false}
                        />
                    </div>
                    <div className="room-members-modal__container">
                        {(inputState.length > 0 ? searchedMembers : Members)?.map((member, index) => {
                            return (
                                <div className="room-members__items" key={member.id + member.type}>
                                    {member.type === RoleType.USER.toLowerCase() ? (
                                        <UserCard
                                            imageSrc={member.profile_photo?.url_path || profileDefault}
                                            text={member.first_name + " " + member.last_name}
                                            subtextStyle="tag-container"
                                            profileImageStyle="image image__profile image__profile--fit image__profile--size-small "
                                            userCardStyle={`${index === Members.length - 1 ? "room-members-item-no-border" : "room-members-item-border"}`}
                                            textStyle={"text"}
                                            textStyleMore25={"text"}
                                            middlePanelStyle={"middle-panel"}
                                            rightText={
                                                hasPrivelegedPermission && !roomDataState?.room_managers?.some(manager => manager.id === member.id) ? (
                                                    <div
                                                        className="room-members__close-icon"
                                                        onClick={() => onRemoveRoomMemberModalOpen(member.id!, member.type)}
                                                    >
                                                        <CloseIcon />
                                                    </div>
                                                ) : null
                                            }
                                            subtext={
                                                <div className="profile-header__tags-content profile-header__tags-content--width-99">
                                                    {member!.hashtags?.map((hashtag, index) => (
                                                        <p
                                                            className="profile-header__tag"
                                                            key={index}
                                                            onClick={() => onHashtagClick(hashtag, navigate, role, parseInt(id!))}
                                                        >
                                                            {hashtag.charAt(0) !== "#" ? "#" + hashtag : hashtag}
                                                        </p>
                                                    ))}
                                                </div>
                                            }
                                            rightPanelStyle={"right-panel"}
                                            onMiddleItemClick={() => onRoomMemberClick(member.id)}
                                            onLeftItemClick={() => onRoomMemberClick(member.id)}
                                        />
                                    ) : (
                                        <UserCard
                                            imageSrc={member.photo?.url_path || organizationProfileDefault}
                                            text={member.name}
                                            subtextStyle="tag-container"
                                            profileImageStyle="image image__profile image__profile--fit image__profile--size-small "
                                            userCardStyle={`${index === Members.length - 1 ? "room-members-item-no-border" : "room-members-item-border"}`}
                                            textStyle={"text"}
                                            textStyleMore25={"text"}
                                            middlePanelStyle={"middle-panel"}
                                            rightText={
                                                hasPrivelegedPermission ? (
                                                    <div
                                                        className="room-members__close-icon"
                                                        onClick={() => onRemoveRoomMemberModalOpen(member.id!, member.type)}
                                                    >
                                                        <CloseIcon />
                                                    </div>
                                                ) : null
                                            }
                                            subtext={
                                                <div className="profile-header__tags-content profile-header__tags-content--width-99">
                                                    {member!.hashtags?.map((hashtag, index) => (
                                                        <p
                                                            className="profile-header__tag"
                                                            key={index}
                                                            onClick={() => onHashtagClick(hashtag, navigate, role, parseInt(id!))}
                                                        >
                                                            {hashtag.charAt(0) !== "#" ? "#" + hashtag : hashtag}
                                                        </p>
                                                    ))}
                                                </div>
                                            }
                                            rightPanelStyle={"right-panel"}
                                            onMiddleItemClick={() => onOrganizationRoomMemberClick(member.id)}
                                            onLeftItemClick={() => onOrganizationRoomMemberClick(member.id)}
                                        />
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </>
            )}
        </div>
    )
}

export default RoomMembersModal
