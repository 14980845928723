import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialRemoveMemberState = { id: 0, type: "" }

const RemoveRoomMemberSlice = createSlice({
    name: "RemoveRoomMemberSlice",
    initialState: initialRemoveMemberState,
    reducers: {
        setRemoveRoomMemberId(state, action: PayloadAction<typeof initialRemoveMemberState>) {
            state.id = action.payload.id
            state.type = action.payload.type
        },
    },
})

export const { setRemoveRoomMemberId } = RemoveRoomMemberSlice.actions
export default RemoveRoomMemberSlice.reducer
