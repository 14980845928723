import React, { useEffect, useState } from "react"
import { CloseIcon, VectorDown, VectorUp } from "./SvgIcons"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { countryStateGetRequest } from "../../Authentication/SignUp/SignupServiceRequests"
import { LoadingAnimation, LogoIcon } from "../../Shared/Components/SvgIcons"
import StorageService from "../../../App/StorageService"
import { validEmail } from "../../../App/Regex"
import { use } from "i18next"
import Tooltip from "../../Shared/Primitive/Tooltip"
import { TooltipQuestionmark } from "../../Shared/Components/SvgIcons"
import HTTPService from "../../../App/HTTPService"
import { headers } from "../../../App/Axios"
import { RootState } from "../../../App/store"
import { Room } from "../../../Types/Room"
import { useNavigate } from "react-router-dom"

function SearchableRoomDropdown(
    props: {
        itemId?: null | number
        hasTooltip?: false | boolean
        tooltipContent?: any
        title?: null | string
        validationError: null | string
        setItemId: Function
    } & React.HTMLAttributes<HTMLDivElement>
) {
    const [selected, setSelected] = useState("")
    const [isSelected, setIsSelected] = useState(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [isEmpty,setIsEmpty] = useState(true)
    let DropdownRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    let baseDropdownClass = "dropdown__select--fixed-width dropdown__select"
    const translation = useSelector(selectTranslations)
    const [filter, setFilter] = useState("")
    const navigate = useNavigate()
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const [roomData, setRoomData] = useState<Array<Room>>([])
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch()

    const userRoomGetRequest = (id: number) => {
        return HTTPService.get(`/user/${id}/room/`, headers)
    }

    useEffect(() => {
        if (userDataState.id) {
            let isMounted = true
            userRoomGetRequest(userDataState.id)
                .then(response => {
                    if (isMounted && response.status === 200) {
                        setRoomData(response.data.room)
                        setIsLoading(false)
                    }
                })
                .catch(error => {
                    console.log(error.response)
                })
            return () => {
                isMounted = false
            }
        }
    }, [navigate, userDataState])

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>) => {
        setFilter(event.target.value)
        setIsDropdownOpen(true)
        setIsEmpty(event.target.value.length == 0)
    }
    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (isDropdownOpen && DropdownRef.current && !DropdownRef.current.contains(event.target as HTMLElement)) {
                setIsDropdownOpen(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    const onRemoveClick = (e : (React.MouseEvent<HTMLDivElement,MouseEvent>)) => {
        e.stopPropagation();
        setFilter("")
        setIsSelected(false)
        setIsEmpty(true)
        props.setItemId()
    }

    const onRoomClick = (name: string, id: number) => {
        setSelected(name)
        setIsSelected(true)
        props.setItemId(id)
        setFilter(name)
        setIsDropdownOpen(false)
        setIsEmpty(false)
    }
    return (
        <div className="dropdown dropdown--fixed-width" ref={DropdownRef} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            {props.hasTooltip && (
                <div className="single-text-input__text">
                    {props.title}
                    <div>
                        <Tooltip direction="settings-top" content={props.tooltipContent}>
                            <TooltipQuestionmark />
                        </Tooltip>
                    </div>
                </div>
            )}
            <div className={baseDropdownClass}>
                <input
                    className="dropdown__select-input"
                    placeholder={translation.pleaseSelect}
                    value={filter}
                    onChange={onInputChange}
                    autoComplete="off"
                />
                <div onClick={onRemoveClick} className="dropdown__icon">{!isEmpty ? <CloseIcon />: ""}</div>
                <div className="dropdown__icon">{isDropdownOpen ? <VectorUp /> : <VectorDown />}</div>
            </div>
            {isDropdownOpen && (
                <div onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="dropdown__container dropdown__container--fixed-width">
                    <div className="dropdown__content">
                        {roomData.map((room, index) => {
                            if (room.name.toLowerCase().includes(filter.toLowerCase()) && room.is_active) {
                                return (
                                    <div>
                                        <div
                                            key={room.id}
                                            className={
                                                selected === room.name
                                                    ? "dropdown__item dropdown__item--selected-true"
                                                    : "dropdown__item dropdown__item"
                                            }
                                            onClick={() => onRoomClick(room.name, room.id)}
                                        >
                                            <p className="dropdown__text">{room.name}</p>
                                        </div>

                                    </div>
                                )
                            }
                        })
                        }
                    </div>
                </div>
            )}
        </div>
    )
}
export default SearchableRoomDropdown
