import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import "../../../Styles/main.scss"
import { setModalData } from "../SharedSlices/ModalSlice"
import { logOut } from "../../User/UserDashboard/Logout"
import HeaderMenuItem from "./HeaderMenuItem"
import HeaderMobileProfileCard from "./HeaderMobileProfileCard"
import { RootState } from "../../../App/store"
import { profileDefault, organizationProfileDefault } from "../../../App/GlobaleVariables"
import { setRoleData } from "../SharedSlices/RolesSlice"
import SessionService from "../../../App/SessionService"
import { RoleType, StorageKey, SubscriptionType } from "../../../App/enums"
import { getUserProfileSettingsState } from "../../User/UserSettings/UserSettingsSlice"
import Image from "../Primitive/Image"

function HeaderMobileMenu() {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    let mobileMenuRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const profileImage =
        role === RoleType.USER ? userDataState.profile_photo?.url_path || profileDefault : organizationDataState.photo?.url_path || organizationProfileDefault

    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (mobileMenuOpen && mobileMenuRef.current && !mobileMenuRef.current.contains(event.target as HTMLElement)) {
                setMobileMenuOpen(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    const onSettingsModalOpen = () => {
        dispatch(setModalData({ open: true, settings: true }))
        dispatch(getUserProfileSettingsState({ status: true, isHashtag: false }))
    }
    const onOrganizationSettingsModalOpen = () => {
        dispatch(setModalData({ open: true, organizationSettings: true }))
    }
    const onAddOrganizationModalOpen = () => {
        dispatch(setModalData({ open: true, addOrganization: true }))
    }

    const onRouteChange = (userPath?: string, organizationPath?: string) => {
        switch (role) {
            case RoleType.USER:
                navigate(userPath!)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(organizationPath!)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    const onGoPremiumScreenNavigation = () => {
        navigate(`/go-premium/${id}`)
        window.scrollTo(0, 0)
    }

    const onReturnToUser = () => {
        SessionService.setItem(StorageKey.roles, RoleType.USER)
        dispatch(setRoleData(RoleType.USER))
        navigate(`/dashboard`)
        window.scrollTo(0, 0)
    }

    return (
        <div ref={mobileMenuRef}>
            <div onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                <Image src={profileImage} alt="profile" className="image image__profile image__profile--fit image__profile--size-small" />
            </div>
            {mobileMenuOpen && (
                <div className="header-mobile-menu">
                    {role === RoleType.USER && (
                        <div onClick={() => onRouteChange("/user-profile", "")} className="header-mobile-menu__item header-mobile-menu__item--height-5-5">
                            <HeaderMobileProfileCard
                                text={translation.individual}
                                subtext={userDataState.first_name + " " + userDataState.last_name}
                                imageSrc={userDataState.profile_photo?.url_path || profileDefault}
                                subtextStyle="subtext header-mobile-profile-card__subtext--font-weight-400"
                                textStyle="text header-mobile-profile-card__text--background-color-red"
                            />
                        </div>
                    )}
                    {role === RoleType.ORGANIZATION && (
                        <div
                            onClick={() => onRouteChange("", `/organization-profile/${organizationDataState.id}`)}
                            className="header-mobile-menu__item header-mobile-menu__item--height-5-5"
                        >
                            <HeaderMobileProfileCard
                                text={translation.organization}
                                subtext={organizationDataState.name}
                                imageSrc={organizationDataState.photo?.url_path || organizationProfileDefault}
                                subtextStyle="subtext header-mobile-profile-card__subtext--font-weight-400"
                                textStyle="text header-mobile-profile-card__text--background-color-gold"
                            />
                        </div>
                    )}
                    {role === RoleType.ORGANIZATION && (
                        <div onClick={() => onReturnToUser()} className="header-mobile-menu__item header-mobile-menu__item--height-5-5">
                            <HeaderMobileProfileCard
                                text={translation.individual}
                                subtext={userDataState.first_name + " " + userDataState.last_name}
                                imageSrc={userDataState.profile_photo?.url_path || profileDefault}
                                subtextStyle="subtext header-mobile-profile-card__subtext--font-weight-400"
                                textStyle="text header-mobile-profile-card__text--background-color-red"
                            />
                        </div>
                    )}

                    {role === RoleType.USER && (
                        <HeaderMenuItem
                            onClick={() => onRouteChange("/my-organizations")}
                            title={translation.myOrganizations}
                            className="header-mobile-menu__item"
                        />
                    )}

                    <HeaderMenuItem
                        onClick={role === RoleType.USER ? onSettingsModalOpen : onOrganizationSettingsModalOpen}
                        title={translation.settings}
                        className="header-mobile-menu__item"
                    />
                    {role === RoleType.USER && (
                        <HeaderMenuItem onClick={onAddOrganizationModalOpen} title={translation.addOrganization} className="header-mobile-menu__item" />
                    )}
                    {role === RoleType.ORGANIZATION && organizationDataState.subscription_type === SubscriptionType.free && (
                        <HeaderMenuItem
                            onClick={onGoPremiumScreenNavigation}
                            title={translation.goPremium}
                            className="header-mobile-menu__item header-mobile-menu__item--gold"
                        />
                    )}
                    <a href="https://edusiia.com/faq/" target="_blank" rel="noreferrer" className="header-mobile-menu__item">
                        {" "}
                        {translation.fAQ}
                    </a>
                    <HeaderMenuItem
                        onClick={logOut}
                        title={translation.logOut}
                        className="header-mobile-menu__item header-mobile-menu__item--hover-color-red header-mobile-menu__item--text-color-white"
                    />
                </div>
            )}
        </div>
    )
}

export default HeaderMobileMenu
