import React from "react"
import ReactDOM from "react-dom"
import App from "./App/App"
import reportWebVitals from "./App/reportWebVitals"
import { store } from "./App/store"
import { Provider } from "react-redux"
import SessionService from "./App/SessionService"
import { Language, RoleType, StorageKey } from "./App/enums"

if (!SessionService.hasItem(StorageKey.language)) {
    SessionService.setItem(StorageKey.language, Language.deutsch)
}

if (!SessionService.hasItem(StorageKey.roles)) {
    SessionService.setItem(StorageKey.roles, RoleType.USER)
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
)

reportWebVitals()
