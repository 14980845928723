import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Color } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { VectorDownBlue, VectorDownGold } from "../../Shared/Components/SvgIcons"

function GoPremiumDropdown(props: {
    text: string
    firstItem?: any
    secondItem?: any
    thirdItem?: any
    color: Color.gold | Color.blue
    membersNumber?: string
    hasMembersText: boolean
}) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(true)
    const translation = useSelector(selectTranslations)

    return (
        <div className="go-premium-dropdown">
            <div className="go-premium-dropdown__header">
                <div className="go-premium-dropdown__title-container">
                    <div className={`go-premium-dropdown__text ${props.color === Color.blue && "go-premium-dropdown__text--color-blue"}`}>{props.text}</div>
                    <div
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        className={`go-premium-dropdown__icon-container ${isDropdownOpen && "go-premium-dropdown__icon-container--clicked"}`}
                    >
                        {props.color === Color.gold ? <VectorDownGold /> : <VectorDownBlue />}
                    </div>
                </div>

                <div className="go-premium-dropdown__divider"></div>
                {props.hasMembersText && <div className="go-premium-dropdown__large-text">{`${props.membersNumber} ${translation.members}`}</div>}
            </div>
            {isDropdownOpen && (
                <div className="go-premium-dropdown__content">
                    {props.firstItem}
                    {props.secondItem}
                    {props.thirdItem}
                </div>
            )}
        </div>
    )
}

export default GoPremiumDropdown
