import React from "react"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import "../../../Styles/main.scss"
import { CloseIcon, SearchIcon } from "../Components/SvgIcons"
import useWindowSize from "../Hooks/useWindowSize"
import { isCallChain } from "typescript"

export type SearchInputType = { searchvalue: string; onRemoveIconClick: () => void; isGlobalSearch: boolean; isChatSearch?: boolean  }

function SearchInput(props: SearchInputType & React.AllHTMLAttributes<HTMLInputElement> & React.AllHTMLAttributes<HTMLDivElement>) {
    const { searchvalue, onRemoveIconClick, isGlobalSearch, isChatSearch, ...rest } = props
    const translation = useSelector(selectTranslations)
    const breakpoint = 970
    const screenWidth = useWindowSize()

    const modifier = isChatSearch?"search-input__chat-margin":""

    const getPlaceholder = () => {
        if(isChatSearch){
            return translation.search_chat
        }

        if (isGlobalSearch) {
            if (screenWidth < breakpoint) {
                return translation.search_mobile
            }
            return translation.search_desktop
        } else {
            return translation.search_mobile
        }
    }

    return (
        <div {...rest} className={`search-input`}>
            <input {...rest} value={searchvalue} className={`search-input__search-field ${modifier} `} placeholder={getPlaceholder()} />
            <div className="search-input__search-icon">
                {searchvalue.length > 0 ? (
                    <div className={`search-input__search-remove-icon ${modifier} `} onClick={onRemoveIconClick}>
                        <CloseIcon />
                    </div>
                ) : (
                    <div className={`${modifier} `}>
                    <SearchIcon color="grey" />
                    </div>
                )}
            </div>
        </div>
    )
}

export default SearchInput
