import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import StorageService from "../../../App/StorageService"
import { setUserData } from "../../Shared/SharedSlices/UserDataSlice"
import { getUserSettingsProfileError, resetUserSettingsProfileError } from "./UserSettingsProfileSlice"
import { User } from "../../../Types/User"
import {StorageKey } from "../../../App/enums"
import { resetUserPasswordConfirmError, getUserProfileSettingsState, getUserPasswordConfirmError } from "./UserSettingsSlice"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { AccountSettings } from "../../Organization/OrganizationSettings/OrganizationSettingsInterfaces"
import internal from "stream"

export type profileSettings = {
    first_name: string
    last_name: string
    email: string
    password: string
    confirm_password: string
    location: string
    location_state?: number,
    hashtag_list?:string[]
}

export const userProfileHashtagPostRequest = (hashtag_list:string[], user: User, verification_email_sent:any)=> async (dispatch: any) =>{
    try{
        const response = await HTTPService.post("/htag/user/",{hashtag_list:hashtag_list},headers)
        if(response.status === 201){
            if (verification_email_sent) {
                dispatch(
                    setModalData({
                        open: true,
                        confirmNewEmailMessage: true,
                    })
                )
            } else {
                dispatch(setModalData({ open: true, settings: true }))
                dispatch(getUserProfileSettingsState({ status: false, isHashtag: false }))
            }
            const userObj = {...user, hashtags:response.data.hashtags}
            dispatch(setUserData(userObj))
            StorageService.setItem(StorageKey.user, StorageService.stringify(userObj))
            await dispatch(resetUserSettingsProfileError())

        }
    }catch(error: any){
            console.log(error)
    }
}

export const patchAllUserData = (setData: profileSettings | AccountSettings, user: User) => async (dispatch: any) => {
    try {
        const response = await HTTPService.patch(`/user/${user.id}/profile/`, setData, headers)
        if (response.status === 200) {
            const verification_email_sent=response.data.verification_email_sent
            
            const userObject = {
                ...user,
                first_name: response.data.first_name,
                last_name: response.data.last_name,
                email: response.data.email,
                location: response.data.location,
                location_state: response.data.location_state
            }

            dispatch(userProfileHashtagPostRequest(setData.hashtag_list!,userObject,verification_email_sent))
        }
    } catch (error: any) {
        dispatch(setModalData({ open: true, settings: true }))
        const errors = {
            msg: error.response.data,
            status: error.response.status,
        }

        await dispatch(
            getUserSettingsProfileError({
                message: errors.msg,
                status: errors.status,
            })
        )
    }
}

export const confirmPasswordPostRequest = (password: string, setData: profileSettings | AccountSettings, user: User) => async (dispatch: any) => {
    try {
        const response = await HTTPService.post("/confirm-existing-password/", { password: password }, headers)
        if (response.status === 200) {
            dispatch(
                setModalData({
                    open: false,
                    userConfirmPasswordModalState: {
                        status: false,
                        setData: { first_name: "", last_name: "", email: "", password: "", confirm_password: "", location: "" },
                        type: "",
                    },
                })
            )

            dispatch(patchAllUserData(setData, user))
            await dispatch(resetUserPasswordConfirmError())
        }
    } catch (error: any) {
        dispatch(getUserPasswordConfirmError(error.response.data.message))
    }
}
