import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../App/store"
import "../../../Styles/main.scss"
import {
    getOrganizationNotificationsSettingsState,
    getOrganizationPrivacySettingsState,
    getOrganizationSubscriptionSettingsState,
} from "../../Organization/OrganizationSettings/OrganizationSettingsSlice"
import { resetUserSettingsProfileError } from "../../User/UserSettings/UserSettingsProfileSlice"
import { getUserNotificationsSettingsState, getUserPaymentSettingsState, getUserPrivacySettingsState } from "../../User/UserSettings/UserSettingsSlice"
import { setModalData } from "../SharedSlices/ModalSlice"
import { resetAddOrganizationError } from "../SharedSlices/OrganizationsSlice"
import { getPhotoSizeErrorMessage, resetCreateRoomError } from "../SharedSlices/RoomSlice"
import { resetUserProfileExperienceError } from "../SharedSlices/WorkExperienceSlice"
import {
    getCoverPhotoDropdownStatus,
    getOrganizationCoverPhotoDropdownStatus,
    getOrganizationProfilePhotoDropdownStatus,
    getProfilePhotoDropdownStatus,
    resetProfilePhotoError,
} from "../SharedSlices/PhotoDropdownSlice"
import { getUserDeleteAccountError } from "../../User/UserSettings/UserDeleteAccountSlice"
import { UserDeleteAccountErrorResponseType } from "../../../Types/Responses/UserDeleteAccountErrorResponseType"

export const setModalOpenTrue = () => {
    document.documentElement.setAttribute("modal-open", "true")
}

export const setModalOpenFalse = () => {
    document.documentElement.setAttribute("modal-open", "false")
}

function Modal(props: { children: any } & React.AllHTMLAttributes<HTMLDivElement>) {
    const modalState = useSelector((state: RootState) => state.modalSlice)
    const dispatch = useDispatch()
    let modalRef = React.useRef() as React.MutableRefObject<HTMLInputElement>

    useEffect(() => {
        if (modalState.open) {
            setModalOpenTrue()
        }
        return () => {
            setModalOpenFalse()
        }
    }, [modalState.open])

    window.onpopstate = () => {
        dispatch(
            setModalData({
                open: false,
                settings: false,
                profile_photo: false,
                cover_photo: false,
                addOrganization: false,
                editDescription: false,
                editEdusiiaReasons: false,
                newExperience: false,
                editExperience: false,
                viewPhotoTypeModal: null!,
                changePhotoTypeModal: null!,
                addEvent: false,
                editEvent: false,
                organizationDescription: false,
                organizationSettings: false,
                deleteRoom: false,
                createRoom: false,
                shareEventToRoomModal: false,
                pinboardCommentsModal: false,
                editRoom: false,
                removeEmployeeModal: false,
                addEmployeeModal: false,
                roomInvitationLinkModal: false,
                reactionsModal: { status: false, reaction: [{ count: 0, name: "", users: [{ id: 0, first_name: "", last_name: "" }] }] },
                eventLinkModal: false,
                roomMembersModal: false,
                goPremiumUpgradeModal: false,
                deleteRoomPost: false,
                deleteLinkPreview: false,
                donationBankTransfer: false,
                successfulCreateRoom: false,
                deleteRoomSuccessfully: false,
                editRoomSuccessfully: false,
                deleteAccountSuccessfully: false,
                successfulRoomMemberRemoval: false,
                successfulCreateOrganization: false,
                employeeRemoval: false,
                deleteOrganizationSuccessfully: false,
                newExperienceAddedSuccessfully: false,
                deleteExperienceSuccessfully: false,
                editExperienceAddedSuccessfully: false,
                deletePostSuccessfully: false,
                leaveRoomSuccessfully: false,
                confirmEmailMessage: false,
                postiveEmailVerification: false,
                negativeEmailVerification: { status: false, message: "" },
                notPremiumCreateRoomModal: false,
                successfullyJoinedRoom: false,
                errorJoiningFullRoom: false,
                contactInfo: false,
                postiveOrganziationEmailVerification: false,
                userConfirmPasswordModalState: {
                    status: false,
                    setData: { first_name: "", last_name: "", email: "", password: "", confirm_password: "", location: "" },
                    type: "",
                },
                addAdminModal: false,
                removeAdminModal: {
                    status: false,
                    admin: {
                        first_name: "",
                        id: 0,
                        last_name: "",
                        profile_photo: {
                            id: 0,
                            url_path: "",
                        },
                        role_id: 0,
                    },
                    removedAdmin: false,
                },
                successfulUpgrade: false,
                postiveNewOrganizationEmailVerification: false,
                postiveNewEmailVerification: false,
                adminRemoved: false,
                creatorsAndManagers: false,
                roomLimitModal: { status: false, max_rooms: 5 },
                deletedEventPopUp: false,
                successfullyOwnershipTransfer: false,
                leaveRoomModal: false,
                leaveChatModal: false,
                joinPrivateRoomModal: {status: false ,question: "", roomId: 0, organizationName: "", roomType: ""}
            })
        )
    }

    useEffect(() => {
        const onMouseDown = (event: Event) => {
            if (modalState.open && modalRef.current && modalRef.current.contains(event.target as HTMLElement)) {
                dispatch(
                    setModalData({
                        open: false,
                        settings: false,
                        profile_photo: false,
                        cover_photo: false,
                        addOrganization: false,
                        editDescription: false,
                        editEdusiiaReasons: false,
                        newExperience: false,
                        editExperience: false,
                        viewPhotoTypeModal: null!,
                        changePhotoTypeModal: null!,
                        addEvent: false,
                        editEvent: false,
                        organizationDescription: false,
                        organizationSettings: false,
                        deleteRoom: false,
                        createRoom: false,
                        shareEventToRoomModal: false,
                        pinboardCommentsModal: false,
                        editRoom: false,
                        removeEmployeeModal: false,
                        addEmployeeModal: false,
                        roomInvitationLinkModal: false,
                        eventLinkModal: false,
                        roomMembersModal: false,
                        goPremiumUpgradeModal: false,
                        deleteRoomPost: false,
                        deleteLinkPreview: false,
                        donationBankTransfer: false,
                        successfulCreateRoom: false,
                        deleteRoomSuccessfully: false,
                        editRoomSuccessfully: false,
                        deleteAccountSuccessfully: false,
                        successfulRoomMemberRemoval: false,
                        successfulCreateOrganization: false,
                        employeeRemoval: false,
                        deleteOrganizationSuccessfully: false,
                        newExperienceAddedSuccessfully: false,
                        deleteExperienceSuccessfully: false,
                        editExperienceAddedSuccessfully: false,
                        deletePostSuccessfully: false,
                        leaveRoomSuccessfully: false,
                        confirmEmailMessage: false,
                        postiveEmailVerification: false,
                        negativeEmailVerification: { status: false, message: "" },
                        notPremiumCreateRoomModal: false,
                        successfullyJoinedRoom: false,
                        errorJoiningFullRoom: false,
                        deleteRoomComment: false,
                        deleteEventComment: false,
                        contactInfo: false,
                        userConfirmPasswordModalState: {
                            status: false,
                            setData: { first_name: "", last_name: "", email: "", password: "", confirm_password: "", location: "" },
                            type: "",
                        },
                        addAdminModal: false,
                        removeAdminModal: {
                            status: false,
                            admin: {
                                first_name: "",
                                id: 0,
                                last_name: "",
                                profile_photo: {
                                    id: 0,
                                    url_path: "",
                                },
                                role_id: 0,
                            },
                            removedAdmin: false,
                        },
                        successfulUpgrade: false,
                        postiveNewOrganizationEmailVerification: false,
                        postiveNewEmailVerification: false,
                        adminRemoved: false,
                        creatorsAndManagers: false,
                        roomLimitModal: { status: false, max_rooms: 5 },
                        deletedEventPopUp: false,
                        successfullyOwnershipTransfer: false,
                        leaveRoomModal: false,
                        leaveChatModal: false,
                        joinPrivateRoomModal: {status: false, question: "", roomId: 0, organizationName: "", roomType: ""},
                        reactionsModal: { status: false, reaction: [{ count: 0, name: "", users: [{ id: 0, first_name: "", last_name: "" }] }] },
                    })
                )
                dispatch(getUserNotificationsSettingsState(false))
                dispatch(getUserPrivacySettingsState(false))
                dispatch(getUserPaymentSettingsState(false))
                dispatch(getOrganizationNotificationsSettingsState(false))
                dispatch(getOrganizationPrivacySettingsState(false))
                dispatch(getOrganizationSubscriptionSettingsState(false))
                setModalOpenFalse()
                dispatch(resetCreateRoomError())
                dispatch(resetAddOrganizationError())
                dispatch(resetUserProfileExperienceError())
                dispatch(resetUserSettingsProfileError())
                dispatch(getPhotoSizeErrorMessage(false))
                dispatch(getProfilePhotoDropdownStatus({ status: false }))
                dispatch(getCoverPhotoDropdownStatus({ status: false }))
                dispatch(getOrganizationCoverPhotoDropdownStatus({ status: false }))
                dispatch(getOrganizationProfilePhotoDropdownStatus({ status: false }))
                dispatch(resetProfilePhotoError())
                dispatch(
                    getUserDeleteAccountError({
                        message: { error: "", reason: "" },
                        status: 0,
                    })
                )
            }
        }

        document.addEventListener("mousedown", onMouseDown)
    })

    if (!modalState.open) return null

    return ReactDOM.createPortal(
        <>
            <div className="modal__overlay" ref={modalRef}></div>

            <div className="modal__card" {...props}>
                {props.children}
            </div>
        </>,
        document.getElementById("portal")!
    )
}

export default Modal
