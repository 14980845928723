import React, { useEffect, useRef, useState } from "react"
import HTTPService from "../../../App/HTTPService"
import "../../../Styles/main.scss"
import Image from "../Primitive/Image"
import { SponsorCardType } from "../../../Types/SponsorCardType"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../App/store"
import { setSponsorCardData } from "../SharedSlices/SponsorCardSlice"
import { headers } from "../../../App/Axios"
import { LoadingAnimation } from "./SvgIcons"
import { organizationProfileDefault } from "../../../App/GlobaleVariables"
import { useNavigate } from "react-router-dom"
import { RoleType } from "../../../App/enums"
import { setRoleStatusData } from "../SharedSlices/RolesSlice"
import { getOrganizationData } from "../../Organization/OrganizationDashboard/GetOrganizationServiceRequest"
import { htmlRegex } from "../../../App/Regex"
import { replaceHTMLCharactersWithCorrespondingLetters } from "../../../App/Helpers/Helpers"

function SponsorCard() {
    const dispatch = useDispatch()
    const SponsorState = useSelector((state: RootState) => state.sponsorSlice.SponsorCardSlice.events)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const navigate = useNavigate()
    const sponsorSlider = useRef<HTMLDivElement>(null)
    let arrayLength = SponsorState.length
    const [isLoading, setIsLoading] = useState(true)

    const sponsoredEventGetRequest = () => {
        return HTTPService.get("/events/", headers)
    }

    useEffect(() => {
        let isMounted = true

        sponsoredEventGetRequest()
            .then((response: SponsorCardType) => {
                if (isMounted && response.status === 200) {
                    setIsLoading(false)
                    dispatch(setSponsorCardData(response.data))
                }
            })
            .catch(error => {
                console.log(error)
            })

        return () => {
            isMounted = false
        }
    }, [dispatch])

    const seconds = 10000
    let index = 0
    const indexRef = useRef(index)

    useEffect(() => {
        if (arrayLength > 1) {
            const interval = setInterval(() => {
                if (indexRef.current < arrayLength) {
                    indexRef.current++
                }

                if (indexRef.current === arrayLength) {
                    indexRef.current = 0
                }

                sponsorSlider.current?.style.setProperty(`transform`, `translateX(calc((-100%)* ${indexRef.current} ))`)
            }, seconds)
            return () => clearInterval(interval)
        }
    }, [arrayLength])

    const onSponsorClick = (orgId: number, eventId: number) => {
        switch (role) {
            case RoleType.ORGANIZATION:
                navigate(`/organization-profile/${orgId!}/event/${eventId}`)
                window.scrollTo(0, 0)
                break
            case RoleType.USER:
                navigate(`/organization-profile/${orgId!}/event/${eventId}`)
                getOrganizationData(orgId.toString()).then(response => {
                    if (response.status === 200) {
                        if (response.data.is_admin) {
                            dispatch(setRoleStatusData(true))
                            window.scrollTo(0, 0)
                        } else {
                            navigate(`/organization-profile/${orgId!}/event/${eventId}`)
                            window.scrollTo(0, 0)
                        }
                    }
                })

                break
            default:
                break
        }
    }

    return (
        <>
            {isLoading ? (
                <div className="sponsor-card__loading-card">
                    <LoadingAnimation />{" "}
                </div>
            ) : (
                <>
                    <div className="sponsor-card__slider-card" ref={sponsorSlider}>
                        {SponsorState?.map(sponsor => {
                            return (
                                <div className="sponsor-card" key={sponsor.id}>
                                    <div className="sponsor-card__container" onClick={() => onSponsorClick(sponsor.organization?.id!, sponsor.id!)}>
                                        <div className="sponsor-card__content">
                                            <div className="sponsor-card__organization">
                                                <div className="sponsor-card__organization-image">
                                                    <Image
                                                        className="image image__profile image__profile--size-small"
                                                        src={sponsor?.organization?.photo?.url_path || organizationProfileDefault}
                                                    />
                                                </div>
                                                <div className="sponsor-card__organization-name">{sponsor?.organization?.name}</div>
                                            </div>
                                            <div className="sponsor-card__background">
                                                <div className="sponsor-card__title">
                                                    <p className="sponsor-card__bold-title" title={sponsor?.title}>
                                                        {sponsor?.title}
                                                    </p>
                                                </div>

                                                {sponsor?.event_photo ? (
                                                    <>
                                                        <div className="sponsor-card__description sponsor-card__description--small">
                                                            {replaceHTMLCharactersWithCorrespondingLetters(
                                                                sponsor
                                                                    ?.description!.replaceAll(/&nbsp;/g, " ")
                                                                    .replace(htmlRegex, "")
                                                                    .replaceAll(/&nbsp/g, " ")
                                                            )}
                                                        </div>

                                                        <div className="sponsor-card__event-image">
                                                            <Image alt="" className="sponsor-card__image" src={sponsor?.event_photo!} />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className="sponsor-card__description-container">
                                                        <div className="sponsor-card__description sponsor-card__description--large">
                                                            {sponsor
                                                                ?.description!.replaceAll(/&nbsp;/g, " ")
                                                                .replace(htmlRegex, "")
                                                                .replaceAll(/&nbsp/g, " ")}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </>
            )}
        </>
    )
}

export default SponsorCard
