export const org_web_notifications = [
    {
        notification_web: "Comments",
        value_none: false,
        value_immediate: true,
    },
    {
        notification_web: "Invitation",
        value_none: false,
        value_immediate: true,
    },
    {
        notification_web: "Reply",
        value_none: false,
        value_immediate: true,
    },
    {
        notification_web: "Posts",
        value_none: false,
        value_immediate: true,
    },
    {
        notification_web: "Members",
        value_none: false,
        value_immediate: true,
    },
]

export const org_email_notifications = [
    {
        notification_email: "Comments",
        value_none: false,
        value_daily: true,
        value_weekly: false,
    },
    {
        notification_email: "Invitation",
        value_none: false,
        value_daily: true,
        value_weekly: false,
    },
    {
        notification_email: "Reply",
        value_none: false,
        value_daily: true,
        value_weekly: false,
    },

    {
        notification_email: "Posts",
        value_none: false,
        value_daily: true,
        value_weekly: false,
    },
    {
        notification_email: "Members",
        value_none: false,
        value_daily: true,
        value_weekly: false,
    },
]
