import { createSlice, PayloadAction, combineReducers } from "@reduxjs/toolkit"

type ProfileStateType={ status: boolean,isHashtag:boolean }
const initialProfileState:ProfileStateType = { status: true,isHashtag:false }

const UserProfileSettingsStateSlice = createSlice({
    name: "UserProfileSettingsState",
    initialState: initialProfileState,
    reducers: {
        getUserProfileSettingsState(state, action: PayloadAction<ProfileStateType>) {
            state.status = action.payload.status
            state.isHashtag = action.payload.isHashtag
        },
    },
})

const initialNotificationsState = { status: false }

const UserNotificationsSettingsStateSlice = createSlice({
    name: "UserNotificationsSettingsState",
    initialState: initialNotificationsState,
    reducers: {
        getUserNotificationsSettingsState(state, action: PayloadAction<boolean>) {
            state.status = action.payload
        },
    },
})

const initialPrivacyState = { status: false }

const UserPrivacySettingsStateSlice = createSlice({
    name: "UserPrivacySettingsState",
    initialState: initialPrivacyState,
    reducers: {
        getUserPrivacySettingsState(state, action: PayloadAction<boolean>) {
            state.status = action.payload
        },
    },
})

const initialPaymentState = { status: false }

const UserPaymentSettingsStateSlice = createSlice({
    name: "UserPaymentSettingsState",
    initialState: initialPaymentState,
    reducers: {
        getUserPaymentSettingsState(state, action: PayloadAction<boolean>) {
            state.status = action.payload
        },
    },
})

const initialDonationLinkState = { link: "" }

const UserDonationLinkSlice = createSlice({
    name: "UserDonationLinkSlice",
    initialState: initialDonationLinkState,
    reducers: {
        getUserDonationLink(state, action: PayloadAction<string>) {
            state.link = action.payload
        },
    },
})

const userPasswordConfrimError = { message: "" }

const UserPasswordConfrimErrorSlice = createSlice({
    name: "UserPasswordConfrimErrorSlice",
    initialState: userPasswordConfrimError,
    reducers: {
        getUserPasswordConfirmError(state, action: PayloadAction<string>) {
            state.message = action.payload
        },
        resetUserPasswordConfirmError(state) {
            state.message = userPasswordConfrimError.message
        },
    },
})

export const { getUserProfileSettingsState } = UserProfileSettingsStateSlice.actions
export const { getUserPrivacySettingsState } = UserPrivacySettingsStateSlice.actions
export const { getUserPaymentSettingsState } = UserPaymentSettingsStateSlice.actions
export const { getUserNotificationsSettingsState } = UserNotificationsSettingsStateSlice.actions
export const { getUserDonationLink } = UserDonationLinkSlice.actions
export const { getUserPasswordConfirmError } = UserPasswordConfrimErrorSlice.actions
export const { resetUserPasswordConfirmError } = UserPasswordConfrimErrorSlice.actions

export default combineReducers({
    userSettingsStatus: UserProfileSettingsStateSlice.reducer,
    userNotificationsSettingsStateSlice: UserNotificationsSettingsStateSlice.reducer,
    userPrivacySettingsStateSlice: UserPrivacySettingsStateSlice.reducer,
    userPaymentSettingsStateSlice: UserPaymentSettingsStateSlice.reducer,
    userDonationLinkSlice: UserDonationLinkSlice.reducer,
    userPasswordConfrimErrorSlice: UserPasswordConfrimErrorSlice.reducer,
})
