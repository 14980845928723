import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { User } from "../../../Types/User"

type NewMemberData = {
    members: Array<User>
}

const initialState: NewMemberData = { members: [] }

const NewMembersSlice = createSlice({
    name: "NewMembersSlice",
    initialState: initialState,
    reducers: {
        setNewMembersData(state, action: PayloadAction<Array<User>>) {
            state.members = action.payload
        },
    },
})

type NewestOrganization = { id: number; name: string; photo: { id: number; url_path: string };hashtags:string[] }

type NewOrganizationData = {
    organizations: Array<NewestOrganization>
}

const initialOrganizationsState: NewOrganizationData = { organizations: [] }

const NewOrganizationsSlice = createSlice({
    name: "NewOrganizationsSlice",
    initialState: initialOrganizationsState,
    reducers: {
        setNewOrganizationsData(state, action: PayloadAction<Array<NewestOrganization>>) {
            state.organizations = action.payload
        },
    },
})
export const { setNewMembersData } = NewMembersSlice.actions
export const { setNewOrganizationsData } = NewOrganizationsSlice.actions
export default combineReducers({ newMembersSlice: NewMembersSlice.reducer, newOrganizationsSlice: NewOrganizationsSlice.reducer })
