import { useState } from "react"
import { CreatorsAndManagers, EditProfileIcon, InviteUserIcon, LeaveIcon, MembersIcon, ShareIcon } from "../../Shared/Components/SvgIcons"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { RootState } from "../../../App/store"
import { CallerComponent, JoinStatus, RoleType, RoomType } from "../../../App/enums"
import { green } from "../../../App/GlobaleVariables"
import MessageBody from "../../Shared/Components/MessageBody"

function RoomMobileMenu() {
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const roles = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const [isChecked, setIsChecked] = useState(false)
    const isMemberInRoom = useSelector((state: RootState) => state.MemberInRoomStatusSlice.MemberInRoomStatusSlice.isMember)
    const RoomInvitePermissions = roomDataState.type === RoomType.Private ? roomDataState?.room_permissions[0].invite_others : true
    const isRoomManager = useSelector((state: RootState) => state.roomSlice.roomManagerSlice.room_manager)
    const isRoomOwner = useSelector((state: RootState) => state.roomSlice.roomOwnerSlice.room_owner)
    const hasPrivilegedPermission = roles === RoleType.USER ? isRoomManager : isRoomOwner

    const onLeaveRoomClick = () => {
        dispatch(setModalData({ open: true, leaveRoomModal: true }))
    }

    return (
        <div className={`room-mobile-menu room-mobile-menu--${hasPrivilegedPermission && "margin-top"}`}>
            <div
                className={`room-mobile-menu__item-container ${
                    hasPrivilegedPermission ? "room-mobile-menu__item-container--top-2" : "room-mobile-menu__item-container--top-4"
                }`}
            >
                <div
                    className={`room-mobile-menu__description-container ${
                        isMemberInRoom === JoinStatus.NON_MEMBER && "room-mobile-menu__description-container--margin-top"
                    }`}
                >
                    <div className="room-mobile-menu__description-text">
                        <MessageBody
                            text={roomDataState?.description!}
                            showPreview={false}
                            showCollapsed={true}
                            showRemovePreviewIcon={false}
                            hashtags={roomDataState!.hashtags!}
                            isCollapsed={isChecked}
                            caller={CallerComponent.roomDescription}
                            toggleCollapse={() => {
                                setIsChecked(!isChecked)
                            }}
                        />
                    </div>
                </div>
                <div className="room-mobile-menu__divider"></div>
                <div
                    className="room-mobile-menu__item"
                    onClick={() => {
                        dispatch(setModalData({ open: true, creatorsAndManagers: true }))
                    }}
                >
                    <CreatorsAndManagers />
                    <p className="room-mobile-menu__text room-mobile-menu__text--share">{translation.creatorsAndManagers}</p>
                </div>

                <div
                    className="room-mobile-menu__item room-mobile-menu__item--members"
                    onClick={() => {
                        dispatch(setModalData({ open: true, roomMembersModal: true }))
                    }}
                >
                    <MembersIcon />
                    <p className="room-mobile-menu__text">{translation.members}</p>
                </div>
                {((isMemberInRoom === JoinStatus.MEMBER && RoomInvitePermissions) || hasPrivilegedPermission) && (
                    <div
                        className="room-mobile-menu__item"
                        onClick={() => {
                            dispatch(setModalData({ open: true, roomInviteModal: true }))
                        }}
                    >
                        <InviteUserIcon color="gold" />
                        <p className="room-mobile-menu__text room-mobile-menu__text--invite">{translation.invite}</p>
                    </div>
                )}

                {hasPrivilegedPermission && roomDataState.type === RoomType.Private ? (
                    <div
                        className="room-mobile-menu__item"
                        onClick={() => {
                            dispatch(setModalData({ open: true, roomInvitationLinkModal: true }))
                        }}
                    >
                        <ShareIcon color="blue" strokeWidth="3" size="size-big" />
                        <p className="room-mobile-menu__text room-mobile-menu__text--share">{translation.share}</p>
                    </div>
                ) : roomDataState.type === RoomType.Public ? (
                    <div
                        className="room-mobile-menu__item"
                        onClick={() => {
                            dispatch(setModalData({ open: true, roomInvitationLinkModal: true }))
                        }}
                    >
                        <ShareIcon color="blue" strokeWidth="3" size="size-big" />
                        <p className="room-mobile-menu__text">{translation.share}</p>
                    </div>
                ) : null}
                {hasPrivilegedPermission && (
                    <div
                        className="room-mobile-menu__item"
                        onClick={() => {
                            dispatch(setModalData({ open: true, editRoom: true }))
                        }}
                    >
                        <EditProfileIcon color={green} modifier="small" />
                        <p className="room-mobile-menu__text room-mobile-menu__text--edit-room">{translation.editRoom}</p>
                    </div>
                )}
                {isMemberInRoom === JoinStatus.MEMBER && !hasPrivilegedPermission && (
                    <div className="room-mobile-menu__item room-mobile-menu__item--red" onClick={onLeaveRoomClick}>
                        <div className="room-header__icon">
                            <LeaveIcon />
                        </div>
                        {translation.leaveRoom}
                    </div>
                )}
            </div>
        </div>
    )
}

export default RoomMobileMenu
