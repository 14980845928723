import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { StorageKey } from "../../../../App/enums"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import StorageService from "../../../../App/StorageService"
import { RootState } from "../../../../App/store"
import "../../../../Styles/main.scss"
import EditDescriptionModal from "../../../Shared/Components/EditDescriptionModal"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { patchUserEdusiiaReasons } from "./UserProfileDescriptionEditServiceRequest"

function UserProfileDescriptionEditEdusiiaReasons() {
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const [edusiiaReasons, setEdusiiaReasons] = useState(userDataState.edusiia_reasons)

    const onModalClose = () => {
        dispatch(setModalData({ open: false, editEdusiiaReasons: false }))
    }
    useEffect(() => {
        if (userDataState.id) {
            let stringUser = StorageService.stringify(userDataState)
            StorageService.setItem(StorageKey.user, stringUser)
        }
    }, [edusiiaReasons?.length, userDataState])

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        dispatch(patchUserEdusiiaReasons(edusiiaReasons, userDataState))
        onModalClose()
    }

    return (
        <EditDescriptionModal
            closeModal={onModalClose}
            title={translation.whyAmIAtEdusiia}
            description={edusiiaReasons}
            setDescription={setEdusiiaReasons}
            onSubmit={onFormSubmit}
        />
    )
}

export default UserProfileDescriptionEditEdusiiaReasons
