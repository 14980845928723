import React from "react"
import { useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"
import { RootState } from "../store"
import { PublicRouteProps } from "./RoutingType"

const PublicRoute = ({ Component }: PublicRouteProps) => {
    const location = useLocation()
    let params = new URLSearchParams(location.search)
    const redirectUrl = params.get("redirectUrl") || "/dashboard"
    const isAuthenticated = useSelector((state: RootState) => state.signinSlice.authenticationSlice)
    const authenticated = isAuthenticated.hasAccessToken

    return !authenticated ? <Component /> : <Navigate to={redirectUrl} />
}
export default PublicRoute
