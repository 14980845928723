import React, { useEffect, useState } from "react"
import "../../../Styles/main.scss"
import { CameraIcon, EditProfileIcon, LocationIcon, LoadingAnimation, HeartIcon, SmallChatIcon, SmallChatIconWhite } from "../../Shared/Components/SvgIcons"
import Image from "../../Shared/Primitive/Image"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../../App/store"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { getCoverPhotoDropdownStatus, getProfilePhotoDropdownStatus } from "../../Shared/SharedSlices/PhotoDropdownSlice"
import { coverDefault, profileDefault } from "../../../App/GlobaleVariables"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import PhotoDropdown from "../../Shared/PhotoDropdown/PhotoDropdown"
import HTTPService from "../../../App/HTTPService"
import { useNavigate, useParams } from "react-router-dom"
import { User } from "../../../Types/User"
import { PhotoTypeModal } from "../../../App/enums"
import { getUserPrivacySettingsState, getUserProfileSettingsState } from "../UserSettings/UserSettingsSlice"
import { setContactInfo } from "../../Shared/SharedSlices/ContactInfo"
import { onHashtagClick } from "../../Shared/Hashtag/onHashtagClick"
import Button from "../../Shared/Primitive/Button"

function UserProfileHeader(props: { isEditable: boolean; isYourProfile: boolean }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const translation = useSelector(selectTranslations)
    const coverPhotoDropdownStatus = useSelector((state: RootState) => state.photoDropdownSlice.coverPhotoDropdownStatusSlice)
    const loadingCoverPhotoDropdownStatus = useSelector((state: RootState) => state.photoDropdownSlice.loadingCoverPhotoDropdownStatusSlice)
    const profilePhotoDropdownStatus = useSelector((state: RootState) => state.photoDropdownSlice.profilePhotoDropdownStatuSlice)
    const loadingProfilePhotoDropdownStatus = useSelector((state: RootState) => state.photoDropdownSlice.loadingProfilePhotoDropdownStatusSlice)
    const [otherUserData, setOtherUserData] = useState<User>()
    const { userId } = useParams()
    let otherUserPrivacySettings = otherUserData?.privacy_settings?.slice(0, 1).shift()
    let userPrivacySettings = userDataState.privacy_settings?.slice(0, 1).shift()
    let userLocationSetting = props.isYourProfile ? userPrivacySettings?.show_location : otherUserPrivacySettings?.show_location

    const getUserRequest = (id: string) => {
        return HTTPService.get(`/profile/${id}/`)
    }

    useEffect(() => {
        let isMounted = true
        if (!props.isYourProfile) {
            getUserRequest(userId!)
                .then(response => {
                    if (isMounted && response.status === 200) {
                        response.data.location_state = response.data.location_state_id
                        setOtherUserData(response.data)
                    }
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        navigate("/not-found")
                    }
                })
        }
        return () => {
            isMounted = false
        }
    }, [navigate, props.isYourProfile, userId])

    const onProfilePhotoModalOpen = () => {
        if (!userDataState.profile_photo?.url_path) {
            return
        }
        dispatch(setModalData({ open: true, viewPhotoTypeModal: PhotoTypeModal.USER_PROFILE }))
    }

    const onCoverPhotoModalOpen = () => {
        if (!userDataState.cover_photo?.url_path) {
            return
        }
        dispatch(setModalData({ open: true, viewPhotoTypeModal: PhotoTypeModal.USER_COVER }))
    }

    const onProfilePhotoClick = () => {
        if (props.isEditable) {
            dispatch(getProfilePhotoDropdownStatus({ status: !profilePhotoDropdownStatus.status }))
        } else if (userDataState.profile_photo?.url_path) {
            onProfilePhotoModalOpen()
        }
    }

    const onUserSettingsModalOpen = (isHashtag: boolean) => {
        dispatch(setModalData({ open: true, settings: true }))
        dispatch(getUserProfileSettingsState({ status: true, isHashtag: isHashtag }))
    }

    const onCoverPhotoClick = () => {
        if (props.isEditable) {
            return
        }
        if (userDataState.cover_photo?.url_path) {
            onCoverPhotoModalOpen()
        }
    }

    const onOtheUserCoverPhotoClick = () => {
        if (otherUserData?.cover_photo?.url_path) {
            dispatch(setModalData({ open: true, viewPhotoTypeModal: PhotoTypeModal.OTHER_USER_COVER }))
        }
        return
    }

    const onOtheUserProfilePhotoClick = () => {
        if (otherUserData?.profile_photo?.url_path) {
            dispatch(setModalData({ open: true, viewPhotoTypeModal: PhotoTypeModal.OTHER_USER_PROFILE }))
        }
        return
    }

    const onAddLocationClick = () => {
        if (props.isYourProfile) {
                dispatch(setModalData({ open: true, settings: true }))
                dispatch(getUserProfileSettingsState({ status: true, isHashtag: false }))
            }
    }

    const onContactInfoClick = () => {
        if (props.isYourProfile) {
            if (userPrivacySettings?.show_contact_info) {
                dispatch(setModalData({ open: true, contactInfo: true }))
                dispatch(setContactInfo({ first_name: userDataState.first_name, last_name: userDataState.last_name, email: userDataState.email }))
            } else {
                dispatch(setModalData({ open: true, settings: true }))
                dispatch(getUserProfileSettingsState({ status: false, isHashtag: false }))
                dispatch(getUserPrivacySettingsState(true))
            }
        } else {
            dispatch(setModalData({ open: true, contactInfo: true }))
            dispatch(setContactInfo({ first_name: otherUserData?.first_name, last_name: otherUserData?.last_name, email: otherUserData?.email! }))
        }
    }

    return (
        <div className="profile-header">
            <div className="profile-header__cover-image-container">
                {loadingCoverPhotoDropdownStatus.loading ? (
                    <div className="profile-header__cover-image-container-loading" style={{ backgroundImage: `url(${userDataState.cover_photo?.url_path})` }}>
                        <LoadingAnimation type="cover" />
                    </div>
                ) : props.isYourProfile ? (
                    <Image
                        className={`profile-header__cover-image profile-header__cover-image--${props.isEditable ? "default" : "pointer"}`}
                        src={userDataState.cover_photo?.url_path || coverDefault}
                        onClick={onCoverPhotoClick}
                    />
                ) : (
                    <Image
                        className={`profile-header__cover-image profile-header__cover-image--${props.isEditable ? "default" : "pointer"}`}
                        src={(otherUserData && otherUserData!.cover_photo?.url_path) || coverDefault}
                        onClick={onOtheUserCoverPhotoClick}
                    />
                )}
                {props.isEditable && (
                    <>
                        <div
                            className="profile-header__camera-icon"
                            onClick={() => dispatch(getCoverPhotoDropdownStatus({ status: !coverPhotoDropdownStatus.status }))}
                        >
                            <CameraIcon color="green" />
                        </div>
                        {coverPhotoDropdownStatus.status && (
                            <PhotoDropdown
                                type={PhotoTypeModal.USER_COVER}
                                currentImage={userDataState.cover_photo?.url_path}
                                viewPhoto={translation.viewCoverPhoto}
                                changePhoto={translation.changeCoverPhoto}
                                photoCropAspect={4 / 1}
                                recommendedResolution={translation.recommendedCoverPhoto}
                            />
                        )}
                    </>
                )}
            </div>
            <div className="profile-header__profile-image-container">
                {loadingProfilePhotoDropdownStatus.loading ? (
                    <div
                        className="profile-header__profile-image-loading"
                        style={{ backgroundImage: `url(${userDataState.profile_photo?.url_path}) `, backgroundSize: "cover" }}
                        onClick={() => dispatch(getProfilePhotoDropdownStatus({ status: !profilePhotoDropdownStatus.status }))}
                    >
                        <LoadingAnimation type="profile" />
                    </div>
                ) : props.isYourProfile ? (
                    <Image
                        className="profile-header__profile-image"
                        src={userDataState.profile_photo?.url_path || profileDefault}
                        onClick={onProfilePhotoClick}
                    />
                ) : (
                    <Image
                        className="profile-header__profile-image"
                        src={(otherUserData && otherUserData!.profile_photo?.url_path) || profileDefault}
                        onClick={onOtheUserProfilePhotoClick}
                    />
                )}
                {props.isEditable && profilePhotoDropdownStatus.status && (
                    <PhotoDropdown
                        type={PhotoTypeModal.USER_PROFILE}
                        currentImage={userDataState.profile_photo?.url_path}
                        viewPhoto={translation.viewPhotoProfile}
                        changePhoto={translation.changePhotoProfile}
                        photoCropAspect={2 / 2}
                        recommendedResolution={translation.recommendedProfilePhoto}
                    />
                )}
            </div>

            <div
                className={
                    (userDataState?.first_name && userDataState?.first_name + " " + userDataState?.last_name).length < 25
                        ? "profile-header__user-name"
                        : "profile-header__user-nameMore25"
                }
            >
                <p className="profile-header__user-name-text">
                    {props.isYourProfile
                        ? userDataState?.first_name
                            ? userDataState?.first_name + " " + userDataState?.last_name
                            : ""
                        : otherUserData?.first_name
                        ? otherUserData!.first_name + " " + otherUserData!.last_name
                        : ""}
                </p>

                {props.isEditable && (
                    <div onClick={() => onUserSettingsModalOpen(false)} className="profile-header__edit-icon">
                        <EditProfileIcon modifier="small" color="#60A49F" />
                    </div>
                )}
            </div>
            
            <div className="profile-header__tags-container">
                {props.isYourProfile ? (
                    userDataState.hashtags?.length! > 0 ? (
                        <div className="profile-header__tags-content">
                            {userDataState.hashtags?.map((hashtag, index) => (
                                <p className="profile-header__tag" key={index} onClick={() => onHashtagClick(hashtag, navigate, role, parseInt(id!))}>
                                    {hashtag.charAt(0) !== "#" ? "#" + hashtag : hashtag}
                                </p>
                            ))}
                        </div>
                    ) : (
                        props.isEditable && (
                            <div className="item__subtext-container">
                                <p className="item__subtext" title={translation.blurryHashtag} onClick={() => onUserSettingsModalOpen(true)}>
                                    {translation.hashtag}
                                </p>
                                <p
                                    className="item__subtext item__subtext--margin-right"
                                    onClick={() => onUserSettingsModalOpen(true)}
                                    title={translation.blurryHashtag}
                                >
                                    {translation.hashtag}
                                </p>
                            </div>
                        )
                    )
                ) : (
                    <div className="profile-header__tags-content">
                        {otherUserData?.hashtags?.map((hashtag, index) => (
                            <p className="profile-header__tag" key={index} onClick={() => onHashtagClick(hashtag, navigate, role, parseInt(id!))}>
                                {hashtag.charAt(0) !== "#" ? "#" + hashtag : hashtag}
                            </p>
                        ))}
                    </div>
                )}
            </div>

            <div className="profile-header__location-container">
                
                {props.isYourProfile ? (
                    <>
                        {userDataState.location_state != null && (
                            <div className="profile-header__location">
                                
                                {userDataState.location_state?.id < 52 && (
                                    <>
                                    <div className="profile-header__location-icon">
                                        <LocationIcon />
                                    </div>
                                    <p className="profile-header__text-location">
                                        {userDataState.location_state?.name_de} , {userDataState.location_state?.country.abbreviation}
                                    </p>
                                    </>
                                )}
                                {userDataState.location_state?.id >= 52 && props.isEditable && (
                                
                                     
                                        <>
                                    <div className="profile-header__location-icon">
                                    <LocationIcon />
                                    </div>
                                    <p className="profile-header__text-location profile-header__add-location-link" onClick={onAddLocationClick}>
                                        {translation.addLocation}
                                    </p>
                                    </>
                                
                                    
                                )}
                            </div>
                        )}

                        {props.isEditable ? (
                            <p
                                className={
                                    userPrivacySettings?.show_contact_info
                                        ? "profile-header__contact-text"
                                        : "profile-header__contact-text profile-header__contact-text--blurry"
                                }
                                onClick={onContactInfoClick}
                                title={`${
                                    translation.contactInfoTooltip +
                                    translation.contactInfoTooltip2 +
                                    translation.contactInfoTooltip3 +
                                    translation.contactInfoTooltip4
                                }`}
                            >
                                {translation.ContactInfo}
                            </p>
                        ) : (
                            userPrivacySettings?.show_contact_info && (
                                <p
                                    className="profile-header__contact-text"
                                    onClick={onContactInfoClick}
                                    title={`${
                                        translation.contactInfoTooltip +
                                        translation.contactInfoTooltip2 +
                                        translation.contactInfoTooltip3 +
                                        translation.contactInfoTooltip4
                                    }`}
                                >
                                    {translation.ContactInfo}
                                </p>
                            )
                        )}
                    </>
                ) : (
                    <>
                         {otherUserData?.location_state != null && userLocationSetting &&(
                            <div className="profile-header__location">
                                
                                {otherUserData?.location_state?.id < 52 && (
                                    <>
                                    <div className="profile-header__location-icon">
                                        <LocationIcon />
                                    </div>
                                    <p className="profile-header__text-location">
                                        {otherUserData.location_state?.name_de} , {otherUserData.location_state?.country.abbreviation}
                                    </p>
                                    </>
                                )}
                            </div>
                        )}
                        {otherUserPrivacySettings?.show_contact_info && (
                            <p className="profile-header__contact-text" onClick={onContactInfoClick}>
                                {translation.ContactInfo}
                            </p>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default UserProfileHeader
