import { createSlice, PayloadAction, combineReducers } from "@reduxjs/toolkit"
import { RoomPosts } from "../../../Types/RoomPosts"

const initialPostState = { post: {} as RoomPosts }

const RoomPostSlice = createSlice({
    name: "roomPostSlice",
    initialState: initialPostState,
    reducers: {
        setRoomPostData(state, action: PayloadAction<RoomPosts>) {
            state.post = action.payload
        },
    },
})

const initialState = { posts: [] as Array<RoomPosts> }

const RoomPostsSlice = createSlice({
    name: "RoomPostsSlice",
    initialState: initialState,
    reducers: {
        setRoomPostsData(state, action: PayloadAction<Array<RoomPosts>>) {
            state.posts = action.payload
        },
        setRoomPostsWithPinnedData(state, action: PayloadAction<RoomPosts>) {
            state.posts = [...state.posts.slice(0, 1), action.payload, ...state.posts.slice(1)]
        },
    },
})

const initialPostIdState = { postId: 0 }

const RoomPostIdSlice = createSlice({
    name: "RoomPostIdSlice",
    initialState: initialPostIdState,
    reducers: {
        setRoomPostId(state, action: PayloadAction<number>) {
            state.postId = action.payload
        },
    },
})

const initialPostPhotoState = { postPhoto: "" }

const RoomPostPhotoSlice = createSlice({
    name: "RoomPostPhotoSlice",
    initialState: initialPostPhotoState,
    reducers: {
        setRoomPostPhoto(state, action: PayloadAction<string>) {
            state.postPhoto = action.payload
        },
    },
})

type postStatus = { postsStatus: boolean; singularPostStatus: boolean; postedInRoomStatus: boolean }

const initialPostStatusState: postStatus = { postsStatus: false, singularPostStatus: false, postedInRoomStatus: false }

const PostStatusSlice = createSlice({
    name: "PostStatusSlice",
    initialState: initialPostStatusState,
    reducers: {
        setPostStatus(state, action: PayloadAction<postStatus>) {
            state.postsStatus = action.payload.postsStatus
            state.singularPostStatus = action.payload.singularPostStatus
            state.postedInRoomStatus = action.payload.postedInRoomStatus
        },
    },
})

const initialEditPostState = { status: false }

const EditPostSlice = createSlice({
    name: "PostStatusSlice",
    initialState: initialEditPostState,
    reducers: {
        setEditPostStatus(state, action: PayloadAction<boolean>) {
            state.status = action.payload
        },
    },
})

const initialCommentIdState = { commentId: 0 }

const RoomCommentIdSlice = createSlice({
    name: "RoomCommentIdSlice",
    initialState: initialCommentIdState,
    reducers: {
        setRoomCommentId(state, action: PayloadAction<number>) {
            state.commentId = action.payload
        },
    },
})
const initialEventCommentIdState = { commentId: 0 }

const RoomEventCommentIdSlice = createSlice({
    name: "RoomEventCommentIdSlice",
    initialState: initialEventCommentIdState,
    reducers: {
        setRoomEventCommentId(state, action: PayloadAction<number>) {
            state.commentId = action.payload
        },
    },
})

const initialPostContentErrorState = { message: "" }

const RoomPostContentErrorSlice = createSlice({
    name: "RoomPostContentErrorSlice ",
    initialState: initialPostContentErrorState,
    reducers: {
        setPostContentErrorState(state, action: PayloadAction<string>) {
            state.message = action.payload
        },
    },
})

export const { setRoomPostData } = RoomPostSlice.actions
export const { setRoomPostsData } = RoomPostsSlice.actions
export const { setRoomPostId } = RoomPostIdSlice.actions
export const { setPostStatus } = PostStatusSlice.actions
export const { setEditPostStatus } = EditPostSlice.actions
export const { setRoomCommentId } = RoomCommentIdSlice.actions
export const { setRoomEventCommentId } = RoomEventCommentIdSlice.actions
export const { setRoomPostPhoto } = RoomPostPhotoSlice.actions
export const { setRoomPostsWithPinnedData } = RoomPostsSlice.actions
export const { setPostContentErrorState } = RoomPostContentErrorSlice.actions

export default combineReducers({
    roomPostSlice: RoomPostSlice.reducer,
    roomPostsSlice: RoomPostsSlice.reducer,
    roomPostIdSlice: RoomPostIdSlice.reducer,
    postStatusSlice: PostStatusSlice.reducer,
    editStatusSlice: EditPostSlice.reducer,
    roomCommentIdSlice: RoomCommentIdSlice.reducer,
    roomEventCommentIdSlice: RoomEventCommentIdSlice.reducer,
    roomPostPhotoSlice: RoomPostPhotoSlice.reducer,
    roomPostContentErrorSlice: RoomPostContentErrorSlice.reducer,
})
