import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GlobalSearchResult } from "../../../Types/GlobalSearchResultType"

type SearchData = {
    result: Array<GlobalSearchResult>
}
const initialState: SearchData = { result: []}

const HashtagSlice = createSlice({
    name: "GlobalSearchSlice",
    initialState: initialState,
    reducers: {
        setHashtagData(state, action: PayloadAction<SearchData>) {
            state.result = action.payload.result
        },
    },
})






export const { setHashtagData } = HashtagSlice.actions



export default combineReducers({
    hashtagSlice: HashtagSlice.reducer,
})