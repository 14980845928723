import { useDispatch, useSelector } from "react-redux"
import HTTPService from "../../../../App/HTTPService"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import { RootState } from "../../../../App/store"
import { CloseIcon } from "../../../Shared/Components/SvgIcons"
import Button from "../../../Shared/Primitive/Button"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { getEventStatus } from "../../../Shared/SharedSlices/SponsorCardSlice"

function EventCommentDelete() {
    const commentId = useSelector((state: RootState) => state.organizationsSlice.pinboardCommentIdSlice.commentId)
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()

    const onModalClose = () => {
        dispatch(setModalData({ open: false, deleteEventComment: false }))
    }

    const DeleteRequest = async () => {
        try {
            const response = await HTTPService.delete(`/event-comment/${commentId}/delete/`)
            if (response.status === 204) {
                dispatch(setModalData({ open: false, deleteEventComment: false }))
                dispatch(getEventStatus(true))
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className="delete-room-modal">
            <div className="delete-room-modal__content">
                <div className="delete-room-modal__upper-panel">
                    <div className="delete-room-modal__title">{translation.deletePost}?</div>
                    <div onClick={onModalClose} className="delete-room-modal__close-icon">
                        <CloseIcon />
                    </div>
                </div>
                <div className="delete-room-modal__divider"></div>
                <div className="delete-room-modal__middle-panel">
                    <div className="delete-room-modal__text">{translation.areYouSureToDeleteComment}</div>
                    <div className="delete-room-modal__delete-icon"></div>
                </div>
                <div className="delete-room-modal__button-container">
                    <div className="delete-room-modal__button">
                        <Button
                            onClick={DeleteRequest}
                            type="submit"
                            className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-red button--hover-brighter button--cursor-pointer button--border-radius-05 "
                        >
                            {translation.delete}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventCommentDelete
