import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { setModalData } from "../SharedSlices/ModalSlice"
import { CloseIcon } from "./SvgIcons"

function NotPremiumCreateRoomModal() {
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()

    const onUpgradeClick = () => {
        dispatch(setModalData({ open: false, notPremiumCreateRoomModal: false }))
        navigate(`/go-premium/${id}`)
    }

    const onModalClose = () => {
        dispatch(setModalData({ open: false, notPremiumCreateRoomModal: false }))
    }
    return (
        <div className="not-premium-modal">
            <div className="not-premium-modal__close-icon" onClick={onModalClose}>
                <CloseIcon />
            </div>
            <div className="not-premium-modal__text-container">
                <p className="not-premium-modal__text not-premium-modal__text--gold" onClick={onUpgradeClick}>
                    {" "}
                    {translation.upgradeYourProfile}
                </p>{" "}
                <p className="not-premium-modal__text">{translation.createAnotherRoom}</p>
            </div>
        </div>
    )
}

export default NotPremiumCreateRoomModal
