import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../App/store"
import { setModalData } from "../SharedSlices/ModalSlice"
import { CloseIcon } from "../Components/SvgIcons"
import Image from "../Primitive/Image"
import { PhotoTypeModal } from "../../../App/enums"
import { PhotoType } from "../../../Types/PhotoType"
import HTTPService from "../../../App/HTTPService"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { User } from "../../../Types/User"

function PhotoModal(props: { type: PhotoType; photo_url?: string }) {
    const dispatch = useDispatch()
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const otherOrganizationPhotoUrl = useSelector((state: RootState) => state.photoDropdownSlice.otherOrganizationPhotoUrlSlice.url)
    const postPhoto = useSelector((state: RootState) => state.RoomPostsSlice.roomPostPhotoSlice.postPhoto)
    const { userId } = useParams()
    const [otherUserData, setOtherUserData] = useState<User>()

    const onModalClose = () => {
        dispatch(setModalData({ open: false, settings: false, viewPhotoTypeModal: null, changePhotoTypeModal: null }))
    }

    const getUserRequest = (id: string) => {
        return HTTPService.get(`/profile/${id}/`)
    }

    useEffect(() => {
        if (props.type === PhotoTypeModal.OTHER_USER_COVER || props.type === PhotoTypeModal.OTHER_USER_PROFILE) {
            getUserRequest(userId!).then(response => {
                if (response.status === 200) {
                    setOtherUserData(response.data)
                }
            })
        }
    }, [])

    const onPhotoOpen = () => {
        switch (props.type) {
            case PhotoTypeModal.ORGANIZATION_PROFILE:
                return organizationDataState.photo?.url_path
            case PhotoTypeModal.ORGANIZATION_COVER:
                return organizationDataState.cover_photo?.url_path
            case PhotoTypeModal.OTHER_ORGANIZATION_PROFILE:
                return otherOrganizationPhotoUrl
            case PhotoTypeModal.OTHER_ORGANIZATION_COVER:
                return otherOrganizationPhotoUrl
            case PhotoTypeModal.USER_COVER:
                return userDataState.cover_photo.url_path
            case PhotoTypeModal.OTHER_USER_COVER:
                return otherUserData?.cover_photo?.url_path
            case PhotoTypeModal.OTHER_USER_PROFILE:
                return otherUserData?.profile_photo?.url_path
            case PhotoTypeModal.POST_FILE:
                return postPhoto
            default:
                return userDataState.profile_photo.url_path
        }
    }
    return (
        <div className="photo-modal">
            <div className="photo-modal__close-modal-container">
                <div className="photo-modal__close-modal" onClick={onModalClose}>
                    <CloseIcon />
                </div>
            </div>
            <Image src={onPhotoOpen()} className="photo-modal__photo " />
        </div>
    )
}

export default PhotoModal
