import React, { useCallback, useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import "../../../Styles/main.scss"
import Button from "../../Shared/Primitive/Button"
import { LargeLogoIcon } from "../../Shared/Components/SvgIcons"
import TextInput from "../../Shared/Primitive/TextInput"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../../App/store"
import { signinPostRequest } from "./SigninServiceRequests"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { resetSigninError } from "./SigninSlice"
import HTTPService from "../../../App/HTTPService"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import SessionService from "../../../App/SessionService"
import { StorageKey } from "../../../App/enums"

const SignInForm = () => {
    const translation = useSelector(selectTranslations)
    const { token, newEmailToken } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const errorState = useSelector((state: RootState) => state.signinSlice.signinError)
    const organizationerrorState = useSelector((state: RootState) => state.EdusiiaMatchingSlice.addOrganizationError)
    const [signinForm, setSigninForm] = useState({ email: "", password: "" })
    let params = new URLSearchParams(location.search)
    const linkName = "invitation-link"
    const redirectUrl = params.get("redirectUrl")

    useEffect(() => {
        if (redirectUrl?.split("/")[1] === linkName) {
            SessionService.setItem(StorageKey.roomInvitationId, params.get("redirectUrl")?.split("/")[2] as string)
        }
    }, [])

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSigninForm({ ...signinForm, [event.target.name]: event.target.value })
    }

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        dispatch(signinPostRequest(signinForm))
    }

    const onSignupButtonClick = () => {
        navigate("/signup")
        dispatch(resetSigninError())
    }

    const getEmailVerification = useCallback(() => {
        return HTTPService.get(`/email-verify/?token=${token}`)
    }, [token])

    const getNewEmailVerification = useCallback(() => {
        return HTTPService.get(`/email-verify/?token=${token}&newemail=${newEmailToken}`)
    }, [newEmailToken, token])

    useEffect(() => {
        if (token) {
            getEmailVerification()
                .then(response => {
                    if (response.status === 200) {
                        dispatch(setModalData({ open: true, postiveEmailVerification: true }))
                    }
                })
                .catch(error => {
                    dispatch(setModalData({ open: true, negativeEmailVerification: { status: true, message: error.response.data.error } }))
                })
        }
        if (newEmailToken) {
            getNewEmailVerification()
                .then(response => {
                    if (response.status === 200) {
                        dispatch(setModalData({ open: true, postiveNewEmailVerification: true }))
                    }
                })
                .catch(error => {
                    dispatch(setModalData({ open: true, negativeEmailVerification: { status: true, message: error.response.data.error } }))
                })
        }
    }, [dispatch, getEmailVerification, getNewEmailVerification, newEmailToken, token])

    return (
        <>
            <form className="signin-form" onSubmit={onFormSubmit}>
                <div className="signin-form__logo">
                    <LargeLogoIcon />
                </div>

                <div className="signin-form__title signin-form__title--margin-top-8 ">
                    <h2 className="h2">{translation.signin}</h2>
                </div>
                <div className="signin-form signin-form__single-input">
                    <p className="p3 signin-form__small-text ">{translation.email}</p>
                    <TextInput type="text" textinputsize="large" name="email" value={signinForm.email} onChange={onInputChange} />
                    <p className="p3  signin-form__error-message">{errorState.message.email}</p>
                </div>

                <div className="signin-form signin-form__single-input">
                    <p className="p3 signin-form__small-text ">{translation.securePassword}</p>
                    <TextInput type="password" textinputsize="large" name="password" value={signinForm.password} autoComplete="on" onChange={onInputChange} />
                    <p className="p3 signin-form__error-message">
                        {errorState.message.password} {errorState.message.non_field_errors}
                    </p>
                </div>
                <div className=" signin-form__forgot-password">
                    <Link to="/forgotpassword" className="p2 signin-form__forgot-password">
                        {translation.forgotPassword}?
                    </Link>

                    <p className="p3  signin-form__error-message">{organizationerrorState.message.error}</p>
                </div>
                <div className=" signin-form__button-container">
                    <div className=" signin-form__button-size">
                        <Button
                            type="submit"
                            className="button button--border-radius-09 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-large button--font-weight-400  "
                        >
                            <div>{translation.signin}</div>
                        </Button>
                    </div>
                </div>
                <div className=" signin-form__title signin-form__title--margin-top-4 " onClick={onSignupButtonClick}>
                    <div className="h3 signin-form__title signin-form__title--pointer">{translation.joinEdusiiaToday}</div>
                    <div className="h3 signin-form__title signin-form__title--pointer">{translation.signup}</div>
                </div>
            </form>
        </>
    )
}
export default SignInForm
