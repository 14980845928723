import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import { RootState } from "../../../../App/store"
import Button from "../../../Shared/Primitive/Button"
import { CloseIcon } from "../../../Shared/Components/SvgIcons"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { EmployeeDeleteRequest } from "./OrganizationProfileEmployeeServiceRequests"
import { useParams } from "react-router-dom"

function OrganizationProfileRemoveEmployeeModal() {
    const translation = useSelector(selectTranslations)
    const employeeId = useSelector((state: RootState) => state.employeesSlice.removeEmployeeSlice.id)
    const dispatch = useDispatch()
    const EmployeesState = useSelector((state: RootState) => state.employeesSlice.employeesSlice.employees)
    const selectedEmployee = EmployeesState.find(employee => employee.user.id === employeeId)
    const { id } = useParams()
    const organizationId = parseInt(id!)

    const onModalClose = () => {
        dispatch(setModalData({ open: false, removeEmployeeModal: false }))
    }

    const handleOnClick = () => {
        dispatch(EmployeeDeleteRequest(organizationId, selectedEmployee?.id!))
    }
    return (
        <div className="remove-employee-modal">
            <div className="remove-employee-modal__title">
                {translation.removeEmployee}{" "}
                <div className="remove-employee-modal__close-icon" onClick={onModalClose}>
                    <CloseIcon />
                </div>
            </div>
            <div className="remove-employee-modal__content">
                {`${translation.removeEmployeeQuestion1}  ${selectedEmployee?.user.first_name}  ${selectedEmployee?.user.last_name} ${translation.removeEmployeeQuestion2}`}
            </div>
            <div className="remove-employee-modal__button ">
                <Button
                    className=" button button--border-radius-09 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400"
                    onClick={handleOnClick}
                >
                    {translation.remove}
                </Button>
            </div>
        </div>
    )
}

export default OrganizationProfileRemoveEmployeeModal
