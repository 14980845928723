import { useSelector } from "react-redux"
import { SearchFilter } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"

function SearchFilterContainer(props: { filterState: string; setFilterState: Function; setPageNum: Function; isGlobaleSearch: boolean }) {
    const translation = useSelector(selectTranslations)
    const onFilterSelect = (SelectedFilter: string) => {
        props.setFilterState(SelectedFilter)
        props.setPageNum(1)
    }
    return (
        <div className="search-component__filter-container">
            <div
                onClick={() => onFilterSelect(SearchFilter.all_results)}
                className={`search-component__filter-item ${props.filterState === SearchFilter.all_results && "search-component__filter-item--selected"}`}
            >
                {translation.allResults}
            </div>
            <div
                onClick={() => onFilterSelect(SearchFilter.users)}
                className={`search-component__filter-item ${props.filterState === SearchFilter.users && "search-component__filter-item--selected"}`}
            >
                {translation.individuals}
            </div>
            <div
                onClick={() => onFilterSelect(SearchFilter.organizations)}
                className={`search-component__filter-item ${props.filterState === SearchFilter.organizations && "search-component__filter-item--selected"}`}
            >
                {translation.organizations}
            </div>
            <div
                onClick={() => onFilterSelect(SearchFilter.rooms)}
                className={`search-component__filter-item ${props.filterState === SearchFilter.rooms && "search-component__filter-item--selected"}`}
            >
                {translation.rooms}
            </div>
            <div
                onClick={() => onFilterSelect(SearchFilter.events)}
                className={`search-component__filter-item ${props.filterState === SearchFilter.events && "search-component__filter-item--selected"}`}
            >
                {translation.events}
            </div>

            <div
                onClick={() => onFilterSelect(SearchFilter.posts_and_comments)}
                className={`search-component__filter-item ${
                    props.filterState === SearchFilter.posts_and_comments && "search-component__filter-item--selected"
                }`}
            >
                {translation.postsAndComments}
            </div>
            {props.isGlobaleSearch && (
                <div
                    onClick={() => onFilterSelect(SearchFilter.hashtags)}
                    className={`search-component__filter-item ${props.filterState === SearchFilter.hashtags && "search-component__filter-item--selected"}`}
                >
                    {translation.hashtags}
                </div>
            )}
        </div>
    )
}

export default SearchFilterContainer
