import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { OrganizationEmailNofificationType, OrganizationWebNofificationType } from "../../../Types/Notification"

export const organizationWebNotificationsSettingsGetRequest = (id: string) => {
    return HTTPService.get(`/organization/${id}/web-notifications/`, headers)
}

export const organizationEmailNotificationsSettingsGetRequest = (id: string) => {
    return HTTPService.get(`/organization/${id}/email-notifications/`, headers)
}

export const organizationWebNotificationsSettingsPatchRequest = (notificationData: OrganizationWebNofificationType, id: string) => {
    HTTPService.patch(
        `/organization/${id}/web-notifications/`,
        {
            comment: notificationData.comment,
            commented_on_same_post:notificationData.commented_on_same_post,
            tags: notificationData.tags,
            org_room_invitation: notificationData.org_room_invitation,
            room_invitation_reply: notificationData.room_invitation_reply,
            room_join_request: notificationData.room_join_request,
            room_join_request_reply: notificationData.room_join_request_reply,
            new_member: notificationData.new_member,
            event_comment: notificationData.event_comment,
            new_post_in_room: notificationData.new_post_in_room,
           
        },
        headers
    )
        .then(response => {
            if (response.status === 200) {
                organizationWebNotificationsSettingsGetRequest(id)
            }
        })
        .catch(error => {
            console.log(error)
        })
}

export const organizationEmailNotificationsSettingsPatchRequest = (notificationData: OrganizationEmailNofificationType, id: string) => {
    HTTPService.patch(
        `/organization/${id}/email-notifications/`,
        {
            comment: notificationData.comment,
            commented_on_same_post:notificationData.commented_on_same_post,
            tags: notificationData.tags,
            room_invitation: notificationData.room_invitation,
            room_invitation_reply: notificationData.room_invitation_reply,
            room_join_request: notificationData.room_join_request,
            room_join_request_reply: notificationData.room_join_request_reply,
            new_member: notificationData.new_member,
            event_comment: notificationData.event_comment,
            new_post_in_room: notificationData.new_post_in_room,
        },
        headers
    )
        .then(response => {
            if (response.status === 200) {
                organizationEmailNotificationsSettingsGetRequest(id)
            }
        })
        .catch(error => {
            console.log(error)
        })
}
