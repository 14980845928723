import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { headers } from "../../../App/Axios"
import { JoinStatus, RoleType, RoomType } from "../../../App/enums"
import { organizationProfileDefault, profileDefault } from "../../../App/GlobaleVariables"
import HTTPService from "../../../App/HTTPService"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { CloseIcon } from "../../Shared/Components/SvgIcons"
import UserCard from "../../Shared/Components/UserCard"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { setRoomMembers } from "../RoomSlices/MembersInRoomSlice"
import { setRemoveRoomMemberId } from "../RoomSlices/RoomMemberSlice"

function RoomMembers() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const translation = useSelector(selectTranslations)
    const isMemberInRoom = useSelector((state: RootState) => state.MemberInRoomStatusSlice.MemberInRoomStatusSlice.isMember)
    const isRoomManager = useSelector((state: RootState) => state.roomSlice.roomManagerSlice.room_manager)
    const isRoomOwner = useSelector((state: RootState) => state.roomSlice.roomOwnerSlice.room_owner)
    const Members = useSelector((state: RootState) => state.MembersInRoomSlice.members)
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const { roomId, id } = useParams()
    const canSeeMembers = roomDataState.type === RoomType.Private ? isMemberInRoom === JoinStatus.MEMBER || isRoomOwner : true
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const hasPrivelegedPermission = role === RoleType.USER ? isRoomManager : isRoomOwner

    const getRequest = useCallback(() => {
        return HTTPService.get(`/room/${roomId}/members/`, headers)
    }, [roomId])

    useEffect(() => {
        getRequest()
            .then(response => {
                if (response.status === 200) {
                    dispatch(setRoomMembers(response.data.user))
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [dispatch, getRequest])

    const onRoomMemberClick = (memberId: number) => {
        switch (role) {
            case RoleType.USER:
                if (userDataState.id === memberId) {
                    navigate("/user-profile")
                } else {
                    navigate(`/user/${memberId}`)
                }
                break
            case RoleType.ORGANIZATION:
                if (userDataState.id === memberId) {
                    navigate("/user-profile")
                } else {
                    navigate(`/user/${memberId}/${id}`)
                }
                break
            default:
                break
        }
    }

    const onOrganizationRoomMemberClick = (memberId: number) => {
        navigate(`/organization-profile/${memberId}`)
    }

    const onRemoveRoomMemberModalOpen = (id: number, type: string) => {
        dispatch(setRemoveRoomMemberId({ id: id, type: type }))
        dispatch(setModalData({ open: true, removeRoomMemberModal: true }))
    }

    return (
        <div className="room-members">
            <div
                className="room-members__header"
                onClick={
                    isMemberInRoom && !canSeeMembers
                        ? undefined
                        : () => {
                              dispatch(setModalData({ open: true, roomMembersModal: true }))
                          }
                }
            >
                <div className="room-members__text">
                    {Members?.length || 0} {Members?.length === 1 ? translation.member : translation.members}
                </div>
            </div>

            <div className="room-members__container">
                {isMemberInRoom && !canSeeMembers ? (
                    <div className="room-members__no-access"> {translation.onlyMembersCanSeeOtherMembers} </div>
                ) : (
                    Members?.map((member, index) => {
                        return (
                            <div className="room-members__items" key={member.id + member.type}>
                                {member.type === RoleType.USER.toLowerCase() ? (
                                    <UserCard
                                        imageSrc={member.profile_photo?.url_path || profileDefault}
                                        text={member.first_name + " " + member.last_name}
                                        subtextStyle="tag-container"
                                        profileImageStyle="image image__profile image__profile--fit image__profile--size-small "
                                        userCardStyle={`${index === Members.length - 1 ? "room-members-item-no-border" : "room-members-item-border"}`}
                                        textStyle={"text"}
                                        textStyleMore25={"text"}
                                        middlePanelStyle={"middle-panel"}
                                        rightText={
                                            hasPrivelegedPermission && !roomDataState?.room_managers?.some(manager => manager.id === member.id) ? (
                                                <div className="room-members__close-icon" onClick={() => onRemoveRoomMemberModalOpen(member.id!, member.type)}>
                                                    <CloseIcon />
                                                </div>
                                            ) : null
                                        }
                                        rightPanelStyle={"right-panel"}
                                        onMiddleItemClick={() => onRoomMemberClick(member.id)}
                                        onLeftItemClick={() => onRoomMemberClick(member.id)}
                                    />
                                ) : (
                                    <UserCard
                                        imageSrc={member.photo?.url_path || organizationProfileDefault}
                                        text={member.name}
                                        subtextStyle="tag-container"
                                        profileImageStyle="image image__profile image__profile--fit image__profile--size-small "
                                        userCardStyle={`${index === Members.length - 1 ? "room-members-item-no-border" : "room-members-item-border"}`}
                                        textStyle={"text"}
                                        textStyleMore25={"text"}
                                        middlePanelStyle={"middle-panel"}
                                        rightText={
                                            hasPrivelegedPermission ? (
                                                <div className="room-members__close-icon" onClick={() => onRemoveRoomMemberModalOpen(member.id!, member.type)}>
                                                    <CloseIcon />
                                                </div>
                                            ) : null
                                        }
                                        rightPanelStyle={"right-panel"}
                                        onMiddleItemClick={() => onOrganizationRoomMemberClick(member.id)}
                                        onLeftItemClick={() => onOrganizationRoomMemberClick(member.id)}
                                    />
                                )}
                            </div>
                        )
                    })
                )}
            </div>
        </div>
    )
}

export default RoomMembers
