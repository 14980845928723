import { NavigateFunction } from "react-router"
import { RoleType, StorageKey } from "../../../App/enums"
import SessionService from "../../../App/SessionService"

export const onDashboardNavigation = (role: keyof typeof RoleType, navigate: NavigateFunction, id: string) => {
    const orgId = SessionService.getItem(StorageKey.organizationId)
    switch (role) {
        case RoleType.USER:
            navigate("/dashboard")
            window.scrollTo(0, 0)
            break
        case RoleType.ORGANIZATION:
            if (orgId === id) {
                navigate(`/organization-dashboard/${id!}`)
                window.scrollTo(0, 0)
            } else {
                navigate(`/organization-dashboard/${orgId!}`)
                window.scrollTo(0, 0)
            }
            break
        default:
            break
    }
}
