import React from "react"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { PostType, RoleType } from "../../../App/enums"
import { green } from "../../../App/GlobaleVariables"
import { RootState } from "../../../App/store"
import { CloseIcon, EditProfileIcon, PinIcon, PostDropdownIcon } from "../../Shared/Components/SvgIcons"

function RoomPostDropdown(props: {
    type: PostType.comment | PostType.post | PostType.event
    first_label: string
    second_label: string
    third_label?: string
    onDeleteModalOpen?: () => void
    onEditPostClick?: () => void
    onPinPostClick?: () => void
    isYourPost: boolean
}) {
    const [isRoomPostDropdownOpen, setIsRoomPostDropdownOpen] = useState(false)
    let dropdownRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    const isRoomManager = useSelector((state: RootState) => state.roomSlice.roomManagerSlice.room_manager)
    const roles = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const isRoomOwner = useSelector((state: RootState) => state.roomSlice.roomOwnerSlice.room_owner)
    const hasPrivilegedPermission = roles === RoleType.USER ? isRoomManager : isRoomOwner

    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (isRoomPostDropdownOpen && dropdownRef.current && !dropdownRef.current.contains(event.target as HTMLElement)) {
                setIsRoomPostDropdownOpen(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    return (
        <>
            <div className="post-dropdown" ref={dropdownRef}>
                <div
                    className="post-dropdown__icon"
                    onClick={() => {
                        setIsRoomPostDropdownOpen(!isRoomPostDropdownOpen)
                    }}
                >
                    <PostDropdownIcon />
                </div>
                {isRoomPostDropdownOpen && (
                    <div
                        className="post-dropdown__item-container"
                        onClick={() => {
                            setIsRoomPostDropdownOpen(!isRoomPostDropdownOpen)
                        }}
                    >
                        {props.type !== PostType.comment && hasPrivilegedPermission && (
                            <div className="post-dropdown__item" onClick={props.onPinPostClick}>
                                <PinIcon color="green" />
                                <p className="post-dropdown__item-edit">{props.third_label}</p>
                            </div>
                        )}
                        {props.isYourPost && (
                            <>
                                <div
                                    className={`${
                                        props.type === PostType.post
                                            ? "post-dropdown__item post-dropdown__item--margin-top"
                                            : props.type === PostType.event
                                            ? "post-dropdown__item post-dropdown__item--margin-top post-dropdown__item--disabled"
                                            : "post-dropdown__item-comment"
                                    }
                            `}
                                    onClick={props.onEditPostClick}
                                >
                                    <EditProfileIcon modifier="small" color={`${green}`} />
                                    <p className="post-dropdown__item-edit">{props.first_label}</p>
                                </div>
                                <div
                                    className={`${
                                        props.type === PostType.post || props.type === PostType.event ? "post-dropdown__item" : "post-dropdown__item-comment "
                                    } `}
                                    onClick={props.onDeleteModalOpen}
                                >
                                    <CloseIcon />
                                    <p className="post-dropdown__item-delete">{props.second_label}</p>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}
export default RoomPostDropdown
