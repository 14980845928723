import React from "react"
import "../../../Styles/main.scss"
import UserCard from "../Components/UserCard"

function HeaderMobileProfileCard(props: { textStyle: string; subtextStyle: string; imageSrc: string; text: string; subtext: string }) {
    return (
        <UserCard
            userCardStyle="header-mobile-profile-card"
            leftPanelStyle="left-panel"
            imageSrc={props.imageSrc}
            profileImageStyle="image "
            middlePanelStyle="middle-panel"
            text={props.text}
            subtext={props.subtext}
            textStyle={props.textStyle}
            textStyleMore25={props.textStyle}
            subtextStyle={props.subtextStyle}
        />
    )
}

export default HeaderMobileProfileCard
