import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { setUserOrganizationsData } from "../../Shared/SharedSlices/OrganizationsSlice"
import { getOrganizationData } from "../OrganizationDashboard/GetOrganizationServiceRequest"
import { getOrganizationPrivacySettingsState } from "./OrganizationSettingsSlice"

export const privacySettingsGetRequest = (id: string) => {
    return HTTPService.get(`/organization/${id}/privacy-settings/`, headers)
}

export const putOrganizationPrivacy = (id: string, userId: number, privacySettingsForm: object) => async (dispatch: any) => {
    const values = [privacySettingsForm]

    try {
        const response = await HTTPService.put(`/organization/${id}/privacy-settings/`, { org_privacy_settings: values }, headers)
        if (response.status === 200) {
            getOrganizationData(id!).then(response => {
                if (response.status === 200) {
                    dispatch(setUserOrganizationsData(response.data))
                    dispatch(getOrganizationPrivacySettingsState(false))
                }
            })
        }
    } catch (error: any) {
        console.log(error)
    }
}
