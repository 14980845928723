import React from "react"
import { useSelector } from "react-redux"
import { RoomType } from "../../../../App/enums"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import Image from "../../../Shared/Primitive/Image"

function OrganizationProfileRoomItem(props: {
    onClick: () => void
    isLastItem: boolean
    image: string
    firstText: string
    type: string
    members: string
    date: string
}) {
    const translation = useSelector(selectTranslations)

    return (
        <div onClick={props.onClick} className={`organization-profile-rooms-item ${props.isLastItem && "organization-profile-rooms-item--border-none"}`}>
            <div className="organization-profile-rooms-item__image">
                <Image className="image image__profile image__profile--fit image__profile--size-small" src={props.image} alt="Organization" />
            </div>
            <div className="organization-profile-rooms-item__organization-name">
                <p className="organization-profile-rooms-item__overflow">{props.firstText}</p>
            </div>
            <div className="organization-profile-rooms-item__type">
                <div
                    className={`organization-profile-rooms-item__type-container organization-profile-rooms-item__type-container--${
                        props.type === translation.private ? RoomType.Private : RoomType.Public
                    }`}
                >
                    <div className="organization-profile-rooms-item__text ">{props.type}</div>
                </div>
            </div>
            <div className="organization-profile-rooms-item__members">
                <div className="organization-profile-rooms-item__members-container">
                    <div className="organization-profile-rooms-item__text">
                        {props.members} {props.members === "1" ? translation.member : translation.members}
                    </div>
                </div>
            </div>
            <div className="organization-profile-rooms-item__date">{props.date}</div>
        </div>
    )
}

export default OrganizationProfileRoomItem
