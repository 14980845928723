import { useDispatch, useSelector } from "react-redux"
import { mitgliedschaftEmail } from "../../../App/GlobaleVariables"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { setModalData } from "../SharedSlices/ModalSlice"
import { CloseIcon } from "./SvgIcons"

function RoomLimitModal(props: { max_rooms: number }) {
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()

    const onModalClose = () => {
        dispatch(setModalData({ open: false, roomLimitModal: { status: false, max_rooms: 5 } }))
    }
    return (
        <div className="room-limit-modal">
            <div className="room-limit-modal__close-icon" onClick={onModalClose}>
                <CloseIcon />
            </div>
            <div className="room-limit-modal__text-container">
                <p className="room-limit-modal__text">
                    {translation.moreThanRoomLimit1 + " " + props.max_rooms + " " + translation.moreThanRoomLimit2 + " "}
                    <a href={`mailto:${mitgliedschaftEmail}`} target="_blank" rel="noreferrer" className="room-limit-modal__text room-limit-modal__text--blue">
                        {mitgliedschaftEmail}
                    </a>
                    .
                </p>
            </div>
        </div>
    )
}

export default RoomLimitModal
