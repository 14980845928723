import React, { useRef, useEffect } from "react"
import { profileDefault, organizationProfileDefault } from "../../../App/GlobaleVariables"
import { formatDate, openLinkInNewTab } from "../../../App/Helpers/Helpers"
import { PostComments } from "../../../Types/RoomPosts"
import { EventComments } from "../../../Types/SponsorResult"
import UserCard from "./UserCard"
import { CommentType, PostType, RoleType } from "../../../App/enums"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../App/store"
import { useNavigate, useParams } from "react-router-dom"
import { setModalData } from "../SharedSlices/ModalSlice"
import RoomPostDropdown from "../../Room/RoomPost/RoomPostDropdown"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { useState } from "react"
import { setRoomCommentId } from "../../Room/RoomPost/RoomPostSlice"
import { setPinboardCommentId } from "../SharedSlices/OrganizationsSlice"
import RoomPostEdit from "../../Room/RoomPost/RoomPostEdit"
import { convertTagElement } from "./Editor/convertTagElement"
import { SmileyFace } from "../../Shared/Components/SvgIcons"
import Reaction from "../../Shared/Components/Reactions"
import Image from "../../Shared/Primitive/Image"
import { hasPostDropdown } from "../../Room/RoomPost/hasPostDropdown"
import { onUserClick } from "../../Room/RoomPost/onNavigation"
import MessageBody from "./MessageBody"
import { CallerComponent } from "../../../App/enums"

function Comments(props: { commentsarray: Array<EventComments> | Array<PostComments>; type: CommentType.event | CommentType.post }) {
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isEditable, setIsEditable] = useState(false)
    const [selectedComment, setSelectedComment] = useState<number>()

    openLinkInNewTab()

    const onCommentUserClick = (content_type: string, role: keyof typeof RoleType, postUserId: number, currentUserId: number, orgId: string) => {
        onUserClick(content_type, role, postUserId, currentUserId, orgId, navigate)
        dispatch(setModalData({ open: false, pinboardCommentsModal: false }))
    }

    const onDeleteModalOpen = (id: number) => {
        switch (props.type) {
            case CommentType.post:
                dispatch(setRoomCommentId(id))
                dispatch(setModalData({ open: true, deleteRoomComment: true }))
                break
            case CommentType.event:
                dispatch(setPinboardCommentId(id))
                dispatch(setModalData({ open: true, deleteEventComment: true }))
                break
            default:
                break
        }
    }

    const onEditCommentClick = (id: number) => {
        setSelectedComment(id)
        setIsEditable(true)
    }

    return (
        <>
            {props.commentsarray?.map(comment => {
                const mentionedUsers = document.querySelectorAll("[data-type]")
                convertTagElement(mentionedUsers, comment.tags!, role, id!, userDataState.id)
                return selectedComment === comment.id ? (
                    <div key={comment.id + comment.content_type}>
                        <RoomPostEdit
                            id={selectedComment!}
                            isEditable={setIsEditable}
                            type={PostType.comment}
                            subtype={props.type}
                            content={comment}
                            removeId={setSelectedComment}
                        />
                    </div>
                ) : (
                    <Comment
                        key={comment.id}
                        type={props.type}
                        comment={comment}
                        hasPostDropdown={hasPostDropdown}
                        onEditCommentClick={onEditCommentClick}
                        onDeleteModalOpen={onDeleteModalOpen}
                        onUserClick={onCommentUserClick}
                    />
                )
            })}
        </>
    )
}

export default Comments

type IProps = {
    comment: EventComments | PostComments
    type: string
    hasPostDropdown: Function
    onEditCommentClick: Function
    onDeleteModalOpen: Function
    onUserClick: Function
}

function Comment({ comment, type, hasPostDropdown, onEditCommentClick, onDeleteModalOpen, onUserClick }: IProps) {
    const { id } = useParams()
    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)
    const [isEmojiSelectorOpen, setIsEmojiSelectorOpen] = useState(false)
    let emojiRef = useRef() as React.MutableRefObject<HTMLInputElement>
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const showRemovePreviewIcon = role === RoleType.USER ? comment.content_object?.id === userDataState.id : comment.content_object?.id === parseInt(id!)
    const isMemberInRoom = useSelector((state: RootState) => state.MemberInRoomStatusSlice.MemberInRoomStatusSlice.isMember)
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const organizationOwnerName = roomDataState.organization?.name
    const organizationOwnerId = roomDataState.organization?.id

    function moderatorOfRoomExists(id: number, first_name: string | undefined) {
        return roomDataState?.room_managers?.some(function (el) {
            return el.id === id && el.first_name === first_name
        })
    }

    const isRoomCreator = organizationOwnerName === comment.content_object?.name && organizationOwnerId === comment.content_object?.id
    const isRoomModerator = isMemberInRoom && moderatorOfRoomExists(comment?.content_object?.id, comment?.content_object?.first_name)
    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (isEmojiSelectorOpen && emojiRef.current && !emojiRef.current.contains(event.target as HTMLElement)) {
                setIsEmojiSelectorOpen(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    useEffect(() => {
        const mentionedUsers = document.querySelectorAll("[data-type]")
        convertTagElement(mentionedUsers, comment.tags!, role, id!, userDataState.id)
    }, [id, comment.tags, role, userDataState.id])

    const openReactionsModal = (
        reaction_info: {
            count: number
            name: string
            users: { id: number; first_name?: string; last_name?: string; profile_photo?: { id: number; url_path: string } }[]
        }[]
    ) => {
        dispatch(setModalData({ open: true, reactionsModal: { status: true, reaction: reaction_info } }))
    }

    const reactionCount = comment.reaction_info?.map(reaction => {
        return reaction.count
    })

    const totalReactionCount = reactionCount?.reduce((total, num) => total + num, 0)

    return (
        <div key={comment.id + comment.content_type}>
            <UserCard
                imageSrc={
                    comment?.content_type === RoleType.USER.toLowerCase()
                        ? comment.content_object?.profile_photo?.url_path || profileDefault
                        : comment.content_object?.photo?.url_path || organizationProfileDefault
                }
                text={
                    comment?.content_type === RoleType.ORGANIZATION.toLowerCase()
                        ? comment.content_object
                            ? comment.content_object?.name
                            : translation.deletedOrganization
                        : comment.content_object
                        ? comment.content_object?.first_name + " " + comment.content_object?.last_name
                        : translation.deletedUser
                }
                rightText={
                    <>
                        <div className="comments-profile-card__date">{formatDate(comment.date, "HH:mm dd.MM.yyyy")} </div>
                        {hasPostDropdown(comment, role, organizationDataState.id, userDataState.id, false,roomDataState.is_active) && (
                            <div className="comments-profile-card__comment-dropdown">
                                <RoomPostDropdown
                                    type={PostType.comment}
                                    first_label={translation.editComment}
                                    second_label={translation.deleteComment}
                                    onDeleteModalOpen={() => onDeleteModalOpen(comment.id)}
                                    onEditPostClick={() => onEditCommentClick(comment.id)}
                                    isYourPost={hasPostDropdown(comment, role, organizationDataState.id, userDataState.id, false,roomDataState.is_active)}
                                />
                            </div>
                        )}
                    </>
                }
                userCardStyle={"comments-profile-card"}
                leftPanelStyle={`left-panel ${comment.content_object && "comments-profile-card__left-panel--cursor-pointer"}`}
                middlePanelStyle={`middle-panel ${comment.content_object && "comments-profile-card__middle-panel--cursor-pointer"}`}
                rightPanelStyle={"right-panel"}
                profileImageStyle={"image image__profile image__profile--fit image__profile--size-small"}
                textStyle={`text ${!comment.content_object && "comments-profile-card__text--deleted"}
                ${isRoomCreator && type === CommentType.post && "comments-profile-card__text--organization"}
                ${isRoomModerator && type === CommentType.post && "comments-profile-card__text--admin"}`}
                textStyleMore25={`text ${!comment.content_object && "comments-profile-card__text--deleted"} ${
                    isRoomCreator && type === CommentType.post && "comments-profile-card__text--organization"
                }
                ${isRoomModerator && type === CommentType.post && "comments-profile-card__text--admin"}`}
                rightTextStyle={"right-panel"}
                onLeftItemClick={() => onUserClick(comment.content_type, role, comment.content_object.id, userDataState.id, id!)}
                onMiddleItemClick={() => onUserClick(comment.content_type, role, comment.content_object.id, userDataState.id, id!)}
            />
            <div className="comments__text">
                <MessageBody
                    caller={type === "event" ? CallerComponent.eventComment : CallerComponent.comment}
                    id={comment.id}
                    showPreview={comment.show_preview}
                    text={comment.content}
                    showRemovePreviewIcon={showRemovePreviewIcon}
                    showCollapsed={false}
                    hashtags={comment.hashtags}
                />
            </div>

            <div className="comments__actions-container">
                <div className="comments__reactions-container">
                    {comment.reaction_info?.map((reaction, index) => {
                        return (
                            <div
                                key={index}
                                className={`comments__reaction ${index === 2 && "comments__reaction--no-margin"}`}
                                onClick={() => openReactionsModal(comment.reaction_info!)}
                            >
                                <div className="comments__emoji" title={reaction.name}>
                                    <Image src={`/reactions/${reaction.name}.svg`} className="comments__emoji-image" />
                                </div>
                            </div>
                        )
                    })}
                    {totalReactionCount! > 0 && <div className={`room-post__reaction-count `}>{totalReactionCount}</div>}

                    {roomDataState.is_active && (
                        <>
                        <div
                        onClick={() => {
                            setIsEmojiSelectorOpen(!isEmojiSelectorOpen)
                        }}
                        className="comments__add-emoji-icon"
                    >
                        <SmileyFace />
                    </div>
                    {isEmojiSelectorOpen && (
                        <div ref={emojiRef} className="comments__emoji-picker">
                            <Reaction
                                caller={type === "event" ? CallerComponent.eventComment : CallerComponent.comment}
                                callerId={comment.id}
                                open={isEmojiSelectorOpen}
                                setIsEmojiSelectorOpen={setIsEmojiSelectorOpen}
                            />
                        </div>
                    )}
                    </>
                    )}
                    
                </div>
            </div>
        </div>
    )
}
