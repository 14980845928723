import { useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { headers } from "../../../App/Axios"
import { organizationProfileDefault } from "../../../App/GlobaleVariables"
import HTTPService from "../../../App/HTTPService"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import "../../../Styles/main.scss"
import { LoadingAnimation } from "../../Shared/Components/SvgIcons"
import UserCard from "../../Shared/Components/UserCard"
import { setUserOrganizationsData } from "../../Shared/SharedSlices/OrganizationsSlice"

function MyOrganizationsGrid() {
    const translation = useSelector(selectTranslations)
    const organizationsDataState = useSelector((state: RootState) => state.organizationsSlice.userOrganizationsSlice.organizations.map(item => item))
    const organizationsData = organizationsDataState.map(item => item)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const GetOrganizationsRequest = useCallback(() => {
        return HTTPService.get(`/my-organization-list/`, headers)
    }, [])

    useEffect(() => {
        if (userDataState.id) {
            GetOrganizationsRequest()
                .then(response => {
                    if (response.status === 200) {
                        dispatch(setUserOrganizationsData(response.data))
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [GetOrganizationsRequest, dispatch, userDataState.id])

    const onCardClick = (id: number) => {
        navigate(`/organization-dashboard/${id}`)
        window.scrollTo(0, 0)
    }

    return (
        <div className="my-organizations">
            <div className="my-organizations__title">{translation.myOrganizations}</div>
            <div className="my-organizations__content">
                {organizationsData.length === 0 && <LoadingAnimation />}
                {organizationsData.map(item => (
                    <UserCard
                        onClick={() => onCardClick(item.id!)}
                        key={item.id}
                        leftPanelStyle="left-panel"
                        userCardStyle="organizations-card"
                        imageSrc={item.photo?.url_path || organizationProfileDefault}
                        profileImageStyle="image image__profile image__profile--fit image__profile--size-medium-small "
                        middlePanelStyle="mid-panel"
                        text={item.name}
                        textStyle="text-style"
                        subtextStyle="subtext-style"
                        textStyleMore25="textMore25"
                    />
                ))}
            </div>
        </div>
    )
}

export default MyOrganizationsGrid
