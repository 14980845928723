import { headers } from "../../../../App/Axios"
import HTTPService from "../../../../App/HTTPService"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { getUserProfileExperienceErrorMessages, resetUserProfileExperienceError, setWorkExperienceData } from "../../../Shared/SharedSlices/WorkExperienceSlice"

export const postUserExperience =
    (
        work_position: string,
        company_name: string,
        start_date: string,
        end_date: string | null,
        userId: number,
        org_id: number | null,
        is_in_edusiia: boolean,
        website_url: string | undefined,
        is_current_role?: boolean
    ) =>
    async (dispatch: any) => {
        const UserExperience = {
            work_position: work_position,
            company_name: company_name,
            start_date: start_date,
            end_date: end_date,
            is_in_edusiia: is_in_edusiia,
            org_id: org_id,
            website_url: website_url,
            is_current_role: is_current_role,
        }

        try {
            const response = await HTTPService.post(`/user/${userId}/work/`, UserExperience, headers)
            if (response.status === 201) {
                const response = await HTTPService.get(`/user/${userId}/work/`, headers)
                dispatch(setWorkExperienceData(response.data))
                dispatch(setModalData({ open: false, newExperience: false }))
                dispatch(setModalData({ open: true, newExperienceAddedSuccessfully: true }))

                await dispatch(resetUserProfileExperienceError())
            }
        } catch (error: any) {
            const errors = {
                msg: error.response.data,
                status: error.response.status,
            }

            await dispatch(
                getUserProfileExperienceErrorMessages({
                    message: errors.msg,
                    status: errors.status,
                })
            )
        }
    }

export const editUserExperience =
    (
        work_position: string,
        company_name: string,
        start_date: string,
        end_date: string | null,
        id: number,
        userId: number,
        org_id: number | null,
        is_in_edusiia: boolean,
        website_url: string | undefined,
        is_current_role: boolean
    ) =>
    async (dispatch: any) => {
        const UserExperience = {
            work_position: work_position,
            company_name: company_name,
            start_date: start_date,
            end_date: end_date,
            is_in_edusiia: is_in_edusiia,
            org_id: org_id,
            website_url: website_url,
            is_current_role: is_current_role,
        }

        try {
            const response = await HTTPService.patch(`/user/${userId}/work/${id}/`, UserExperience, headers)
            if (response.status === 200) {
                const response = await HTTPService.get(`/user/${userId}/work/`, headers)
                dispatch(setWorkExperienceData(response.data))
                dispatch(setModalData({ open: false, editExperience: false }))
                dispatch(setModalData({ open: true, editExperienceAddedSuccessfully: true }))

                await dispatch(resetUserProfileExperienceError())
            }
        } catch (error: any) {
            const errors = {
                msg: error.response.data,
                status: error.response.status,
            }

            await dispatch(
                getUserProfileExperienceErrorMessages({
                    message: errors.msg,
                    status: errors.status,
                })
            )
        }
    }

export const deleteExperience = (id: number, userId: number) => async (dispatch: any) => {
    try {
        const response = await HTTPService.delete(`/user/${userId}/work/${id}/`, headers)
        if (response.status === 204) {
            const response = await HTTPService.get(`/user/${userId}/work/`, headers)
            dispatch(setModalData({ open: false, editExperience: false }))
            dispatch(setWorkExperienceData(response.data))
            dispatch(setModalData({ open: true, deleteExperienceSuccessfully: true }))
        }
    } catch (error: any) {
        return
    }
}
