import React from "react"
import { useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { PremiumIconSmall } from "../../Shared/Components/SvgIcons"
import Button from "../../Shared/Primitive/Button"

function GoPremiumPriceCard(props: { onClick?: () => void; isProfitableOrganization: boolean; monthNumber: number; price: number; isCurrentPlan?: boolean }) {
    const translation = useSelector(selectTranslations)

    return (
        <div
            className={`go-premium-price-card ${props.isProfitableOrganization && "go-premium-price-card--color-red"} ${
                props.isCurrentPlan && "go-premium-price-card--display-none"
            }`}
        >
            <div className="go-premium-price-card__permium-container">
                <div className="go-premium-price-card__premium-title">
                    <div className="go-premium-price-card__permium-icon">
                        <PremiumIconSmall />
                    </div>
                    <div
                        className={`go-premium-price-card__text go-premium-price-card__text--${
                            !props.isProfitableOrganization ? "color-gold" : "color-red"
                        } go-premium-price-card__text--font-size-18 go-premium-price-card__text--font-weight-700`}
                    >
                        {translation.premium}
                    </div>
                </div>

                <div className="go-premium-price-card__mobile-button">
                    <Button
                        onClick={props.onClick}
                        className={`button button--font-size-small button--font-weight-400 button--text-color-white button--${
                            !props.isProfitableOrganization ? "color-gold" : "color-red"
                        }  button--hover-brighter button--cursor-pointer button--border-radius-05`}
                    >
                        {translation.buy}
                    </Button>
                </div>
            </div>
            <div className="go-premium-price-card__offer-container">
                <div className="go-premium-price-card__month-container">
                    <div
                        className={`go-premium-price-card__text go-premium-price-card__text${
                            !props.isProfitableOrganization ? "--color-gold" : "--color-red"
                        } go-premium-price-card__text--font-size-60 go-premium-price-card__text--font-weight-900 go-premium-price-card__text--margin-right-05`}
                    >
                        {props.monthNumber}
                    </div>
                    <div
                        className={`go-premium-price-card__text go-premium-price-card__text--${
                            !props.isProfitableOrganization ? "color-gold" : "color-red"
                        } go-premium-price-card__text--font-size-30 go-premium-price-card__text--font-weight-500`}
                    >
                        {props.monthNumber === 12 ? translation.months : translation.month}
                    </div>
                </div>
                <div className="go-premium-price-card__price-container">
                    <div className="go-premium-price-card__text go-premium-price-card__text--color-blue go-premium-price-card__text--font-size-18 go-premium-price-card__text--font-weight-500 ">
                        {`€${props.price} ${props.monthNumber === 12 ? translation.perYear : translation.perMonth}`}
                        <br />
                        <p className="go-premium-price-card__text go-premium-price-card__text--color-blue go-premium-price-card__text--font-size-16  go-premium-price-card__text--font-weight-400">
                            {translation.freeMonth}
                        </p>
                    </div>
                </div>
            </div>
            <div className="go-premium-price-card__button">
                <Button
                    onClick={props.onClick}
                    className={`button button--font-size-small button--font-weight-400 button--text-color-white button--${
                        !props.isProfitableOrganization ? "color-gold" : "color-red"
                    } button--hover-brighter button--cursor-pointer button--border-radius-05`}
                >
                    <div className="go-premium-price-card__button-text">{translation.buy}</div>
                </Button>
            </div>
        </div>
    )
}

export default GoPremiumPriceCard
