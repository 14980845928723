import React from "react"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { EditorModifier, JoinStatus, PostType, RoleType } from "../../../App/enums"
import { ConvertStringToHTML } from "../../../App/Helpers/Helpers"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { CloseIcon, SendIcon, SmileIcon, FileIcon } from "../../Shared/Components/SvgIcons"
import { roomCommentPostRequest, roomPostPostRequest } from "../RoomPost/RoomPostRequests"
import Picker from "emoji-picker-react"
import CharacterCounter from "../../Shared/Components/CharacterCounter"
import { acceptedFileTypes, fileImageType } from "../../../App/GlobaleVariables"
import EditorTextArea from "../../Shared/Components/Editor/EditorTextArea"
import { Editor as TinyMCEEditor } from "tinymce"
import { MentionedUsersType } from "../../../Types/RoomPosts"
import { setPostContentErrorState, setPostStatus } from "../RoomPost/RoomPostSlice"
import DocumentTextAreaPreview from "../../Shared/Components/DocumentTextAreaPreview"
import { hashtagRegex } from "../../../App/Regex"
import Image from "../../Shared/Primitive/Image"

export type RoomTextAreaType = {
    isMemberInRoom: string
    share_documents: boolean
    hasPrivelegedPermission: boolean
    type: PostType.post | PostType.comment
    enabled?: boolean
}

function RoomTextArea(props: RoomTextAreaType) {
    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)
    const { roomId, postId, id } = useParams()
    const [image, setImage] = useState<any>(null)
    const [documentState, setDocumentState] = useState<any>(null)
    const [previewUrl, setPreviewUrl] = useState<string>()
    const [content, setContent] = useState<string>("")
    const [inputState, setInputState] = useState<string>("")
    const [isEmojiSelectorOpen, setIsEmojiSelectorOpen] = useState(false)
    const selectedPost = useSelector((state: RootState) => state.RoomPostsSlice.roomPostSlice.post)
    const posts = useSelector((state: RootState) => state.RoomPostsSlice.roomPostsSlice.posts)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const organization = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const postStatus = useSelector((state: RootState) => state.RoomPostsSlice.postStatusSlice.postedInRoomStatus)
    const photoziseErrorState = useSelector((state: RootState) => state.roomSlice.photoSizeErrorSlice.message)
    const postContentErrorStatus = useSelector((state: RootState) => state.RoomPostsSlice.roomPostContentErrorSlice.message)
    const editorRef = useRef<TinyMCEEditor | null>(null)
    const [fileType, setFileType] = useState("")
    let emojiRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    let inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    const contentMaxLength =
        props.type === PostType.comment ? 2000 : parseInt(roomId!) === 239 && parseInt(id!) === organization.id && parseInt(id!) === 314 ? 15000 : 2000

    useEffect(() => {
        if (postStatus && editorRef.current) {
            setInputState("")
            setPreviewUrl("")
            dispatch(setPostContentErrorState(""))
            setImage(null)
            setDocumentState(null)
            editorRef.current.setContent("")
            setFileType("")
            dispatch(setPostStatus({ postsStatus: false, singularPostStatus: false, postedInRoomStatus: false }))
            if (inputRef.current) {
                inputRef.current.value = ""
            }
        }
    }, [dispatch, postStatus])

    const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files

        if (files) {
            const reader = new FileReader()
            reader.readAsDataURL(files[0])

            reader.addEventListener("load", () => {
                if (fileImageType.includes(files[0].type)) {
                    setImage(files[0])
                    setDocumentState(null)
                } else {
                    setDocumentState(files[0])
                    setImage(null)
                }
                setFileType(files[0].type)
                setPreviewUrl(URL.createObjectURL(files[0]))
            })
        }
    }

    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (isEmojiSelectorOpen && emojiRef.current && !emojiRef.current.contains(event.target as HTMLElement)) {
                setIsEmojiSelectorOpen(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    const onEmojiClick = (event: { preventDefault: () => void }, emojiObject: any) => {
        event.preventDefault()
        if (editorRef.current) {
            editorRef.current!.insertContent(emojiObject.emoji)
        }
    }

    const onPostSubmit = () => {
        const objectId = role === RoleType.USER ? userDataState.id : organization?.id
        const mentionedUsersArray: MentionedUsersType[] = []
        let modifiedContent = ""
        if (editorRef.current!) {
            const htmlContent = ConvertStringToHTML(editorRef!.current!.getContent({format: 'raw'}))
            const tagSpans = htmlContent.querySelectorAll("[data-type]")

            tagSpans.forEach((span, index) => {
                span.innerHTML = "@tagged"
                mentionedUsersArray.splice(index, 0, {
                    tagged_user: span.getAttribute("data-type") === RoleType.USER.toLocaleLowerCase() ? parseInt(span.id) : null,
                    tagged_org: span.getAttribute("data-type") === RoleType.ORGANIZATION.toLocaleLowerCase() ? parseInt(span.id) : null,
                })
                modifiedContent = htmlContent.outerHTML.replaceAll("<body>", "").replaceAll("</body>", "").replace(hashtagRegex, "$1")
            })

            if (mentionedUsersArray.length >= 1 || editorRef.current!.getContent({ format: "text" }).length > 0) {
                const formData = new FormData()

                formData.append("content", modifiedContent.length > 0 ? modifiedContent : editorRef!.current!.getContent({format: 'raw'}).replace(hashtagRegex, "$1"))
                formData.append("content_type", role.toLowerCase())
                formData.append("object_id", objectId?.toString()!)
                formData.append("tags", JSON.stringify(mentionedUsersArray))

                switch (props.type) {
                    case PostType.comment:
                        formData.append("post", postId!)
                        dispatch(roomCommentPostRequest(formData, selectedPost))
                        break
                    case PostType.post:
                        formData.append("room", roomId!)
                        image !== null && formData.append("post_file", image)
                        documentState !== null && formData.append("post_file", documentState)
                        fileType.length > 0 && formData.append("file_type", fileType)
                        dispatch(roomPostPostRequest(formData, posts))
                        break
                    default:
                        break
                }
            }
        }
    }

    const removeFile = () => {
        setPreviewUrl("")
        setImage(null)
        setDocumentState(null)
        if (inputRef.current) {
            inputRef.current.value = ""
        }
    }

    return (
        <div>
            {props.hasPrivelegedPermission ? (
                <div className="room-layout__right-panel-input-container">
                    <div className="room-layout__photo-text-container">
                        <EditorTextArea
                            inputState={inputState}
                            setInputState={setInputState}
                            editorRef={editorRef}
                            editorPlaceholder={props.type === PostType.post ? translation.typeYourMessage : translation.addAComment}
                            setContent={setContent}
                            editorHeight={62}
                            modifier={EditorModifier.roomTextArea}
                            initialEditorValue=""
                            enterPressed={() => {}}
                        />
                        {image && (
                            <div className="room-layout__post-image-container">
                                <Image className="room-layout__post-image" src={previewUrl} alt="post" />
                                <div className="room-layout__post-image-cancel-container" onClick={removeFile}>
                                    <Image src={previewUrl} alt="post" className="room-layout__post-image room-layout__post-image--opacity" />
                                    <div className="room-layout__post-image-cancel">
                                        <CloseIcon />
                                    </div>
                                </div>
                            </div>
                        )}
                        {documentState && (
                            <DocumentTextAreaPreview document_name={documentState.name} document_size={documentState.size} onRemoveFile={removeFile} />
                        )}
                        {postContentErrorStatus.length > 0 && <p className="room-layout__error-message">{postContentErrorStatus}</p>}
                        {photoziseErrorState && <p className="crop__error-message">{translation.fileIsTooBig}</p>}
                    </div>
                    <CharacterCounter max={contentMaxLength} current={content.length} modifier="post" />
                    <div className="room-layout__right-panel-icons-container">
                        <div onClick={() => setIsEmojiSelectorOpen(!isEmojiSelectorOpen)} className="room-layout__right-panel-icon">
                            <SmileIcon />
                        </div>
                        {isEmojiSelectorOpen && (
                            <div ref={emojiRef} className="room-layout__emoji-picker">
                                <Picker disableSkinTonePicker={false} onEmojiClick={onEmojiClick} />
                            </div>
                        )}
                        {props.type === PostType.post && (
                            <div className="room-layout__right-panel-icon">
                                <input className="room-layout__input-file" type="file" onChange={onFileChange} accept={acceptedFileTypes} ref={inputRef} />
                                <FileIcon />
                            </div>
                        )}
                        {content.length > 0 && content.length < contentMaxLength + 1 ? (
                            <div className="room-layout__right-panel-icon" onClick={onPostSubmit}>
                                <SendIcon color="green" />
                            </div>
                        ) : (
                            <div className="room-layout__right-panel-icon room-layout__right-panel-icon--disable">
                                <SendIcon color="grey" />
                            </div>
                        )}
                    </div>
                </div>
            ) : props.isMemberInRoom === JoinStatus.MEMBER ? (
                props.share_documents ? (
                    <div className="room-layout__right-panel-input-container">
                        <div className="room-layout__photo-text-container">
                            <EditorTextArea
                                inputState={inputState}
                                setInputState={setInputState}
                                editorRef={editorRef}
                                editorPlaceholder={props.type === PostType.post ? translation.typeYourMessage : translation.addAComment}
                                setContent={setContent}
                                editorHeight={62}
                                modifier={EditorModifier.roomTextArea}
                                initialEditorValue=""
                                enterPressed={() => {}}
                            />
                            {image && (
                                <div className="room-layout__post-image-container">
                                    <Image className="room-layout__post-image" src={previewUrl} alt="post" />
                                    <div className="room-layout__post-image-cancel-container" onClick={removeFile}>
                                        <Image src={previewUrl} alt="post" className="room-layout__post-image room-layout__post-image--opacity" />
                                        <div className="room-layout__post-image-cancel">
                                            <CloseIcon />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {documentState && (
                                <DocumentTextAreaPreview document_name={documentState.name} document_size={documentState.size} onRemoveFile={removeFile} />
                            )}
                            {postContentErrorStatus.length > 0 && <p className="room-layout__error-message">{postContentErrorStatus}</p>}
                            {photoziseErrorState && <p className="room-layout__error-message">{translation.fileIsTooBig}</p>}
                        </div>
                        <CharacterCounter max={contentMaxLength} current={content.length} modifier="post" />
                        <div className="room-layout__right-panel-icons-container">
                            <div onClick={() => setIsEmojiSelectorOpen(!isEmojiSelectorOpen)} className="room-layout__right-panel-icon ">
                                <SmileIcon />
                            </div>
                            {isEmojiSelectorOpen && (
                                <div ref={emojiRef} className="room-layout__emoji-picker">
                                    <Picker disableSkinTonePicker={false} onEmojiClick={onEmojiClick} />
                                </div>
                            )}
                            {props.type === PostType.post && (
                                <div className="room-layout__right-panel-icon">
                                    <input className="room-layout__input-file" type="file" accept={acceptedFileTypes} onChange={onFileChange} ref={inputRef} />
                                    <FileIcon />
                                </div>
                            )}
                            {content.length > 0 && content.length < contentMaxLength + 1 ? (
                                <div className="room-layout__right-panel-icon" onClick={onPostSubmit}>
                                    <SendIcon color="green" />
                                </div>
                            ) : (
                                <div className="room-layout__right-panel-icon room-layout__right-panel-icon--disable">
                                    <SendIcon color="grey" />
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="room-layout__right-panel-input-no-access">{translation.cantPostInRoom}</div>
                )
            ) : (
                <div className="room-layout__right-panel-input-no-access"> {translation.onlyMembersCanPostInRoom}</div>
            )}
        </div>
    )
}

export default RoomTextArea
