import { Dispatch } from "react"
import { NavigateFunction } from "react-router"
import { ResultType, RoleType } from "../../../App/enums"
import { GlobalSearchResult } from "../../../Types/GlobalSearchResultType"
import { setPostStatus } from "../../Room/RoomPost/RoomPostSlice"
import { setModalData } from "../SharedSlices/ModalSlice"

export const onSearchResultClick = (result: GlobalSearchResult,navigate:NavigateFunction,dispatch:Dispatch<any>,role:keyof typeof RoleType,userId:number,orgId:number ) => {
   switch (result.type) {
    case ResultType.User:
        if(userId === result.id){
            navigate(`/user-profile`)
            window.scrollTo(0, 0)
        }else{
            switch (role) {
                case RoleType.USER:
                    navigate(`/user/${result.id!}`)
                    window.scrollTo(0, 0)
                    break
                case RoleType.ORGANIZATION:
                    navigate(`/user/${result.id!}/${orgId}`)
                    window.scrollTo(0, 0)
                    break
                default:
                    break
            }
        }
        break;
   case ResultType.Organization:
    switch (role) {
        case RoleType.USER:
            navigate(`/organization-profile/${result.id!}`)
            window.scrollTo(0, 0)

            break
        case RoleType.ORGANIZATION:
            navigate(`/organization-profile/${result.id!}`)
            window.scrollTo(0, 0)

            break
        default:
            break
    }
    break;
    case ResultType.Room:
        switch (role) {
            case RoleType.USER:
                navigate(`/room/${result.id!}`)
                dispatch(setPostStatus({ postsStatus: true, singularPostStatus: false, postedInRoomStatus: false }))
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(`/room/${orgId}/${result.id!}`)
                dispatch(setPostStatus({ postsStatus: true, singularPostStatus: false, postedInRoomStatus: false }))
                window.scrollTo(0, 0)
                break
            default:
                break
        }
        break;
    case ResultType.Event:
        const event_id = result.event ? result.event.id : result.id
        const organization_id = result.event ? result.event.organization : result.organization
        switch (role) {
            case RoleType.USER:
                navigate(`/organization-profile/${organization_id}/event/${event_id!}`)
                window.scrollTo(0, 0)
                dispatch(setModalData({ open: true, pinboardCommentsModal: true }))

                break
            case RoleType.ORGANIZATION:
                navigate(`/organization-profile/${orgId}/event/${event_id!}`)
                window.scrollTo(0, 0)
                dispatch(setModalData({ open: true, pinboardCommentsModal: true }))
                break
            default:
                break
        }
        break
    case ResultType.Comment:
        switch (role) {
            case RoleType.USER:
                navigate(`/room-post/${result.room!}/${result.post!}`)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(`/room-post/${orgId}/${result.room!}/${result.post!}`)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
        break;
    case ResultType.Post:
        switch (role) {
            case RoleType.USER:
                navigate(`/room-post/${result.room!}/${result.id!}`)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(`/room-post/${orgId}/${result.room!}/${result.id!}`)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
            break;
    case ResultType.Hashtag:
        switch (role) {
            case RoleType.USER:
                navigate(`/hashtag?keyword=${result.text}`)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                navigate(`/${orgId}/hashtag?keyword=${result.text}`)
                window.scrollTo(0, 0)
                break
            default:
                break
    }
    break;
    default:
        break;
   }
   

   



}