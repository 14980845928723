import React, { useState, useEffect, useRef, RefObject } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { ResultType, RoleType, SearchFilter } from "../../../App/enums"
import { profileDefault, organizationProfileDefault, fileImageType } from "../../../App/GlobaleVariables"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { GlobalSearchResult } from "../../../Types/GlobalSearchResultType"
import { documentNameLength, formatDate } from "../../../App/Helpers/Helpers"
import DOMPurify from "dompurify"
import { onGetGlobalSearchFilteredData } from "./GlobalSearchServiceRequest"
import { setGlobalSearchData } from "./GlobalSearchSlice"
import SearchFilterContainer from "./SearchFilterContainer"
import { onSearchResultClick } from "./onSearchResultClick"
import { onNameFilter, onPhotoFilter } from "./SearchDataFilterFunctions"
import { LoadingAnimation } from "../Components/SvgIcons"
import { htmlRegex } from "../../../App/Regex"
import useWindowSize from "../Hooks/useWindowSize"
import { onHashtagClick } from "../Hashtag/onHashtagClick"
import Image from "../../Shared/Primitive/Image"
import EventPhoto from "../../../App/assets/EventPhoto.jpg"

function SearchComponent() {
    const location = useLocation()
    let params = new URLSearchParams(location.search)
    const searchedQuery = params.get("searchedQuery")
    const translation = useSelector(selectTranslations)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const [filterState, setFilterState] = useState<string>(SearchFilter.all_results)
    const [showPost, setShowPost] = useState<{ [key: number]: boolean }>({})
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingMoreData, setIsLoadingMoreData] = useState(false)
    const searchData = useSelector((state: RootState) => state.GlobalSearchSlice.globalSearchSlice.result)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()
    const maxPostLength = 100
    const [pageNum, setPageNum] = useState(1)
    const [hasNextPage, setHasNextPage] = useState(false)
    const scrollContainer = useRef() as RefObject<HTMLDivElement>
    const screenWidth = useWindowSize()
    const breakpoint = 500

    useEffect(() => {
        const getData = setTimeout(() => {
            const controller = new AbortController()
            const { signal } = controller
            if (searchedQuery !== null && searchedQuery!.length > 2) {
                setIsLoading(true)
                const hasHashtag = searchedQuery.charAt(0) === "#"
                onGetGlobalSearchFilteredData(searchedQuery!.replace("#", "")!, 1, filterState, hasHashtag)
                    .then(response => {
                        if (response.status === 200) {
                            dispatch(setGlobalSearchData({ result: response.data.results }))
                            setHasNextPage(Boolean(response.data.next!)!)
                            setIsLoading(false)
                            setPageNum(1)
                        }
                    })
                    .catch(error => {
                        setPageNum(1)
                        if (signal.aborted) return
                        console.log(error)
                    })
            } else {
                setPageNum(1)
            }
          }, 500)
      
          return () => clearTimeout(getData)
    }, [dispatch, filterState, searchedQuery])

    const editPostContent = (content: string, result: GlobalSearchResult) => {
        const cleanHtml = DOMPurify.sanitize(content, {
            ALLOWED_TAGS: ["b", "p", "span", "a", "div", "img", "header", "footer"],
            ALLOWED_ATTR: ["class", "href", "target", "data-type", "className", "id", "rel", "src", "style", "width", "type", "data-reactroot"],
        })

        let newString = cleanHtml.replaceAll(htmlRegex, "").replaceAll("&lt;", "<").replaceAll("&gt;", ">").replaceAll("&nbsp;", " ")

        const onButtonClick = (index: number) => () => {
            setShowPost(state => ({
                ...state,
                [index]: !state[index],
            }))
        }

        if (newString.length > maxPostLength) {
            let newHTML = !showPost[result?.id!] ? newString.substring(0, maxPostLength) + "..." : newString
            const parts = newHTML.split(new RegExp(`(${searchedQuery})`, "gi"))

            return (
                <div>
                    <div className={`search-component__text search-component__text--post`}>
                        {parts.map((part, i) => (
                            <span
                                key={i}
                                className={`search-component__text search-component__text--font-size-14 ${
                                    part.toLowerCase() === searchedQuery!.toLowerCase() && "search-component__text--bold"
                                }`}
                                onClick={() => onSearchResultClick(result, navigate, dispatch, role, userDataState.id, parseInt(id!))}
                            >
                                {part}
                            </span>
                        ))}
                    </div>
                    <div className="search-component__post-button" onClick={onButtonClick(result?.id!)} key={result?.id!}>
                        {!showPost[result?.id!] ? translation.seeAllDetails : translation.showLess}
                    </div>
                </div>
            )
        }

        const parts = newString.split(new RegExp(`(${searchedQuery})`, "gi"))

        return (
            <span className={`search-component__text search-component__text--post`}>
                {parts.map((part, i) => (
                    <span
                        key={i}
                        className={`search-component__text search-component__text--font-size-14 ${
                            part.toLowerCase() === searchedQuery!.toLowerCase() && "search-component__text--bold"
                        }`}
                        onClick={() => onSearchResultClick(result, navigate, dispatch, role, userDataState.id, parseInt(id!))}
                    >
                        {part}
                    </span>
                ))}
            </span>
        )
    }

    const onScroll = () => {
        if (scrollContainer.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollContainer.current

            if (Math.ceil(scrollTop + clientHeight + 1) >= scrollHeight && hasNextPage && !isLoadingMoreData) {
                const controller = new AbortController()
                const { signal } = controller
                setIsLoadingMoreData(true)
                const hasHashtag = searchedQuery!.charAt(0) === "#"
                onGetGlobalSearchFilteredData(searchedQuery!.replace("#", "")!, pageNum + 1, filterState, hasHashtag)
                    .then(response => {
                        if (response.status === 200) {
                            dispatch(setGlobalSearchData({ result: [...searchData, ...response.data.results] }))
                            setHasNextPage(Boolean(response.data.next!)!)
                            setPageNum(pageNum + 1)
                            setIsLoadingMoreData(false)
                        }
                    })
                    .catch(error => {
                        setPageNum(1)
                        if (signal.aborted) return
                        console.log(error)
                    })
            }
        }
    }

    return (
        <div className="search-component">
            <div className="search-component__content">
                <SearchFilterContainer filterState={filterState} setFilterState={setFilterState} setPageNum={setPageNum} isGlobaleSearch={true} />
                {isLoading ? (
                    <div className="search-component__results-container">
                        <LoadingAnimation type="cover" />
                    </div>
                ) : (
                    <div className="search-component__results-container">
                        <div className="search-component__results-content" ref={scrollContainer} onScroll={onScroll}>
                            {searchData &&
                                searchData!.length > 0 &&
                                searchData!.map((result, index) => {
                                    if (result.type === ResultType.Post || result.type === ResultType.Comment) {
                                        const fileName = result.post_file && result?.post_file!.split("/")!.pop()!.split("#")[0].split("?")[0]
                                        const fileType = fileName && fileName.slice(fileName.indexOf(".") + 1).toUpperCase()
                                        return (
                                            <div
                                                className={`search-component__result-container ${
                                                    searchData.length - 1 === index && "search-component__result-container--no-border"
                                                } `}
                                                key={result.id + result.type}
                                            >
                                                <div
                                                    className={`search-component__result search-component__result--no-border
                                                search-component__result--post `}
                                                    onClick={() => onSearchResultClick(result, navigate, dispatch, role, userDataState.id, parseInt(id!))}
                                                >
                                                    <div className="search-component__left-panel">
                                                        <img
                                                            className="search-component__image"
                                                            src={
                                                                result.content_type === RoleType.ORGANIZATION.toLowerCase()
                                                                    ? result.content_object?.photo?.url_path || organizationProfileDefault
                                                                    : result.content_object?.profile_photo?.url_path || profileDefault
                                                            }
                                                            alt=""
                                                        />
                                                    </div>

                                                    <div className={`search-component__middle-panel`}>
                                                        <div
                                                            className={`search-component__text search-component__text--font-size-14  search-component__text--bold ${
                                                                !result.content_object?.id && "search-component__text--deleted"
                                                            }`}
                                                        >
                                                            {result.content_type === RoleType.ORGANIZATION.toLowerCase()
                                                                ? result.content_object?.name || translation.deletedOrganization
                                                                : result.content_object === null
                                                                ? translation.deletedUser
                                                                : result.content_object?.first_name + " " + result.content_object?.last_name}
                                                        </div>
                                                        <div className={`search-component__text search-component__text--font-size-12`}>
                                                            {translation.at + " " + result.room_name!}
                                                        </div>
                                                        <div className="search-component__subtext search-component__subtext--post">
                                                            {result.type === ResultType.Post ? translation.post : translation.comment}
                                                        </div>

                                                        {result.type === ResultType.Post && result.post_file ? (
                                                            fileImageType.includes(result.file_type!) ? (
                                                                <Image className="search-component__post-image" src={result.post_file} alt="" />
                                                            ) : (
                                                                <div className="room-layout__document-container">
                                                                    <img className="room-layout__document-image" src={`/document-icon.svg`} alt="doc" />
                                                                    <div className="room-layout__document-content">
                                                                        <a href={result.post_file} className="room-layout__document-title">
                                                                            {documentNameLength(fileName!, screenWidth, breakpoint)}
                                                                        </a>
                                                                        <p className="room-layout__document-size">{fileType}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        ) : null}
                                                    </div>

                                                    <div className={`search-component__right-panel-post`}>{formatDate(result.date!, "HH:mm dd MMM")!}</div>
                                                </div>
                                                {editPostContent(result.content!, result)}
                                            </div>
                                        )
                                    }

                                    return result.type === ResultType.Hashtag && result.total_results === null ? (
                                        <div className="search-component__no-data-text">{translation.nothingToShowYet}</div>
                                    ) : (
                                        <div
                                            key={result.id + result.type}
                                            className={`search-component__result ${searchData.length - 1 === index && "search-component__result--no-border"}`}
                                        >
                                            <div
                                                className="search-component__left-panel"
                                                onClick={() => onSearchResultClick(result, navigate, dispatch, role, userDataState.id, parseInt(id!))}
                                            >
                                                {result.type === ResultType.Event ? (
                                                    <Image className="search-component__image" src={onPhotoFilter(result) || EventPhoto} alt="" />
                                                ) : null}
                                                {result.type !== ResultType.Event && (
                                                    <Image className="search-component__image" src={onPhotoFilter(result)} alt="" />
                                                )}
                                            </div>
                                            <div className="search-component__right-panel">
                                                <div
                                                    className={`search-component__text ${
                                                        result.type === ResultType.Event && "search-component__text--font-large"
                                                    }`}
                                                    onClick={() => onSearchResultClick(result, navigate, dispatch, role, userDataState.id, parseInt(id!))}
                                                >
                                                    {onNameFilter(result!)}
                                                </div>

                                                <div
                                                    className="search-component__subtext"
                                                    onClick={() => onSearchResultClick(result, navigate, dispatch, role, userDataState.id, parseInt(id!))}
                                                >
                                                    {result.type === ResultType.User
                                                        ? translation.individual
                                                        : result.type === ResultType.Organization
                                                        ? translation.organization
                                                        : result.type === ResultType.Room
                                                        ? translation.room
                                                        : result.type === ResultType.Hashtag
                                                        ? result.total_results === 1
                                                            ? result.total_results + " " + translation.result
                                                            : result.total_results + " " + translation.results
                                                        : translation.event}
                                                </div>
                                                {(result.type === ResultType.User || ResultType.Organization) && (
                                                    <div className="search-component__tag-container">
                                                        {result.hashtags?.map((hashtag, index) => (
                                                            <div
                                                                className="search-component__tag"
                                                                key={index}
                                                                onClick={() => onHashtagClick(hashtag, navigate, role, parseInt(id!))}
                                                            >
                                                                {hashtag.charAt(0) !== "#" ? "#" + hashtag : hashtag}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                            {isLoadingMoreData && (
                                <div className="rooms-card__container">
                                    <LoadingAnimation type="profile" />
                                </div>
                            )}
                            {searchData === undefined && (
                                <div className="search-dropdown__no-data-text">{`${translation.noResultThatContain} '${searchedQuery}' ${translation.wasFound}`}</div>
                            )}
                            {searchData && searchData!.length === 0 && (searchedQuery!.length !== 0 || searchedQuery!.length === 0) && (
                                <div className="search-component__no-data-text">{translation.nothingToShowYet}</div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SearchComponent
