import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { RoleType } from "../../../App/enums"
import { profileDefault } from "../../../App/GlobaleVariables"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import Image from "../../Shared/Primitive/Image"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"

function ManagersCard() {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const translation = useSelector(selectTranslations)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const roomDataState = useSelector((state: RootState) => state.roomSlice.roomSlice.room)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)

    const onRoomManagerClick = (roomManagerId: number) => {
        dispatch(setModalData({ open: false, roomMembersModal: false }))
        switch (role) {
            case RoleType.USER:
                if (roomManagerId === userDataState.id) {
                    navigate("/user-profile")
                    window.scrollTo(0, 0)
                } else {
                    navigate(`/user/${roomManagerId}`)
                    window.scrollTo(0, 0)
                }
                break
            case RoleType.ORGANIZATION:
                if (roomManagerId === userDataState.id) {
                    navigate("/user-profile")
                    window.scrollTo(0, 0)
                } else {
                    navigate(`/user/${roomManagerId}/${id}`)
                    window.scrollTo(0, 0)
                }
                break
            default:
                break
        }
    }
    return (
        <div className="managers-card">
            <div className="managers-card__container">
                {roomDataState.room_managers?.map(roomManager => {
                    return (
                        <div key={roomManager.id} className="managers-card__outer-container" onClick={() => onRoomManagerClick(roomManager.id)}>
                            <Image
                                src={roomManager?.profile_photo?.url_path || profileDefault}
                                className="image image__profile image__profile--fit image__profile--size-small"
                            />
                            <span className="managers-card__full-name">{roomManager.first_name + " " + roomManager.last_name}</span>
                            <p className="managers-card__outer-name">{(roomManager.first_name.length > 13 ? 
                                  (roomManager.first_name.charAt(0) + ". ") :  roomManager.first_name )
                                   + " " + roomManager.last_name.charAt(0) + ". "}
                                </p>
                        </div>
                    )
                })}
            </div>
            <div className="managers-card__title">{translation.roomManagers}</div>
        </div>
    )
}

export default ManagersCard
