import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { UserSettingsProfileResponseMessage } from "../../../Types/Responses/UserSettingsProfileResponseMessage"

interface ErrorData {
    message: UserSettingsProfileResponseMessage
    status: number
}
const initialState: ErrorData = {
    message: { confirm_password: "", email: "", first_name: "", last_name: "", location: "", old_password: "", password: "", non_field_errors: "" },
    status: 0,
}

const UserSettingsProfileErrorSlice = createSlice({
    name: "SettingsProfileError",
    initialState: initialState,
    reducers: {
        getUserSettingsProfileError(state, action: PayloadAction<ErrorData>) {
            state.message = action.payload.message
            state.status = action.payload.status
        },
        resetUserSettingsProfileError(state) {
            state.message = initialState.message
        },
    },
})
export const { getUserSettingsProfileError } = UserSettingsProfileErrorSlice.actions
export const { resetUserSettingsProfileError } = UserSettingsProfileErrorSlice.actions
export default UserSettingsProfileErrorSlice.reducer
