import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../App/store"
import { setModalData } from "../SharedSlices/ModalSlice"
import { CameraIcon } from "../Components/SvgIcons"
import Modal from "../Components/Modal"
import { PhotoProps } from "../../../Types/PhotoType"
import PhotoCropModal from "./PhotoCropModal"

function PhotoDropdown(props: PhotoProps & React.AllHTMLAttributes<HTMLDivElement>) {
    const dispatch = useDispatch()
    const modalStatus = useSelector((state: RootState) => state.modalSlice)
    const [image, setImage] = useState<any>("")
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)

    const onPhotoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files

        if (files) {
            const reader = new FileReader()
            reader.readAsDataURL(files[0])
            reader.addEventListener("load", () => {
                setImage(reader.result)
                dispatch(setModalData({ open: true, changePhotoTypeModal: props.type }))
            })
        }
    }

    const onPhotoView = () => {
        if (!props.currentImage) {
            return
        }
        dispatch(setModalData({ open: true, viewPhotoTypeModal: props.type }))
    }

    return (
        <>
            <div className={`photo-dropdown-${props.type}`}>
                <div
                    {...(!props.currentImage
                        ? { className: `photo-dropdown-${props.type}__modal photo-dropdown-${props.type}__modal--disable` }
                        : { className: `photo-dropdown-${props.type}__modal ` })}
                    onClick={onPhotoView}
                >
                    <CameraIcon {...(!props.currentImage ? { color: `disable` } : { color: `blue` })} />
                    <span className={`photo-dropdown-${props.type}__text`}> {props.viewPhoto}</span>
                </div>
                <label className={`photo-dropdown-${props.type}__modal`}>
                    <CameraIcon color="blue" />
                    <input
                        type="file"
                        className={`photo-dropdown-${props.type}__input`}
                        accept="image/apng, image/avif, image/jpeg, image/png, image/svg+xml, image/webp"
                        onChange={onPhotoChange}
                    />
                    <div className={`photo-dropdown-${props.type}__text`}>
                        {props.changePhoto}
                        <p className={`photo-dropdown-${props.type}__text photo-dropdown-${props.type}__text--small`}>{props.recommendedResolution}</p>
                    </div>
                </label>
            </div>
            {modalStatus.open && modalStatus.changePhotoTypeModal && (
                <Modal children={<PhotoCropModal imageUrl={image} zoomInit={zoom} aspectInit={props.photoCropAspect} cropInit={crop} />} />
            )}
        </>
    )
}

export default PhotoDropdown
