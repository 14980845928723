import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { PhotoTypeModal } from "../../../App/enums"
import { organizationCoverDefault } from "../../../App/GlobaleVariables"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { Organization } from "../../../Types/Organization"
import { CameraIcon, LoadingAnimation } from "../../Shared/Components/SvgIcons"
import PhotoDropdown from "../../Shared/PhotoDropdown/PhotoDropdown"
import Image from "../../Shared/Primitive/Image"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { getOrganizationCoverPhotoDropdownStatus, getOtherOrganizationPhotoUrl } from "../../Shared/SharedSlices/PhotoDropdownSlice"

function OrganizationProfileCoverPhoto(props: { isYourOrganization: boolean; otherOrganization: Organization }) {
    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const organizationCoverPhotoDropdownStatus = useSelector((state: RootState) => state.photoDropdownSlice.organizationCoverPhotoDropdownStatusSlice)
    const loadingCoverPhotoDropdownStatus = useSelector((state: RootState) => state.photoDropdownSlice.loadingOrganizationCoverPhotoDropdownStatusSlice)

    const onCoverPhotoModalOpen = () => {
        if (props.otherOrganization?.cover_photo?.url_path) {
            dispatch(setModalData({ open: true, viewPhotoTypeModal: PhotoTypeModal.OTHER_ORGANIZATION_COVER }))
            dispatch(getOtherOrganizationPhotoUrl({ url: props.otherOrganization?.cover_photo.url_path }))
        }
        return
    }

    return (
        <div className="organization-profile-cover-photo">
            {loadingCoverPhotoDropdownStatus.loading ? (
                <div
                    className="organization-profile-cover-photo__cover-image-container-loading"
                    style={{ backgroundImage: `url(${organizationDataState.cover_photo?.url_path})` }}
                >
                    <LoadingAnimation type="cover" />
                </div>
            ) : (
                <div className="organization-profile-cover-photo__container">
                    {props.isYourOrganization ? (
                        <Image
                            className="organization-profile-cover-photo__image"
                            src={organizationDataState.cover_photo?.url_path || organizationCoverDefault}
                            alt="organization"
                        />
                    ) : (
                        <Image
                            className="organization-profile-cover-photo__image"
                            src={props.otherOrganization?.cover_photo?.url_path! || organizationCoverDefault}
                            alt="organization"
                            onClick={onCoverPhotoModalOpen}
                        />
                    )}

                    {props.isYourOrganization && (
                        <div
                            className="organization-profile-cover-photo__icon"
                            onClick={() => dispatch(getOrganizationCoverPhotoDropdownStatus({ status: !organizationCoverPhotoDropdownStatus.status }))}
                        >
                            <CameraIcon color="green" />
                        </div>
                    )}

                    {organizationCoverPhotoDropdownStatus.status && (
                        <div>
                            <PhotoDropdown
                                type={PhotoTypeModal.ORGANIZATION_COVER}
                                currentImage={organizationDataState.cover_photo?.url_path!}
                                viewPhoto={translation.viewCoverPhoto}
                                changePhoto={translation.changeCoverPhoto}
                                photoCropAspect={4 / 1}
                                recommendedResolution={translation.recommendedCoverPhoto}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default OrganizationProfileCoverPhoto
