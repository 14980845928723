interface divProp extends React.AllHTMLAttributes<HTMLDivElement> {}

function UserProfileView(props: divProp) {
    const divProps: React.HTMLAttributes<HTMLDivElement> = { ...props }
    return (
        <div className="view" {...divProps}>
            {props.children}
        </div>
    )
}

export default UserProfileView
