import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { LockerIcon } from "../Components/SvgIcons"
import Button from "../Primitive/Button"

function NoPermissionPage() {
    const translation = useSelector(selectTranslations)
    const navigate = useNavigate()
    return (
        <div className="no-permission">
            <div className="no-permission__title">
                <div className="no-permission__icon">
                    <LockerIcon />
                </div>
                {translation.permissionNeeded}
            </div>
            <div className="no-permission__content">
                <p className="no-permission__text">{translation.noPermissionForPage}</p>
                <div className="no-permission__button">
                    <Button
                        className="button button--color-blue button--hover-brighter button--cursor-pointer button--border-radius-05 button--text-color-white button--font-weight-700"
                        onClick={() => navigate("/dashboard") }
                    >
                        {translation.goToDashboard}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default NoPermissionPage
