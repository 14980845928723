import { createSlice, PayloadAction, combineReducers } from "@reduxjs/toolkit"

const initialProfileState = { status: true }

const OrganizationProfileSettingsStateSlice = createSlice({
    name: "OrganizationProfileSettingsState",
    initialState: initialProfileState,
    reducers: {
        getOrganizationProfileSettingsState(state, action: PayloadAction<boolean>) {
            state.status = action.payload
        },
    },
})

const initialNotificationsState = { status: false }

const OrganizationNotificationsSettingsStateSlice = createSlice({
    name: "OrganizationNotificationsSettingsState",
    initialState: initialNotificationsState,
    reducers: {
        getOrganizationNotificationsSettingsState(state, action: PayloadAction<boolean>) {
            state.status = action.payload
        },
    },
})

const initialPrivacyState = { status: false }

const OrganizationPrivacySettingsStateSlice = createSlice({
    name: "OrganizationPrivacySettingsState",
    initialState: initialPrivacyState,
    reducers: {
        getOrganizationPrivacySettingsState(state, action: PayloadAction<boolean>) {
            state.status = action.payload
        },
    },
})

const initialSubscriptionState = { status: false }

const OrganizationSubscriptionSettingsStateSlice = createSlice({
    name: "OrganizationSubscriptionSettingsState",
    initialState: initialSubscriptionState,
    reducers: {
        getOrganizationSubscriptionSettingsState(state, action: PayloadAction<boolean>) {
            state.status = action.payload
        },
    },
})

export const { getOrganizationProfileSettingsState } = OrganizationProfileSettingsStateSlice.actions
export const { getOrganizationPrivacySettingsState } = OrganizationPrivacySettingsStateSlice.actions
export const { getOrganizationNotificationsSettingsState } = OrganizationNotificationsSettingsStateSlice.actions
export const { getOrganizationSubscriptionSettingsState } = OrganizationSubscriptionSettingsStateSlice.actions

export default combineReducers({
    organizationSettingsStatus: OrganizationProfileSettingsStateSlice.reducer,
    organizationNotificationsSettingsStateSlice: OrganizationNotificationsSettingsStateSlice.reducer,
    organizationPrivacySettingsStateSlice: OrganizationPrivacySettingsStateSlice.reducer,
    organizationSubscriptionSettingsStateSlice: OrganizationSubscriptionSettingsStateSlice.reducer,
})
