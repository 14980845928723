import { RoleType } from "../../../../App/enums"
import { TagType } from "../../../../Types/EditorTypes"

export const onMention = (text: string, user: TagType, spanArray: string[]) => {
    const splitContent = text?.split(" ")

    const value = splitContent
        .map((word, index) => {
            const startsWithNewLine = word.startsWith("\n")

            if (word.includes("\n")) {
                const newLineArray = word.split("\n")
                let temp = 0
                word = newLineArray
                    .map((newLineWord, index) => {
                        if (newLineWord.replaceAll("<p>", "").replaceAll("</p>", "").charAt(0) === "@") {
                            if (spanArray[temp] === newLineWord.replaceAll("<p>", "").replaceAll("</p>", "").replace("@", "")) {
                                temp = temp + 1

                                return startsWithNewLine ? "&nbsp;" + newLineWord : newLineWord
                            } else {
                                newLineWord = `<span class="mention-span" contenteditable="false" id="${user.id}" data-type="${user.type}">${
                                    user.type === RoleType.USER.toLowerCase()
                                        ? "@" + user.first_name?.replaceAll(" ", "") + user.last_name?.replaceAll(" ", "")
                                        : "@" + user.name?.replaceAll(" ", "")
                                }</span> &nbsp;`
                                return startsWithNewLine ? "&nbsp;" + newLineWord : newLineWord
                            }
                        }
                        return startsWithNewLine ? "&nbsp;" + newLineWord : newLineWord
                    })
                    .join(" ")

                return startsWithNewLine ? "&nbsp;" + word : word
            }

            if (startsWithNewLine) {
                word = word.replace("\n", "")
            }

            let temp = 0
            if (word.replaceAll("<p>", "").replaceAll("</p>", "").charAt(0) === "@") {
                if (spanArray[temp] === word.replaceAll("<p>", "").replaceAll("</p>", "").replace("@", "")) {
                    temp = temp + 1

                    return startsWithNewLine ? "&nbsp;" + word : word
                } else {
                    if(index+1 < splitContent.length){
                        let nextWord = splitContent[index+1]
                        if(nextWord.length > 0){
                            nextWord = splitContent[index+1].replaceAll("<p>", "").replaceAll("</p>", "").toLowerCase()
                            if(user.last_name?.toLowerCase().startsWith(nextWord) || user.first_name?.toLowerCase().includes(nextWord)){
                                splitContent[index+1] = ""
                            }
                        }
                    }
                    word = `<span class="mention-span" contenteditable="false" id="${user.id}" data-type="${user.type}">${
                        user.type === RoleType.USER.toLowerCase()
                            ? "@" + user.first_name?.replaceAll(" ", "") + user.last_name?.replaceAll(" ", "")
                            : "@" + user.name?.replaceAll(" ", "")
                    }</span>`
                    return startsWithNewLine ? "&nbsp;" + word : word
                }
            }
            return startsWithNewLine ? "&nbsp;" + word : word
        })
        .join(" ")
    return value
}
