export const halloEmail = "hallo@edusiia.com"
export const jungedEmail = " jugend@edusiia.com"
export const mitgliedschaftEmail = "mitgliedschaft@edusiia.com"
export const nikiEmail = "niki@edusiia.com"
export const profileDefault = "/edusiia_placeholder_individual_profile.png"
export const organizationProfileDefault = "/edusiia_placeholder_organisation_profile.png"
export const coverDefault = "/edusiia_placeholder_individual_header.png"
export const organizationCoverDefault = "/edusiia_placeholder_organisation_header.png"
export const roomDefaultPhoto = "/edusiia_placeholder_room_picture.png"
export const hashtagPlaceholder = "/placeholder_hashtag.png"
export const employee = "employee"

export const EURO_3 = process.env.REACT_APP_EURO_3!
export const EURO_5 = process.env.REACT_APP_EURO_5!
export const EURO_10 = process.env.REACT_APP_EURO_10!
export const EURO_20 = process.env.REACT_APP_EURO_20!
export const EURO_30 = process.env.REACT_APP_EURO_30!
//payment plans id's:
export const FOR_PROFIT_MONTHLY_LESS_THAN_500K = process.env.REACT_APP_FOR_PROFIT_MONTHLY_LESS_THAN_500K!
export const FOR_PROFIT_ANNUALLY_LESS_THAN_500K = process.env.REACT_APP_FOR_PROFIT_ANNUALLY_LESS_THAN_500K!

export const FOR_PROFIT_MONTHLY_500K_TO_1M = process.env.REACT_APP_FOR_PROFIT_MONTHLY_500K_TO_1M!
export const FOR_PROFIT_ANNUALLY_500K_TO_1M = process.env.REACT_APP_FOR_PROFIT_ANNUALLY_500K_TO_1M!

export const FOR_PROFIT_MONTHLY_MORE_THAN_1M = process.env.REACT_APP_FOR_PROFIT_MONTHLY_MORE_THAN_1M!
export const FOR_PROFIT_ANNUALLY_MORE_THAN_1M = process.env.REACT_APP_FOR_PROFIT_ANNUALLY_MORE_THAN_1M!

export const NON_PROFIT_MONTHLY_ONE_TO_TEN = process.env.REACT_APP_NON_PROFIT_MONTHLY_ONE_TO_TEN!
export const NON_PROFIT_ANNUALLY_ONE_TO_TEN = process.env.REACT_APP_NON_PROFIT_ANNUALLY_ONE_TO_TEN!

export const NON_PROFIT_MONTHLY_ELEVEN_TO_ONEHUNDRED = process.env.REACT_APP_NON_PROFIT_MONTHLY_ELEVEN_TO_ONEHUNDRED!
export const NON_PROFIT_ANNUALLY_ELEVEN_TO_ONEHUNDRED = process.env.REACT_APP_NON_PROFIT_ANNUALLY_ELEVEN_TO_ONEHUNDRED!

export const NON_PROFIT_MONTHLY_ONEHUNDRED_PLUS = process.env.REACT_APP_NON_PROFIT_MONTHLY_ONEHUNDRED_PLUS!
export const NON_PROFIT_ANNUALLY_ONEHUNDRED_PLUS = process.env.REACT_APP_NON_PROFIT_ANNUALLY_ONEHUNDRED_PLUS!

export const not_found = "not found"

export const year = "Year"
export const month = "Month"

export const green = "#60A49F"
export const grey = "#c4c4c4"
export const eventDescriptionMaxLength = 400
export const eventTitleMaxLength = 50
export const postMaxLength = 2000
export const commentMaxLength = 1500

export const acceptedFileTypes = "image/apng, image/gif, image/avif, image/jpeg, image/jpg, image/png, image/svg+xml, image/webp, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
export const fileImageType = ["image/apng ", "image/svg+xml", "image/webp", "image/avif", "image/jpeg", "image/png", "image/gif", "image/jpg"]
