import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface LoadingStatus {
    status: boolean
}

const loadingStatusState: LoadingStatus = { status: false }

const LoadingStatusSlice = createSlice({
    name: "LoadingStatusSlice",
    initialState: loadingStatusState,
    reducers: {
        getLoadingStatus(state, action: PayloadAction<boolean>) {
            state.status = action.payload
        },
    },
})

export const { getLoadingStatus } = LoadingStatusSlice.actions

export default LoadingStatusSlice.reducer
