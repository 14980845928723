import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { InputType } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import "../../../Styles/main.scss"
import { LoadingAnimation } from "../../Shared/Components/SvgIcons"
import Button from "../../Shared/Primitive/Button"
import Checkbox from "../../Shared/Primitive/Checkbox"
import { privacySettingsGetRequest, putOrganizationPrivacy } from "./OrganizationPrivacyServiceRequest"
import useWindowSize from "../../Shared/Hooks/useWindowSize"

function OrganizationPrivacySettings() {
    const { id } = useParams()
    const userId = useSelector((state: RootState) => state.userDataSlice.id)
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(true)
    const [privacySettingsForm, setPrivacySettingsForm] = useState({
        search_results: true,
        google_search: false,
        private_messages: true,
        show_location: false,
        show_contact_info: false,
    })
    const screenWidth = useWindowSize()
    const breakpoint = 768

    useEffect(() => {
        privacySettingsGetRequest(id!).then(response => {
            if (response.status === 200) {
                const data = response.data.org_privacy_settings[0]
                setPrivacySettingsForm({
                    search_results: data.search_results,
                    google_search: data.google_search,
                    private_messages: data.private_messages,
                    show_location: data.show_location,
                    show_contact_info: data.show_contact_info,
                })
                setIsLoading(false)
            }
        })
    }, [id])

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.type === InputType.checkbox ? event.target.checked : event.target.value
        setPrivacySettingsForm({ ...privacySettingsForm, [event.target.name]: value })
    }
    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        dispatch(putOrganizationPrivacy(id!, userId!, privacySettingsForm))
    }

    return (
        <form onSubmit={onFormSubmit} className="organization-privacy-settings">
            {isLoading ? (
                <div className="organization-privacy-settings__loading-container">
                    <LoadingAnimation />
                </div>
            ) : (
                <>
                    <div className="organization-privacy-settings__content">
                        <div className="organization-privacy-settings__checkbox-container">
                            <Checkbox color="dark-blue" name="search_results" checked={privacySettingsForm.search_results} onChange={onInputChange} />
                            <div className="organization-privacy-settings__checkbox-label"> {translation.myProfileInfoCanBeDisplayedInSearchResults}</div>
                        </div>
                        <div className="organization-privacy-settings__checkbox-container">
                            <Checkbox color="dark-blue" name="google_search" checked={privacySettingsForm.google_search} onChange={onInputChange} />
                            <div className="organization-privacy-settings__checkbox-label"> {translation.myProfileInfoCanBeFoundInSearchEngines}</div>
                        </div>
                        <div className="organization-privacy-settings__checkbox-container organization-privacy-settings__checkbox-container--display-none">
                            <Checkbox color="dark-blue" name="private_messages" checked={privacySettingsForm.private_messages} onChange={onInputChange} />
                            <div className="organization-privacy-settings__checkbox-label"> {translation.otherMembersCanSendMePrivateMessages}</div>
                        </div>
                    </div>
                    <div className="organization-privacy-settings__button-container">
                        <div className="organization-privacy-settings__button">
                            <Button
                                type="submit"
                                className={`button ${
                                    screenWidth > breakpoint && "button--border-radius-05"
                                } button--color-gold button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400 `}
                            >
                                {translation.save}
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </form>
    )
}

export default OrganizationPrivacySettings
