import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { not_found, profileDefault } from "../../../App/GlobaleVariables"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import { NewMemberResponse } from "../../../Types/Responses/NewMemberResponse"
import Header from "../Header/Header"
import DonateButton from "../Components/DonateButton"
import Footer from "../Layout/Footer"
import MainLayout from "../Layout/MainLayout"
import OrganizationProfileCard from "../Components/OrganizationProfileCard"
import ModalGroup from "../Components/ModalGroup"
import RoomDropdown from "../Components/RoomDropdown"
import SponsorCard from "../Components/SponsorCard"
import { LoadingAnimation, SearchIcon } from "../Components/SvgIcons"
import UserCard from "../Components/UserCard"
import { setNewMembersData } from "../SharedSlices/NewestMembersSlice"
import { useNavigate, useParams } from "react-router-dom"
import { newestMemberGetRequest } from "./NewestMemberGetRequest"
import { RoleType } from "../../../App/enums"
import NewestOrganizationsCard from "../NewestOrganizations/NewestOrganizations"
import IndividualProfileCard from "../Components/IndividualProfileCard"
import BackgroundWrapper from "../Components/BackgroundWrapper"
import { onHashtagClick } from "../Hashtag/onHashtagClick"

function NewestMembersScreen() {
    const translation = useSelector(selectTranslations)
    const newMembersState = useSelector((state: RootState) => state.newMembers.newMembersSlice.members)
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(true)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const navigate = useNavigate()
    const { id } = useParams()
    const donationLink = useSelector((state: RootState) => state.UserSettingsStatusSlice.userDonationLinkSlice.link)

    const onSeeAllEventsClick = () => {
        switch (role) {
            case RoleType.ORGANIZATION:
                navigate(`/all-premium-events/${id}`)
                break
            case RoleType.USER:
                navigate(`/all-premium-events`)
                break
            default:
                break
        }
    }

    useEffect(() => {
        let isMounted = true

        newestMemberGetRequest()
            .then((response: NewMemberResponse) => {
                if (isMounted && response.status === 200) {
                    dispatch(setNewMembersData(response.data))
                    setIsLoading(false)
                }
            })
            .catch(error => {
                console.log(error)
            })
        return () => {
            isMounted = false
        }
    }, [dispatch])

    const members = newMembersState.filter(member => {
        return member.id !== userDataState.id
    })

    const onNavigationToUserProfile = (userId: number) => {
        switch (role) {
            case RoleType.USER:
                const userPath = `/user/${userId}`
                navigate(userPath)
                window.scrollTo(0, 0)
                break
            case RoleType.ORGANIZATION:
                const organizationPath = `/user/${userId}/${id}`
                navigate(organizationPath)
                window.scrollTo(0, 0)
                break
            default:
                break
        }
    }

    return (
        <BackgroundWrapper>
            <Header />
            <MainLayout
                leftPanelFirstComponent={role === RoleType.USER ? <OrganizationProfileCard /> : <IndividualProfileCard />}
                leftPanelSecondComponent={<RoomDropdown type={role!} />}
                leftPanelForthComponent={role === RoleType.USER && translation.newestOrganizations}
                leftPanelFifthComponent={role === RoleType.USER ? <NewestOrganizationsCard /> : null}
                RightPanelFirstComponent={role === RoleType.USER ? donationLink === not_found ? <DonateButton /> : null : null}
                RightPanelForthComponent={
                    <div className="main-layout__sponsored-title">
                        {translation.sponsoredText}
                        <div onClick={onSeeAllEventsClick} className="main-layout__sponsored-icon">
                            <SearchIcon color="dark-blue" />
                        </div>
                    </div>
                }
                RightPanelFifthComponent={<SponsorCard />}
                overflowModifier={true}
            >
                <div className="newest-members-screen">
                    <div className="newest-members-screen__title">{translation.membersWhoJoinedEdusiia}</div>
                    <div className="newest-members-screen__card">
                        <div className="newest-members-screen__content">
                            {isLoading ? (
                                <div className="newest-members-screen__loading">
                                    <LoadingAnimation />
                                </div>
                            ) : (
                                members?.map(item => {
                                    return (
                                        <div key={item.id}>
                                            <UserCard
                                                onTextClick={() => onNavigationToUserProfile(item.id!)}
                                                onLeftItemClick={() => onNavigationToUserProfile(item.id!)}
                                                imageSrc={item.profile_photo?.url_path || profileDefault}
                                                text={item.first_name + " " + item.last_name}
                                                subtext={
                                                    <div className="profile-header__tags-content profile-header__tags-content--width-99">
                                                        {item.hashtags?.map((hashtag, index) => (
                                                            <p
                                                                className="profile-header__tag"
                                                                key={index}
                                                                onClick={() => onHashtagClick(hashtag, navigate, role, parseInt(id!))}
                                                            >
                                                                {hashtag.charAt(0) !== "#" ? "#" + hashtag : hashtag}
                                                            </p>
                                                        ))}
                                                    </div>
                                                }
                                                userCardStyle={"newest-members-expanded-item"}
                                                leftPanelStyle={"left-panel"}
                                                middlePanelStyle={"middle-panel"}
                                                profileImageStyle={"image image__profile image__profile--fit image__profile--size-medium-small"}
                                                textStyle={"text"}
                                                textStyleMore25={"text"}
                                                subtextStyle={"subtext"}
                                                rightTextStyle={"right-text"}
                                                textStyleContainer={"text-container"}
                                                subtextStyleContainer={"subtext-container"}
                                            />
                                        </div>
                                    )
                                })
                            )}
                        </div>
                    </div>
                </div>
            </MainLayout>
            <Footer />
            <ModalGroup />
        </BackgroundWrapper>
    )
}

export default NewestMembersScreen
