import { useEffect, useState } from "react"
import { RootState } from "../../../App/store"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {  LeaveIcon, LeftArrowGreen, TrashCanIcon, TrashCanIconSmall } from "../../Shared/Components/SvgIcons"
import { setActiveConversation } from "../ChatSlice"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import Button from "../../Shared/Primitive/Button"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import Image from "../../Shared/Primitive/Image"
import { singleChatListGetRequest } from "../ChatServiceRequest"

function ChatHeader() {
    const translation = useSelector(selectTranslations)
    const activeConversationId = useSelector((state: RootState) => state.chatSlice.activeConversationId)
    const conversations = useSelector((state: RootState) => state.chatSlice.conversations)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const navigate = useNavigate()
    const [chatPartner, setChatPartner] = useState({
        id: 0,
        user: { id: 0, first_name: "", last_name: "", online: false, profile_photo: { id: 0, url_path: "" } },
    })
    const [chatPartnerStatusModifier,setChatPartnerStatusModifier] = useState("offline") 
    const dispatch = useDispatch()

    useEffect(() => {
        if (activeConversationId > 0) {
            let conversation = conversations.filter(conv => conv.id == activeConversationId)[0]
            setChatPartner(conversation.member.filter(userObj => userObj.user.id != userDataState.id)[0])
            setChatPartnerStatusModifier(chatPartner.user.online?"online":"offline")
        }
    }, [activeConversationId,chatPartnerStatusModifier])

    const onBackClick = () => {
        dispatch(setActiveConversation(0))
    }

    const onLeaveChatClick = () => {
        dispatch(setModalData({ open: true, leaveChatModal: true }))
    }

    return (
        <>
            {activeConversationId > 0 && (
                <div className="chat-layout__right-panel-header">
                    <div className="chat-header-container">
                        <div className="chat-header">
                            <div className="chat-header__chat-content">
                                <div className="chat-header__back-to-list" onClickCapture={() => onBackClick()}><LeftArrowGreen/></div>
                                <div className="chat-header__profile-photo" onClick={() => navigate(`/user/${chatPartner.user.id}`)}>
                                    {chatPartner.user?.profile_photo?.url_path != null ? (
                                        <div className="image__profile--holder">
                                            <Image
                                                src={chatPartner.user.profile_photo.url_path}
                                                className="image__profile image__profile--size-small  image__profile--fit"
                                            />
                                            <span className={`image__profile--status-${chatPartner.user.online? ('online'):('offline')} topRight`}>&nbsp;</span>
                                        </div>
                                    ) : (
                                        <div className="image__profile--holder">
                                        <Image
                                            src="/edusiia_placeholder_individual_profile.png"
                                            className="image__profile image__profile--size-small image__profile--fit"
                                        />
                                        <span className={`image__profile--status-${chatPartner.user.online? ('online'):('offline')} topRight`}>&nbsp;</span>
                                        </div>
                                    )}
                                </div>
                                <div className="chat-header__name-container" onClick={() => navigate(`/user/${chatPartner.user.id}`)}>
                                    <div className="chat-header__name">
                                        {chatPartner.user.first_name != '' ?(
                                            chatPartner.user.first_name + " " + chatPartner.user.last_name
                                        ):
                                        (
                                            translation.deletedUser
                                        )}
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="chat-header__button chat-header__button">
                                <div className="chat-header__button-icon" onClick={onLeaveChatClick}>
                                    <TrashCanIconSmall />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default ChatHeader
