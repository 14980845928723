import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import HTTPService from "../../../../App/HTTPService"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import { RootState } from "../../../../App/store"
import "../../../../Styles/main.scss"
import { User } from "../../../../Types/User"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import UserProfileDescriptionItem from "./UserProfileDescriptionItem"

function UserProfileDescription(props: { isEditable: boolean; isYourProfile: boolean }) {
    const navigate = useNavigate()
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const [otherUserData, setOtherUserData] = useState<User>()
    const { userId } = useParams()

    const getUserRequest = (id: string) => {
        return HTTPService.get(`/profile/${id}/`)
    }

    useEffect(() => {
        if (!props.isYourProfile) {
            getUserRequest(userId!)
                .then(response => {
                    if (response.status === 200) {
                        setOtherUserData(response.data)
                    }
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        navigate("/not-found")
                    }
                })
        }
    }, [navigate, props.isYourProfile, userId])

    const onEditDescriptionOpen = () => {
        dispatch(setModalData({ open: true, editDescription: true }))
    }

    const onEditReasonsOpen = () => {
        dispatch(setModalData({ open: true, editEdusiiaReasons: true }))
    }

    return (
        <div className="user-profile-description">
            <div className="user-profile-description__content">
                <div className="user-profile-description__text">
                    {props.isYourProfile ? (
                        <UserProfileDescriptionItem
                            onEdit={onEditDescriptionOpen}
                            editIcon={props.isEditable}
                            title={translation.myRoleinEducation}
                            description={userDataState?.description === undefined ? translation.noDescriptionYet : userDataState.description}
                        />
                    ) : (
                        <UserProfileDescriptionItem
                            editIcon={false}
                            title={translation.myRoleinEducation}
                            description={otherUserData! && otherUserData!.description!}
                        />
                    )}
                </div>
                <div className="user-profile-description__divider"></div>
                <div className="user-profile-description__text">
                    {props.isYourProfile ? (
                        <UserProfileDescriptionItem
                            onEdit={onEditReasonsOpen}
                            editIcon={props.isEditable}
                            title={translation.whyAmIAtEdusiia}
                            description={userDataState?.edusiia_reasons === undefined ? translation.noDescriptionYet : userDataState.edusiia_reasons}
                        />
                    ) : (
                        <UserProfileDescriptionItem
                            editIcon={false}
                            title={translation.whyAmIAtEdusiia}
                            description={otherUserData! && otherUserData!.edusiia_reasons!}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default UserProfileDescription
