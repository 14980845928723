import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { PasswordConfirmationEnum } from "../../../App/enums"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import Button from "../../Shared/Primitive/Button"
import Checkbox from "../../Shared/Primitive/Checkbox"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"

function OrganizationDeleteAccount() {
    const translation = useSelector(selectTranslations)
    const [isChecked, setIsChecked] = useState(false)
    let dispatch = useDispatch()

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked)
    }

    const onFormSumbit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        dispatch(
            setModalData({
                open: true,
                userConfirmPasswordModalState: {
                    status: true,
                    type: PasswordConfirmationEnum.organization_delete,
                },
            })
        )
    }
    return (
        <form className="delete-account" onSubmit={onFormSumbit}>
            <div className="delete-account__form">
                <p className="delete-account__title">{translation.deleteAccount}</p>
                <p className="delete-account__information-title">{translation.wantToDeleteOrganizationalAccount}</p>
                <div className="delete-account__information-container">
                    <p className="delete-account__information">{translation.asTheOwnerYouCanDelete}</p>
                    <p className="delete-account__information">{translation.beforeProceedingWarning}</p>
                    <ul>
                        <li className="delete-account__information">{translation.firstOrganizationDeleteAccountWarning}</li>
                        <li className="delete-account__information">{translation.secondOrganizationDeleteAccountWarning}</li>
                        <li className="delete-account__information">{translation.thirdOrganizationDeleteAccountWarning1}</li>
                    </ul>
                </div>

                <div className="delete-account__checkbox  delete-account__checkbox--padding-45 ">
                    <Checkbox color="red" checked={isChecked} onChange={onInputChange} />
                    <p className="delete-account__checkbox-text">{translation.acceptConsequences}</p>
                </div>
            </div>
            <div className="delete-account__button-container">
                <div className="delete-account__button">
                    {isChecked ? (
                        <Button className="button button--border-radius-05 button--color-red button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400 ">
                            {translation.delete}
                        </Button>
                    ) : (
                        <Button
                            disabled
                            className="button button--border-radius-05 button--color-grey  button--text-color-white button--font-size-small button--font-weight-400 "
                        >
                            {translation.delete}
                        </Button>
                    )}
                </div>
            </div>
        </form>
    )
}

export default OrganizationDeleteAccount
