import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import "../../../Styles/main.scss"
import { CloseIcon, BackIcon } from "../../Shared/Components/SvgIcons"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import OrganizationNotificationSettings from "./OrganizationNotificationSettings"
import OrganizationPrivacySettings from "./OrganizationPrivacySettings"
import OrganizationAccountSettings from "./OrganizationAccountSettings"
import OrganizationDeleteAccount from "./OrganizationDeleteAccountSettings"
import { resetOrganizationAccountError } from "../../Shared/SharedSlices/OrganizationsSlice"
import { RootState } from "../../../App/store"
import {
    getOrganizationNotificationsSettingsState,
    getOrganizationPrivacySettingsState,
    getOrganizationProfileSettingsState,
    getOrganizationSubscriptionSettingsState,
} from "./OrganizationSettingsSlice"
import SubscriptionSettings from "./SubscriptionSettings"
import useWindowSize from "../../Shared/Hooks/useWindowSize"

function OrganizationSettingsModal() {
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()
    const account = useSelector((state: RootState) => state.OrganziationSettingsStatusSlice.organizationSettingsStatus.status)
    const notification = useSelector((state: RootState) => state.OrganziationSettingsStatusSlice.organizationNotificationsSettingsStateSlice.status)
    const privacy = useSelector((state: RootState) => state.OrganziationSettingsStatusSlice.organizationPrivacySettingsStateSlice.status)
    const subscription = useSelector((state: RootState) => state.OrganziationSettingsStatusSlice.organizationSubscriptionSettingsStateSlice.status)
    const organization = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const isOwner = useSelector((state: RootState) => state.organizationsSlice.isOwnerSlice.isOwner)
    const [deleteAccount, setDeleteAccount] = useState(false)
    const screenWidth = useWindowSize()
    const breakpoint = 768

    useEffect(() => {
        if (screenWidth < breakpoint) {
            dispatch(getOrganizationProfileSettingsState(false))
        }
    }, [])

    const onModalClose = () => {
        dispatch(setModalData({ open: false, organizationSettings: false, viewPhotoTypeModal: null, changePhotoTypeModal: null }))
        dispatch(getOrganizationNotificationsSettingsState(false))
        dispatch(getOrganizationPrivacySettingsState(false))
        dispatch(getOrganizationSubscriptionSettingsState(false))
        dispatch(resetOrganizationAccountError())
    }

    const onBackIconClick = () => {
        dispatch(getOrganizationProfileSettingsState(!account))
        dispatch(resetOrganizationAccountError())
    }

    return (
        <div className="settings-modal">
            <div className="settings-modal__mobile-close-icon-container">
                <div onClick={onModalClose} className="settings-modal__mobile-close-icon">
                    <CloseIcon />
                </div>
            </div>
            <div className="settings-left-panel">
                <div className="settings-left-panel__title">
                    <div className="settings-text">{translation.settings}</div>
                </div>
                {!notification && !privacy && !deleteAccount && !subscription ? (
                    <div className={`settings-left-panel__item ${account && "settings-left-panel__item--align-start"}`} onClick={onBackIconClick}>
                        <div className="settings-left-panel__icon">{account && <BackIcon />}</div>
                        <div className={`settings-text ${account && "text-position"}`}>{!account ? translation.account : translation.AllSettings}</div>
                    </div>
                ) : null}
                {!account && !privacy && !deleteAccount && !subscription ? (
                    <div
                        className={`settings-left-panel__item ${notification && "settings-left-panel__item--align-start"}`}
                        onClick={() => dispatch(getOrganizationNotificationsSettingsState(!notification))}
                    >
                        <div className="settings-left-panel__icon">{notification && <BackIcon />}</div>
                        <div className={`settings-text ${notification && "text-position"}`}>
                            {!notification ? translation.notifications : translation.AllSettings}
                        </div>
                    </div>
                ) : null}
                {!account && !notification && !deleteAccount && !subscription ? (
                    <div
                        className={`settings-left-panel__item ${privacy && "settings-left-panel__item--align-start"}`}
                        onClick={() => dispatch(getOrganizationPrivacySettingsState(!privacy))}
                    >
                        <div className="settings-left-panel__icon">{privacy && <BackIcon />}</div>
                        <div className={`settings-text ${privacy && "text-position"}`}>{!privacy ? translation.privacy : translation.AllSettings}</div>
                    </div>
                ) : null}
                {!notification && !privacy && !deleteAccount && !account && organization?.subscription_type! > 0 && isOwner ? (
                    <div className="settings-left-panel__item" onClick={() => dispatch(getOrganizationSubscriptionSettingsState(!subscription))}>
                        <div className="settings-left-panel__icon">{subscription && <BackIcon />}</div>
                        <div className={`settings-text ${subscription && "text-position"}`}>
                            {!subscription ? translation.subscription : translation.AllSettings}
                        </div>
                    </div>
                ) : null}
                {!account && !notification && !privacy && !subscription && isOwner ? (
                    <div className="settings-left-panel__item" onClick={() => setDeleteAccount(!deleteAccount)}>
                        <div className="settings-left-panel__icon">{deleteAccount && <BackIcon />}</div>
                        <div className={`settings-text ${deleteAccount && "text-position"}`}>
                            {!deleteAccount ? translation.DeleteAccount : translation.AllSettings}
                        </div>
                    </div>
                ) : null}
                <div
                    className={`settings-modal__mobile-settings ${
                        !deleteAccount && !privacy && !account && !subscription && "settings-modal__mobile-settings--height-none"
                    }`}
                >
                    {deleteAccount && <OrganizationDeleteAccount />} {account && <OrganizationAccountSettings />}
                    {privacy && <OrganizationPrivacySettings />}
                    {subscription && <SubscriptionSettings />}
                </div>
            </div>
            <div className={`settings-right-panel  ${notification && "settings-right-panel--mobile"}`}>
                <div className="settings-right-panel__close-settings">
                    <div onClick={onModalClose} className="close-icon">
                        <CloseIcon />
                    </div>
                </div>
                <div className={`settings-right-panel__settings ${notification && "settings-right-panel__settings--mobile"}`}>
                    {privacy && <OrganizationPrivacySettings />}
                    {deleteAccount && <OrganizationDeleteAccount />}
                    {account && <OrganizationAccountSettings />}
                    {notification && <OrganizationNotificationSettings />}
                    {subscription && <SubscriptionSettings />}
                </div>
            </div>
        </div>
    )
}

export default OrganizationSettingsModal
