import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { setModalData } from "../SharedSlices/ModalSlice"

function NoOrganizationCard() {
    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)
    const onAddOrganizationModalOpen = () => {
        dispatch(setModalData({ open: true, addOrganization: true }))
    }
    return (
        <div className="no-organization-card">
            <p className="no-organization-card__text" onClick={onAddOrganizationModalOpen}>
                {translation.createOrganization}
            </p>
        </div>
    )
}

export default NoOrganizationCard
