import { headers } from "../../../App/Axios"
import { StorageKey } from "../../../App/enums"
import HTTPService from "../../../App/HTTPService"
import StorageService from "../../../App/StorageService"
import { User } from "../../../Types/User"
import { GetOrganizationRequest } from "../../Organization/OrganizationDashboard/GetOrganizationServiceRequest"
import { setModalData } from "../SharedSlices/ModalSlice"
import {
    getCoverPhotoDropdownStatus,
    getLoadingCoverPhotoDropdownStatus,
    getLoadingOrganizationCoverPhotoDropdownStatus,
    getLoadingOrganizationProfilePhotoDropdownStatus,
    getLoadingProfilePhotoDropdownStatus,
    getOrganizationCoverPhotoDropdownStatus,
    getOrganizationProfilePhotoDropdownStatus,
    getProfilePhotoDropdownStatus,
    getProfilePhotoError,
    resetProfilePhotoError,
} from "../SharedSlices/PhotoDropdownSlice"
import { getPhotoSizeErrorMessage } from "../SharedSlices/RoomSlice"
import { setUserData } from "../SharedSlices/UserDataSlice"

const accessToken = StorageService.getItem(StorageKey.accessToken) as string

export const header = {
    headers: { Authorization: `Bearer ${accessToken}`, "Content-Type": "multipart/form-data" },
}

export const ProfilePhotoGetRequest = (user: User) => {
    return HTTPService.get(`/user/${user.id}/profile-photo/`, headers)
}

export const ProfilePhotoPutRequest = (user: User, formData: FormData, setLoadImage: Function) => async (dispatch: Function) => {
    try {
        const response = await HTTPService.put(`/user/${user.id}/profile-photo/`, formData, header)
        if (response.status === 200) {
            dispatch(setModalData({ open: false, viewPhotoTypeModal: null, changePhotoTypeModal: null, settings: false }))
            ProfilePhotoGetRequest(user).then(response => {
                if (response.status === 200) {
                    dispatch(
                        setUserData({
                            ...user,
                            profile_photo: response.data.profile_photo,
                        })
                    )
                    dispatch(getPhotoSizeErrorMessage(false))
                    dispatch(getLoadingProfilePhotoDropdownStatus({ loading: false }))
                    dispatch(resetProfilePhotoError())
                }
            })
            dispatch(getProfilePhotoDropdownStatus({ status: false }))
            setLoadImage(false)
        }
    } catch (error: any) {
        if (error.response.status === 413) {
            dispatch(getPhotoSizeErrorMessage(true))
            dispatch(getLoadingProfilePhotoDropdownStatus({ loading: false }))
            setLoadImage(false)
        }
        if (error.response.status === 400) {
            dispatch(getLoadingProfilePhotoDropdownStatus({ loading: false }))
            setLoadImage(false)
            dispatch(getProfilePhotoError(error.response!.data[0]!))
        }
        console.log(error)
    }
}

export const OrganizationProfilePhotoPutRequest =
    (id: string | undefined, user: User, formData: FormData, setLoadImage: Function) => async (dispatch: Function) => {
        try {
            const response = await HTTPService.put(`/organization/${id}/profile-photo/`, formData, header)
            if (response.status === 200) {
                dispatch(setModalData({ open: false, viewPhotoTypeModal: null, changePhotoTypeModal: null, settings: false }))
                dispatch(GetOrganizationRequest(id, user))
                dispatch(getOrganizationProfilePhotoDropdownStatus({ status: false }))
                dispatch(getPhotoSizeErrorMessage(false))
                dispatch(getLoadingOrganizationProfilePhotoDropdownStatus({ loading: false }))
                dispatch(resetProfilePhotoError())
            }
        } catch (error: any) {
            if (error.response.status === 413) {
                dispatch(getPhotoSizeErrorMessage(true))
                dispatch(getLoadingOrganizationProfilePhotoDropdownStatus({ loading: false }))
                setLoadImage(false)
            }
            if (error.response.status === 400) {
                dispatch(getLoadingOrganizationProfilePhotoDropdownStatus({ loading: false }))
                setLoadImage(false)
                dispatch(getProfilePhotoError(error.response!.data[0]!))
            }
            console.log(error)
        }
    }

export const OrganizationCoverPhotoPutRequest =
    (id: string | undefined, user: User, formData: FormData, setLoadImage: Function) => async (dispatch: Function) => {
        try {
            const response = await HTTPService.put(`/organization/${id}/cover-photo/`, formData, header)
            if (response.status === 200) {
                dispatch(getOrganizationCoverPhotoDropdownStatus({ status: false }))
                dispatch(setModalData({ open: false, viewPhotoTypeModal: null, changePhotoTypeModal: null, settings: false }))
                dispatch(GetOrganizationRequest(id, user))
                dispatch(getPhotoSizeErrorMessage(false))
                dispatch(getLoadingOrganizationCoverPhotoDropdownStatus({ loading: false }))
                dispatch(resetProfilePhotoError())
                setLoadImage(false)
            }
        } catch (error: any) {
            if (error.response.status === 413) {
                dispatch(getPhotoSizeErrorMessage(true))
                dispatch(getLoadingOrganizationCoverPhotoDropdownStatus({ loading: false }))
                setLoadImage(false)
            }
            if (error.response.status === 400) {
                dispatch(getLoadingOrganizationCoverPhotoDropdownStatus({ loading: false }))
                setLoadImage(false)
                dispatch(getProfilePhotoError(error.response!.data[0]!))
            }
            console.log(error)
        }
    }

export const CoverPhotoGetRequest = (user: User) => {
    return HTTPService.get(`/user/${user.id}/cover-photo/`, headers)
}

export const CoverPhotoPutRequest = (user: User, formData: FormData, setLoadImage: Function) => async (dispatch: Function) => {
    try {
        const response = await HTTPService.put(`/user/${user.id}/cover-photo/`, formData, header)
        if (response.status === 200) {
            dispatch(getCoverPhotoDropdownStatus({ status: false }))
            dispatch(setModalData({ open: false, settings: false, viewPhotoTypeModal: null, changePhotoTypeModal: null }))
            dispatch(resetProfilePhotoError())
            CoverPhotoGetRequest(user).then(response => {
                if (response.status === 200) {
                    dispatch(
                        setUserData({
                            ...user,
                            cover_photo: response.data.cover_photo,
                        })
                    )
                    dispatch(getPhotoSizeErrorMessage(false))
                    dispatch(getLoadingCoverPhotoDropdownStatus({ loading: false }))
                }
            })
        }
    } catch (error: any) {
        if (error.response.status === 413) {
            dispatch(getPhotoSizeErrorMessage(true))
            dispatch(getLoadingCoverPhotoDropdownStatus({ loading: false }))
            setLoadImage(false)
        }
        if (error.response.status === 400) {
            dispatch(getLoadingCoverPhotoDropdownStatus({ loading: false }))
            setLoadImage(false)
            dispatch(getProfilePhotoError(error.response!.data[0]!))
        }
        console.log(error)
    }
}
