import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import "../../../../Styles/main.scss"
import Button from "../../../Shared/Primitive/Button"
import Checkbox from "../../../Shared/Primitive/Checkbox"
import { CloseIcon } from "../../../Shared/Components/SvgIcons"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { RootState } from "../../../../App/store"
import { deleteExperience, editUserExperience } from "./UserProfileExperienceServiceRequest"
import moment from "moment"
import TextInput from "../../../Shared/Primitive/TextInput"
import { resetUserProfileExperienceError } from "../../../Shared/SharedSlices/WorkExperienceSlice"
import { isDefined } from "../../../../App/Helpers/Helpers"
import { headers } from "../../../../App/Axios"
import HTTPService from "../../../../App/HTTPService"
import { searchedOrganization } from "../../../../Types/SearchedOrganizations"
import { InputType } from "../../../../App/enums"

function UserProfileEditExperienceModal() {
    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)
    const errorMessagesState = useSelector((state: RootState) => state.workExperienceSlice.workExperienceError)
    const workExperienceState = useSelector((state: RootState) => state.workExperienceSlice.workExperienceSlice.work)
    const experienceId = useSelector((state: RootState) => state.workExperienceSlice.workExperienceIdSlice.id)
    const selectedWorkExperience = workExperienceState.find(experience => experience.id === experienceId)
    const userId = useSelector((state: RootState) => state.userDataSlice.id)
    const [filter, setFilter] = useState("")
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    let DropdownRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
    const [expanded, setExpanded] = useState(false)
    const modalStatus = useSelector((state: RootState) => state.modalSlice)
    const [editExperienceForm, setEditExperienceForm] = useState({
        position: selectedWorkExperience!.work_position,
        company_name: selectedWorkExperience!.company_name,
        startDate: new Date(selectedWorkExperience!.start_date),
        endDate: isDefined(selectedWorkExperience!.end_date) ? new Date(selectedWorkExperience!.end_date) : null,
        isInEdusia: selectedWorkExperience!.is_in_edusiia,
        organizationId: selectedWorkExperience!.org_id || null,
        website_url: selectedWorkExperience!.website_url,
        is_current_role: selectedWorkExperience?.is_current_role,
    })
    const [searchedOrganizations, setSearchedOrganizations] = useState<searchedOrganization[]>([])

    const getRequest = useCallback((name: string) => {
        return HTTPService.get(`/org/search?${name}`, headers)
    }, [])

    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (isDropdownOpen && DropdownRef.current && !DropdownRef.current.contains(event.target as HTMLElement)) {
                setIsDropdownOpen(false)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    })

    useEffect(() => {
        const onMouseDownHandler = (event: Event) => {
            if (modalStatus.editExperience) {
                setExpanded(true)
            }
        }
        document.addEventListener("mousedown", onMouseDownHandler)
    }, [modalStatus.editExperience])

    useEffect(() => {
        if (editExperienceForm?.company_name?.length > 1) {
            setIsDropdownOpen(true)
            getRequest(editExperienceForm.company_name)
                .then(response => {
                    if (response.status === 200) {
                        setSearchedOrganizations(response.data)
                    }
                })
                .catch(error => {
                    setIsDropdownOpen(false)
                })
        } else {
            setIsDropdownOpen(false)
        }
    }, [editExperienceForm.company_name, getRequest])

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.type === InputType.checkbox ? event.target.checked : event.target.value
        setEditExperienceForm({ ...editExperienceForm, [event.target.name]: value })
        if (event.target.name === "company_name") {
            editExperienceForm.company_name.length > 1 && setFilter(event.target.value)
            if (editExperienceForm.organizationId === null) {
                setEditExperienceForm({ ...editExperienceForm, company_name: event.target.value, isInEdusia: false })
            } else {
                setEditExperienceForm({ ...editExperienceForm, company_name: event.target.value, organizationId: null })
            }
        }
        setIsDropdownOpen(false)
    }

    const onOrganizationClick = (organizationName: string, is_in_edusiia: boolean, org_id: number) => {
        setIsDropdownOpen(false)
        setEditExperienceForm({ ...editExperienceForm, company_name: organizationName, isInEdusia: true, organizationId: org_id })
    }

    const onModalClose = () => {
        dispatch(setModalData({ open: false, editExperience: false }))
        dispatch(resetUserProfileExperienceError())
        setExpanded(false)
    }

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        const formattedStartDate = moment(editExperienceForm.startDate).format("YYYY-MM-DD")
        const formattedEndDate = moment(editExperienceForm.endDate).format("YYYY-MM-DD")

        if (editExperienceForm.endDate !== null) {
            dispatch(
                editUserExperience(
                    editExperienceForm.position,
                    editExperienceForm.company_name,
                    formattedStartDate,
                    formattedEndDate,
                    experienceId,
                    userId!,
                    editExperienceForm.organizationId,
                    editExperienceForm.isInEdusia,
                    editExperienceForm.website_url,
                    editExperienceForm.is_current_role!
                )
            )
        } else {
            dispatch(
                editUserExperience(
                    editExperienceForm.position,
                    editExperienceForm.company_name,
                    formattedStartDate,
                    null,
                    experienceId,
                    userId!,
                    editExperienceForm.organizationId,
                    editExperienceForm.isInEdusia,
                    editExperienceForm.website_url,
                    editExperienceForm.is_current_role!
                )
            )
        }
    }

    const onDeleteExperienceClick = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        dispatch(deleteExperience(experienceId, userId!))
    }

    // const onCheckboxClick = () => {
    //     setEditExperienceForm({ ...editExperienceForm, endDate: null, is_current_role: !editExperienceForm.is_current_role })
    // }

    return (
        <form onSubmit={onFormSubmit} className="user-profile-edit-experience-modal">
            <div onClick={onModalClose} className="user-profile-edit-experience-modal__close-icon">
                <CloseIcon />
            </div>
            <div className="user-profile-edit-experience-modal__title-container">
                <div className="user-profile-edit-experience-modal__title">{translation.myExperience} </div>
            </div>
            <div className="user-profile-edit-experience-modal__content">
                <div className="user-profile-edit-experience-modal__double-input-container ">
                    <div className="user-profile-edit-experience-modal__input-container  user-profile-edit-experience-modal__input-container--margin-right">
                        <div className="user-profile-edit-experience-modal__text">{translation.position}</div>
                        <TextInput
                            textinputsize="small-40"
                            name="position"
                            placeholder={translation.position}
                            value={editExperienceForm.position}
                            onChange={onInputChange}
                            autoComplete="off"
                        />
                        <div className="user-profile-edit-experience-modal__error-text">{errorMessagesState.message.work_position}</div>
                    </div>
                    <div className="user-profile-edit-experience-modal__input-container" ref={DropdownRef} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        <div className="user-profile-edit-experience-modal__text">{translation.companyName}</div>
                        <TextInput
                            textinputsize="small-40"
                            placeholder={translation.companyName}
                            value={editExperienceForm.company_name}
                            onChange={onInputChange}
                            name="company_name"
                            autoComplete="off"
                        />
                        {expanded && editExperienceForm.organizationId === null && isDropdownOpen && (
                            <div onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="user-profile-edit-experience-modal__input-list">
                                {searchedOrganizations
                                    ?.filter(organization => organization.name.toLowerCase().includes(filter.toLowerCase()))
                                    .map((organization, index) => {
                                        return (
                                            <div
                                                key={organization.id}
                                                className={`user-profile-edit-experience-modal__input-list_organization  
                                        ${
                                            searchedOrganizations.length - 1 === index &&
                                            "user-profile-edit-experience-modal__input-list_organization--no-border"
                                        } `}
                                                onClick={() => onOrganizationClick(organization.name, true, organization.id)}
                                            >
                                                <div className="user-profile-edit-experience-modal__text">{organization?.name}</div>
                                            </div>
                                        )
                                    })}
                            </div>
                        )}
                        <div className="user-profile-edit-experience-modal__error-text">{errorMessagesState.message.company_name}</div>
                    </div>
                </div>
                <div className="user-profile-edit-experience-modal__double-input-container ">
                    <div className="user-profile-edit-experience-modal__input-container  user-profile-edit-experience-modal__input-container--margin-right">
                        <div className="user-profile-edit-experience-modal__text">
                            {translation.website}
                            <div className="user-profile-edit-experience-modal__text--optional">{translation.optional}</div>
                        </div>
                        <TextInput
                            textinputsize="small-40"
                            placeholder="https://"
                            name="website_url"
                            value={editExperienceForm.website_url}
                            onChange={onInputChange}
                            autoComplete="off"
                        />
                        <div className="user-profile-edit-experience-modal__error-text">{errorMessagesState.message.work_position}</div>
                    </div>
                </div>
                <div className="user-profile-edit-experience-modal__double-input-container user-profile-edit-experience-modal__double-input-container--margin-left-02">
                    <div className="user-profile-edit-experience-modal__date-container user-profile-edit-experience-modal__date-container--margin-right-2">
                        <div className="user-profile-edit-experience-modal__text">{translation.startDate}</div>
                        <DatePicker
                            wrapperClassName="react-datepicker-wrapper"
                            dateFormat={"yyyy-MM-dd"}
                            selected={editExperienceForm.startDate}
                            onChange={(date: Date) => setEditExperienceForm({ ...editExperienceForm, startDate: date })}
                            maxDate={editExperienceForm.endDate}
                            placeholderText="yyyy-mm-dd"
                            showYearDropdown
                            scrollableYearDropdown
                            dropdownMode="select"
                        />
                        <div className="user-profile-edit-experience-modal__error-text">{errorMessagesState.message.start_date}</div>
                    </div>

                    {!editExperienceForm.is_current_role && (
                        <div className="user-profile-edit-experience-modal__date-container user-profile-edit-experience-modal__date-container--margin-right-2">
                            <div className="user-profile-edit-experience-modal__date-container">
                                <div className="user-profile-edit-experience-modal__text">{translation.endDate}</div>
                                <DatePicker
                                    wrapperClassName="react-datepicker-wrapper"
                                    dateFormat={"yyyy-MM-dd"}
                                    selected={editExperienceForm.endDate}
                                    onChange={(date: Date) =>
                                        editExperienceForm.is_current_role
                                            ? setEditExperienceForm({ ...editExperienceForm, endDate: null })
                                            : setEditExperienceForm({ ...editExperienceForm, endDate: date })
                                    }
                                    minDate={editExperienceForm.startDate}
                                    maxDate={new Date()}
                                    placeholderText="yyyy-mm-dd"
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                />
                                <div className="user-profile-edit-experience-modal__error-text">{errorMessagesState.message.end_date}</div>
                                <div className="user-profile-edit-experience-modal__date-input-container"></div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="user-profile-edit-experience-modal__checkbox-container">
                    <Checkbox
                        color="dark-blue"
                        checked={editExperienceForm.is_current_role}
                        onChange={() => setEditExperienceForm({ ...editExperienceForm, endDate: null, is_current_role: !editExperienceForm.is_current_role })}
                    />
                    <div className="user-profile-edit-experience-modal__checkbox-label">{translation.currentlyWorking}</div>
                </div>
            </div>
            <div className="user-profile-edit-experience-modal__custom-error-text">{errorMessagesState.message.is_current_role}</div>
            <div className="user-profile-edit-experience-modal__buttons-container">
                <div onClick={onDeleteExperienceClick} className="user-profile-edit-experience-modal__left-button">
                    {translation.deleteExperience}
                </div>
                <div className="user-profile-edit-experience-modal__right-button">
                    <Button
                        type="submit"
                        className="button button--font-size-small button--font-weight-400 button--text-color-white button--color-gold button--hover-brighter button--cursor-pointer button--border-radius-05 "
                    >
                        {translation.save}
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default UserProfileEditExperienceModal
