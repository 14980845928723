import { useSelector, useDispatch } from "react-redux"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import Button from "../../../Shared/Primitive/Button"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import { ShareIcon } from "../../../Shared/Components/SvgIcons"

function RoomInvitationLinkButton() {
    const translation = useSelector(selectTranslations)
    const dispatch = useDispatch()

    const openModal = () => {
        dispatch(setModalData({ open: true, roomInvitationLinkModal: true }))
    }
    return (
        <div className="room-invitation-link-button">
            <Button
                onClick={openModal}
                className=" button button--border-radius-05  button--color-blue button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-700 "
            >
                <div className="room-invitation-link-button__button-content">
                    {" "}
                    <div className="room-invitation-link-button__icon">
                        <ShareIcon color="white" strokeWidth="2" size="size-small" />
                    </div>
                    <p className="room-invitation-link-button__text">{translation.invitationLink}</p>
                </div>
            </Button>
        </div>
    )
}

export default RoomInvitationLinkButton
