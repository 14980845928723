import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { RoleType } from "../../../App/enums"
import { RootState } from "../../../App/store"

function BackgroundWrapper(props: { children: JSX.Element[] | JSX.Element }) {
    const { id, userId } = useParams()
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const [userBackground, setUserBackground] = useState(true)

    useEffect(() => {
        if (role === RoleType.USER && id) {
            setUserBackground(false)
        } else if (role === RoleType.USER && userId) {
            setUserBackground(true)
        } else if (role === RoleType.ORGANIZATION && id) {
            setUserBackground(false)
            if (role === RoleType.ORGANIZATION && userId) {
                setUserBackground(true)
            }
        }
    }, [id, role, userId])

    return <div className={`background-wrapper ${userBackground ? "background-wrapper--user" : "background-wrapper--organization"}`}>{props.children}</div>
}

export default BackgroundWrapper
