import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Room } from "../../../../Types/Room"

type RoomData = {
    rooms: Array<Room>
}
const initialState: RoomData = { rooms: [] }

const UserRoomSearchSlice = createSlice({
    name: "UserRoomSearchSlice",
    initialState: initialState,
    reducers: {
        setRoomsSearchedData(state, action: PayloadAction<Array<Room>>) {
            state.rooms = action.payload
        },
    },
})

const queryState ={query: ""}

const SearchQuerySlice = createSlice({
    name: "SearchQuerySlice",
    initialState: queryState,
    reducers: {
        setSearchQuery(state , action: PayloadAction<string>) {
            state.query = action.payload
        }
    }
})

export const { setRoomsSearchedData } = UserRoomSearchSlice.actions
export const {setSearchQuery} = SearchQuerySlice.actions

export default combineReducers({
    RoomsSlice: UserRoomSearchSlice.reducer,
    SearchQuerySlice: SearchQuerySlice.reducer,
})
