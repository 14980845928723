import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import "../../../Styles/main.scss"
import FeedItem from "../../Shared/Components/FeedItem"
import SponsorCard from "../../Shared/Components/SponsorCard"
import { SearchIcon } from "../../Shared/Components/SvgIcons"
import { GetOrganizationRequest } from "./GetOrganizationServiceRequest"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { RoleType } from "../../../App/enums"
import { setSearchQuery } from "../OrganizationProfile/OrganizationProfileEmpoyees/EmployeesSlice"
import { SubscriptionType } from "../../../App/enums"
import HTTPService from "../../../App/HTTPService"
import { headers } from "../../../App/Axios"
import ArticleCard from "../../Shared/ArticleCard/ArticleCard"
import useWindowSize from "../../Shared/Hooks/useWindowSize"
import { arrayOfLength, isDefined } from "../../../App/Helpers/Helpers"
import { Room } from "../../../Types/Room"
import OrganizationDashboardButtons from "./OrganizationDashboardButtons"
import { setRoomSortOption } from "../../Shared/SharedSlices/RoomSlice"
import OrgaFeedItem from "../../Shared/Components/OrgaFeedItem"

function DashboardFeed(props: { hasArticleCard: boolean }) {
    const { id } = useParams()
    const translation = useSelector(selectTranslations)
    const organizationDataState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const rooms = useSelector((state: RootState) => state.roomSlice.roomsSlice.owned_rooms)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const screenWidth = useWindowSize()
    const breakpoint = 1023
    const [joinedRooms, setJoinedRooms] = useState<Room[]>([])
    const [ownedRooms, setOwnedRooms] = useState<Room[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const orgSortOption = useSelector((state: RootState) => state.roomSlice.orgRoomSortOptionSlice.sort_options)

    const GetOrganizationFeedItemRequest = useCallback(() => {
        return HTTPService.get(`/organization/${id}/rooms/posts/`, headers)
    }, [id])

    useEffect(() => {
        let isMounted = true
        if (isDefined(id)) {
            setIsLoading(true)
            GetOrganizationFeedItemRequest()
                .then(response => {
                    if (isMounted && response.status === 200) {
                        setJoinedRooms(response.data.organization_room.joined_rooms)
                        setOwnedRooms(response.data.organization_room.owned_rooms)
                        setIsLoading(false)

                        dispatch(setRoomSortOption(response.data.sort_options))
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }, [GetOrganizationFeedItemRequest, dispatch, id, orgSortOption])

    useEffect(() => {
        const searchPagePathnames = [`/search-screen/${id}`, `/search-screen`]
        if (!searchPagePathnames.includes(location.pathname)) {
            dispatch(setSearchQuery(""))
        }
    }, [dispatch, id, location.pathname])

    useEffect(() => {
        if (userDataState.id) {
            dispatch(GetOrganizationRequest(id, userDataState, navigate, location))
        }
    }, [dispatch, id, location, navigate, userDataState, userDataState.id])

    const roomLimitGetRequest = () => {
        return HTTPService.get(`/org/${id}/room-limit/`, headers)
    }

    const onRoomCreate = () => {
        if (organizationDataState.subscription_type === SubscriptionType.free && rooms!.length > 0) {
            dispatch(setModalData({ open: true, notPremiumCreateRoomModal: true }))
        } else {
            roomLimitGetRequest()
                .then(response => {
                    if (response.status === 200) {
                        dispatch(setModalData({ open: true, createRoom: true }))
                    }
                })
                .catch(error => {
                    if (error.response.status === 400) {
                        dispatch(setModalData({ open: true, roomLimitModal: { status: true, max_rooms: error.response.data.max_rooms } }))
                    }
                })
        }
    }
    const onSeeAllEventsClick = () => {
        switch (role) {
            case RoleType.ORGANIZATION:
                navigate(`/all-premium-events/${id}`)
                break
            case RoleType.USER:
                navigate(`/all-premium-events`)
                break
            default:
                break
        }
    }

    const hasNoOwnedRoomsPosts = arrayOfLength(ownedRooms).filter(postNumber => postNumber! > 0).length >= 1
    const hasJoinedRoomsNoPosts = arrayOfLength(joinedRooms).filter(postNumber => postNumber! > 0).length >= 1

    return (
        <div className="organization-dashboard-feed">
            <div className="organization-dashboard-feed__header">
                <div
                    className={
                        organizationDataState.name && organizationDataState.name.length < 25
                            ? `organization-dashboard-feed__text dashboard-feed__text--font-size-org dashboard-feed__text--font-weight-700 dashboard-feed__text--margin-bottom-06`
                            : `organization-dashboard-feed__text dashboard-feed__text--font-size-up-to-25-chars  dashboard-feed__text--font-weight-700 dashboard-feed__text--margin-bottom-06`
                    }
                >
                    {translation.halloTeamOf + " " + organizationDataState.name + ","}
                </div>
                <div className="organization-dashboard-feed__text dashboard-feed__text--font-size-18 dashboard-feed__text--font-weight-500 dashboard-feed__text--margin-bottom-27">
                    {translation.feedWelcomeText}
                </div>
                <OrganizationDashboardButtons />
            </div>
            <div className="organization-dashboard-feed__content">
                {ownedRooms!.length === 0 && !isLoading ? (
                    <div className="dashboard-feed__header dashboard-feed__header--no-content-card">
                        <p className="dashboard-feed__text dashboard-feed__text--width-90 dashboard-feed__text--font-weight-500 dashboard-feed__text--margin-top-1">
                            {translation.noOwnedRooms1}
                            <span className="dashboard-feed__text dashboard-feed__text--link" onClick={onRoomCreate}>
                                {translation.noOwnedRooms2}
                            </span>
                        </p>
                    </div>
                ) : !hasNoOwnedRoomsPosts && !isLoading ? (
                    <div className="dashboard-feed__header dashboard-feed__header--no-content-card">
                        <p className="dashboard-feed__text dashboard-feed__text--width-90 dashboard-feed__text--font-weight-500 dashboard-feed__text--margin-top-1">
                            {translation.noOwnedRooms1}
                            <span className="dashboard-feed__text dashboard-feed__text--link" onClick={onRoomCreate}>
                                {translation.noOwnedRooms2}
                            </span>
                        </p>
                    </div>
                ) : (
                    <OrgaFeedItem type={RoleType.ORGANIZATION} rooms={ownedRooms} isLoading={isLoading} />
                )}
                <div className="dashboard-feed__header">
                    <div className="organization-dashboard-feed__container">{translation.newPostsInOtherRooms} </div>
                </div>
                {joinedRooms!.length === 0 && !isLoading ? (
                    <div className="dashboard-feed__header dashboard-feed__header--no-content-card">
                        <p className="dashboard-feed__text dashboard-feed__text--width-90 dashboard-feed__text--font-weight-500 dashboard-feed__text--margin-top-1">
                            {translation.noJoinedRooms1}
                            <span className="dashboard-feed__text dashboard-feed__text--link" onClick={() => navigate(`/rooms/${id!}`)}>
                                {translation.roomSearch}
                            </span>
                            {translation.noJoinedRooms2}
                        </p>
                    </div>
                ) : !hasJoinedRoomsNoPosts && !isLoading ? (
                    <div className="dashboard-feed__header dashboard-feed__header--no-content-card">
                        <p className="dashboard-feed__text dashboard-feed__text--width-90 dashboard-feed__text--font-weight-500 dashboard-feed__text--margin-top-1">
                            {translation.noJoinedRooms1}
                            <span className="dashboard-feed__text dashboard-feed__text--link" onClick={() => navigate(`/rooms/${id!}`)}>
                                {translation.roomSearch}
                            </span>
                            {translation.noJoinedRooms2}
                        </p>
                    </div>
                ) : (
                    <OrgaFeedItem type={RoleType.ORGANIZATION} rooms={joinedRooms} isLoading={isLoading} />
                )}

                {props.hasArticleCard && screenWidth < breakpoint && <ArticleCard />}
                <div className="organization-dashboard-feed__sponsor-title ">
                    {translation.sponsoredText}
                    <div onClick={onSeeAllEventsClick} className="main-layout__sponsored-icon">
                        <SearchIcon color="dark-blue" />
                    </div>
                </div>
                <div className="organization-dashboard-feed__sponsor-card">
                    <SponsorCard />
                </div>
            </div>
        </div>
    )
}

export default DashboardFeed
