import { headers } from "../../../../App/Axios"
import HTTPService from "../../../../App/HTTPService"
import { User } from "../../../../Types/User"
import { setUserData } from "../../../Shared/SharedSlices/UserDataSlice"

export const patchUserDescription = (description: string, user: User) => async (dispatch: any) => {
    const UserDescription = {
        description: description,
    }
    try {
        const response = await HTTPService.patch(`/user/${user.id}/description/`, UserDescription, headers)
        if (response.status === 200) {
            dispatch(
                setUserData({
                    ...user,
                    description: response.data.description,
                })
            )
        }
    } catch (error: any) {
        console.log(error)
    }
}

export const patchUserEdusiiaReasons = (edusiia_reasons: string, user: User) => async (dispatch: any) => {
    const UserDescription = {
        edusiia_reasons: edusiia_reasons,
    }

    try {
        const response = await HTTPService.patch(`/user/${user.id}/description/`, UserDescription, headers)
        if (response.status === 200) {
            dispatch(
                setUserData({
                    ...user,
                    edusiia_reasons: response.data.edusiia_reasons,
                })
            )
        }
    } catch (error: any) {
        console.log(error)
    }
}
