import { useDispatch, useSelector } from "react-redux"
import { headers } from "../../../App/Axios"
import HTTPService from "../../../App/HTTPService"
import { useNavigate } from "react-router-dom"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { useEffect, useState } from "react"
import { SponsorResult } from "../../../Types/SponsorResult"
import { RootState } from "../../../App/store"
import { RoleType } from "../../../App/enums"
import { getOrganizationData } from "../../Organization/OrganizationDashboard/GetOrganizationServiceRequest"
import { setRoleStatusData } from "../SharedSlices/RolesSlice"
import SearchInput from "../Primitive/SearchInput"
import { setSearchQuery } from "../../Organization/OrganizationProfile/OrganizationProfileEmpoyees/EmployeesSlice"
import { LoadingAnimation } from "../Components/SvgIcons"
import UserCard from "../Components/UserCard"
import EventPhoto from "../../../App/assets/EventPhoto.jpg"
import { htmlRegex } from "../../../App/Regex"
import Button from "../Primitive/Button"
import { replaceHTMLCharactersWithCorrespondingLetters } from "../../../App/Helpers/Helpers"

function AllSponsoredEventsCard() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const translation = useSelector(selectTranslations)
    const [inputValue, setInputValue] = useState("")
    const [allEventsData, setAllEventsData] = useState<Array<SponsorResult>>([])
    const [searchedEventsData, setSearchedEventsData] = useState<Array<SponsorResult>>([])
    const [hasMoreEvents, setHasMoreEvents] = useState(false)
    const [hasMoreSearchEvents, setHasMoreSearchEvents] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingMoreEvents, setIsLoadingMoreEvents] = useState(false)
    const [pageNum, setPageNum] = useState(1)
    const [searchPageNum, setSearchPageNum] = useState(1)
    const role = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)

    const getEventsData = (pageNumber: number) => {
        return HTTPService.get(`/events/?page=${pageNumber}`, headers)
    }

    const getSearchEventsData = (searchedQuery: string, pageNumber: number) => {
        return HTTPService.get(`events/?search=${searchedQuery}`, headers)
    }

    const onRemoveIconClick = () => {
        setInputValue("")
        dispatch(setSearchQuery(""))
    }

    useEffect(() => {
        getEventsData(1)
            .then(response => {
                if (response.status === 200) {
                    setAllEventsData(response.data)
                    setHasMoreEvents(response.data.next)
                    setIsLoading(false)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    const onSeeMoreEventsClick = () => {
        setIsLoadingMoreEvents(true)
        if (inputValue.length > 1) {
            getSearchEventsData(inputValue, searchPageNum + 1)
                .then(response => {
                    if (response.status === 200) {
                        setSearchedEventsData([...searchedEventsData, ...response.data.results])
                        setHasMoreSearchEvents(response.data.next)
                        setSearchPageNum(searchPageNum + 1)
                        setIsLoadingMoreEvents(false)
                    }
                })
                .catch(error => {
                    console.log(error)
                    setSearchPageNum(1)
                })
        } else {
            getEventsData(pageNum + 1)
                .then(response => {
                    if (response.status === 200) {
                        setAllEventsData([...allEventsData, ...response.data.results])
                        setHasMoreEvents(response.data.next)
                        setPageNum(pageNum + 1)
                        setIsLoadingMoreEvents(false)
                    }
                })
                .catch(error => {
                    console.log(error)
                    setPageNum(1)
                })
        }
    }

    const showButton = () => {
        if (inputValue?.length > 0 && searchedEventsData?.length > 0 && hasMoreSearchEvents) {
            return true
        } else if (inputValue?.length === 0 && allEventsData?.length > 0 && hasMoreEvents) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if (inputValue.length > 2) {
            const getData = setTimeout(() => {
                setIsLoading(true)
                getSearchEventsData(`${inputValue}`, 1)
                    .then(response => {
                        if (response.status === 200) {
                            setSearchedEventsData(response.data)
                            setHasMoreSearchEvents(response.data.next)
                            setSearchPageNum(1)
                            setPageNum(1)
                            setIsLoading(false)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }, 500)

            return () => clearTimeout(getData)
        }
    }, [inputValue])

    const onSponsorClick = (orgId: number, eventId: number) => {
        switch (role) {
            case RoleType.ORGANIZATION:
                navigate(`/organization-profile/${orgId!}/event/${eventId}`)
                window.scrollTo(0, 0)
                break
            case RoleType.USER:
                navigate(`/organization-profile/${orgId!}/event/${eventId}`)
                getOrganizationData(orgId.toString()).then(response => {
                    if (response.status === 200) {
                        if (response.data.is_admin) {
                            dispatch(setRoleStatusData(true))
                            window.scrollTo(0, 0)
                        } else {
                            navigate(`/organization-profile/${orgId!}/event/${eventId}`)
                            window.scrollTo(0, 0)
                        }
                    }
                })

                break
            default:
                break
        }
    }
    return (
        <div className="all-rooms-card">
            <p className="all-rooms-card__title"> {translation.seeAllEvents}</p>
            <div className="all-rooms-card__search-input">
                <SearchInput
                    onRemoveIconClick={onRemoveIconClick}
                    searchvalue={inputValue!}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setInputValue(event.target.value)
                    }}
                    isGlobalSearch={false}
                />
            </div>
            {isLoading ? (
                <div className="rooms-card__loading-container">
                    {" "}
                    <LoadingAnimation type="cover" />
                </div>
            ) : (
                <div className="rooms-card__container">
                    {inputValue.length > 0 && searchedEventsData?.length === 0 ? (
                        <div className="rooms-card__no-data-text">{`${translation.noResultThatContain} '${inputValue}' ${translation.wasFound}`}</div>
                    ) : (
                        (inputValue.length > 0 && searchedEventsData?.length > 0 ? searchedEventsData : allEventsData)?.map(event => {
                            return (
                                <div key={event.id} className={`rooms-card-wrapper`} onClick={() => onSponsorClick(event.organization?.id!, event.id!)}>
                                    <div className={`rooms-card`}>
                                        <UserCard
                                            imageSrc={event.event_photo || EventPhoto}
                                            text={event.title}
                                            subtext={translation.by + " " + event?.organization?.name!}
                                            userCardStyle={"rooms-card-content"}
                                            leftPanelStyle={"left-panel"}
                                            middlePanelStyle={"middle-panel"}
                                            profileImageStyle={"image image__profile image__profile--fit image__profile--size-small"}
                                            textStyle={"text rooms-card-content__text--blue"}
                                            textStyleMore25={"textMore25 rooms-card-content__textMore25--blue"}
                                            subtextStyle={"subtext"}
                                            textStyleContainer={"text-container"}
                                            subtextStyleContainer={"subtext-container"}
                                        />
                                    </div>

                                    <p className="rooms-card__event-description">
                                        {replaceHTMLCharactersWithCorrespondingLetters(
                                            event
                                                ?.description!.replaceAll(/&nbsp;/g, " ")
                                                .replace(htmlRegex, "")
                                                .replaceAll(/&nbsp/g, " ")
                                        )}
                                    </p>
                                </div>
                            )
                        })
                    )}
                    {isLoadingMoreEvents && (
                        <div className="rooms-card__container">
                            <LoadingAnimation type="profile" />
                        </div>
                    )}
                    {showButton() && (
                        <div className="user-profile-experience__button-container">
                            <div className="user-profile-experience__button-size">
                                <Button
                                    onClick={onSeeMoreEventsClick}
                                    className="button button--border-radius-09 button--color-grey button--hover-darker button--cursor-pointer button--text-color-grey button--font-size-small button--font-weight-400  "
                                >
                                    <div className="user-profile-experience__button-content"> {translation.seeMore}</div>
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default AllSponsoredEventsCard
