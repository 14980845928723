import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectTranslations } from "../../../App/i18n/i18nSlice"
import { RootState } from "../../../App/store"
import Button from "../../Shared/Primitive/Button"
import TextInput from "../../Shared/Primitive/TextInput"
import DoubleTextInput from "../../Shared/Components/DoubleTextInput"
import SingleTextInput from "../../Shared/Components/SingleTextInput"
import "../../../Styles/main.scss"
import { setModalData } from "../../Shared/SharedSlices/ModalSlice"
import { patchAllUserData } from "./UserSettingsProfileServiceRequests"
import { PasswordConfirmationEnum } from "../../../App/enums"
import HashtagInput from "../../Shared/Components/HashtagInput"
import useWindowSize from "../../Shared/Hooks/useWindowSize"
import SearchableDropdown from "../../Shared/Components/SearchableDropdown"

function UserSettingsProfile() {
    const translation = useSelector(selectTranslations)
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const [firstName, setFirstName] = useState(userDataState.first_name)
    const [lastName, setLastName] = useState(userDataState.last_name)
    const [location_state, setLocationState] = useState(userDataState.location_state != null ? userDataState.location_state.id : 0)
    const [email, setEmail] = useState(userDataState.email)
    const [newPassword, setNewPassword] = useState("")
    const [repeatPassword, setRepeatPassword] = useState("")
    const [location, setLocation] = useState(userDataState.location || "")
    const [hashtags, setHashtags] = useState<string[]>(userDataState.hashtags || [])
    const dispatch = useDispatch()
    const settingsProfileError = useSelector((state: RootState) => state.userSettingsProfileSlice)
    const screenWidth = useWindowSize()
    const breakpoint = 768
    const [userStateFieldError, setUserStateFieldError] = useState("")

    const onFormSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        const data = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: newPassword,
            confirm_password: repeatPassword,
            location: location,
            location_state_id: location_state,
            hashtag_list: hashtags,
        }
        if (email !== userDataState.email || (newPassword.length !== 0 && repeatPassword.length !== 0)) {
            dispatch(setModalData({ open: true, userConfirmPasswordModalState: { status: true, setData: data, type: PasswordConfirmationEnum.user_email } }))
        } else {
            dispatch(patchAllUserData(data, userDataState))
        }
    }

    return (
        
        <div className="settings-profile">
            <div className="settings-profile__form">
                <DoubleTextInput
                    firstTitle={translation.firstName}
                    secondTitle={translation.lastName}
                    firstTextInput={
                        <TextInput
                            type="text"
                            textinputsize="extra-small"
                            placeholder={translation.name}
                            name="name"
                            value={firstName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value)}
                            autoComplete="off"
                        />
                    }
                    firstErrorText={settingsProfileError.message.first_name}
                    secondTextInput={
                        <TextInput
                            type="text"
                            textinputsize="extra-small"
                            placeholder={translation.surname}
                            name="surname"
                            value={lastName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLastName(event.target.value)}
                            autoComplete="off"
                        />
                    }
                    secondErrorText={settingsProfileError.message.last_name}
                />

                <SingleTextInput
                    title={translation.hashtags}
                    hasTooltip={true}
                    textInput={<HashtagInput hashtags={hashtags} setHashtags={setHashtags} />}
                    errorText={""}
                    tooltipContent={
                        <p>
                            {translation.hashtagTooltip}
                            <br />
                            {translation.hashtagTooltip2}
                            <br />
                            {translation.hashtagTooltip3}
                        </p>
                    }
                />
                <SingleTextInput
                    title={translation.email}
                    hasTooltip={true}
                    tooltipContent={
                        <p>
                            {translation.contactInfoTooltip} <br /> {translation.contactInfoTooltip2} <br /> {translation.contactInfoTooltip3}
                            <br /> {translation.contactInfoTooltip4}
                        </p>
                    }
                    textInput={
                        <TextInput
                            type="text"
                            textinputsize="large-40"
                            placeholder={translation.email}
                            name="email"
                            value={email}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                            autoComplete="off"
                        />
                    }
                    errorText={settingsProfileError.message.email}
                />
                <DoubleTextInput
                    firstTitle={translation.newSecurePassword}
                    secondTitle={translation.repeatNewSecurePassword}
                    firstTextInput={
                        <TextInput
                            type="password"
                            textinputsize="extra-small"
                            placeholder={translation.newPassword}
                            name="newPassword"
                            value={newPassword}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value)}
                            autoComplete="new-password"
                        />
                    }
                    firstErrorText={settingsProfileError.message.password}
                    secondTextInput={
                        <TextInput
                            type="password"
                            textinputsize="extra-small"
                            placeholder={translation.repeatPassword}
                            name="repeatPassword"
                            value={repeatPassword}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRepeatPassword(event.target.value)}
                            autoComplete="new-password"
                        />
                    }
                    secondErrorText={settingsProfileError.message.confirm_password || settingsProfileError.message.non_field_errors}
                />

                <SearchableDropdown inSettingsModal={true} hasTooltip={true}
                    tooltipContent={
                        <p>
                            {translation.locationTooltip} <br /> {translation.locationTooltip2} <br /> {translation.locationTooltip3}
                            <br /> {translation.locationTooltip4}
                        </p>
                    } title={translation.location} validationError={userStateFieldError} itemId={location_state} setItemId={setLocationState}/>
            </div>
            <div className="settings-profile__button-container">
                <div className="settings-profile__button">
                    <Button
                        className={`button ${
                            screenWidth > breakpoint && "button--border-radius-05"
                        } button--color-gold button--hover-brighter button--cursor-pointer button--text-color-white button--font-size-small button--font-weight-400 `}
                        onClick={onFormSubmit}
                    >
                        {translation.save}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default UserSettingsProfile
