import React from "react"
import "../../../Styles/main.scss"

function ToggleSwitch(props: React.AllHTMLAttributes<HTMLInputElement>) {
    return (
        <label className="switch">
            <input className="input" type="checkbox" {...props} />
            <span className="slider"></span>
        </label>
    )
}

export default ToggleSwitch
