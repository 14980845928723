import Image from "../../../Shared/Primitive/Image"
import { CloseIcon, CommentIcon, CopyIcon, LoadingAnimation, SendIcon, ShareIcon } from "../../../Shared/Components/SvgIcons"
import { RootState } from "../../../../App/store"
import { useDispatch, useSelector } from "react-redux"
import { setModalData } from "../../../Shared/SharedSlices/ModalSlice"
import Comments from "../../../Shared/Components/Comments"
import { organizationProfileDefault, postMaxLength, profileDefault } from "../../../../App/GlobaleVariables"
import { CallerComponent, CommentType, EditorModifier, RoleType } from "../../../../App/enums"
import { useEffect, useRef, useState } from "react"
import HTTPService from "../../../../App/HTTPService"
import { headers } from "../../../../App/Axios"
import { getEventStatus, setSingularEventData } from "../../../Shared/SharedSlices/SponsorCardSlice"
import { selectTranslations } from "../../../../App/i18n/i18nSlice"
import { ConvertStringToHTML } from "../../../../App/Helpers/Helpers"
import { setPinboardId } from "../OrganizationProfilePinboardSlice"
import CharacterCounter from "../../../Shared/Components/CharacterCounter"
import { Editor as TinyMCEEditor } from "tinymce"
import EditorTextArea from "../../../Shared/Components/Editor/EditorTextArea"
import { MentionedUsersType } from "../../../../Types/RoomPosts"
import MessageBody from "../../../Shared/Components/MessageBody"
import { setPostContentErrorState } from "../../../Room/RoomPost/RoomPostSlice"

function OrganizationProfilePinboardCommentModal() {
    const pinboardIdState = useSelector((state: RootState) => state.pinboardIdSlice.id)
    const isYourOrganization = useSelector((state: RootState) => state.pinboardIdSlice.isYourOrganization)
    const dispatch = useDispatch()
    const userDataState = useSelector((state: RootState) => state.userDataSlice)
    const roleState = useSelector((state: RootState) => state.rolesSlice.RolesSlice.roles)
    const pinboardEvent = useSelector((state: RootState) => state.sponsorSlice.SingularEventSlice.event)
    const organizationState = useSelector((state: RootState) => state.organizationsSlice.organizationSlice.organization)
    const [content, setContent] = useState<string>("")
    const [inputState, setInputState] = useState<string>("")
    const translation = useSelector(selectTranslations)
    const editorRef = useRef<TinyMCEEditor | null>(null)
    const [isLoading, setIsLoading] = useState(true)
    const [makeEventRequest, setMakeEventRequest] = useState(true)
    const contentErrorStatus = useSelector((state: RootState) => state.RoomPostsSlice.roomPostContentErrorSlice.message)

    const closeModal = () => {
        dispatch(setModalData({ open: false, pinboardCommentsModal: false }))
    }

    const singularEventGetRequest = (eventId: number) => {
        return HTTPService.get(`/event/${eventId}/`, headers)
    }

    useEffect(() => {
        let isMounted = true
        if (makeEventRequest) {
            singularEventGetRequest(pinboardIdState)
                .then(response => {
                    if (isMounted && response.status === 200) {
                        dispatch(setSingularEventData(response.data))
                        setIsLoading(false)
                        setMakeEventRequest(false)
                        dispatch(setPostContentErrorState(""))
                    }
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        dispatch(setModalData({ open: true, pinboardCommentsModal: false, deletedEventPopUp: true }))
                        setIsLoading(false)
                    }
                })
            return () => {
                isMounted = false
            }
        }
    }, [dispatch, makeEventRequest, pinboardIdState])

    const onInviteLinkModalOpen = () => {
        dispatch(setModalData({ open: false, pinboardCommentsModal: false }))
        dispatch(setModalData({ open: true, eventLinkModal: true }))
        dispatch(setPinboardId({ id: pinboardIdState, isYourOrganization: isYourOrganization }))
    }

    const onShareEventToRoomModalOpen = () => {
        dispatch(setModalData({ open: false, pinboardCommentsModal: false }))
        dispatch(setModalData({ open: true, shareEventToRoomModal: true }))
        dispatch(setPinboardId({ id: pinboardIdState, isYourOrganization: isYourOrganization }))
    }

    const eventCommentPostRequest = async (data: FormData) => {
        try {
            const response = await HTTPService.post(`/event-comment/`, data, headers)
            if (response.status === 201) {
                dispatch(setSingularEventData({ ...pinboardEvent, comments: [response.data, ...pinboardEvent.comments!] }))
                dispatch(getEventStatus(true))
                setMakeEventRequest(true)
                setInputState("")
                editorRef.current!.setContent("")
                dispatch(setPostContentErrorState(""))
            }
        } catch (error: any) {
            if (error.response?.status === 400) {
                dispatch(setPostContentErrorState(error.response.data.content[0]))
            }
            console.log(error)
        }
    }

    const onEnterPress = (event: { preventDefault: () => void }) => {
        event.preventDefault()
        const eventRoleId = roleState === RoleType.ORGANIZATION ? organizationState.id : userDataState.id

        const mentionedUsersArray: MentionedUsersType[] = []
        let modifiedContent = ""
        if (editorRef.current!) {
            const htmlContent = ConvertStringToHTML(editorRef!.current!.getContent())
            const spans = htmlContent.querySelectorAll("[data-type]")

            spans.forEach((span, index) => {
                span.innerHTML = "@tagged"
                mentionedUsersArray.splice(index, 0, {
                    tagged_user: span.getAttribute("data-type") === RoleType.USER.toLocaleLowerCase() ? parseInt(span.id) : null,
                    tagged_org: span.getAttribute("data-type") === RoleType.ORGANIZATION.toLocaleLowerCase() ? parseInt(span.id) : null,
                })
                modifiedContent = htmlContent.outerHTML.replaceAll("<body>", "").replaceAll("</body>", "")
            })

            if (mentionedUsersArray.length >= 1 || editorRef.current!.getContent({ format: "text" }).length > 0) {
                const formData = new FormData()

                formData.append("content", modifiedContent.length > 0 ? modifiedContent : editorRef!.current!.getContent())
                formData.append("content_type", roleState.toLowerCase())
                formData.append("object_id", eventRoleId?.toString()!)
                formData.append("tags", JSON.stringify(mentionedUsersArray))
                formData.append("event", pinboardIdState?.toString()!)

                eventCommentPostRequest(formData)
            }
        }
    }

    return (
        <div className="pinboard-comments">
            <div className="pinboard-comments__container">
                <div className="pinboard-comments__close-icon" onClick={closeModal}>
                    <CloseIcon />
                </div>
                {isLoading ? (
                    <div className="pinboard-comments__loading-container">
                        <LoadingAnimation />
                    </div>
                ) : (
                    <div className="pinboard-comments__content">
                        <div className="pinboard-comments__organization-container">
                            <Image
                                src={pinboardEvent?.organization?.photo?.url_path || organizationProfileDefault}
                                className="image image__profile image__profile--size-medium-5"
                            />
                            <div className="pinboard-comments__name">{pinboardEvent?.organization?.name}</div>
                        </div>
                        <div className="pinboard-comments__title-container">
                            <div className="pinboard-comments__title"> {pinboardEvent?.title}</div>
                        </div>
                        {pinboardEvent?.event_photo ? (
                            <div className="pinboard-comments__event-content">
                                <div className="pinboard-comments__text">
                                    <MessageBody
                                        text={pinboardEvent?.description!}
                                        showPreview={false}
                                        showCollapsed={false}
                                        showRemovePreviewIcon={false}
                                        hashtags={pinboardEvent!.hashtags!}
                                        caller={CallerComponent.eventDescription}
                                    />
                                </div>
                                <div className="pinboard-comments__event-image">
                                    <Image src={pinboardEvent?.event_photo} className="pinboard-comments__image" />
                                </div>
                            </div>
                        ) : (
                            <div className="pinboard-comments__description">
                                <MessageBody
                                    text={pinboardEvent?.description!}
                                    showPreview={false}
                                    showCollapsed={false}
                                    showRemovePreviewIcon={false}
                                    hashtags={pinboardEvent!.hashtags!}
                                    caller={CallerComponent.eventDescription}
                                />
                            </div>
                        )}

                        <div className="pinboard-comments__icons-container">
                            <div className="pinboard-comments__icons">
                                <div className="pinboard-comments__edit">
                                    <CommentIcon />
                                </div>
                                {isYourOrganization && (
                                    <div className="pinboard-comments__edit" onClick={onShareEventToRoomModalOpen}>
                                        <ShareIcon color="grey" strokeWidth="3" size="size-big" />
                                    </div>
                                )}
                                <div className="pinboard-comments__edit" onClick={onInviteLinkModalOpen}>
                                    <CopyIcon />
                                </div>
                            </div>
                            <div className="pinboard-comments__answers">
                                {pinboardEvent.comment_count === 1
                                    ? +pinboardEvent.comment_count + " " + translation.comment
                                    : pinboardEvent.comment_count + " " + translation.comments}{" "}
                            </div>
                        </div>

                        <div className="pinboard-comments__comments">
                            <div className="pinboard-comments__comment-container">
                                <Image
                                    src={
                                        roleState === RoleType.ORGANIZATION
                                            ? organizationState.photo?.url_path || organizationProfileDefault
                                            : userDataState.profile_photo?.url_path || profileDefault
                                    }
                                    className="image image__profile image__profile--size-small image__profile--margin-top-03"
                                />
                                <div className="pinboard-comments__text-area-container">
                                    <EditorTextArea
                                        inputState={inputState}
                                        setInputState={setInputState}
                                        editorRef={editorRef}
                                        editorPlaceholder={translation.addAComment}
                                        setContent={setContent}
                                        editorHeight={62}
                                        modifier={EditorModifier.eventCommentTextArea}
                                        initialEditorValue=""
                                        enterPressed={() => {}}
                                    />
                                    <CharacterCounter current={content.length} max={postMaxLength} />

                                    {content.length > 0 && content.length < postMaxLength + 1 ? (
                                        <div className="pinboard-comments__send-icon" onClick={onEnterPress}>
                                            <SendIcon color="green" />
                                        </div>
                                    ) : (
                                        <div className="pinboard-comments__send-icon-disabled">
                                            <SendIcon color="grey" />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {contentErrorStatus.length > 0 && <p className="room-layout__error-message">{contentErrorStatus}</p>}
                            <Comments commentsarray={pinboardEvent?.comments!} type={CommentType.event} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default OrganizationProfilePinboardCommentModal
